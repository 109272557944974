import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import CallMade from "@mui/icons-material/CallMade";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Grid, Tooltip } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { setUploadedFileURLAction } from "./proposal.slice";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { useUploadProposalFile } from "./Raise-Claim/service";
import { useGetConditionalErrorMessage } from "../../hooks/useGetConditionalErrorMessage";
import InlineLoader from "./InlineLoader";
import { LuFilePlus2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import { setfileIcon } from "../MyDocuments/Documenthelper";
import { BiSolidFilePdf } from "react-icons/bi";
import { BsCloudArrowUp } from "react-icons/bs";
import { VscEye } from "react-icons/vsc";
import VisibilityIcon from "@mui/icons-material/Visibility";
const FilePicker = ({
  label,
  id,
  isRequired,
  files,
  setFiles,
  setValue,
  trigger,
  description,
  shouldHideRemoveButton,
  allowOnlyImages,
  acceptedFormatTextAndMode,
  disableInstantUpload,
  errors,
  prefillName,
  prefillUrl,
  proposalRead,
  register,
  itemData,
  unregister,
  claim,
  selectdocumenttoUpload,
}) => {
  console.log("selectdocumenttoUpload", selectdocumenttoUpload);
  const { errorMessage } = useGetConditionalErrorMessage({
    errors,
    id,
  });
  const [leadFilePreview, setleadFilePreview] = useState();
  useEffect(() => {
    if (prefillUrl) {
      setleadFilePreview(prefillUrl);
    }
  }, [prefillUrl]);

  const {
    data: fileURL,
    mutate: uploadProposalFile,
    isLoading: isUploadProposalFileLoading,
    isError,
  } = useUploadProposalFile();
  const { uploadedFileURL } = useSelector((state) => state.proposalConfig);
  const dispatch = useDispatch();
  useEffect(() => {
    if (fileURL?.data.data?.url && fileURL?.data.data?.fieldSlug) {
      let fieldSlug = fileURL?.data.data?.fieldSlug;
      let url = fileURL?.data.data?.url;
      dispatch(setUploadedFileURLAction({ [fieldSlug]: url }));
    }
  }, [fileURL]);
  // useEffect(() => {
  //   if (selectdocumenttoUpload) {
  //     selectdocumenttoUpload?.map((item) => {
  //       uploadedFileURL !== item?.key &&
  //         dispatch(setUploadedFileURLAction({ [item?.key]: null }));
  //     });
  //   }
  // }, [selectdocumenttoUpload?.length]);
  useEffect(() => {
    if (isError) {
      Swal.fire({
        text: "File Upload Failed",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [isError]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      ...(!allowOnlyImages && { "application/pdf": [".pdf"] }),
    },
    onDrop: async (acceptedFiles, rejectedFiles) => {
      if (!_.isEmpty(rejectedFiles)) {
        Swal.fire({
          text: "Only JPG, JPEG, PNG, and PDF files are allowed",
          icon: "error",
        });
      } else {
        setFiles({ ...files, [id]: acceptedFiles?.[0] });
        setValue(`${id}`, acceptedFiles?.[0]);
        dispatch(setUploadedFileURLAction({ [id]: acceptedFiles?.[0] }));
        if (!disableInstantUpload) {
          const formData = new FormData();
          formData.append("file", acceptedFiles?.[0]);
          formData.append("field_slug", id);
          uploadProposalFile(formData);
        }
        if (isRequired) {
          trigger([id]);
        }
      }
    },
  });
  const handleRealFilePreview = (id) => {
    if (uploadedFileURL?.[id] && !disableInstantUpload) {
      window.open(uploadedFileURL?.[id], "_blank");
    }
    if (uploadedFileURL?.[id]?.path && disableInstantUpload) {
      window.open(URL.createObjectURL(uploadedFileURL?.[id]), "_blank");
    }
  };
  const handleRemoveFile = (id) => {
    if (leadFilePreview) {
      setleadFilePreview("");
      unregister(id);
    }
    setValue(id, null);
    const newFiles = { ...files };
    delete newFiles?.[id];
    setFiles(newFiles);
    console.log(`id`, id);
    dispatch(setUploadedFileURLAction({ [id]: null }));
  };

  console.log(`uploadedFileURL?.[${id}]`, uploadedFileURL?.[id]);
  return (
    <FilePickerContainer claim={claim}>
      {/* Made changes as per Git Id: 8457 */}
      <p className="file_label" title={label}>
        {label}
        {acceptedFormatTextAndMode && !acceptedFormatTextAndMode?.viewMode && (
          <>
            <br />
            Accepted Format :{" "}
            <span className="isRequired">
              {acceptedFormatTextAndMode?.title}
            </span>
            <br />
            {/* Max Size :<span className="isRequired">{"1 MB"}</span> */}
          </>
        )}
        {isRequired && !proposalRead && <span className="isRequired">*</span>}
        {description && (
          <Tooltip title={description} arrow placement="top">
            <InfoOutlinedIcon
              sx={{
                fontSize: "12px",
                marginLeft: "3px",
                position: "relative",
                top: "1px",
              }}
            />
            {/* <InfoIcon sx={{ fontSize: "12px", marginLeft: "3px" }} /> */}
          </Tooltip>
        )}
      </p>
      <FilePickerStyled height={true}>
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              ...(proposalRead && { pointerEvents: "none" }),
            }}
          >
            {!shouldHideRemoveButton ? (
              <div>
                <div
                  {...getRootProps()}
                  className="dropzone upload_action_button"
                >
                  <input {...getInputProps()} />
                  {uploadedFileURL?.[id] || leadFilePreview ? (
                    // (fileURL?.data?.data?.url || uploadedFileURL?.[id])
                    (uploadedFileURL?.[id]?.path || uploadedFileURL?.[id])
                      ?.split(".")
                      ?.at(-1) === "pdf" ? (
                      <BiSolidFilePdf
                        style={{
                          fontSize: "25px",
                          color: "#CB0606",
                        }}
                      />
                    ) : (
                      <img
                        style={{ objectFit: "contain", padding: "5px" }}
                        // src={fileURL?.data?.data?.url || uploadedFileURL?.[id]}
                        src={uploadedFileURL?.[id]}
                      />
                    )
                  ) : (
                    <>
                      <BsCloudArrowUp />
                    </>
                  )}
                  <p className="upload_text">
                    {isUploadProposalFileLoading ? (
                      <>
                        Uploading
                        <InlineLoader />
                      </>
                    ) : uploadedFileURL?.[id] || leadFilePreview ? (
                      // (fileURL?.data?.data?.url || uploadedFileURL?.[id])
                      (uploadedFileURL?.[id]?.path || uploadedFileURL?.[id])
                        ?.split("/")
                        ?.at(-1)
                        ?.slice(0, 10) + "..."
                    ) : (
                      "Click to upload"
                    )}
                  </p>
                  {(uploadedFileURL?.[id] || leadFilePreview) && (
                    <Tooltip arrow title="Preview">
                      <VisibilityIcon
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() =>
                          uploadedFileURL?.[id]
                            ? handleRealFilePreview(id)
                            : window.open(leadFilePreview, "_blank")
                        }
                      />
                    </Tooltip>
                  )}
                </div>
                {errorMessage && <Error>{errorMessage}</Error>}
              </div>
            ) : (
              <div className="dropzone upload_action_button">
                <BackupOutlinedIcon />
                <p className="upload_text">{"Uploaded File"}</p>
              </div>
            )}
            {(uploadedFileURL?.[id] || leadFilePreview) && (
              <div className="delete-file">
                <RxCrossCircled onClick={() => handleRemoveFile(id)} />
              </div>
            )}
          </div>
          {/* {uploadedFileURL?.[id] || leadFilePreview ? (
            <>
              <Tooltip arrow title="Preview">
                <div>
                  <div className="dropzone view_delete_action_button">
                    <PreviewOutlinedIcon
                      onClick={() =>
                        uploadedFileURL?.[id]
                          ? handleRealFilePreview(id)
                          : window.open(leadFilePreview, "_blank")
                      }
                    ></PreviewOutlinedIcon>
                  </div>
                </div>
              </Tooltip>
              {!shouldHideRemoveButton && !proposalRead && (
                <Tooltip arrow title="Delete">
                  <div>
                    <div className="dropzone view_delete_action_button">
                      <DeleteForeverOutlinedIcon
                        onClick={() => handleRemoveFile(id)}
                      ></DeleteForeverOutlinedIcon>
                    </div>
                  </div>
                </Tooltip>
              )}
              {!uploadedFileURL?.[id] && (
                <input
                  type="hidden"
                  {...register(prefillName)}
                  value={JSON.stringify({
                    documentManagementConfigurationId: itemData?.value,
                    documentName: itemData?.label,
                    documentPath: leadFilePreview,
                  })}
                ></input>
              )}
            </>
          ) : (
            <></>
          )} */}
        </div>
      </FilePickerStyled>
    </FilePickerContainer>
  );
};
export default FilePicker;
const RealPreviewStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-left: 20px;
`;
const FilePickerContainer = styled.div`
  margin-bottom: 10px;
  .isRequired {
    color: red;
    margin-left: 3px;
  }
  .file_label {
    margin: 0;
    padding: 0;
    color: ${({ theme, claim }) => theme?.primaryColor};
    padding-left: 1px;
    font-size: 12px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 6px;
  }
`;
const FilePickerStyled = styled.div`
  margin-bottom: 10px;
  .upload_action_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    padding: 7px 12px;
    background: #ffffff;
    border-radius: 10px;
    color: #3276e8;
    // font-size: 21px;
    font-weight: 500;
    height: ${({ height }) => (height ? "32px" : "56px")};
    white-space: ${({ height }) => height && "nowrap"};
    border: 2px solid #2222221a;

    svg {
      width: ${({ height }) => !height && "32px"};
      height: ${({ height }) => !height && "32px"};
    }

    img {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
  }
  .view_delete_action_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    padding: 0 5px;
    background: #fff;
    border-radius: 4px;
    color: rgba(17, 85, 95, 1);
    font-size: 16px;
    font-weight: 500;
    height: 95%;
    white-space: nowrap;
    outline: 1px solid rgba(17, 85, 95, 1);
  }
  .upload_text {
    margin: 4px;
    display: flex;
    font-size: ${({ height }) => (height ? "13px" : "14px")};
    font-weight: 500;
    line-height: 18.23px;
    color: #222222;
  }

  .delete-file {
    position: absolute;
    right: -5px;
    top: 24px;
    cursor: pointer;
  }
`;

const Error = styled.p`
  margin-bottom: 0;
  margin-top: ${({ top }) => top || "0px"};
  margin-left: ${({ left }) => left || "0px"};
  font-size: ${({ theme: { fontSize } }) =>
    fontSize ? `calc(13px + ${fontSize - 92}%)` : "10px"};
  text-align: left;
  color: ${({ color }) => color || "red"};

  font-weight: 500;
  bottom: ${({ bottom }) => bottom || "0px"};
  margin: 0;
`;
