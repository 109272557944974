export const endorsementStageObj = [
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "In Progress",
    value: "InProgress",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
];
