import React, { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useAddRiskometer, useRiskometer } from "./service";
import { useLocation, useNavigate } from "react-router-dom";
import { saveData } from "./riskoMeterMember.slice";
import Swal from "sweetalert2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const RiskometerFamilyHistory = ({ handleSave, handleBack, activeTab }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutateAsync: ageRiskometer } = useRiskometer(activeTab);
  const { mutate: addRisko } = useAddRiskometer();
  const { coverageAmountPayload, resData, allData, range } = useSelector(
    (state) => state.riskoMeterMember
  );
  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge)?.length > 1;

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (!selectedOption) return;

    const payload = {
      questionId: 7,
      lobId: +lobId,
      request_data: {
        familyType: coverageAmountPayload?.request_data?.familyType,
        option: selectedOption,
      },
    };

    dispatch(
      saveData({
        medicalHistory: {
          selectedOption,
        },
      })
    );

    const rangeRating =
      range.reduce((acc, item) => acc + item, 0) / range.length;

    const AddRiskoPayload = {
      lobId: +lobId,
      ratings: rangeRating,
    };

    if (isSelfAgeOnly) {
      addRisko(AddRiskoPayload);
    }

    ageRiskometer(payload);

    if (isMoreThanOne) {
      setIsModalOpen(true);
    }
  };

  const handleNavigate = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to navigate to the dashboard?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DC004E",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, navigate!",
      cancelButtonText: "No, stay here",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
        window.location.reload();
      } else {
        handleSave();
      }
    });
  };

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    // Set color for maximum value label
    axis.renderer.labels.template.adapter.add("fill", (fill, target) => {
      if (target.dataItem && target.dataItem.value === 20) {
        return am4core.color("#FFF"); // Change color to black for max value
      }
      return fill;
    });

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={handleBack}>
            <ArrowBackIcon />
          </BackButton>
        </div>
        <Title>Family History</Title>
        <Subtitle>
          Is there any family history of chronic health conditions?
        </Subtitle>
        <form>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "16px",
            }}
          >
            {[
              "No Known Issues",
              "Minor Health Issues in Family",
              "Chronic Conditions in Family",
              "Serious Health Conditions in Family",
              "Critical Health Issues in Family",
            ].map((option, index) => (
              <OptionContainer
                key={index}
                isSelected={selectedOption === option}
                onClick={() => setSelectedOption(option)}
              >
                <input
                  type="radio"
                  id={`option${index}`}
                  name="familyHistory"
                  style={{ display: "none" }}
                  checked={selectedOption === option}
                  onChange={() => setSelectedOption(option)}
                />
                <label htmlFor={`option${index}`} style={{ fontSize: "16px" }}>
                  {option}
                </label>
              </OptionContainer>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "25px",
              justifyContent: "space-around",
            }}
          >
            <AddButton type="button" onClick={onSubmit}>
              Submit
            </AddButton>
            {isSelfAgeOnly && !isMoreThanOne && (
              <NavigateButton type="button" onClick={handleNavigate}>
                <ArrowBackIcon /> Navigate to Dashboard
              </NavigateButton>
            )}
          </div>
        </form>
      </Container>
      {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />}

      {/* Dialog (Modal) to display the chart */}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Riskometer Chart</DialogTitle>
        <DialogContent>
          <ChartContainer id="chartdiv" />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          <NavigateButton type="button" onClick={handleNavigate}>
            <ArrowBackIcon /> Navigate to Dashboard
          </NavigateButton>
          <AddButton onClick={() => setIsModalOpen(false)} color="primary">
            Close
          </AddButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RiskometerFamilyHistory;

const Container = styled.div`
  padding: 24px;
  width: 125vw;
  max-width: 800px;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: -20px;
`;

const Subtitle = styled.h3`
  text-align: center;
  font-size: 18px;
  padding-top: 30px;
`;

const OptionContainer = styled.div`
  border: ${({ isSelected, theme }) =>
    isSelected ? `1px solid ${theme?.ascentColor}` : "1px solid #e0e0e0"};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? `${theme.lightAscentColor}` : "white"};
  min-width: 240px;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const NavigateButton = styled.button`
  gap: 5px;
  border: 1px solid ${({ theme }) => theme?.ascentColor};
  color: ${({ theme }) => theme?.ascentColor};
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  &:hover {
    opacity: 0.8;
  }
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #d0d0d0;
  }
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 256px;
`;
