import { useMutation, useQuery } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";

export const useGetRenewalListingData = () => {
  const { data, mutate, isLoading, isError, isFetching } = useMutation(
    (payload) => {
      return httpClient("policy_master/renewal/listing", {
        method: "POST",
        data: payload,
      });
    }
  );
  return { data, mutate, isLoading, isError };
};

export const usePostRenewalByAdvisior = () => {
  const { data, mutate, isLoading, isError, isFetching, isSuccess } =
    useMutation((payload) => {
      return httpClient("renewal/byAdvisor", {
        method: "POST",
        data: payload,
      });
    });
  return { data, mutate, isLoading, isError, isSuccess };
};

export const usePostRenewalBySelf = () => {
  const { data, mutate, isLoading, isError, isFetching, isSuccess } =
    useMutation(
      (payload) => {
        return httpClient(`/renewal/bySelf/${payload?.id}/${payload?.lob}`, {
          method: "GET",
        });
      },
      {
        onSuccess: (data) => {
          if (data?.data?.status) {
            const url = data?.data?.data;
            window.open(url, "_blank");
          }
        },
      }
    );
  return { data, mutate, isLoading, isError, isSuccess };
};
