import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const leadSlice = createSlice({
  name: "leadSlice",
  initialState: {
    leadError: null,
    leadSuccess: null,
    channelType: "",
    selectedRole: []
  },
  reducers: {
    setLeadSuccessAction: (state, { payload }) => {
      state.leadSuccess = payload;
      state.leadError = null;
    },
    setLeadErrorAction: (state, { payload }) => {
      state.loading = null;
      state.leadError = serializeError(payload);
      state.leadSuccess = null;
    },
    setChannelType: (state, { payload }) => {
      state.channelType = payload;
    },
    addSelectedRole: (state, { payload }) => {
      state.selectedRole = payload
    }
  },
});

export const { setLeadErrorAction, setLeadSuccessAction, setChannelType, addSelectedRole } =
  leadSlice.actions;
export default leadSlice.reducer;
