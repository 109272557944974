import { isMemberDeleted } from "./customHooks/isMemberDeleted";
import { isNewData } from "./customHooks/isNewData";
import { Fragment } from "react";
import {
  AddedMember,
  AddedMembersContainer,
  AddedTitle,
  RemovedMember,
  RemovedMembersContainer,
  RemovedTitle,
} from "./policyEntryStyles";
import { isEmpty } from "lodash";

const RenderNewChanges = ({
  endorsementData,
  value,
  dataKey,
  title,
  nameKey,
}) => {
  const deletedMembers = isMemberDeleted(endorsementData, dataKey);
  const addedMembers = isNewData(endorsementData, dataKey);

  return (
    <Fragment key={value}>
      {!isEmpty(deletedMembers) && (
        <>
          <RemovedTitle>{`Removed ${title}`}</RemovedTitle>
          <RemovedMembersContainer>
            {deletedMembers.map(member => (
              <RemovedMember key={member?.member_id}>
                {member?.[nameKey] || "- - -"}
              </RemovedMember>
            ))}
          </RemovedMembersContainer>
        </>
      )}
      {!isEmpty(addedMembers) && (
        <>
          <AddedTitle>{`Added ${title}`}</AddedTitle>
          <AddedMembersContainer>
            {addedMembers.map(member => (
              <AddedMember key={member?.member_id}>
                {member?.[nameKey] || "- - -"}
              </AddedMember>
            ))}
          </AddedMembersContainer>
        </>
      )}
    </Fragment>
  );
};

export default RenderNewChanges;
