import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import CallMade from "@mui/icons-material/CallMade";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Grid, Tooltip } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Button, Typography } from "../../components/Typography/Typography";
import ButtonWrapper from "../../components/ButtonWrapper/index";
import InlineLoader from "../../components/InlineLoader/inlineLoader";
import { useUploadProposalFile } from "./proposalSummaryService";
import { setUploadedFileURLAction } from "./proposal.slice";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { useGetConditionalErrorMessage } from "./getConditionalErrorMessage";
import { CustomLabel } from "./inputStyle";

function ProposalFilePicker({
  label,
  id,
  isRequired,
  files,
  setFiles,
  setValue,
  trigger,
  description,
  shouldHideRemoveButton,
  allowOnlyImages,
  acceptedFormatTextAndMode,
  disableInstantUpload,
  errors,
  prefillName,
  prefillUrl,
  proposalRead,
  register,
  itemData,
  unregister,
  isEndorsement = false,
}) {
  const { theme } = useSelector(state => state.theme);
  const { errorMessage } = useGetConditionalErrorMessage({
    errors,
    id,
  });
  const [leadFilePreview, setleadFilePreview] = useState();
  const [acceptedFilesState, setAcceptedFilesState] = useState();
  useEffect(() => {
    if (prefillUrl) {
      setleadFilePreview(prefillUrl);
    }
  }, [prefillUrl]);

  const {
    data: fileURL,
    mutate: uploadProposalFile,
    isLoading: isUploadProposalFileLoading,
    isError,
  } = useUploadProposalFile();
  const { Error } = Typography;

  const { uploadedFileURL } = useSelector(state => state.proposalConfig);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      fileURL?.data.data?.url &&
      fileURL?.data.data?.fieldSlug &&
      !disableInstantUpload
    ) {
      let fieldSlug = fileURL?.data.data?.fieldSlug;
      let url = fileURL?.data.data?.url;
      dispatch(setUploadedFileURLAction({ [fieldSlug]: url }));
      // added this code to allow file addition in redux only if api is successful
      setFiles({ ...files, [id]: acceptedFilesState?.[0] });
      if (isEndorsement) {
        setValue(`${id}`, url);
      } else {
        setValue(`${id}`, acceptedFilesState?.[0]);
      }
    }
  }, [fileURL, disableInstantUpload]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        text: "File Upload Failed",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [isError]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      ...(!allowOnlyImages && {
        "application/pdf": [".pdf"],
      }),
    },
    onDrop: async (acceptedFiles, rejectedFiles) => {
      if (!_.isEmpty(rejectedFiles)) {
        Swal.fire({
          text: "Only JPG, JPEG, PNG, and PDF files are allowed",
          icon: "error",
        });
      } else {
        // added this code to prevent file addition in redux if api fails
        setAcceptedFilesState(acceptedFiles);
        if (disableInstantUpload) {
          setFiles({ ...files, [id]: acceptedFiles?.[0] });
          setValue(`${id}`, acceptedFiles?.[0]);
          dispatch(setUploadedFileURLAction({ [id]: acceptedFiles?.[0] }));
        }
        if (!disableInstantUpload) {
          const formData = new FormData();
          formData.append("file", acceptedFiles?.[0]);
          formData.append("field_slug", id);
          uploadProposalFile(formData);
        }
        if (isRequired) {
          trigger([id]);
        }
      }
    },
  });

  const handleRealFilePreview = id => {
    if (uploadedFileURL?.[id] && !disableInstantUpload) {
      window.open(uploadedFileURL?.[id], "_blank");
    }
    if (uploadedFileURL?.[id]?.path && disableInstantUpload) {
      window.open(URL.createObjectURL(uploadedFileURL?.[id]), "_blank");
    }
  };

  const handleRemoveFile = id => {
    if (leadFilePreview) {
      setleadFilePreview("");
      unregister(id);
    }
    const newFiles = { ...files };
    delete newFiles?.[id];
    setFiles(newFiles);
    dispatch(setUploadedFileURLAction({ [id]: null }));
    setValue(id, null);
    trigger(id);
  };
  return (
    <FilePickerContainer id={id}>
      <p className="file_label">
        {acceptedFormatTextAndMode && !acceptedFormatTextAndMode?.viewMode && (
          <>
            <br />
            Accepted Format :{" "}
            <span className="isRequired">
              {acceptedFormatTextAndMode?.title}
            </span>
            <br />
            {/* Max Size :<span className="isRequired">{"1 MB"}</span> */}
          </>
        )}
      </p>
      <CustomLabel htmlFor={id} title={label}>
        {label}{" "}
        {isRequired && !proposalRead && <span className="isRequired">*</span>}
        {description && !proposalRead && (
          <Tooltip title={description} arrow placement="top">
            <InfoOutlinedIcon
              sx={{
                fontSize: "12px",
                marginLeft: "3px",
                position: "relative",
                top: "1px",
              }}
            />
            {/* <InfoIcon sx={{ fontSize: "12px", marginLeft: "3px" }} /> */}
          </Tooltip>
        )}
      </CustomLabel>
      <FilePickerStyled>
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              ...(proposalRead && { pointerEvents: "none" }),
            }}
          >
            {!shouldHideRemoveButton ? (
              <div>
                <div
                  {...getRootProps()}
                  className="dropzone upload_action_button"
                >
                  <input {...getInputProps()} />
                  <BackupOutlinedIcon />
                  <p className="upload_text">
                    {isUploadProposalFileLoading ? (
                      <>
                        Uploading
                        <InlineLoader />
                      </>
                    ) : uploadedFileURL?.[id] || leadFilePreview ? (
                      "File Uploaded"
                    ) : (
                      "Upload File"
                    )}
                  </p>
                </div>
                {errorMessage && <Error>{errorMessage}</Error>}
              </div>
            ) : (
              <div className="dropzone upload_action_button">
                <BackupOutlinedIcon />
                <p className="upload_text">{"Uploaded File"}</p>
              </div>
            )}
          </div>
          {uploadedFileURL?.[id] || leadFilePreview ? (
            <>
              <Tooltip arrow title="Preview">
                <div>
                  <div className="dropzone view_delete_action_button">
                    <PreviewOutlinedIcon
                      style={{ color: theme.primaryColor }}
                      onClick={() =>
                        uploadedFileURL?.[id]
                          ? handleRealFilePreview(id)
                          : window.open(leadFilePreview, "_blank")
                      }
                    ></PreviewOutlinedIcon>
                  </div>
                </div>
              </Tooltip>
              {!shouldHideRemoveButton && !proposalRead && (
                <Tooltip arrow title="Delete">
                  <div>
                    <div className="dropzone view_delete_action_button">
                      <DeleteForeverOutlinedIcon
                        style={{ color: theme.primaryColor }}
                        onClick={() => handleRemoveFile(id)}
                      ></DeleteForeverOutlinedIcon>
                    </div>
                  </div>
                </Tooltip>
              )}
              {!uploadedFileURL?.[id] && (
                <input
                  type="hidden"
                  {...register(prefillName)}
                  value={JSON.stringify({
                    documentManagementConfigurationId: itemData?.value,
                    documentName: itemData?.label,
                    documentPath: leadFilePreview,
                  })}
                ></input>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </FilePickerStyled>
    </FilePickerContainer>
  );
}

export default ProposalFilePicker;

const RealPreviewStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-left: 20px;
`;

const FilePickerContainer = styled.div`
  // margin-bottom: 10px;
  margin-top: -6px;
  .isRequired {
    color: red;
    margin-left: 3px;
  }
  .file_label {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.primaryColor};
    padding-left: 1px;
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 6px;
  }
`;

const FilePickerStyled = styled.div`
  margin-bottom: 10px;
  .upload_action_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    padding: 0 5px;
    background: ${({ theme }) => theme.primaryColor};
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    height: 100%;
    white-space: nowrap;
  }
  .view_delete_action_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    padding: 0 5px;
    background: #fff;
    border-radius: 4px;
    color: rgba(17, 85, 95, 1);
    font-size: 16px;
    font-weight: 500;
    height: 95%;
    white-space: nowrap;
    outline: 1px solid rgba(17, 85, 95, 1);
  }
  .upload_text {
    margin: 4px;
    display: flex;
  }
`;
