import { isEmpty, isObject } from "lodash";
import {
  INSURED_MEMBER_DETAILS,
  NOMINEE_DETAILS,
  PAYMENT_DETAILS,
  SOURCING_DETAILS,
} from "../proposalCreateConstants";

export function isNewData(difference, arrayKey) {
  if (isEmpty(difference)) return;

  if (difference?.[arrayKey]?.length) {
    const newlyAddedData = difference?.[arrayKey]?.filter(value => {
      return value?.type === "NEW";
    });

    return newlyAddedData?.length ? newlyAddedData : [];
  }
  return [];
}
