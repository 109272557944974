import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import styled from "styled-components";
// import { Input } from "../../components";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
// import {
//   allowOnlyNumbers,
//   preventFirstZero,
// } from "../../HelperFunctions/HelperFunctions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { useGetRiskoMeter } from "../customerDashboard/service";
import { useGetCoverageAmount, useRiskometer } from "../service";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveData, setResAction } from "../riskoMeterMember.slice";

const TermExpenses = ({ handleSave, handleBack, activeTab }) => {
  const { coverageAmountPayload, resData, allData, range } = useSelector(
    (state) => state.riskoMeterMember
  );

  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge || {}).length > 1;

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const {
    mutateAsync: ageRiskometer,
    isLoading: customerAddLoading,
    data,
  } = useRiskometer(activeTab);
  const dispatch = useDispatch();
  const [currentExpense, setCurrentExpense] = useState(1000000);
  const [inflationRate, setInflationRate] = useState(15);
  const [timePeriod, setTimePeriod] = useState(8);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");
  const { data: policyCoverageAmount } = useGetCoverageAmount(lobId);

  const values = getValues();

  const onSubmit = async () => {
    const payload = {
      questionId: 6,
      lobId: +lobId,
      request_data: {
        currentExpenses: currentExpense || "100000",
        annualInflationRate: inflationRate || "7",
        timePeriod: timePeriod || "10",
      },
    };

    dispatch(
      saveData({
        Expenses: {
          ...values,
          currentExpense,
          inflationRate,
          timePeriod,
        },
      })
    );

    const res = await ageRiskometer(payload);

    if (res?.success) {
      handleSave();
    }
  };

  const { theme } = useSelector(({ theme }) => theme);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  const formatIndianNumber = (num) => {
    num = num.toString();

    let [integer, decimal] = num.split(".");

    if (integer.length > 3) {
      let lastThree = integer.slice(-3);
      let rest = integer.slice(0, integer.length - 3);

      rest = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      integer = rest + "," + lastThree;
    }

    return decimal ? `${integer}.${decimal}` : integer;
  };

  const handleExpenseChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setCurrentExpense(0);
    } else {
      const parsedValue = parseInt(value.replace(/[^0-9]/g, ""), 10);

      if (isNaN(parsedValue)) {
        setCurrentExpense(0);
      } else {
        const newExpense = Math.min(parsedValue, 10000000);
        setCurrentExpense(newExpense);
      }
    }
  };

  const handleInflationChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setInflationRate(0);
    } else {
      const parsedValue = parseInt(value.replace(/[^0-9]/g, ""), 10);

      if (isNaN(parsedValue)) {
        setInflationRate(0);
      } else {
        const newInflationRate = Math.min(parsedValue, 15);
        setInflationRate(newInflationRate);
      }
    }
  };

  const handleTimeChange = (e) => {
    const value = e.target.value;

    const parsedValue = parseInt(value.replace(/[^0-9]/g, ""), 10);

    if (isNaN(parsedValue)) {
      setTimePeriod(0);
    } else {
      const newTimePeriod = Math.min(parsedValue, 70);
      setTimePeriod(newTimePeriod);
    }
  };

  useEffect(() => {
    if (allData?.Expenses) {
      setCurrentExpense(allData.Expenses.currentExpense);
      setInflationRate(allData.Expenses.inflationRate);
      setTimePeriod(allData.Expenses.timePeriod);
    }
  }, [allData]);

  const calculateFutureCost = (currentExpenses, inflationRate, timePeriod) => {
    // Calculate the future cost based on the provided inputs
    const futureCost =
      currentExpenses * Math.pow(1 + inflationRate / 100, timePeriod);

    // Return the result with two decimal places
    return futureCost.toFixed(2);
  };

  // Now pass all three parameters to the function
  const futureCost = calculateFutureCost(inflationRate, timePeriod);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={handleBack}>
            <ArrowBackIcon />
          </BackButton>
        </div>
        <Title>Expenses</Title>
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <InputSection>
            <Label>Value of Current Expenses</Label>
            <ValueInput
              value={formatIndianNumber(currentExpense)}
              onChange={handleExpenseChange}
            />
            <RangeInput
              type="range"
              min="100000"
              max="10000000"
              step="50000"
              value={currentExpense}
              onChange={handleExpenseChange}
            />
          </InputSection>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr, 1fr",
            }}
          >
            {/* <InputSection> */}
            <Label>Annual Inflation Rate (%)</Label>
            <ValueInput
              value={inflationRate}
              onChange={handleInflationChange}
            />
            {/* </InputSection> */}
          </div>
          <RangeInput
            type="range"
            min="0"
            max="15"
            step="1"
            value={inflationRate}
            onChange={handleInflationChange}
          />
          <InputSection>
            <Label>Time Period (In Years)</Label>
            <ValueInput value={timePeriod} onChange={handleTimeChange} />
            <RangeInput
              type="range"
              min="1"
              max="70"
              step="1"
              value={timePeriod}
              onChange={handleTimeChange}
            />
          </InputSection>
          <ResultWrapper>
            <FutureCostText>Future Cost:</FutureCostText>
            <FutureCostValue>
              ₹{resData?.data?.data?.expense || 0}
            </FutureCostValue>
          </ResultWrapper>
          <AddButton type="submit">Continue</AddButton>
        </form>
      </Container>
      {/* {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />} */}
    </div>
  );
};

export default TermExpenses;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
`;

const Container = styled.div`
  border-radius: 8px;
  padding: 24px;
  width: 125vw;
  max-width: 800px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #d0d0d0;
  }
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin: 10px auto;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const InputSection = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: #4a5568;
  margin-bottom: 8px;
  display: block;
`;

const ValueInput = styled.input`
  background-color: #fff;
  color: #4a5568;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
`;

const RangeInput = styled.input`
  width: 100%;
  margin-top: 8px;
`;

const ResultWrapper = styled.div`
  text-align: right;
  margin-top: 2rem;
`;

const FutureCostText = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #4a4a4a;
`;

const FutureCostValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #2f855a;
  color: ${({ theme }) => theme?.ascentColor};
`;
