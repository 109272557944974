import { isNil } from "lodash";

export const getTooltipTitle = (
  totalNomineeShare,
  // nomineeShareisZero,
  activeCount,
  isNotSubmitBtn = false,
) => {
  if (
    !isNil(totalNomineeShare) &&
    totalNomineeShare !== 100 &&
    totalNomineeShare !== 0
    // &&
    // !nomineeShareisZero
  ) {
    return (
      <span>
        Cumulative nominee share should be 100%. <br /> Current Value is{" "}
        <strong>{totalNomineeShare}</strong>
      </span>
    );
  }

  // if (nomineeShareisZero) {
  //   return (
  //     <span>
  //       Nominee Share must not be <strong>0</strong>
  //     </span>
  //   );
  // }
  if (isNotSubmitBtn) return "";
  return `${activeCount === 0 ? "Save and Proceed" : "Submit"}`;
};
