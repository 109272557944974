import TabPanel from "@mui/lab/TabPanel";
import { AppBar, Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { styled } from "@mui/system";
import { color } from "@amcharts/amcharts4/core";
import { Padding } from "@mui/icons-material";

const FixedWidthTab = styled(Tab)(({ theme, isActive }) => ({
  textTransform: "none",
  borderBottom: isActive ? "3px solid #3f51b5" : "none",
  color: isActive ? "black" : "grey",
}));

const Tabpanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const FixedWidthTabs = ({ tabs }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const tabStyle = {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18.23px",
    textAlign: "left",
    width: "max-content",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        style={{ paddingTop: "15px", borderBottom: "1px solid #adadad" }}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="fixed width tabs example"
      >
        {tabs?.map((tab, index) => (
          <FixedWidthTab
            key={index}
            label={tab?.label}
            isActive={value === index}
            style={tabStyle}
          />
        ))}
      </Tabs>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ paddingTop: "15px" }}
      >
        {tabs?.map((tab, index) => (
          <Tabpanel key={index} value={value} index={index}>
            {tab?.component}
          </Tabpanel>
        ))}
      </SwipeableViews>
    </Box>
  );
};

export default FixedWidthTabs;
