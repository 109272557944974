import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import Select, { components as selectComponents } from "react-select";
import styled from "styled-components";
import { useGetConditionalErrorMessage } from "../../hooks/useGetConditionalErrorMessage";
import { useGetSummaryValue } from "../../hooks/useGetSummaryValue";
// import { StyledValue } from "../../modules/Proposal-Journey/Proposal-Listing/ProposalListingView";
import Typography from "../Typography/Typography";
import SmartSearchDropdown from "./SmartSearchDropdown";
import { CustomLabel } from "../form-components/input/style";
import { useSelector } from "react-redux";

function Dropdown({
	id,
	label,
	placeholder = "Select",
	selectObj,
	isRequired,
	control,
	isMulti,
	isLoading,
	closeMenuOnSelect,
	maxMenuHeight,
	proposalRead,
	components,
	marginBottom,
	isDisabled,
	noOptionMessage,
	description,
	onChange,
	errors,
	onInput,
	isClearable,
	watch,
	reset,
	setValue,
	smartSearchEndpoint,
	register,
	fieldId,
	minHeight,
	Width,
	brokerDataShuffle,
	onInputChange,
	dropDownInputValue,
	setScrolledToBottom,
	flag,
	setDistrictDropDownData,
	menuPortalTarget,
	percentage,
	isRemove,
	closeMenuOnScroll = false,
	menuPlacement,
	modern,
	claim,
	removedValueKey,
	...rest
}) {
	const { Error } = Typography;
	const selectRef = useRef();
	const [inputFocus, setInputFocus] = useState(false);
	// const { theme } = useSelector((state) => state.theme);
	const { errorMessage } = useGetConditionalErrorMessage({
		errors,
		id,
	});

	const { fieldValue } = useGetSummaryValue({
		watch,
		id,
	});

	// temp. handling missing case when an object is returned
	const summaryValue =
		typeof fieldValue === "object" ? fieldValue?.label : fieldValue;

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			backgroundColor: isDisabled ? "#E2E2E2" : "white",
			width: "100%",
			border: errorMessage
				? "1px solid red"
				: state.isFocused
				? "1px solid #2bb9a8"
				: claim
				? "none"
				: "1px solid rgb(169, 167, 167)",
			borderRadius: brokerDataShuffle ? "50px" : "4px",
			background: brokerDataShuffle && !claim ? "transparent" : "#F1F1F1",
			fontSize: "12px",
			minHeight: 32,
			maxHeight: isMulti ? 80 : 32,
			boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
			padding: brokerDataShuffle ? "0 10px" : "0 5px",
			cursor: "pointer",
			"&:hover": {
				border: errorMessage
					? "1px solid red"
					: state.isFocused
					? "1px solid rgb(67, 115, 235)"
					: claim
					? "none"
					: "1px solid rgb(169, 167, 167)",
			},
		}),
		menu: (provided) => ({
			...provided,
			zIndex: 100,
			margin: "5px 0 0 0",
			padding: 0,
			// To remove padding of option container
			"& > div:first-child": {
				padding: "0",
				maxHeight: "140px !important",
			},
			//To remove border of last option
			"* > div:last-child": {
				border: "none",
			},
			"*::-webkit-scrollbar": {
				width: "3px",
			},
			"*::-webkit-scrollbar-track": {
				backgroundColor: "lightgrey",
			},
			"*::-webkit-scrollbar-thumb": {
				backgroundColor: "#2BB9A8",
			},
		}),
		menuPortal: (base) => ({ ...base, zIndex: 9999 }),
		dropdownIndicator: (provided) => ({
			...provided,
			border: 0,
			padding: 0,
			display: isDisabled ? "none" : "",
		}),
		option: (styles, { isFocused, isSelected }) => ({
			...styles,
			fontWeight: 600,
			letterSpacing: "0.5px",
			fontSize: "12px",
			color: "#2BB9A8",
			borderBottom: "1px solid #CADEDD",
			backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#E8E8E8" : "white",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			padding: "8px",
			cursor: "pointer",
			transition: "all 0.2s ease",
		}),
		valueContainer: (provided, state) => {
			const selected_elements = state?.children?.[0];
			const is_more_than_four_selected =
				Array.isArray(selected_elements) && selected_elements?.length >= 3;
			return {
				...provided,
				padding: "0",
				maxHeight: isMulti ? 64 : 32,
				overflowY: is_more_than_four_selected && "auto",
				"::-webkit-scrollbar": {
					width: "2px",
				},
				"::-webkit-scrollbar-track": {
					margin: "5px 0",
					backgroundColor: "lightgrey",
				},
				"::-webkit-scrollbar-thumb": {
					backgroundColor: "#2BB9A8",
				},
			};
		},
		indicatorsContainer: (provided) => ({
			...provided,
			display: isDisabled ? "none" : "flex",
			padding: "0",
			"& div": {
				padding: "0",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			},
			"& span": {
				display: "none",
			},
		}),
		singleValue: (provided) => ({
			...provided,
			color: "#000",
		}),
		multiValue: (provided) => ({
			...provided,
			color: "#2BB9A8",
			borderRadius: "5px",
			margin: "5px",
			background: "#22bdaa30",
			fontSize: "12px",
			fontWeight: "600",
		}),
		multiValueLabel: (provided) => ({
			...provided,
			color: "#2BB9A8",
		}),
		placeholder: (provided) => ({
			...provided,
			// overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		}),
	};

	const readOnlyCustomStyles = {
		control: (provided, state) => ({
			...provided,
			width: "100%",
			fontSize: "12px",
			boxShadow: state.isFocused ? "0 0 0 0.2rem transparent" : "none",
			border: "none",
			background: "#fff",
			marginTop: "-1px",
			padding: "0 5px",
			background: proposalRead && "1px solid #e2e2e2",
			minHeight: 32,
			maxHeight: 32,
			"*::-webkit-scrollbar": {
				display: "none",
			},
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			border: 0,
			padding: 0,
			display: "none",
		}),
		option: (styles, { isFocused, isSelected }) => ({
			...styles,
			fontWeight: 600,
			fontSize: "18px",
			color: "#2BB9A8",
			borderBottom: ".25px solid",
			backgroundColor: isSelected ? "#D1F8E8" : isFocused ? "#fff" : "#fff",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		}),
		valueContainer: (provided) => ({
			...provided,
			padding: "0",
			maxHeight: 35,
		}),
		indicatorsContainer: (provided) => ({
			...provided,
			padding: "0",
			display: "none",
		}),
		singleValue: (provided) => ({
			...provided,
			color: "#000",
			fontWeight: 600,
			fontSize: "14px",
			marginLeft: 0,
		}),
	};

	const { IndicatorsContainer, Menu } = selectComponents;

	const [showSmartSearch, setShowSmartSearch] = useState(false);

	useEffect(() => {
		if (showSmartSearch) {
			const timer = setTimeout(() => {
				setInputFocus(true);
			}, 500);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [showSmartSearch]);

	const CustomIndicatorsContainer = forwardRef(
		({ clearValue, hasValue, setIsMenuOpen, ...props }, ref) => {
			return (
				<IndicatorsContainer {...props}>
					{hasValue && (
						<ClearRoundedIcon
							onClick={() => {
								clearValue();
								// ref?.current?.blur();
							}}
						/>
					)}
					{smartSearchEndpoint && (
						<>
							{!showSmartSearch && (
								<Tooltip
									title="Smart Search"
									placement="top"
									arrow
								>
									<SearchIcon
										onClick={() => {
											ref.current.blur();
											setShowSmartSearch(true);
										}}
									/>
								</Tooltip>
							)}
						</>
					)}
					{!showSmartSearch && (
						<KeyboardArrowDownRoundedIcon
							onClick={() => {
								ref.current.focus();
							}}
						/>
					)}
				</IndicatorsContainer>
			);
		}
	);
	CustomIndicatorsContainer.displayName = "CustomIndicatorsContainer";
	const MenuRef = forwardRef((menuProps, ref) => {
		return (
			<Menu
				{...menuProps}
				ref={ref}
				autoFocus={false}
			/>
		);
	});
	MenuRef.displayName = "MenuRef";
	const handleValueChange = (value, actionMeta) => {
		if (removedValueKey && setValue) {
			actionMeta?.action === "remove-value"
				? setValue(removedValueKey, actionMeta?.removedValue)
				: setValue(removedValueKey, {});
		}
	};
	return (
		<LOBContainerStyled
			minHeight={minHeight}
			marginBottom={marginBottom}
			isDisabled={isDisabled}
			Width={Width}
			percentage={percentage}
			isRemove={isRemove}
			modern={modern}
		>
			<div style={{ width: showSmartSearch ? "66%" : "100%" }}>
				{label ? (
					<CustomLabel
						htmlFor={id}
						title={label}
						claim={claim}
					>
						{/* {label?.length > 15 ? `${label.slice(0, 30)}...` : label} */}
						{label}
						{isRequired && !proposalRead && (
							<span className="isRequired">*</span>
						)}
						{description && !proposalRead && (
							<Tooltip
								title={description}
								arrow
								placement="top"
							>
								<InfoOutlinedIcon
									sx={{
										fontSize: "12px !important",
										color: "rgb(17, 85, 95) !important",
										marginLeft: "3px",
										position: "relative",
										top: "1px",
									}}
								/>
							</Tooltip>
						)}
					</CustomLabel>
				) : (
					<></>
				)}
				{proposalRead ? (
					<StyledValue>{summaryValue || "- - -"}</StyledValue>
				) : (
					<>
						<Controller
							control={control}
							name={id}
							render={({ field, fieldState: { error } }) => {
								return (
									<Select
										{...field}
										inputRef={field.ref} // Assign the field's ref
										menuPlacement={
											selectObj?.length > 2 ? menuPlacement : undefined
										}
										filterOption={(option, rawInput) => {
											return (
												option?.label
													?.toLowerCase()
													?.includes(rawInput?.toLowerCase()?.trim()) ||
												undefined
											);
										}}
										onInputChange={
											onInputChange
												? (e) => {
														dropDownInputValue(e);
														setDistrictDropDownData([]);
														setScrolledToBottom((prev) => ({
															...prev,
															page: 1,
															size: 20,
														}));
												  }
												: undefined
										}
										id={id}
										isMulti={isMulti}
										isLoading={isLoading}
										placeholder={
											placeholder
												? placeholder
												: !showSmartSearch
												? `Select`
												: ""
										}
										onChange={(value, actionMeta) => [
											handleValueChange(value, actionMeta),
											field.onChange(value),
											...[onChange && onChange()],
										]}
										onFocus={() => field.ref.current?.focus()} // Ensure focus works correctly
										onBlur={() => [field.onBlur, setShowSmartSearch(false)]}
										closeMenuOnSelect={closeMenuOnSelect}
										openMenuOnFocus
										options={selectObj}
										styles={proposalRead ? readOnlyCustomStyles : customStyles}
										maxMenuHeight={maxMenuHeight}
										isSearchable={!proposalRead}
										isDisabled={isDisabled}
										isClearable={isClearable}
										menuPortalTarget={menuPortalTarget}
									/>
								);
							}}
						/>

						{!!errorMessage && <Error>{errorMessage}</Error>}
					</>
				)}
			</div>
			{showSmartSearch && (
				<div style={{ flex: 1 }}>
					<label />
					<SmartSearchDropdown
						id={"customField"}
						name={"customField"}
						register={register}
						errors={errors}
						watch={watch}
						smartSearchEndpoint={smartSearchEndpoint}
						fieldId={fieldId}
						setValue={setValue}
						inputFocus={inputFocus}
						setInputFocus={setInputFocus}
						setShowSmartSearch={setShowSmartSearch}
						selectRef={selectRef}
					/>
				</div>
			)}
		</LOBContainerStyled>
	);
}

export default Dropdown;

const LOBContainerStyled = styled.div`
	display: flex;
	gap: 25px;
	.isRequired {
		color: red;
		margin-left: 3px;
	}
	.css-12a83d4-MultiValueRemove {
		display: ${({ isRemove }) => (isRemove ? "none" : "")};
	}
	min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "75px")};
	width: ${({ Width, percentage }) =>
		Width ? (percentage ? `${Width}%` : `${Width}px`) : "auto"};
	svg {
		color: grey;
		font-size: 20px;
	}
`;

const StyledValue = styled("span")({
	gridArea: "1 / 1 / 2 / 3",
	maxWidth: "100%",
	overflow: "hidden",
	whiteSpace: "pre-line",
	color: "#000c",
	marginLeft: "3px",
	marginRight: "2px",
	boxSizing: "border-box",
	fontWeight: "600",
	fontSize: "14px",
	display: "inline-block",
	wordWrap: "break-word",
});
