import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HttpClient from "../../api/httpClient";
import {
  setBrokerage,
  setIdAction,
  setPolicyViewCards,
  setProposalErrorAction,
  setProposalPartialUpdateSuccess,
  setProposalSuccessAction,
} from "./proposal.slice";
import Swal from "sweetalert2";
import { useState } from "react";

export const useGetProposalValues = ({ lobId, userId }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["proposal_view_values"],
    queryFn: () =>
      HttpClient(`/transaction_summary/list/${lobId}?id=${userId}`, {
        method: "POST",
      }),
    enabled: lobId && userId ? true : false,
    cacheTime: 0,
    staleTime: 0,
  });
  return { data, isLoading };
};

export const useCreateProposalData = () => {
  const dispatch = useDispatch();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient("/transaction_summary/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.status) {
          dispatch(setProposalErrorAction(data.message));
          return;
        }
        dispatch(setIdAction(data?.data?.data));
        dispatch(setProposalSuccessAction(data.message));
      },
    }
  );

  return { mutate };
};

export const useCreateProposalMongoId = (setSubmittedAt) => {
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { policyViewCards } = useSelector((state) => state.proposalConfig);

  const { mutate, isLoading, isError } = useMutation(
    (payload) => {
      return HttpClient("/transaction-field-draft/update-draft", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        // dispatch(setPolicyEditIndex(index));
        const updatedState = {};
        for (const key in policyViewCards) {
          updatedState[key] = true;
        }
        dispatch(setPolicyViewCards(updatedState));
        setSubmittedAt(new Date().getTime());

        const mongoId = data?.data?.data?.user_temp_id;
        const queryParams = new URLSearchParams(currentLocation.search);
        queryParams.set("mongoId", mongoId);
        const setMongoId = queryParams.toString();
        navigate(`?${setMongoId}`);
        dispatch(setProposalPartialUpdateSuccess(true));
      },
    }
  );
  return { mutate, isLoading, isError };
};

export const useUpdateExistingProposalData = (setSubmittedAt, stopNav) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mongoId = queryParams.get("mongoId");
  const lobId = queryParams.get("lobId");
  const transactionId = queryParams.get("transactionId");
  const [asd, setAsd] = useState({});

  const { mutate: updateFinalSubmit } = useUpdateFinalSubmit(stopNav);

  const { policyViewCards } = useSelector((state) => state.proposalConfig);

  const { mutate, isLoading, isError, data } = useMutation(
    ({ payload, mongoId: id }) => {
      setAsd({ payload, id });
      return HttpClient(`/transaction-field-draft/${id}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onSuccess: (res) => {
        if (transactionId) {
          updateFinalSubmit({
            // oldTransactionId: oldTransactionId,
            data: [asd?.payload?.data?.[0]?.data],
            ...asd?.payload,
            id: mongoId,
            lobid: lobId,
          });
        }

        // dispatch(setPolicyEditIndex(index));
        const updatedState = {};
        for (const key in policyViewCards) {
          updatedState[key] = true;
        }
        dispatch(setPolicyViewCards(updatedState));
        setSubmittedAt(new Date().getTime());

        // sessionStorage.setItem("id", data?.data?.data?.user_temp_id);
        dispatch(setProposalPartialUpdateSuccess(true));
        queryClient.invalidateQueries(["proposalGetById"]);
      },
    }
  );
  return { mutate, isLoading, isError, data };
};

export const useCreateFinalSubmit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate, isLoading, data } = useMutation(
    ({ id, oldTransactionId }) => {
      return HttpClient(`policy-field-draft/policy-submit`, {
        method: "POST",
        data: oldTransactionId ? { id, oldTransactionId } : { id },
      });
    },
    {
      onSuccess: (data) => {
        if (!data?.data?.status) {
          dispatch(
            setProposalErrorAction(
              data?.data?.errorData
                ? Object.values(data?.data?.errorData)
                : data?.data?.message
            )
          );
          return;
        }
        const transactionId = data?.data?.data;
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("transactionId", transactionId);
        navigate(`?${queryParams.toString()}`);
        dispatch(setBrokerage({ transactionId: data?.data?.data }));
        dispatch(setProposalSuccessAction(data.message));
      },
    }
  );
  return { mutate, isLoading, data };
};

export const useCreateFinalSubmitInEditFromRenewal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate, isLoading } = useMutation(
    ({ id, oldTransactionId }) => {
      // /transaction-field-draft/final-submit
      // /policy-field-draft/policy-submit3
      return HttpClient(`/transaction-field-draft/final-submit`, {
        method: "POST",
        data: { id, oldTransactionId },
      });
    },
    {
      onSuccess: (data) => {
        if (!data?.data?.status) {
          dispatch(
            setProposalErrorAction(
              data?.data?.errorData
                ? Object.values(data?.data?.errorData)
                : data?.data?.message
            )
          );
          return;
        }
        const transactionId = data?.data?.data;
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("transactionId", transactionId);
        navigate(`?${queryParams.toString()}`);
        dispatch(setBrokerage({ transactionId: data?.data?.data }));
        dispatch(setProposalSuccessAction(data.message));
      },
    }
  );
  return { mutate, isLoading };
};

export const useUpdateFinalSubmit = (stopNav) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, isLoading, data } = useMutation(
    (payload) => {
      const { lobid, data, addOnPremiums } = payload;
      return HttpClient(
        `/transaction-field-draft/final-submit/update/${payload.id}`,
        {
          method: "PUT",
          data: {
            lobid,
            data,
            addOnPremiums: addOnPremiums?.length ? addOnPremiums : [],
          },
        }
      );
    },
    {
      onSuccess: (res) => {
        if (stopNav && !res?.data?.status) {
          Swal.fire({
            text: res?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
        }
        if (!stopNav) {
          Swal.fire({
            text: res?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false,
          }).then((result) => {
            if (result.isConfirmed && res?.data?.status) {
              navigate("/search-proceed");
              dispatch(setProposalSuccessAction(null));
            }
          });
        }
      },
    }
  );
  return { mutate, isLoading, data };
};

export const useGetProposalDataById = (id) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ["proposalGetById", id],
    queryFn: () => HttpClient(`/transaction-field-draft/${id}`),
    enabled: id ? true : false,
  });
  return { data, isError, isLoading };
};

export const useGetRestructuredBulkUploadProposalData = (isBulkUploadData) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient("transaction-field-draft/process_bulk_upload", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["proposalGetById"]);
      },
    },
    {
      enabled: isBulkUploadData,
    }
  );
  return { mutate };
};

export const useGetManufacturerData = (lobid) => {
  const { data, isLoading } = useQuery({
    queryKey: ["getManufacturerData", lobid],
    queryFn: () =>
      HttpClient("lob_manufacturer/get-manufacturer", {
        method: "POST",
        data: { lobid },
      }),
    enabled: lobid ? true : false,
  });
  return { data, isLoading };
};

export const useGetModelData = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("/lob_manufacturer/get-model", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetVersionData = () => {
  // version numbers based on model no
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("/lob_manufacturer/get-version", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetFuelData = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("/lob_manufacturer/get-version-fuel-type", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetVersionDetails = () => {
  // entire mmv data using version number
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("lob_manufacturer/get-version-details", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};
export const useGetRewardCalculate = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("reward_configurator/calculator", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetBrokerageCalculate = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("brokerage_Configurator/calculate", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetTransactionType = (lobId) => {
  const { data } = useQuery({
    queryKey: ["transactionType", lobId],
    queryFn: () =>
      HttpClient("common_master/transaction/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchParam: {
            lobId: lobId,
            transactionTypeId: "",
          },
        },
      }),
  });
  return { data };
};

export const useGetBankDetails = (ifscCode) => {
  const { data } = useQuery({
    queryKey: ["ifscCode", ifscCode],
    queryFn: () =>
      HttpClient("/master_bank/get_master_bank", {
        method: "POST",
        data: { ifscCode: ifscCode },
      }),
    enabled: Boolean(ifscCode && ifscCode.length === 11),
  });
  return { data };
};

export const useGetBankDataFromIfsc = (ifscCodeArray) => {
  const ifscCodeDestructed = Array.isArray(ifscCodeArray)
    ? ifscCodeArray?.map((value) => value.ifscCode)
    : [];

  const fetchMenuMasterData = (ifscCode) => {
    return HttpClient("/master_bank/get_master_bank", {
      method: "POST",
      data: { ifscCode },
    }).then((response) => response.data);
  };

  const data = useQueries({
    queries: ifscCodeDestructed.map((ifscCode) => {
      return {
        queryKey: ["ifsc-code", ifscCode],
        queryFn: () => fetchMenuMasterData(ifscCode),
        enabled: Boolean(ifscCode && ifscCode.length === 11),
        cacheTime: 0,
        staleTime: 0,
      };
    }),
  });
  return { data };
};

export const useGetICBranchDetails = (companyId) => {
  const { data } = useQuery({
    // queryKey: ["branchDetails"],
    queryKey: ["branchDetails", companyId],
    queryFn: () => HttpClient(`masterBranch/list_ic_id/${companyId}`),
    enabled: companyId ? true : false,
  });
  return { data };
};

export const useGetBranchLocation = () => {
  const payload = {
    page: 0,
    size: 0,
    sortBy: "",
    sortOrder: "",
    officeCode: "",
    pincode: "",
  };
  const { data } = useQuery({
    queryKey: ["branchLocation"],
    queryFn: () =>
      HttpClient(`location/list`, {
        method: "POST",
        data: payload,
      }),
  });
  return { data: data?.data?.data };
};

export const useCheckIfPolicyIsPresent = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("/transaction-field-draft/policy_check", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useUploadProposalFile = () => {
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      return HttpClient("transaction-field-draft/upload-file", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        Swal.fire({
          text: data?.data?.message,
          icon: data?.data?.errortype,
        });
      },
    }
  );
  return { data, mutate, isLoading, isError };
};

export const useGetUserById = ({ userId }) => {
  const { data } = useQuery({
    queryKey: ["user_by_id", userId],
    queryFn: () => HttpClient(`users/user_type/${userId}`),
    enabled: userId ? true : false,
  });
  return { data };
};

export const useGetAllCustomers = () => {
  const { data, mutate, isLoading } = useMutation(
    ({ firstName, customerRefId }) => {
      return HttpClient("customer_records/listPaging", {
        method: "POST",
        data: customerRefId
          ? {
              firstName: "",
              customerRefId,
            }
          : {
              firstName,
              customerRefId: "",
            },
      });
    }
  );
  return { data, mutate, isLoading };
};

export const useAddCustomerFromPolicyEntry = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient(`/customer_records/add?${payload}`, {
      method: "POST",
    });
  });
  return { data, mutate, isLoading };
};

export const useGetProductsList = (searchValue) => {
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["products", searchValue],
    queryFn: () =>
      HttpClient("common_master/product_master/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchProduct: searchValue ? { searchValue } : {},
        },
      }),
  });
  return { data, refetch, isFetching };
};

export const useGetRoleWiseUser = (roleId) => {
  const { data } = useQuery({
    queryKey: ["userList", roleId],
    queryFn: () =>
      HttpClient(`users/list/Paging_Sorting`, {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "desc",
          searchValue: {
            firstName: "",
            lastName: "",
            userStatus: "",
            mobileNumber: "",
            emailId: "",
            departmentId: 0,
            roleId: roleId,
            userTypeId: 0,
            reportToId: 0,
            sourcingFlag: "Y",
          },
        },
      }),
  });
  return { data };
};

export const useGetProductsListPolicyEntry = ({ icId, product }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");
  const { data, refetch, isFetching, isLoading } = useQuery({
    queryKey: ["products", icId, product],
    queryFn: () =>
      HttpClient("common_master/product_master/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchProduct: { productName: product, companyId: icId, lobId } || {},
        },
      }),
    // enabled: !!icId,
  });
  return { data, refetch, isFetching, isLoading };
};

export const useGetCustomer = () => {
  // customer ref id or firstName
  const { mutate, data, isLoading, isSuccess } = useMutation((payload) => {
    return HttpClient(`customer_records/listPaging`, {
      method: "POST",
      data: payload,
    });
  });

  return { mutate, data, isLoading, isSuccess };
};

export const useGetAddonLobWise = () => {
  const { mutate, data, isLoading } = useMutation((payload) => {
    return HttpClient(`addon_master/listPaging`, {
      method: "POST",
      data: payload,
    });
  });

  return { mutate, data, isLoading };
};

export const useUpdateTransactionStage = (transactionStage) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (payload) => {
      return HttpClient(`transaction-field-draft/updateTransactionStage`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["proposalGetById"]);
        Swal.fire({
          text: data?.data?.status ? data?.data?.data : data?.data?.message,
          icon: data?.data?.errortype,
          confirmButtonText: "OK",
          confirmButtonColor: "#DC004E",
          showCancelButton: false,
          reverseButtons: true,
          focusConfirm: true,
          focusCancel: false,
          scrollbarPadding: false,
          showCloseButton: false,
        }).then(() => {
          if (
            transactionStage &&
            transactionStage != "Policy Issued" &&
            data?.data?.status
          ) {
            navigate("/proposals");
          }
        });
      },
    }
  );
  return {
    updateTransactionStage: mutate,
    isLoadingUpdateTransactionStage: isLoading,
  };
};

export const useGetCustomerById = ({ id }) => {
  // customer id
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["customer_by_id", id],
    queryFn: () => HttpClient(`/customer_records/List/${id}`),
    enabled: id ? true : false,
  });
  return { data, isLoading, isSuccess };
};

export const useGetUserTypeTroughSourceType = (id) => {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["source_by_id", id],
    queryFn: () => HttpClient(`/lead_source_master/listById/${id}`),
    enabled: id ? true : false,
  });
  return { data, isLoading, isSuccess };
};

export const useGetCustomerDocumentsByCustomerId = (id) => {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["customerDocs", id],
    queryFn: () =>
      HttpClient(`/customer_additional_document/listByCustomerId/${id}`),
    enabled: id ? true : false,
  });
  return { customerDocs: data?.data?.data || [], isLoading, isSuccess };
};

export const useGetStateCityFromPincode = (pincode, key, setValue) => {
  const { data } = useQuery({
    queryKey: ["pincode", pincode],
    queryFn: () => HttpClient(`pincode/list/${pincode}`),
    enabled: Boolean(pincode && String(pincode).length === 6),
    onSuccess: (data) => {
      if (!data?.data.status && key && setValue) {
        Swal.fire({
          text: `Data for pincode (${pincode}) is not available`,
          icon: data?.alertType,
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
          // confirmButtonColor:"#11555F",
        }).then(() => setValue(key, null));
      }
    },
  });

  return { data };
};

export const useAddDocumentsToCustomerViaPolicyEntry = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation((payload) => {
    return HttpClient("customer_additional_document/add", {
      method: "POST",
      data: payload,
    });
  });

  return { data, mutateAsync, isLoading, isError };
};
export const useAddRenewalStatusFromPolicyEntry = () => {
  const navigate = useNavigate();
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      return HttpClient("customer_renewal_request/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (res) => {
        if (res.data.status) {
          Swal.fire({
            text: res?.data?.message,
            icon: res?.data?.alertType || res?.data?.errortype,
            confirmButtonText: "OK",
            confirmButtonColor: "#11555F",
            // confirmButtonColor:"#11555F",
            didClose: () => {
              navigate(`/renewal-report`);
            },
          });
        } else {
          Swal.fire({
            text: res?.data?.message,
            icon: res?.data?.alertType || res?.data?.errortype,
            confirmButtonText: "OK",
            confirmButtonColor: "#11555F",
            // confirmButtonColor:"#11555F",
          });
        }
      },
    }
  );

  return { data, mutate, isLoading, isError };
};
