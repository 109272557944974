import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { RealPreviewStyled } from "../Customer/customerAdditionalDoc/AdditionalDocFilePicker";
import { Button, FilePicker } from "../../components";
import CallMade from "@mui/icons-material/CallMade";
import ButtonWrapper from "../../components/ButtonWrapper";
import { DocumentUploadForm } from "../MyDocuments/components";
import Swal from "sweetalert2";
import { usePostRenewalByAdvisior, usePostRenewalBySelf } from "./service";

const RenewInsurancePolicyModalForm = ({
  filePreview,
  setModalOpen,
  policyData,
}) => {
  const {
    mutate: renewalByAdvisor,
    data,
    isSuccess,
  } = usePostRenewalByAdvisior(setModalOpen);
  const {
    mutate,
    isSuccess: renewalSuccess,
    data: renewalData,
  } = usePostRenewalBySelf();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    unregister,
    trigger,
    register,
  } = useFormContext();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setValue("renewalOption", "");
    if (filePreview) {
      setValue("document", filePreview);
    }
  }, []);

  useEffect(() => {
    if (renewalData?.data?.status) {
      renewalByAdvisor({
        transactionId: policyData?.["Transaction Id"],
        lob: policyData?.lobId,
        renewalBy: "Self",
      });
    }
  }, [renewalData?.data?.status]);

  if (renewalSuccess) {
    if (renewalData) {
      // Swal.fire(
      //   "",
      //   renewalData?.data?.message,
      //   renewalData?.data?.errortype
      // ).then((res) => {
      //   if (res.isConfirmed) {
      //   }
      // });
      setModalOpen({ open: false, data: "" });
    }
  }

  if (data?.data?.status && !renewalSuccess) {
    Swal.fire(
      "",
      `Your policy renewal for Policy Number ${
        policyData?.["Policy No"] || policyData?.policyNo
      } has been initiated.We will contact you shortly with further details.`,
      "success"
    ).then((res) => {
      if (res.isConfirmed) {
        setModalOpen({ open: false, data: "" });
      }
    });
  }
  if (data && !data?.data?.status && !renewalSuccess) {
    Swal.fire(
      "",
      `Your policy renewal for Policy Number ${policyData?.["Policy No"]} has been failed.`,
      "error"
    ).then((res) => {
      if (res.isConfirmed) {
        setModalOpen({ open: false, data: "" });
      }
    });
  }
  const onSubmit = ({ renewalOption }) => {
    if (renewalOption === "withAdvisor") {
      renewalByAdvisor({
        transactionId:
          policyData?.["Transaction Id"] || policyData?.transaction_id,
        lob: policyData?.lobId,
      });
    } else if (renewalOption === "self") {
      mutate({
        id: policyData?.["Transaction Id"] || policyData?.transaction_id,
        lob: policyData?.lobId,
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          color: "#5E5ADB",
          margin: "0px 0px 10px 23px",
          width: "70%",
          fontSize: "14px",
        }}
      >
        Are you renewing your insurance policy by yourself or with an advisor?
      </div>
      <Container>
        <Grid container>
          <Grid item lg={6} xs={12}>
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Select an option:</FormLabel> */}
              <Controller
                name="renewalOption"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RadioGroup
                    sx={{
                      "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                        {
                          color: "#038C8C",
                        },
                      "& .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label":
                        {
                          color: "#344054",
                          fontSize: "16px",
                          fontWeight: "bold",
                          lineHeight: "22.4px",
                          textAlign: "left",
                        },
                      width: "340px",
                    }}
                    {...field}
                  >
                    <FormControlLabel
                      value="self"
                      control={<Radio />}
                      label={"By Self"}
                    />

                    <p style={{ margin: "0px 0px 16px 32px" }}>
                      If you have renewed your policy by self then please upload
                      policy PDF
                    </p>
                    {/* <div
                      style={{
                        marginLeft: "30px",
                        lineHeight: "22.4px",
                        fontWeight: 500,
                        width: "312px",
                        marginTop: "-25px",
                        marginBottom: "-10px",
                      }}
                    >
                      <p>
                        If you have renewed your policy by self then please
                        <span
                          style={{
                            color: "#4373EB",
                            textDecoration: "underline",
                            marginLeft: "5px",
                            textDecorationSkipInk: "auto",
                          }}
                        >
                          upload policy PDF
                        </span>
                      </p>
                    </div> */}
                    <FormControlLabel
                      value="withAdvisor"
                      control={<Radio />}
                      label="With Advisor"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* {watch("renewalOption") === "self" && (
          <RealPreviewStyled
            style={{
              justifyContent: "flex-start",
            }}
            readOnlyStyles={false}
          >
            <Button
              bgColor="transparent"
              textColor="#11555F"
              id="add_broker"
              variant="outlined"
              borderColor="#11555F"
              endIcon={<CallMade />}
              onClick={() => window.open(filePreview, "_blank")}
            >
              Preview an Uploaded File
            </Button>
          </RealPreviewStyled>
        )} */}
        {/* {watch("renewalOption") === "self" && (
          <FilePicker
            name="document"
            label={"Document Upload Here"}
            control={control}
            files={files}
            setFiles={setFiles}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            unregister={unregister}
            isRequired={false}
            isReadOnly={false}
          />
        )} */}

        <ButtonWrapper
          style={{
            margin: "10px 0",
          }}
        >
          <Button
            type="submit"
            themeType={"theme1"}
            id="search_btn"
            bgColor={"#5E5ADB"}
            style={{
              borderRadius: "15px",
            }}
          >
            Proceed
          </Button>
          <Button
            themeType={"theme1"}
            btnBack={"tertiary"}
            id="reset_btn"
            textColor="#11555F"
            bgColor="transparent"
            borderColor="none"
            onClick={() =>
              setModalOpen({
                open: false,
                data: "",
              })
            }
          >
            Cancel
          </Button>
        </ButtonWrapper>
      </Container>
    </form>
  );
};

export default RenewInsurancePolicyModalForm;
