import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
  } from "@tanstack/react-query";
  import HttpClient from "../../api/httpClient";
  
  const useGetLOBMaster = () => {
    const { data, isFetching, isLoading } = useQuery({
      queryKey: ["LOB"],
      queryFn: () => HttpClient("lob/list"),
    });
  
    return { data, isFetching, isLoading };
  };
  
  export default useGetLOBMaster;
  
  export const useGetLobById = id => {
    console.log('id', id)
    const { data } = useQuery({
      queryKey: ["lobById", id],
      queryFn: () => HttpClient(`common_master/lob/list/${id}`),
      enabled: !!id,
    });
  
    return { data };
  };
  
  export const useGetBifurcatedData = () => {
    const queryClient = useQueryClient();
    const { data } = useQuery(["bifurcatedDataKey"], () =>
      HttpClient("customer_renewal_request/records", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          search: {
            transactionId: "",
            lobId: 0,
            raiseDate: "",
            completedDate: "",
            customerId: 0,
            stage: "",
          },
        },
      }),
    );
    return { data };
  };
  
  export const useUpdateRenewalStatus = () => {
    const queryClient = useQueryClient();
    const { mutate, data, isError } = useMutation(
      payload => {
      
        return HttpClient(
          `customer_renewal_request/update_stage/${payload.renewalId}`,
          {
            method: "PUT",
            data: payload,
          },
        );
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["bifurcatedDataKey"]);
          queryClient.invalidateQueries(["customer_renewal_request/listing"]);
        },
      },
    );
    return { mutate, data, isError };
  };
  