import moment from "moment";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  PLAN_TYPE_GRIHA_RAKSHA,
  INSURED_MEMBER_DETAILS,
  PLAN_TYPE_LAGHU_UDHYAM,
  LIFE_ASSURED_DETAILS,
  PI_DETAILS,
  POLICY_DETAILS,
  PROPERTY_DETAILS,
  PROPOSER_DETAILS,
  SATP,
  PLAN_TYPE_SOOKSHMA_UDHYAM,
  TP_1_YEAR,
  TP_LONG_TERM_3_YEARS,
  TP_LONG_TERM_5_YEARS,
  VEHICLE_DETAILS,
  companyFields,
  floaterCoverFields,
  grihaRakshaFields,
  individualFields,
  piCompanyFields,
  piIndividualFields,
  CARGO_DETAILS,
  SOURCING_DETAILS,
  PREMIUM_DETAILS,
  NOMINEE_DETAILS,
  INSURED_MEMBER_TRAVELLING_DETAILS,
  PROFESSIONAL_INDEMNITY_LOB,
  PET_LOB,
  SAOD,
  OFFICE_PACKAGE_LOB,
} from "./proposalCreateConstants";
import { Grid, Tooltip } from "@mui/material";
import { DatePickerInput, Input } from "../../components";
import {
  allCapitalize,
  allowAlphanumericHyphenAndSpace,
  allowAlphanumericHyphenSpaceAndCapitalize,
  allowInputInFootInchFormat,
  allowOnlyNumbers,
  allowOnlyNumbersAndDecimal,
  allowPercentageNumbersUpto100,
  allowValidPhoneNumber,
  handleFloatInput,
  preventCharactersInInput,
  preventSpecialAndNumbersInInput,
  preventSpecialInInput,
  preventWhiteSpaceInInput,
  preventZeroAsInitial,
  verifyValidAadhar,
  verifyValidEmail,
  verifyValidNumbers,
  verifyValidPAN,
  verifyValidPincode,
} from "../../HelperFunctions/HelperFunctions";
import ProposalFilePicker from "./proposalFilePicker";
import {
  BmiCategory,
  Description,
  EndorsementDiffSpan,
} from "./policyEntryStyles";
import _, { isNil, isUndefined } from "lodash";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import ProposalDropdown from "./proposalDropdown";
import { useSelector } from "react-redux";
import { checkDifference } from "./customHooks/checkDifference";
import ToggleButton from "./toggleButton";
import {
  BIKE_LOB,
  CAR_LOB,
  GCV_LOB,
  HEALTH_LOB,
  INVESTMENT_LOB,
  MARINE_LOB,
  PCV_LOB,
  PROPERTY_LOB,
  TERM_LOB,
  TRAVEL_LOB,
  WORKMENS_COMPENSATION_LOB,
} from "./claimJourneyConstant";
export const RenderFormFieldsEndorsement = ({
  fieldsArray,
  formTitle,
  nestedForm,
  nestedIndex,
  methods,
  otherData,
  otherFunctions,
  masterData,
  activeCount,
  payloadCheck,
  isPremiumDetailsReadOnly,
  theme,
  PREMIUM_FIELDS,
}) => {
  const {
    register,
    setValue,
    control,
    watch,
    errors,
    trigger,
    unregister,
    setError,
  } = methods;

  const {
    allCustomers,
    isCustomerLoading,
    allProducts,
    multipleSameRelationCustomerMembers,
    isProductLoading,
    branchData,
    fuelData,
    versionData,
    modelData,
    manufacturerData,
    masterTableData,
    lobData,
    userData,
    roleData,
    roleWiseUser,
    locationData,
    icData,
    addonData,
    sourceTypeData,
  } = masterData;

  const {
    summaryData,
    editCard,
    hideGrihaFields,
    policyDetailsBusinessType,
    isBusinessTypeConditionsLobWise,
    noFloaterCover,
    planTypeFromApi,
    policyDetailsPlanType,
    fieldsOtherThanGrihaRaksha,
    leadId,
    leadDocuments,
    customDropdownFields,
    summaryDataKeyValue,
    indexOfSelfNominee,
    indexOfSelfInsured,
  } = otherData;

  const {
    setSelectedCustomer,
    setCurrentField,
    currentField,
    setSelectedProduct,
    clearCustomerData,
    setFiles,
    files,
    setShowAddonBifurcation,
    showAddonBifurcation,
    setMembers,
    removeAddon,
  } = otherFunctions;

  const {
    isVehicleFinanced,
    isSameAddress,
    addresschecktwo,
    policystDate,
    policyExpirydate,
    pucExpiryDate,
    nomineeDob,
    regDate,
    policyStartdate,
    estimatedArrivaldate,
    estimatedDeparturedate,
    firstName,
    bookDate,
    maritialStatus,
    assuredmaritalStatus,
    addressCheck,
    bmi,
    pucStartdate,
    payments,
    medicalHistory,
    customerRefId,
    members: watchMembers,
    nominee: watchNominee,
    sourcing: watchSourcing,
    addOnPremiums: watchAddOnPremium,
    typeofcustomer,
    locationofRisk,
    medicalHistoryim,
    businessType,
    businessTypes,
    policyType,
    policyTypepd,
    fuelType,
    itSection,
    eiaNumber,
    assuredeiaNumber,
    dob,
    lifeAssureddob,
    policyExpirydatepd,
    idProof,
    addressProof,
    incomeProof,
    assuredidProof,
    assuredaddressProof,
    assuredincomeProof,
    occupation,
    planType,
    shippingtype,
    basisvaluation,
    standaloneGriha,
    detailsofinsuredProperty,
    coverType,
    selectContent,
    customertype,
    tenure,
    tenurenew, //prev policy
    policyTerm,
    tripType,
    travelPurpose,
    relWithprop,
  } = watch();
  const currentLob = lobData?.data?.data?.lob;
  const currentLobSlug = lobData?.data?.data?.lobslug;
  const isHealthLob = currentLobSlug === HEALTH_LOB;
  const isCarLob = currentLobSlug === CAR_LOB;
  const isBikeLob = currentLobSlug === BIKE_LOB;
  const isGcvLob = currentLobSlug === GCV_LOB;
  const isPcvLob = currentLobSlug === PCV_LOB;
  const isTermLob = currentLobSlug === TERM_LOB;
  const isPropertyLob = currentLobSlug === PROPERTY_LOB;
  const isMarineLob = currentLobSlug === MARINE_LOB;
  const isWorkmensCompensationLob =
    currentLobSlug === WORKMENS_COMPENSATION_LOB;
  const isInvestmentLob = currentLobSlug === INVESTMENT_LOB;
  const isTravelLob = currentLobSlug === TRAVEL_LOB;
  const isPetLob = currentLobSlug === PET_LOB;
  const isProfessionalIndemnityLob =
    currentLobSlug === PROFESSIONAL_INDEMNITY_LOB;
  const isOfficePackageLob = currentLobSlug === OFFICE_PACKAGE_LOB;

  const getComprehensiveOrTp = str => {
    const lowerStr = str?.toLowerCase();
    return lowerStr?.includes("comprehensive") || lowerStr?.includes("tp");
  };
  function extractNumbersFromString(str) {
    // Regular expression to match one or more digits
    const regex = /\d+/g;
    const numbers = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      numbers.push(parseInt(match[0], 10));
    }
    return numbers;
  }

  const isComprehensiveOrTp = getComprehensiveOrTp(businessType?.value);
  // const tpYears = extractNumbersFromString(businessType?.value)?.[
  //   extractNumbersFromString(businessType?.value)?.length - 1
  // ];

  const tpPeriod = extractNumbersFromString(businessType?.value)?.[
    extractNumbersFromString(businessType?.value)?.length - 1
  ];
  const odPeriod = extractNumbersFromString(businessType?.value)?.[
    extractNumbersFromString(businessType?.value)?.length - 2
  ];

  const tpPeriodForPrevPolicy = extractNumbersFromString(
    businessTypes?.value,
  )?.[extractNumbersFromString(businessTypes?.value)?.length - 1];
  const odPeriodForPrevPolicy = extractNumbersFromString(
    businessTypes?.value,
  )?.[extractNumbersFromString(businessTypes?.value)?.length - 2];

  const getMinDateYrs = (dateToCompare, years) => {
    if (dateToCompare && years) {
      return new Date(
        moment(dateToCompare).add(years, "year").subtract(1, "day"),
      );
    }
  };

  const proposerDetailsFromApi = summaryDataKeyValue;

  const policyDetailsFromApi = summaryDataKeyValue;

  const relationWithproposer =
    watchMembers?.[currentField?.index]?.relWithprop?.value;

  const startDateFromApi =
    summaryDataKeyValue && summaryDataKeyValue?.policystDate;

  const dobYesterDay = moment();
  const yesterday = moment(dobYesterDay).subtract(1, "days");
  const minDate =
    policystDate &&
    new Date(moment(policystDate).add(1, "year").subtract(1, "day")); // Min date for policy expiry date | policy details card
  const maxDateExp =
    policystDate &&
    new Date(moment(policystDate).add(3, "years").subtract(1, "day")); // Min date for policy expiry date | policy details card
  const minDate2 = policyStartdate && new Date(policyStartdate); // Min date for policy expiry date | prev policy details card
  const minDate3 = bookDate && new Date(bookDate); // Min date for policy start date
  const minDate4 =
    isWorkmensCompensationLob &&
    policystDate &&
    moment(policystDate).add(1, "month"); // Min date for policy exp date for workmensCompensation lob
  const maxDate4 =
    isWorkmensCompensationLob &&
    policystDate &&
    moment(policystDate).add(1, "year"); // Max date for policy exp date for workmensCompensation lob
  const pucStartMinDate = regDate && new Date(regDate); // Min date for puc start date
  const pucExpiryMinDate =
    pucStartdate && new Date(moment(pucStartdate).add(1, "days")); // Min date for puc expiry date
  const maxDate = policystDate && new Date(policystDate);

  const today = moment();
  const birthDate = moment(nomineeDob, "YYYY-MM-DD");
  const isNomineeMinor = today.diff(birthDate, "years");
  const proposerDob = today.diff(dob, "years");
  const assuredDob = today.diff(
    watchMembers?.[nestedIndex]?.lifeAssureddob,
    "years",
  );
  const nomDob = today.diff(watchNominee?.[nestedIndex]?.nomineeDob, "years");
  const insuredDob = today.diff(watchMembers?.[nestedIndex]?.dobIMD, "years");

  const selectedPropertyCoverType =
    (Array.isArray(coverType) && coverType?.map(item => item?.value)) || [];
  const selectedPropertyContentType =
    (Array.isArray(selectContent) && selectContent?.map(item => item?.value)) ||
    [];

  function formatDateDifference(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    // Calculate the total difference in years, months, and days
    const years = end.diff(start, "years");
    start.add(years, "years");

    const months = end.diff(start, "months");
    start.add(months, "months");

    const days = end.diff(start, "days");

    const formatted = `${String(years).padStart(2, "0")}y ${String(months).padStart(2, "0")}m ${String(days).padStart(2, "0")}d`;

    return formatted;
  }
  function getYearlySpecificDates(date, years) {
    let startDate = moment(date);
    let datesArray = [];
    for (let i = 1; i <= years; i++) {
      // Calculate the date exactly one year, two years, and three years, etc from today
      let date = startDate.clone().add(i, "years").subtract(1, "days");
      datesArray.push(date.format("YYYY-MM-DD"));
    }
    return datesArray;
  }

  const getBmiCategory = bmi => {
    if (bmi > 1 && bmi < 18.5) {
      return "( Underweight )";
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      return "( Normal Weight )";
    } else if (bmi >= 25 && bmi <= 29.9) {
      return "( Overweight )";
    } else if (bmi >= 30) {
      return "( Obese )";
    } else {
      return "";
    }
  };

  const bmiVal = getBmiCategory(bmi);

  const dynamicKey = (() => {
    switch (nestedForm) {
      case "nestedMembers":
        return `members[${nestedIndex}].`;
      case "nestedPayments":
        return `payments[${nestedIndex}].`;
      case "nestedEmployees":
        return `members[${nestedIndex}].`;
      case "nestedNominee":
        return `nominee[${nestedIndex}].`;
      case "nestedSources":
        return `sourcing[${nestedIndex}].`;
      case "nestedAddons":
        return `addOnPremiums[${nestedIndex}].`;

      default:
        return "";
    }
  })();

  const makeSelfFieldsReadOnly = (formTitle, index, indexOfSelfNominee) => { };

  return fieldsArray?.map((item, i) => {
    const allFieldCommonProps =
    // item?.category_name === "Premium Details" && isPremiumDetailsReadOnly
    //   ? {
    //       id: `${dynamicKey}${item.key}`,
    //       label: item.label,
    //       isRequired: item?.mandatory === "Y" && activeCount !== 1,
    //       placeholder: `Enter ${item?.label}`,
    //       description: item.description,
    //       errors: errors,
    //       readOnly: false || activeCount !== 0,
    //     }
    //   :
    {
      id: `${dynamicKey}${item.key}`,
      label: item.label,
      isRequired:
        activeCount !== 1 &&
        (item?.mandatory === "Y" ||
          [
            "addons",
            "amountType",
            "amount",
            "odPremium",
            "tpPremium",
          ].includes(item.key)),
      placeholder: `Enter ${item?.label}`,
      description: item.description,
      errors: errors,
      readOnly:
        // item?.category_name === "Premium Details"
        //   ? true
        //   :
        item?.is_editable === "N" ||
        item?.is_endorsable === "N" ||
        activeCount !== 0 ||
        (formTitle === NOMINEE_DETAILS &&
          indexOfSelfNominee === nestedIndex &&
          watchNominee?.[nestedIndex]?.nomineeRelation?.value === "Self" &&
          [
            "titleNd",
            "nomineeGender",
            "nomineeDob",
            "nomineeRemark",
            "nomineeFullname",
          ].includes(item?.key)) ||
        (["family floater", "individual"].includes(
          planType?.value?.toLowerCase(),
        ) &&
          ["insuredsumInsured"].includes(item?.key)) ||
        (formTitle === INSURED_MEMBER_DETAILS &&
          indexOfSelfInsured === nestedIndex &&
          watchMembers?.[nestedIndex]?.relWithprop?.value === "Self" &&
          [
            "insuredmemberName",
            "genderIMD",
            "dobIMD",
            "insuredContactnumber",
            "insuredeiaNumber",
            "insuredeiaRepository",
          ].includes(item?.key)) ||
        (formTitle === LIFE_ASSURED_DETAILS &&
          // 0 === nestedIndex &&
          relWithprop?.value === "Self" &&
          ![
            "relWithprop",
            "weightImd",
            "heighttype",
            "heightImd",
            "bmiImd",
          ].includes(item?.key)) ||
        (formTitle === PREMIUM_DETAILS &&
          showAddonBifurcation &&
          ["addOnpremium"]?.includes(item?.key)),
      // [
      //   "lifeassuredFname",
      //   "lifeassuredMname",
      //   "lifeassuredLname",
      //   "assuredmobileNo",
      //   "assuredmobileNotwo",
      //   "assuredEmail",
      //   "assuredEmailtwo",
      //   "assuredAnnualincome",
      //   "assuredAddressone",
      //   "assuredAddresstwo",
      //   "assuredLandmark",
      //   "assuredAnnivrsary",
      //   "lifeAssureddob",
      //   "assuredeiaNumber",
      //   "assuredeiaRepository",
      //   "lifeassuredGender",
      // ].includes(item?.key)),

      // proposalRead: activeCount === 1,
      // watch: watch,PREMIUM_FIELDS
    };

    const inputFieldCommonProps = {
      // minHeight: "58px",
      themeType: "theme1",
      name: `${dynamicKey}${item.key}`,
      maxLength: item.length,
      autoComplete: "none",
      inputRef: register(`${dynamicKey}${item.key}`),
      numberToWord: [
        "annualIncome",
        "coverage",
        "sumAssured",
        "maturityBenefit",
        "assuredAnnualincome",
        "insuredsumInsured",
        "totalAmount",
        "assuredDeath",
        "assuredMaturity",
        "benefitPayout",
        "customesum",
        "cargosum",
        "valuationamount",
        "addOnpremium",
        "suminsuredo",
        "suminsuredtwo",
        "suminsuredthree",
        "suminsuredfour",
        "contentsum",
        "structuresum",
        "Buildingsum",
        "cargovalue",
      ].includes(item?.key)
        ? true
        : item?.is_premium_bearing === "Y",
      watch: watch,
    };
    const dropdownAndDateCommonProps = {
      control: control,
      register: register,
      setValue: setValue,
      isClearable: true,
    };
    const commonPropsForGrid = {
      item: true,
      sx: { position: "relative" },
      lg: 3,
      md: 4,
      sm: 6,
      xs: 6,
    };
    const masterDataPropsForDropdown = {
      masterTableData: masterTableData,
      manufacturerData: manufacturerData,
      modelData: modelData,
      versionData: versionData,
      fuelData: fuelData,
      branchData: branchData,
      locationData: locationData,
      userData: userData,
      roleData: roleData,
      roleWiseUser: roleWiseUser,
      allCustomers: allCustomers,
      icData: icData,
      businessType: businessType?.value,
      policyType: policyType?.value || policyTypepd?.value,
      lobName: lobData?.data?.data?.lob,
      addonData: addonData,
      sourceTypeData: sourceTypeData,
      watchMembers: watchMembers,
      watchEmployee: watchMembers,
      watchNominee: watchNominee,
      watchSourcing: watchSourcing,
      watchAddOnPremium: watchAddOnPremium,
      typeOfCustomer: proposerDetailsFromApi?.typeofcustomer,
      typeOfPropertyCustomer: customertype?.value,
      watch: watch,
    };
    const identityProof = idProof?.value?.toLowerCase();
    const addProof = addressProof?.value?.toLowerCase();
    const getComprehensiveOrTp = str => {
      const lowerStr = str?.toLowerCase();
      return lowerStr?.includes("comprehensive") || lowerStr?.includes("tp");
    };
    function extractNumbersFromString(str) {
      // Regular expression to match one or more digits
      const regex = /\d+/g;
      const numbers = [];
      let match;
      while ((match = regex.exec(str)) !== null) {
        numbers.push(parseInt(match[0], 10));
      }
      return numbers;
    }
    const isPlanTypeComprehensiveOrTpForPrevPolicy = getComprehensiveOrTp(
      businessTypes?.value,
    );
    const isPlanTypeComprehensiveOrTp = getComprehensiveOrTp(
      businessType?.value,
    );
    const tpPeriod = extractNumbersFromString(businessType?.value)?.[
      extractNumbersFromString(businessType?.value)?.length - 1
    ];
    const resetHiddenFields = key => {
      if (watch(key) !== null) {
        setValue(key, null);
      }
    };

    const getMinDateFunc = () => {
      if (
        (isPropertyLob || isOfficePackageLob || isPetLob) &&
        ["1 Year"].includes(tenure?.label) &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(moment(policystDate).add(1, "year").subtract(1, "day"));
      } else if (
        (isPropertyLob || isOfficePackageLob || isPetLob) &&
        ["2-5 Years"].includes(tenure?.label) &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(moment(policystDate).add(2, "year").subtract(1, "day"));
      } else if (
        (isPropertyLob || isOfficePackageLob || isPetLob) &&
        ["more than 5 years"].includes(tenure?.label) &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(moment(policystDate).add(6, "year").subtract(1, "day"));
      } else if (isPetLob && ["Age"].includes(item.key)) {
        return new Date(moment().subtract(10, "year"));
      } else if (
        isWorkmensCompensationLob &&
        tenure?.label &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(
          moment(policystDate)
            .add(extractNumbersFromString(tenure?.label), "months")
            .subtract(1, "day"),
        );
      } else if (
        (isPropertyLob || isOfficePackageLob) &&
        ["1 Year"].includes(tenurenew?.label) &&
        ["policyExpirydatepd"].includes(item.key)
      ) {
        return new Date(
          moment(policyStartdate).add(1, "year").subtract(1, "day"),
        );
      } else if (
        (isPropertyLob || isOfficePackageLob) &&
        ["2-5 Years"].includes(tenurenew?.label) &&
        ["policyExpirydatepd"].includes(item.key)
      ) {
        return new Date(
          moment(policyStartdate).add(2, "year").subtract(1, "day"),
        );
      } else if (
        (isPropertyLob || isOfficePackageLob) &&
        ["more than 5 years"].includes(tenurenew?.label) &&
        ["policyExpirydatepd"].includes(item.key)
      ) {
        return new Date(
          moment(policyStartdate).add(6, "year").subtract(1, "day"),
        );
      } else if (
        isTermLob &&
        policyTerm &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(
          moment(policystDate).add(policyTerm, "year").subtract(1, "day"),
        );
      } else if (
        isBusinessTypeConditionsLobWise &&
        ["odexpirydate"]?.includes(item.key)
      ) {
        if (businessType?.value?.toLowerCase()?.includes("short")) {
          if (businessType?.value?.toLowerCase()?.includes("month")) {
            return new Date(
              moment(policystDate).add(tpPeriod, "month").subtract(1, "day"),
            );
          } else {
            return new Date(
              moment(policystDate).add(tpPeriod, "years").subtract(1, "day"),
            );
          }
        } else if (businessType?.value === SAOD) {
          return getMinDateYrs(policystDate, 1);
        } else {
          return new Date(
            moment(policystDate).add(odPeriod, "years").subtract(1, "day"),
          );
        }
      } else if (
        isBusinessTypeConditionsLobWise &&
        ["odexpirydatepd"]?.includes(item.key)
      ) {
        if (businessTypes?.value?.toLowerCase()?.includes("short")) {
          if (businessTypes?.value?.toLowerCase()?.includes("month")) {
            return new Date(
              moment(policyStartdate)
                .add(tpPeriodForPrevPolicy, "month")
                .subtract(1, "day"),
            );
          } else {
            return new Date(
              moment(policyStartdate)
                .add(tpPeriodForPrevPolicy, "years")
                .subtract(1, "day"),
            );
          }
        } else if (businessTypes?.value === SAOD) {
          return getMinDateYrs(policyStartdate, 1);
        } else {
          return new Date(
            moment(policyStartdate)
              .add(odPeriodForPrevPolicy, "years")
              .subtract(1, "day"),
          );
        }
      }
      //  else if (
      //   (isBusinessTypeConditionsLobWise) &&
      //   ["odexpirydatepd"]?.includes(item.key)
      // ) {
      //   if (policyDetailsFromApi?.odexpirydate) {
      //     return new Date(moment(policyDetailsFromApi?.odexpirydate));
      //   }
      // }
      else if (
        isPlanTypeComprehensiveOrTp &&
        isBusinessTypeConditionsLobWise &&
        ["tpexpirydate"]?.includes(item.key)
      ) {
        if (businessType?.value?.toLowerCase()?.includes("month")) {
          return new Date(
            moment(policystDate).add(tpPeriod, "month").subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policystDate).add(tpPeriod, "years").subtract(1, "day"),
          );
        }
      } else if (
        isPlanTypeComprehensiveOrTpForPrevPolicy &&
        isBusinessTypeConditionsLobWise &&
        ["tpexpirydatepd"]?.includes(item.key)
      ) {
        if (businessTypes?.value?.toLowerCase()?.includes("month")) {
          return new Date(
            moment(policyStartdate)
              .add(tpPeriodForPrevPolicy, "month")
              .subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policyStartdate)
              .add(tpPeriodForPrevPolicy, "years")
              .subtract(1, "day"),
          );
        }
      } else if (
        !isPlanTypeComprehensiveOrTp &&
        isBusinessTypeConditionsLobWise &&
        ["tpexpirydate"]?.includes(item.key)
      ) {
        return getMinDateYrs(policystDate, 1);
      } else if (
        !isPlanTypeComprehensiveOrTpForPrevPolicy &&
        isBusinessTypeConditionsLobWise &&
        ["tpexpirydatepd"]?.includes(item.key)
      ) {
        return getMinDateYrs(policyStartdate, 1);
      }
      // else if (
      //   !isPlanTypeComprehensiveOrTp &&
      //   isCarLob &&
      //   ["tpexpirydate"]?.includes(item.key)
      // ) {
      //   return getMinDateYrs(policystDate, 3);
      // } else if (
      //   !isPlanTypeComprehensiveOrTp &&
      //   isBikeLob &&
      //   ["tpexpirydate"]?.includes(item.key)
      // ) {
      //   return getMinDateYrs(policystDate, 5);
      // }
      else if (
        businessType?.label &&
        !["HospiCash"]?.includes(businessType?.label) &&
        ["policyExpirydate"]?.includes(item.key)
      ) {
        return minDate;
      } else if (
        businessType?.label &&
        ["HospiCash"]?.includes(businessType?.label) &&
        ["policyExpirydate"]?.includes(item.key)
      ) {
        return new Date(moment(policystDate).add(1, "day"));
      } else if (
        isMarineLob &&
        planType?.label === "Annual Open " &&
        ["policyExpirydate", "policyExpirydatepd"]?.includes(item.key)
      ) {
        if (formTitle === POLICY_DETAILS) {
          return new Date(
            moment(policystDate).add(1, "year").subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policyStartdate).add(1, "year").subtract(1, "day"),
          );
        }
      } else if (
        isMarineLob &&
        planType?.label === "Singel Transit" &&
        ["policyExpirydate", "policyExpirydatepd"]?.includes(item.key)
      ) {
        if (formTitle === POLICY_DETAILS) {
          return new Date(
            moment(policystDate).add(3, "months").subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policyStartdate).add(3, "months").subtract(1, "day"),
          );
        }
      } else if (
        isTravelLob &&
        travelPurpose?.label === "Multi Individual" &&
        ["policyExpirydate"]?.includes(item.key)
      ) {
        return new Date(moment(policystDate).add(15, "days"));
      } else if (
        isProfessionalIndemnityLob &&
        ["policyExpirydate"]?.includes(item.key)
      ) {
        return new Date(moment(policystDate).add(1, "year").subtract(1, "day"));
      } else if (["policyExpirydate"]?.includes(item.key)) {
        return new Date(moment(policystDate).add(1, "day"));
      } else if (isMarineLob && ["estimatedArrivaldate"]?.includes(item.key)) {
        if (!estimatedDeparturedate) {
          return new Date(moment(policyDetailsFromApi?.policystDate));
        } else if (
          moment(policyDetailsFromApi?.policystDate).isAfter(
            moment(estimatedDeparturedate),
          )
        ) {
          return new Date(moment(policyDetailsFromApi?.policystDate));
        } else {
          return new Date(moment(estimatedDeparturedate));
        }
      } else if (["estimatedDeparturedate"]?.includes(item.key)) {
        return new Date(moment(policyDetailsFromApi?.policystDate));
      } else if (item.key === "policyExpirydatepd") {
        return minDate2;
      } else if (["journeyEndDate", "journeyStartDate"].includes(item.key)) {
        return new Date(policyDetailsFromApi?.policystDate);
      } else if (item.key === "policystDate") {
        return minDate3;
      } else if (item.key === "pucStartdate") {
        return pucStartMinDate;
      } else if (["pucExpiryDate", "pucExpirydate"]?.includes(item.key)) {
        return pucExpiryMinDate;
      } else if (item.key === "estimatedArrivaldate") {
        return estimatedDeparturedate;
      } else if (isMarineLob && ["invoicedaten"]?.includes(item?.key)) {
        return new Date(moment(policyDetailsFromApi?.bookDate));
      } else {
        return;
      }
    };

    const getMaxDateFunc = () => {
      if (["policyExpirydate"].includes(item.key) && isHealthLob) {
        return new Date(maxDateExp);
      } else if (
        isMarineLob &&
        planType?.label === "Annual Open " &&
        ["policyExpirydate", "policyExpirydatepd"]?.includes(item.key)
      ) {
        if (formTitle === POLICY_DETAILS) {
          return new Date(
            moment(policystDate).add(1, "year").subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policyStartdate).add(1, "year").subtract(1, "day"),
          );
        }
      } else if (
        isMarineLob &&
        planType?.label === "Singel Transit" &&
        ["policyExpirydate", "policyExpirydatepd"]?.includes(item.key)
      ) {
        if (formTitle === POLICY_DETAILS) {
          return new Date(
            moment(policystDate).add(3, "months").subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policyStartdate).add(3, "months").subtract(1, "day"),
          );
        }
      } else if (isMarineLob && ["invoicedaten"]?.includes(item?.key)) {
        return new Date(moment(policyDetailsFromApi?.policyExpirydate));
      } else if (
        (isPropertyLob || isOfficePackageLob || isPetLob) &&
        ["1 Year"].includes(tenure?.label) &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(moment(policystDate).add(1, "year").subtract(1, "day"));
      } else if (
        (isPropertyLob || isOfficePackageLob || isPetLob) &&
        ["2-5 Years"].includes(tenure?.label) &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(moment(policystDate).add(5, "year").subtract(1, "day"));
      } else if (
        (isPropertyLob || isOfficePackageLob || isPetLob) &&
        ["more than 5 years"].includes(tenure?.label) &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(
          moment(policystDate)
            .add(2100 - moment().year(), "year")
            .subtract(1, "day"),
        );
      } else if (isPetLob && ["Age"].includes(item.key)) {
        return new Date(moment());
      } else if (
        isWorkmensCompensationLob &&
        tenure?.label &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(
          moment(policystDate)
            .add(extractNumbersFromString(tenure?.label), "months")
            .subtract(1, "day"),
        );
      } else if (
        (isPropertyLob || isOfficePackageLob) &&
        ["1 Year"].includes(tenurenew?.label) &&
        ["policyExpirydatepd"].includes(item.key)
      ) {
        return new Date(
          moment(policyStartdate).add(1, "year").subtract(1, "day"),
        );
      } else if (
        (isPropertyLob || isOfficePackageLob) &&
        ["2-5 Years"].includes(tenurenew?.label) &&
        ["policyExpirydatepd"].includes(item.key)
      ) {
        return new Date(
          moment(policyStartdate).add(5, "year").subtract(1, "day"),
        );
      } else if (
        (isPropertyLob || isOfficePackageLob) &&
        ["more than 5 years"].includes(tenurenew?.label) &&
        ["policyExpirydatepd"].includes(item.key)
      ) {
        return new Date(
          moment(policyStartdate)
            .add(2100 - moment().year(), "year")
            .subtract(1, "day"),
        );
      } else if (
        isTermLob &&
        policyTerm &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return new Date(
          moment(policystDate).add(policyTerm, "year").subtract(1, "day"),
        );
      } else if (
        isTravelLob &&
        ["Multi Individual", "Individual"].includes(travelPurpose?.label) &&
        ["policyExpirydate"]?.includes(item.key)
      ) {
        return new Date(moment(policystDate).add(1, "year").subtract(1, "day"));
      } else if (["policyStartdate", "policyExpirydatepd"].includes(item.key)) {
        return new Date(startDateFromApi);
      } else if (item.key === "policystDate" && isHealthLob) {
        return new Date(moment(bookDate).add(6, "months"));
      } else if (item.key === "inceptionDate") {
        return new Date(moment(policystDate));
      } else if (
        formTitle === VEHICLE_DETAILS &&
        ["regDate"]?.includes(item.key)
      ) {
        return new Date(
          moment(startDateFromApi).isBefore(moment())
            ? startDateFromApi
            : moment(),
        );
      } else if (
        watch(`nominee.[${nestedIndex}].nomineeRelation`)?.label === "Spouse" &&
        ["nomineeDob"]?.includes(item.key)
      ) {
        return new Date(moment().subtract(18, "years"));
      } else if (
        isBusinessTypeConditionsLobWise &&
        isPlanTypeComprehensiveOrTp &&
        ["tpexpirydate"]?.includes(item.key)
      ) {
        if (businessType?.value?.toLowerCase()?.includes("month")) {
          return new Date(
            moment(policystDate).add(tpPeriod, "month").subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policystDate).add(tpPeriod, "years").subtract(1, "day"),
          );
        }
      } else if (
        isBusinessTypeConditionsLobWise &&
        isPlanTypeComprehensiveOrTpForPrevPolicy &&
        ["tpexpirydatepd"]?.includes(item.key)
      ) {
        if (businessTypes?.value?.toLowerCase()?.includes("month")) {
          return new Date(
            moment(policyStartdate)
              .add(tpPeriodForPrevPolicy, "month")
              .subtract(1, "day"),
          );
        } else {
          return new Date(
            moment(policyStartdate)
              .add(tpPeriodForPrevPolicy, "years")
              .subtract(1, "day"),
          );
        }
      } else if (
        isBusinessTypeConditionsLobWise &&
        ["odexpirydate"]?.includes(item.key)
      ) {
        if (businessType?.value?.toLowerCase()?.includes("short")) {
          if (businessType?.value?.toLowerCase()?.includes("month")) {
            return new Date(
              moment(policystDate).add(tpPeriod, "month").subtract(1, "day"),
            );
          } else {
            return new Date(
              moment(policystDate).add(tpPeriod, "years").subtract(1, "day"),
            );
          }
        } else if (businessType?.value === SAOD) {
          return getMinDateYrs(policystDate, 1);
        } else {
          return new Date(
            moment(policystDate).add(odPeriod, "years").subtract(1, "day"),
          );
        }
      } else if (
        isBusinessTypeConditionsLobWise &&
        ["odexpirydatepd"]?.includes(item.key)
      ) {
        if (businessTypes?.value?.toLowerCase()?.includes("short")) {
          if (businessTypes?.value?.toLowerCase()?.includes("month")) {
            return new Date(
              moment(policyStartdate)
                .add(tpPeriodForPrevPolicy, "month")
                .subtract(1, "day"),
            );
          } else {
            return new Date(
              moment(policyStartdate)
                .add(tpPeriodForPrevPolicy, "years")
                .subtract(1, "day"),
            );
          }
        } else if (businessTypes?.value === SAOD) {
          return getMinDateYrs(policyStartdate, 1);
        } else {
          return new Date(
            moment(policyStartdate)
              .add(odPeriodForPrevPolicy, "years")
              .subtract(1, "day"),
          );
        }
      }
      //  else if (
      //   (isBusinessTypeConditionsLobWise) &&
      //   ["odexpirydatepd"]?.includes(item.key)
      // ) {
      //   if (policyDetailsFromApi?.odexpirydate) {
      //     return new Date(
      //       moment(policyDetailsFromApi?.odexpirydate)
      //         .add(10, "days")
      //         .subtract(1, "day"),
      //     );
      //   }
      // }
      else if (["nomineeDob", "regDate", "leadGendate"].includes(item.key)) {
        return new Date();
      } else if (["dob"]?.includes(item.key) && isInvestmentLob) {
        return new Date(moment().subtract(1, "year"));
      } else if (["dob", "lifeAssureddob"]?.includes(item.key)) {
        return new Date(moment().subtract(18, "years"));
      } else if (["journeyEndDate", "journeyStartDate"].includes(item.key)) {
        return new Date(policyDetailsFromApi?.policyExpirydate);
      } else if (
        relationWithproposer === "Spouse" &&
        ["dobIMD"]?.includes(item.key)
      ) {
        return new Date(moment().subtract(18, "years"));
      } else if (["nomineeDob", "dobIMD"].includes(item.key)) {
        return yesterday._d;
      } else if (["paymentDate"].includes(item.key)) {
        return new Date(policyDetailsFromApi?.policyExpirydate);
      } else if (
        ["estimatedDeparturedate", "estimatedArrivaldate"]?.includes(item.key)
      ) {
        return new Date(moment(policyDetailsFromApi?.policyExpirydate));
      } else if (
        isProfessionalIndemnityLob &&
        ["policyExpirydate"]?.includes(item.key)
      ) {
        return new Date(moment(policystDate).add(1, "year").subtract(1, "day"));
      } else if (item.key === "estimatedDeparturedate") {
        return estimatedArrivaldate;
      } else if (item.key === "ObtainedYear") {
        return new Date();
      } else if (item.key === "pucStartdate") {
        return pucExpiryDate;
      } else if (item.key === "bookDate") {
        return new Date();
      } else if (item.key === "issuedate") {
        return new Date();
      } else if (isTravelLob && item.key === "dateOfBirth") {
        if (
          ["Study Plan"].includes(policyDetailsFromApi?.travelPurpose) ||
          policyDetailsFromApi?.travel === "Studies"
        ) {
          return new Date(moment().subtract(18, "years"));
        } else {
          return new Date(moment().subtract(91, "days"));
        }
      }
    };

    // item.field = field_type from API
    if (item.field === "HIDDEN") {
      return null;
    } else {
      // new requirement to display both documents
      // if (pastInsurance?.label === "No" && item.key === "uploadPdf") {
      //   return <></>;
      // }
      if (maritialStatus?.value !== "Married" && item?.key === "anniversary") {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        watch(`assuredmaritalStatus`)?.value !== "Married" &&
        item?.key === "assuredAnnivrsary"
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!payments?.[nestedIndex]?.paymentMode?.value &&
          item.key === "micrCode") ||
        (payments?.[nestedIndex]?.paymentMode?.value === "Online" &&
          ["chequeNo", "micrCode"].includes(item.key))
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!payments?.[nestedIndex]?.paymentMode?.value &&
          item.key === "chequeNo") ||
        (payments?.[nestedIndex]?.paymentMode?.value === "Online" &&
          item.key === "chequeNo") ||
        (payments?.[nestedIndex]?.paymentMode?.value === "Cash" &&
          item.key === "chequeNo")
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!payments?.[nestedIndex]?.paymentMode?.value &&
          ["ecsdate", "ecspayment"].includes(item.key)) ||
        (payments?.[nestedIndex]?.paymentMode?.value &&
          payments?.[nestedIndex]?.paymentMode?.value !== "Online" &&
          ["ecsdate", "ecspayment"].includes(item.key))
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!payments?.[nestedIndex]?.ecspayment &&
          ["ecsdate"].includes(item.key)) ||
        (payments?.[nestedIndex]?.ecspayment !== "Yes" &&
          ["ecsdate"].includes(item.key))
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["PaymentReceipt", "itCertificate"].includes(item.key) &&
        nestedIndex
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!medicalHistory && item.key === "uploadDocument") ||
        (medicalHistory === "No" && item.key === "uploadDocument")
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }

      if (
        watch(`members[${nestedIndex}].medicalHistoryim`) !== "Yes" &&
        item.key === "uploadDocumentim" &&
        formTitle === INSURED_MEMBER_DETAILS
        //    ||
        // (medicalHistoryim !== "Yes" &&
        //   item.key === "uploadDocumentim" &&
        //   formTitle === LIFE_ASSURED_DETAILS)
      ) {
        if (
          watch(`members[${nestedIndex}].medicalHistoryim`) !== undefined &&
          watch(`members[${nestedIndex}].medicalHistoryim`) !== "Yes"
        ) {
          resetHiddenFields(`members[${nestedIndex}].${item?.key}`);
        }
        return <></>;
      }
      if (
        watch(`medicalHistoryim`) !== "Yes" &&
        item.key === "uploadDocumentim" &&
        formTitle === LIFE_ASSURED_DETAILS
        //    ||
        // (medicalHistoryim !== "Yes" &&
        //   item.key === "uploadDocumentim" &&
        //   formTitle === LIFE_ASSURED_DETAILS)
      ) {
        if (
          watch(`medicalHistoryim`) !== undefined &&
          watch(`medicalHistoryim`) !== "Yes"
        ) {
          resetHiddenFields(`${item?.key}`);
        }
        return <></>;
      }
      if (
        !watch(`members[${nestedIndex}].insuredeiaNumber`) &&
        !isUndefined(watch(`members[${nestedIndex}].insuredeiaNumber`)) &&
        item.key === "insuredeiaRepository" &&
        formTitle === INSURED_MEMBER_DETAILS
      ) {
        resetHiddenFields(`members[${nestedIndex}].insuredeiaRepository`);
        return <></>;
      }

      if (
        payments?.[nestedIndex]?.paymentMode?.value === "Cash" &&
        [
          "micrCode",
          "ifscCode",
          "banName",
          "bankBranchname",
          "accNumber",
          "accountType",
          "transactionId",
          "chequeNo",
          "paymentFile",
        ].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!watchNominee?.[nestedIndex]?.nomineeDob &&
          !isUndefined(watchNominee?.[nestedIndex]?.nomineeDob) &&
          item.key === "appointeeName") ||
        (nomDob >= 18 && item.key === "appointeeName")
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        companyFields?.includes(item.key) &&
        typeofcustomer?.label === "Individual" &&
        formTitle === PROPOSER_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        individualFields?.includes(item.key) &&
        typeofcustomer?.label === "Company" &&
        formTitle === PROPOSER_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      // if (
      //   grihaRakshaFields?.includes(item?.key) &&
      //   hideGrihaFields?.includes(policyDetailsPlanType)
      // ) {
      //   return <></>;
      // }
      // if (
      //   fieldsOtherThanGrihaRaksha?.includes(item?.key) &&
      //   policyDetailsPlanType === PLAN_TYPE_GRIHA_RAKSHA
      // ) {
      //   return <></>;
      // }
      if (
        formTitle === PI_DETAILS &&
        piIndividualFields?.includes(item?.key) &&
        !piIndividualFields
          .filter(field => piCompanyFields.includes(field))
          ?.includes(item?.key) &&
        planTypeFromApi === "Company"
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === PI_DETAILS &&
        piCompanyFields?.includes(item?.key) &&
        !piCompanyFields
          .filter(field => piIndividualFields.includes(field))
          ?.includes(item?.key) &&
        planTypeFromApi === "Individual"
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (floaterCoverFields?.includes(item?.key) && noFloaterCover) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        (!isVehicleFinanced || isVehicleFinanced === "No") &&
        ["selectFinacer", "financerAggrementType", "financerCity"].includes(
          item.key,
        )
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        isSameAddress === "Yes" &&
        formTitle !== PROPOSER_DETAILS &&
        formTitle !== LIFE_ASSURED_DETAILS &&
        !isMarineLob &&
        ["addressLineonev", "pinCode", "state", "city"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        isTermLob &&
        watch(`occupationTerm`)?.label !== "Others" &&
        ["otherOccupation"].includes(item?.key)
      ) {
        if (
          watch(`occupationTerm`)?.label &&
          watch(`occupationTerm`)?.label !== "Others"
        ) {
          // resetHiddenFields(item?.key);
        }
        return <></>;
      }
      if (
        isInvestmentLob &&
        watch(`occupationInvestment`)?.label !== "Others" &&
        ["otherOccupation"].includes(item?.key)
      ) {
        if (
          watch(`occupationInvestment`)?.label &&
          watch(`occupationInvestment`)?.label !== "Others"
        ) {
          // resetHiddenFields(item?.key);
        }
        return <></>;
      }
      if (
        watch(`addresschecktwo`) !== "No" &&
        formTitle === LIFE_ASSURED_DETAILS &&
        [
          "addressAssuredone",
          "addressAssuredtwo",
          "assuredPincode",
          "assuredState",
          "assuredCity",
          "landmarkAssured",
        ].includes(item.key)
      ) {
        return <></>;
      }
      if (
        formTitle === PROPERTY_DETAILS &&
        locationofRisk !== "No" &&
        [
          "addressLineoneinsured",
          "addressLinetwoinsured",
          "pincodeinsured",
          "stateinsured",
          "cityinsured",
        ].includes(item.key)
      ) {
        return <></>;
      }
      if (
        (formTitle === VEHICLE_DETAILS || formTitle === PROPOSER_DETAILS) &&
        isSameAddress !== "No" &&
        [
          "addressLineonev",
          "addressLinetwov",
          "pincodeVd",
          "stateVd",
          "cityVd",
        ].includes(item.key)
      ) {
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        !fuelType?.label &&
        ["pucNumber", "pucStartdate", "pucExpiryDate", "kwh", "cc"].includes(
          item.key,
        )
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        fuelType?.label === "ELECTRIC" &&
        ["pucNumber", "pucStartdate", "pucExpiryDate", "cc"].includes(item.key)
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        fuelType?.label !== "ELECTRIC" &&
        ["kwh"].includes(item.key)
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        isBusinessTypeConditionsLobWise &&
        ([
          TP_1_YEAR,
          TP_LONG_TERM_5_YEARS,
          TP_LONG_TERM_3_YEARS,
          SATP,
        ]?.includes(businessType?.label) ||
          (!businessType?.label &&
            [
              TP_1_YEAR,
              TP_LONG_TERM_5_YEARS,
              TP_LONG_TERM_3_YEARS,
              SATP,
            ]?.includes(policyDetailsBusinessType))) &&
        ["odexpirydate", "odPremium"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!["Personal Accident"]?.includes(businessType?.label) ||
          (!businessType?.label &&
            !["Personal Accident"]?.includes(policyDetailsBusinessType))) &&
        ["deathbenefit", "temporaryDisablement"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!["Top-up and Super Top-up"]?.includes(businessType?.label) ||
          (!businessType?.label &&
            !["Top-up and Super Top-up"]?.includes(
              policyDetailsBusinessType,
            ))) &&
        ["deductible"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        !idProof?.label &&
        ["identityvalue", "uploadIdentity"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        !watch(`assuredidProof`)?.label &&
        ["identityvalueN", "uploadIdentitydoc"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        !addressProof?.label &&
        ["addressvalue", "uploadAddress"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        !watch(`assuredaddressProof`)?.label &&
        ["addressvalueN", "addressproofN"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        !incomeProof?.label &&
        ["uploadIncome", "incomeValue"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (idProof?.label !== "Others" && ["identityName"].includes(item.key)) {
        return <></>;
      }
      if (
        addressProof?.label !== "Other" &&
        ["addressName"].includes(item.key)
      ) {
        return <></>;
      }
      if (incomeProof?.label !== "Other" && ["icomeName"].includes(item.key)) {
        return <></>;
      }

      if (
        watch(`assuredidProof`)?.label !== "Others" &&
        ["idproofname"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        watch(`assuredaddressProof`)?.label !== "Other" &&
        ["addressproofname"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        watch(`assuredincomeProof`)?.label !== "Other" &&
        ["Incomename"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !isUndefined(occupation) &&
        occupation?.label !== "Others" &&
        ["occupationN"].includes(item.key)
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        !watch(`assuredincomeProof`)?.label &&
        ["uploadIncomeN"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (incomeProof?.label == "None" && ["uploadIncome"].includes(item.key)) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        watch(`assuredincomeProof`)?.label == "None" &&
        ["uploadIncomeN"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        policyType?.label !== "Renewal" &&
        ["renewalNoticePdf"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        !["Portability", "Renewal"].includes(policyType?.label) &&
        ["inceptionDate"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        (!["HospiCash"]?.includes(businessType?.label) ||
          (!businessType?.label &&
            !["HospiCash"]?.includes(policyDetailsBusinessType))) &&
        ["hospitalDailylimit"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        isBusinessTypeConditionsLobWise &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return <></>;
      }
      if (!itSection?.label && ["certificate"].includes(item.key)) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        // eiaNumber !== undefined &&

        !eiaNumber &&
        !isUndefined(eiaNumber) &&
        ["eiaRepository"].includes(item.key)
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }

      if (
        formTitle === LIFE_ASSURED_DETAILS &&
        !watch(`assuredeiaNumber`) &&
        [`assuredeiaRepository`].includes(item.key)
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        // building type
        (!standaloneGriha?.label ||
          standaloneGriha?.label !== "Multi-storey building") &&
        ["floorNo"].includes(item.key) &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        detailsofinsuredProperty?.label !== "Other" &&
        ["propertyName"].includes(item.key) &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        [
          "contentsum",
          "selectContent",
          "suminsuredo",
          "suminsuredtwo",
          "suminsuredthree",
          "suminsuredfour",
        ].includes(item.key) &&
        !selectedPropertyCoverType?.includes("Content") &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["structuresum"].includes(item.key) &&
        !selectedPropertyCoverType?.includes("Structure") &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["Buildingsum"].includes(item.key) &&
        !selectedPropertyCoverType?.includes("Property") &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        [
          "suminsuredo",
          "suminsuredtwo",
          "suminsuredthree",
          "suminsuredfour",
        ].includes(item.key) &&
        !selectContent?.length &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["suminsuredo"].includes(item.key) &&
        !selectedPropertyContentType?.includes("Electrical / Electronic") &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["suminsuredtwo"].includes(item.key) &&
        !selectedPropertyContentType?.includes(
          "Furniture & Fixtures, Fittings and other equipment",
        ) &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["suminsuredthree"].includes(item.key) &&
        !selectedPropertyContentType?.includes("Other Content") &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["suminsuredfour"].includes(item.key) &&
        !selectedPropertyContentType?.includes("Plant & Machinery") &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === PI_DETAILS &&
        piIndividualFields?.includes(item?.key) &&
        !piIndividualFields
          .filter(field => piCompanyFields.includes(field))
          ?.includes(item?.key) &&
        customertype?.label === "Company"
      ) {
        resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === PI_DETAILS &&
        piCompanyFields?.includes(item?.key) &&
        !piCompanyFields
          .filter(field => piIndividualFields.includes(field))
          ?.includes(item?.key) &&
        customertype?.label === "Individual"
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        [
          "companyName",
          "registrationDetails",
          "detailsofinsuredProperty",
          "propertyName",
          "companygst",
          "companypan",
          "addressLineonepd",
          "addresslinetwopd",
          "pincodepdP",
          "StateP",
          "cityP",
          "addresscompany",
          "uploadMSME",
        ].includes(item.key) &&
        customertype?.value !== "Business" &&
        isPropertyLob &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        ["rateofcostGriha", "suminsuredhouseGriha"].includes(item.key) &&
        customertype?.value !== "Home Insurance" &&
        formTitle === PROPERTY_DETAILS
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === CARGO_DETAILS &&
        !shippingtype?.label &&
        ["cargosum", "commoditytype", "currencytype", "customesum"].includes(
          item.key,
        )
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        isTravelLob &&
        formTitle === POLICY_DETAILS &&
        tripType?.label !== "Multi Trip" &&
        ["tripDuration"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        isTravelLob &&
        formTitle === INSURED_MEMBER_TRAVELLING_DETAILS &&
        watch(`members[${nestedIndex}].diseaseanyN`) !== "Yes" &&
        ["specifyDisease"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        isTravelLob &&
        formTitle === NOMINEE_DETAILS &&
        nestedIndex >= 1 &&
        ["relationP"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === CARGO_DETAILS &&
        shippingtype?.label === "Inland" &&
        [
          "currencytype",
          "customesum",
          "fromcountry",
          "fromstate",
          "customfromstate",
          "tocountry",
          "tostate",
          "customtostate",
        ].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === CARGO_DETAILS &&
        shippingtype?.label === "Export" &&
        [
          "customesum",
          "pincodeOne",
          "stateOne",
          "cityOne",
          "pincodetwo",
          "statetwo",
          "citytwo",
        ].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === CARGO_DETAILS &&
        shippingtype?.label === "Import" &&
        [
          "pincodeOne",
          "stateOne",
          "cityOne",
          "pincodetwo",
          "statetwo",
          "citytwo",
        ].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === CARGO_DETAILS &&
        shippingtype?.label &&
        ((shippingtype?.label === "Export" &&
          ["tostate", "customfromstate"].includes(item?.key)) ||
          (shippingtype?.label === "Import" &&
            ["fromstate", "customtostate"].includes(item?.key)))
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === CARGO_DETAILS &&
        !basisvaluation?.label &&
        ["valuationamount"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === SOURCING_DETAILS &&
        // !watchSourcing?.[nestedIndex]?.sourceType?.label &&
        !watchSourcing?.[nestedIndex]?.userType?.label &&
        ["role", "employeeName", "customerName", "referencerName"].includes(
          item.key,
        )
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === SOURCING_DETAILS &&
        // watchSourcing?.[nestedIndex]?.sourceType?.label !== "Employee" &&
        watchSourcing?.[nestedIndex]?.userType?.label !== "Employee" &&
        ["role", "employeeName"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === SOURCING_DETAILS &&
        // watchSourcing?.[nestedIndex]?.sourceType?.label !== "Customer" &&
        watchSourcing?.[nestedIndex]?.userType?.label !== "Customer" &&
        ["customerName"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === SOURCING_DETAILS &&
        // ["Customer", "Employee"].includes(
        //   watchSourcing?.[nestedIndex]?.sourceType?.label,
        // ) &&
        ["Customer", "Employee"].includes(
          watchSourcing?.[nestedIndex]?.userType?.label,
        ) &&
        ["referencerName"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === CARGO_DETAILS &&
        policyDetailsPlanType !== "Annual Open" &&
        ["perlocationlimit", "persendinglimit"].includes(item.key)
      ) {
        // resetHiddenFields(item?.key);
        return <></>;
      }
      if (
        formTitle === PROPOSER_DETAILS &&
        isSameAddress !== "No" &&
        isMarineLob &&
        ["addresspdone", "addresspdtwo", "landmarkpdtwo"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        formTitle === PROPOSER_DETAILS &&
        addressCheck !== "No" &&
        !isMarineLob &&
        [
          "addresspdone",
          "addresspdtwo",
          "pinCodetwo",
          "pincodetwo",
          "statetwo",
          "citytwo",
          "landmarkpdtwo",
        ].includes(item?.key)
      ) {
        return <></>;
      } else if (
        multipleSameRelationCustomerMembers?.length > 0
          ? [...customDropdownFields, "nomineeFullname"].includes(item.key) &&
          formTitle !== INSURED_MEMBER_TRAVELLING_DETAILS
          : customDropdownFields?.includes(item.key) &&
          formTitle !== INSURED_MEMBER_TRAVELLING_DETAILS
      ) {
        return (
          <Grid {...commonPropsForGrid}>
            {!!firstName?.length && ["firstName"].includes(item.key) && (
              <Tooltip placement="top" arrow title="Remove Customer">
                <CloseIcon
                  sx={{
                    color: "grey",
                    fontSize: "20px",
                    cursor: "pointer",
                    zIndex: 2,
                    position: "absolute",
                    top: "39px",
                    right: "8px",
                  }}
                  onClick={() => {
                    setValue("customerRefId", "");
                    clearCustomerData();
                    setSelectedCustomer(null);
                  }}
                />
              </Tooltip>
            )}
            <CustomDropdown
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              placeholder={`Select / Enter ${item?.label}`}
              onChange={e => {
                if (nestedForm) {
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
                }
                if (["firstName", "payerName"].includes(item.key)) {
                  preventSpecialAndNumbersInInput(e);
                }
              }}
              onFocus={() => {
                if (nestedForm) {
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
                }
              }}
              options={
                ["firstName", "payerName"].includes(item.key)
                  ? allCustomers
                  : ["nomineeFullname"].includes(item.key)
                    ? multipleSameRelationCustomerMembers
                    : allProducts
              }
              stateUpdaterFn={
                ["firstName", "payerName"].includes(item.key)
                  ? setSelectedCustomer
                  : setSelectedProduct
              }
              isLoading={isCustomerLoading || isProductLoading}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item.field === "CHAR") {
        return (
          <>
            <Grid {...commonPropsForGrid}>
              <ToggleButton
                name={`${dynamicKey}${item.key}`}
                label={item.label}
                isRequired={item?.mandatory === "Y" && activeCount !== 1}
                description={item.description}
                register={register}
                errors={errors}
                readOnly={
                  item?.category_name === "Premium Details"
                    ? true
                    : item?.is_editable === "N" ||
                    item?.is_endorsable === "N" ||
                    activeCount !== 0 ||
                    (formTitle === NOMINEE_DETAILS &&
                      indexOfSelfNominee === nestedIndex &&
                      watchNominee?.[nestedIndex]?.nomineeRelation?.value ===
                      "Self" &&
                      [
                        "titleNd",
                        "nomineeGender",
                        "nomineeDob",
                        "nomineeRemark",
                        "nomineeFullname",
                      ].includes(item?.key)) ||
                    (formTitle === INSURED_MEMBER_DETAILS &&
                      indexOfSelfInsured === nestedIndex &&
                      watchMembers?.[nestedIndex]?.relWithprop?.value ===
                      "Self" &&
                      [
                        "insuredmemberName",
                        "genderIMD",
                        "dobIMD",
                        "insuredContactnumber",
                        "insuredeiaNumber",
                      ].includes(item?.key)) ||
                    (formTitle === LIFE_ASSURED_DETAILS &&
                      // 0 === nestedIndex &&
                      relWithprop?.value === "Self" &&
                      item?.key !== "relWithprop")
                }
                row
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                onChange={e => {
                  if (
                    ["isSameAddress"].includes(item?.key)
                    //  &&
                    // isSameAddress === "No"
                  ) {
                    setValue("addressLineonev", null);
                    setValue("addressLinetwov", null);
                    setValue("pincodeVd", null);
                    setValue("stateVd", null);
                    setValue("cityVd", null);
                    setValue(
                      "isSameAddress",
                      isSameAddress === "Yes" ? "No" : "Yes",
                    );
                  } else if (
                    ["addressCheck"].includes(item?.key)
                    //  &&
                    // isSameAddress === "No"
                  ) {
                    setValue("addresspdone", null);
                    setValue("addresspdtwo", null);
                    setValue("pinCodetwo", null);
                    setValue("statetwo", null);
                    setValue("citytwo", null);
                    setValue("landmarkpdtwo", null);
                    setValue(
                      "addressCheck",
                      addressCheck === "Yes" ? "No" : "Yes",
                    );
                  } else if (["isVehicleFinanced"].includes(item.key)) {
                    setValue("selectFinacer", null);
                    setValue("financerAggrementType", null);
                    setValue("financerCity", null);
                    setValue(
                      "isVehicleFinanced",
                      isVehicleFinanced === "Yes" ? "No" : "Yes",
                    );
                  } else {
                    setValue(`${dynamicKey}${item.key}`, e.target.value);
                  }
                }}
                // color="opposite"
                color="primary"
              />
              {/* {item?.key?.includes("compensationAct") && (
                <Description>{item.description}</Description>
              )} */}
              <EndorsementDiffSpan>
                {checkDifference(
                  payloadCheck?.data,
                  allFieldCommonProps?.id || inputFieldCommonProps?.name,
                  watch,
                  PREMIUM_FIELDS,
                )}
              </EndorsementDiffSpan>
            </Grid>
          </>
        );
      } else if (item.field === "DROPDOWN") {
        return (
          <Grid {...commonPropsForGrid}>
            <ProposalDropdown
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              {...masterDataPropsForDropdown}
              placeholder={`Select ${item?.label}`}
              filter={item.key}
              onChange={e => {
                nestedForm &&
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
                ["idProof", "addressProof"].includes(item?.key) &&
                  setValue(fieldsArray?.[i + 1]?.key, "");

                if (
                  ["relWithprop", "nomineeRelation"].includes(item?.key)
                  // formTitle === INSURED_MEMBER_DETAILS &&
                  // watch(`${dynamicKey}${item?.key}`)?.label === "Self"
                ) {
                  // fieldsArray?.map(
                  //   i =>
                  //     !["relWithprop", "nomineeRelation"].includes(i?.key) &&
                  //     setValue(`${dynamicKey}${i?.key}`, ""),
                  // );
                  if (
                    formTitle === INSURED_MEMBER_DETAILS &&
                    watch(`${dynamicKey}${item?.key}`)?.label === "Self"
                  ) {
                    setValue(
                      `${dynamicKey}${"insuredmemberName"}`,
                      watch("firstName"),
                    );
                    setValue(`${dynamicKey}${"genderIMD"}`, watch("gender"));
                    setValue(`${dynamicKey}${"dobIMD"}`, watch("dob"));
                    setValue(
                      `${dynamicKey}${"insuredContactnumber"}`,
                      watch("mobileNo"),
                    );
                  }
                  if (
                    formTitle === NOMINEE_DETAILS &&
                    watch(`${dynamicKey}${item?.key}`)?.label === "Self"
                  ) {
                    setValue(`${dynamicKey}${"titleNd"}`, watch("title"));
                    setValue(
                      `${dynamicKey}${"nomineeFullname"}`,
                      watch("firstName"),
                    );
                    setValue(
                      `${dynamicKey}${"nomineeGender"}`,
                      watch("gender"),
                    );
                    setValue(`${dynamicKey}${"nomineeDob"}`, watch("dob"));
                  }
                }
                if (["make"].includes(item?.key)) {
                  setValue("model", null);
                  setValue("version", null);
                  setValue("cc", null);
                  setValue("fuelType", null);
                  setValue("seatingCapacity", null);
                }
                if (["model"].includes(item?.key)) {
                  setValue("version", null);
                  setValue("cc", null);
                  setValue("fuelType", null);
                  setValue("seatingCapacity", null);
                }
                if (["version"].includes(item?.key)) {
                  setValue("cc", null);
                  setValue("fuelType", null);
                  setValue("seatingCapacity", null);
                }
              }}
              currentField={currentField}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item.field === "MULTISELECT") {
        return (
          <Grid {...commonPropsForGrid}>
            <ProposalDropdown
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              {...masterDataPropsForDropdown}
              placeholder={`Select ${item?.label}`}
              filter={item.key}
              onChange={() => {
                nestedForm &&
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
              }}
              isMulti={true}
              currentField={currentField}
            />{" "}
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item.field === "DATE") {
        return (
          <Grid {...commonPropsForGrid}>
            <DatePickerInput
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              setError={setError}
              setValue={setValue}
              watch={watch}
              specificDates={
                ["policyExpirydate"].includes(item.key) &&
                  isHealthLob &&
                  !["HospiCash"]?.includes(businessType?.label)
                  ? getYearlySpecificDates(policystDate, 3)
                  : ["policyExpirydate"].includes(item.key) &&
                    (isPropertyLob || isOfficePackageLob || isPetLob) &&
                    ["2-5 Years"].includes(tenure?.label)
                    ? getYearlySpecificDates(moment(policystDate), 5)
                    : ["policyExpirydate"].includes(item.key) &&
                      (isPropertyLob || isOfficePackageLob || isPetLob) &&
                      ["more than 5 years"].includes(tenure?.label)
                      ? getYearlySpecificDates(
                        policystDate,
                        2100 - moment().year(),
                      )
                      : ["policyExpirydatepd"].includes(item.key) &&
                        (isPropertyLob || isOfficePackageLob) &&
                        ["2-5 Years"].includes(tenurenew?.label)
                        ? getYearlySpecificDates(moment(policyStartdate), 5)
                        : ["policyExpirydatepd"].includes(item.key) &&
                          (isPropertyLob || isOfficePackageLob) &&
                          ["more than 5 years"].includes(tenurenew?.label)
                          ? getYearlySpecificDates(
                            policyStartdate,
                            2100 - moment().year(),
                          )
                          : []
              }
              themeType={"theme1"}
              name={`${dynamicKey}${item.key}`}
              isRequired={
                isBusinessTypeConditionsLobWise &&
                  ((![
                    TP_1_YEAR,
                    TP_LONG_TERM_5_YEARS,
                    TP_LONG_TERM_3_YEARS,
                    SATP,
                  ].includes(businessType?.label) &&
                    item.key === "odexpirydate") ||
                    item.key === "tpexpirydate")
                  ? true
                  : item?.mandatory === "Y"
              }
              autoComplete="none"
              defaultValue={""}
              required={false}
              inputRef={register(`${dynamicKey}${item.key}`)}
              testId="modal_input"
              minDate={getMinDateFunc()}
              maxDate={getMaxDateFunc()}
              // customView={item.key == "dob" ? ["year", "month"] : false}
              // customFormat={item.key == "dob" ? "MMM-yyyy" : false}
              onChange={e => {
                if (["policystDate"].includes(item.key)) {
                  setValue("odexpirydate", null);
                  setValue("tpexpirydate", null);
                  setValue("policystDate", new Date(e));
                } else {
                  setValue(
                    `${dynamicKey}${item.key}`,
                    isNil(e) ? null : new Date(e),
                  );
                }
              }}
            />
            {["dob"].includes(item.key) && (
              <BmiCategory isEndorsement bmiValue={20}>
                {proposerDob ? proposerDob + " Years" : ""}
              </BmiCategory>
            )}
            {["dobIMD"].includes(item.key) && (
              <BmiCategory isEndorsement bmiValue={20}>
                {insuredDob ? insuredDob + " Years" : ""}
              </BmiCategory>
            )}
            {["lifeAssureddob"].includes(item.key) && (
              <BmiCategory isEndorsement bmiValue={20}>
                {assuredDob ? assuredDob + " Years" : ""}
              </BmiCategory>
            )}
            {["nomineeDob"].includes(item.key) && (
              <BmiCategory isEndorsement bmiValue={20}>
                {nomDob ? nomDob + " Years" : ""}
              </BmiCategory>
            )}
            {/* {["anniversary"].includes(item.key) && (
              <BmiCategory isEndorsement bmiValue={20}>
                {nomDob ? nomDob + " Years" : ""}
              </BmiCategory>
            )} */}
            <EndorsementDiffSpan
            // isChange={Boolean(
            //   (["dob"].includes(item.key) && proposerDob) ||
            //     (["dobIMD"].includes(item.key) && insuredDob) ||
            //     (["lifeAssureddob"].includes(item.key) && assuredDob) ||
            //     (["nomineeDob"].includes(item.key) && nomDob),
            // )}
            >
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item.validation_group === "MOBILE") {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={e => {
                verifyValidNumbers(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (["ADHAAR", "AADHAR"].includes(item.validation_group)) {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={e => {
                verifyValidAadhar(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item?.field === "INT") {
        const isErrorForBMI =
          editCard?.[formTitle] && nestedForm === "nestedMembers"
            ? !!errors?.members?.[nestedIndex]?.[item.key]
            : nestedForm === "nestedPayments"
              ? !!errors?.payments?.[nestedIndex]?.[item.key]
              : !!errors?.[item.key];

        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              maxLength={
                item?.key === "coverage" &&
                  policyDetailsPlanType === PLAN_TYPE_SOOKSHMA_UDHYAM
                  ? 8
                  : item?.key === "coverage" &&
                    policyDetailsPlanType === PLAN_TYPE_LAGHU_UDHYAM
                    ? 9
                    : item?.length
              }
              required={false}
              onChange={e => {
                preventCharactersInInput(e);
                ["mobileNo"].includes(item.key) && allowValidPhoneNumber(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
            {item.key === "bmiImd" && (
              <BmiCategory
                style={{
                  top: isErrorForBMI ? "78px" : "66px",
                  color: "green",
                }}
                isEndorsement
                bmiValue={getBmiCategory(
                  watch(`members[${nestedIndex}].bmiImd`) || watch(`bmiImd`),
                )}
              >
                {getBmiCategory(
                  watch(`members[${nestedIndex}].bmiImd`) || watch(`bmiImd`),
                )}
              </BmiCategory>
            )}
            {item.key === "totalDays" && policystDate && policyExpirydate && (
              <BmiCategory isEndorsement bmiValue={20}>
                {formatDateDifference(
                  policystDate,
                  moment(policyExpirydate).add(1, "day"),
                )}
              </BmiCategory>
            )}
            {item.key === "totalDayspd" &&
              policyStartdate &&
              policyExpirydatepd && (
                <BmiCategory isEndorsement bmiValue={20}>
                  {formatDateDifference(
                    policyStartdate,
                    moment(policyExpirydatepd).add(1, "day"),
                  )}
                </BmiCategory>
              )}
            {item.key === "addOnpremium" &&
              // isPremiumDetailsReadOnly &&
              activeCount !== 1 && (
                <Tooltip
                  title={`${showAddonBifurcation ? "Remove" : "Add"} Addon Bifurcation`}
                  arrow
                  placement="top"
                >
                  <BmiCategory
                    style={{
                      position: "absolute",
                      left: "auto",
                      right: 0,
                      cursor: "pointer",
                      backgroundColor: theme.buttons_color,
                      color: theme.tertiaryColor,
                      borderRadius: "5px",
                      padding: "0 5px",
                      marginTop: "2px",
                      border: "1px solid black",
                    }}
                    bmiValue={20}
                    onClick={() => {
                      if (showAddonBifurcation) {
                        unregister(`addOnPremiums`);
                        setValue(`addOnPremiums`, []);
                        setValue("addOnpremium", 0);
                        watchAddOnPremium?.map((itm, index) => {
                          index && removeAddon(index);
                        });
                      }
                      setShowAddonBifurcation(!showAddonBifurcation);
                    }}
                  >
                    {`${showAddonBifurcation ? "- Remove" : "+ Add"}`}
                  </BmiCategory>
                </Tooltip>
              )}
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item?.field === "FLOAT") {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={e => {
                handleFloatInput(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item?.field === "PERCENTAGE") {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={e => {
                allowPercentageNumbersUpto100(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item?.field === "TEXTAREA") {
        return (
          <Grid sx={{ position: "relative" }} item lg={12}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={e => {
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else if (item.field === "FILE") {
        // if (!editCard?.[formTitle]) {
        //   return null;
        // }
        // return;
        return (
          // activeCount !== 1 &&
          <Grid item lg={3}>
            <ProposalFilePicker
              {...allFieldCommonProps}
              proposalRead={allFieldCommonProps.readOnly}
              label={
                ["certificate"].includes(item?.key)
                  ? (itSection?.label || "") + " " + item.label
                  : item.label
              }
              files={files}
              setFiles={setFiles}
              setValue={setValue}
              trigger={trigger}
              isEndorsement={true}
              control={control}
            />
            <EndorsementDiffSpan
              style={{
                position: "relative",
                top: "-7px",
                left: "7px",
              }}
            >
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      } else {
        if (
          ["ALPHANUMERIC", "VARCHAR", "TEXT", "IFSC"].includes(
            item?.validation_group,
          )
        ) {
          return (
            <Grid {...commonPropsForGrid}>
              {!!customerRefId?.length === 0 &&
                ["customerRefId"].includes(item.key) && (
                  <Tooltip placement="top" arrow title="Remove Customer">
                    <CloseIcon
                      sx={{
                        color: "grey",
                        fontSize: "20px",
                        cursor: "pointer",
                        zIndex: 2,
                        position: "absolute",
                        top: "39px",
                        right: "8px",
                      }}
                      onClick={() => {
                        setValue("customerRefId", "");
                        clearCustomerData();
                        setSelectedCustomer(null);
                      }}
                    />
                  </Tooltip>
                )}
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                // readOnly={
                //   ["customerRefId"].includes(item.key) && !!leadId
                //     ? true
                //     : item?.is_editable === "N"
                // }
                isRequired={
                  isBusinessTypeConditionsLobWise &&
                    ((![
                      TP_1_YEAR,
                      TP_LONG_TERM_5_YEARS,
                      TP_LONG_TERM_3_YEARS,
                      SATP,
                    ].includes(policyDetailsBusinessType) &&
                      item.key === "odPremium") ||
                      item.key === "tpPremium")
                    ? true
                    : item?.mandatory === "Y"
                }
                maxLength={
                  ["identityvalue"].includes(item?.key) &&
                    identityProof?.includes("aadhaar")
                    ? 12
                    : ["identityvalue"].includes(item?.key) &&
                      identityProof?.includes("pan card")
                      ? 10
                      : ["identityvalue"].includes(item?.key) &&
                        identityProof?.includes("voter")
                        ? 10
                        : ["identityvalue"].includes(item?.key) &&
                          identityProof?.includes("passport")
                          ? 9
                          : ["identityvalue"].includes(item?.key) &&
                            identityProof?.includes("driving")
                            ? 16
                            : ["addressvalue"].includes(item?.key) &&
                              addProof?.includes("aadhaar")
                              ? 12
                              : ["addressvalue"].includes(item?.key) &&
                                addProof?.includes("pan card")
                                ? 10
                                : ["addressvalue"].includes(item?.key) &&
                                  addProof?.includes("voter")
                                  ? 10
                                  : ["addressvalue"].includes(item?.key) &&
                                    addProof?.includes("passport")
                                    ? 9
                                    : ["addressvalue"].includes(item?.key) &&
                                      addProof?.includes("driving")
                                      ? 16
                                      : item?.length
                }
                required={false}
                onChange={
                  [
                    "premiumPayername",
                    "firstName",
                    "middleName",
                    "lastName",
                    "lifeassuredFname",
                    "lifeassuredLname",
                    "lifeassuredMname",
                  ].includes(item.key)
                    ? e => preventSpecialAndNumbersInInput(e)
                    : [
                      "ifscCode",
                      "nomineePanno",
                      "companygst",
                      "cinNumber",
                      "panNumber",
                      // "proposalNum",
                      // "policyNo",
                    ].includes(item.key)
                      ? e => {
                        preventSpecialInInput(e);
                        allCapitalize(e);
                      }
                      : ["identityvalue"].includes(item?.key) &&
                        identityProof?.includes("aadhaar")
                        ? e => {
                          allowOnlyNumbers(e);
                        }
                        : ["addressvalue"].includes(item?.key) &&
                          addProof?.includes("aadhaar")
                          ? e => {
                            allowOnlyNumbers(e);
                          }
                          : ["identityvalue"].includes(item?.key) &&
                            ["pan card", "voter", "passport", "driving"].some(
                              item => identityProof?.includes(item),
                            )
                            ? e => {
                              allowAlphanumericHyphenSpaceAndCapitalize(e);
                            }
                            : ["addressvalue"].includes(item?.key) &&
                              [
                                "pan card",
                                "voter",
                                "passport",
                                "driving",
                              ].some(item => addProof?.includes(item))
                              ? e => {
                                allowAlphanumericHyphenSpaceAndCapitalize(e);
                              }
                              : [
                                "bankBranchname",
                                "vehicleRegNo",
                                "banName",
                                "relationId",
                              ].includes(item.key)
                                ? e => preventSpecialInInput(e)
                                : isTravelLob &&
                                  ["passportNumber"].includes(item.key)
                                  ? e => {
                                    allowAlphanumericHyphenSpaceAndCapitalize(
                                      e,
                                    );
                                  }
                                  : [
                                    "chassisNo",
                                    "engineNo",
                                    "passportNumber",
                                  ].includes(item.key)
                                    ? e => {
                                      preventSpecialInInput(e);
                                      allCapitalize(e);
                                    }
                                    : ["drivingLicence"].includes(item.key)
                                      ? e => {
                                        allCapitalize(e);
                                        allowAlphanumericHyphenAndSpace(e);
                                      }
                                      : ["accNumber"].includes(item.key)
                                        ? e => {
                                          allowOnlyNumbers(e);
                                        }
                                        : (item?.key === "heightImd" &&
                                          watch(
                                            `members[${nestedIndex}].heighttype`,
                                          )?.label === "In Feet") ||
                                          watch(`heighttype`)?.label ===
                                          "In Feet"
                                          ? e => {
                                            allowInputInFootInchFormat(e);
                                          }
                                          : (item?.key === "heightImd" &&
                                            watch(
                                              `members[${nestedIndex}].heighttype`,
                                            )?.label === "In Cm") ||
                                            watch(`heighttype`)?.label ===
                                            "In Cm"
                                            ? e => {
                                              allowOnlyNumbers(e);
                                            }
                                            : [
                                              "totalAmount",
                                              "totalPremium",
                                              "tpPremium",
                                              "netPremium",
                                              "basePremium",
                                              "odPremium",
                                              "taxAmount",
                                            ]?.includes(item?.key)
                                              ? e => {
                                                preventZeroAsInitial(e);
                                              }
                                              : null
                }
              />
              {item.label === "BMI" && (
                <BmiCategory isEndorsement bmiValue={bmi}>
                  {bmiVal}
                </BmiCategory>
              )}
              {item.key === "policyPeriod" &&
                policystDate &&
                policyExpirydate && (
                  <BmiCategory isEndorsement bmiValue={20}>
                    {formatDateDifference(
                      policystDate,
                      moment(policyExpirydate).add(1, "day"),
                    )}
                  </BmiCategory>
                )}
              <EndorsementDiffSpan>
                {checkDifference(
                  payloadCheck?.data,
                  allFieldCommonProps?.id || inputFieldCommonProps?.name,
                  watch,
                  PREMIUM_FIELDS,
                )}
              </EndorsementDiffSpan>
            </Grid>
          );
        } else if (item.validation_group === "EMAIL") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                onChange={e => {
                  verifyValidEmail(e);
                  preventWhiteSpaceInInput(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
              <EndorsementDiffSpan>
                {checkDifference(
                  payloadCheck?.data,
                  allFieldCommonProps?.id || inputFieldCommonProps?.name,
                  watch,
                  PREMIUM_FIELDS,
                )}
              </EndorsementDiffSpan>{" "}
            </Grid>
          );
        } else if (item.validation_group === "ALPHABETIC") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                onChange={e => {
                  preventSpecialAndNumbersInInput(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
              <EndorsementDiffSpan>
                {checkDifference(
                  payloadCheck?.data,
                  allFieldCommonProps?.id || inputFieldCommonProps?.name,
                  watch,
                  PREMIUM_FIELDS,
                )}
              </EndorsementDiffSpan>
            </Grid>
          );
        } else if (item.validation_group === "PINCODE") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                inputRef={register(`${dynamicKey}${item.key}`)}
                onChange={e => {
                  verifyValidPincode(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
              <EndorsementDiffSpan>
                {checkDifference(
                  payloadCheck?.data,
                  allFieldCommonProps?.id || inputFieldCommonProps?.name,
                  watch,
                  PREMIUM_FIELDS,
                )}
              </EndorsementDiffSpan>
            </Grid>
          );
        } else if (item.validation_group === "PAN") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                onChange={e => {
                  verifyValidPAN(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
              <EndorsementDiffSpan>
                {checkDifference(
                  payloadCheck?.data,
                  allFieldCommonProps?.id || inputFieldCommonProps?.name,
                  watch,
                  PREMIUM_FIELDS,
                )}
              </EndorsementDiffSpan>{" "}
            </Grid>
          );
        } else if (["NUMERIC", "INT"].includes(item?.validation_group)) {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                isRequired={
                  isBusinessTypeConditionsLobWise &&
                    ((![
                      TP_1_YEAR,
                      TP_LONG_TERM_5_YEARS,
                      TP_LONG_TERM_3_YEARS,
                      SATP,
                    ].includes(policyDetailsBusinessType) &&
                      item.key === "odPremium") ||
                      item.key === "tpPremium")
                    ? true
                    : item?.mandatory === "Y"
                }
                required={false}
                onChange={e => {
                  [
                    "totalAmount",
                    "basePremium",
                    "addOnpremium",
                    "netPremium",
                    "taxAmount",
                  ].includes(item.key)
                    ? allowOnlyNumbersAndDecimal(e)
                    : preventCharactersInInput(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
              <EndorsementDiffSpan>
                {checkDifference(
                  payloadCheck?.data,
                  allFieldCommonProps?.id || inputFieldCommonProps?.name,
                  watch,
                  PREMIUM_FIELDS,
                )}
              </EndorsementDiffSpan>
            </Grid>
          );
        }
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
            />
            <EndorsementDiffSpan>
              {checkDifference(
                payloadCheck?.data,
                allFieldCommonProps?.id || inputFieldCommonProps?.name,
                watch,
                PREMIUM_FIELDS,
              )}
            </EndorsementDiffSpan>
          </Grid>
        );
      }
    }
  });
};
