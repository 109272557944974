import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Zoom,
} from "@mui/material";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import ClearIcon from "@mui/icons-material/Clear";
// import { useGetNotifications, useReadNotification } from "./service";
import * as DOMPurify from "dompurify";
import { useReadNotification } from "./service";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Notification = ({ handleClose, open, notification }) => {
  const navigate = useNavigate();
  const [openMsg, setOpenMsg] = React.useState({
    state: false,
    content: "",
    scheduledAt: "",
  });

  const { readNotification } = useReadNotification();

  return (
    <>
      {notification?.length > 0 && (
        <Menu
          sx={{
            "& ul": {
              padding: "0px",
            },
            "& .MuiMenu-paper": {
              borderRadius: "10px",
              overflow: "auto",
            },
            maxHeight: "350px",
            maxWidth: "400px",
            top: 15,
          }}
          anchorEl={open}
          open={Boolean(open)}
          onClose={handleClose}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              padding: "5px 20px",
              borderBottom: "1px solid rgba(168, 169, 173, 0.5)",
              backgroundColor: "#fff",
              minWidth: "340px",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#1976d2",
                margin: "5px",
              }}
            >
              Notifications
            </Box>
          </Box>
          <Box sx={{ padding: "10px", paddingBottom: "0px" }}>
            {notification?.map((item, index) => (
              <MenuItem
                key={index}
                sx={{
                  display: "flex",
                  marginBottom: "8px",
                  backgroundColor: "#f2f2f2",
                  justifyContent: "space-between",
                  wordWrap: "normal",
                }}
                onClick={() => {
                  setOpenMsg({
                    state: true,
                    content: item?.content,
                    scheduledAt: item?.scheduledAt,
                  });
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                  }}
                >
                  <MarkUnreadChatAltIcon
                    sx={{ color: "#1976d2" }}
                    fontSize="small"
                  />
                  <Box
                    sx={{
                      ml: 2,
                      fontSize: "14px",
                      color: "#333",
                    }}
                  >
                    {item?.content?.length > 30
                      ? item?.content?.slice(0, 30) + "..."
                      : item?.content}
                  </Box>
                </Box>
                <Tooltip
                  title="Mark as read"
                  arrow
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 200 }}
                  placement="top"
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      readNotification({
                        scheduledCommunicationsAudiencesId:
                          item?.scheduledCommunicationsAudiencesId,
                        newStatus: "Y",
                      });
                    }}
                  >
                    <MarkChatReadIcon
                      sx={{ color: "#1976d2" }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
          </Box>
          {/* <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Button onClick={() => {
              navigate('/activity')
              handleClose()
            }}>
              View All Notifications
            </Button>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "5px 20px",
              borderBottom: "1px solid rgba(168, 169, 173, 0.5)",
              backgroundColor: "#fff",
              minWidth: "340px",
              position: "sticky",
              bottom: 0,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#1976d2",
                margin: "5px",
              }}
            >
              <Button
                onClick={() => {
                  navigate("/activity");
                  handleClose();
                }}
              >
                View All Notifications
              </Button>
            </Box>
          </Box>
        </Menu>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openMsg?.state}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ color: "#1976d2", fontWeight: 600 }}
        >
          Notification
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(openMsg?.content),
              }}
            />
            <span style={{ fontSize: "12px", color: "#afafaf" }}>
              {moment(openMsg?.scheduledAt)?.format("DD-MM-YYYY hh:mm A")}
            </span>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenMsg({ state: false, content: "" })}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Notification;
