import styled from "styled-components";

export const ProposalCreateSummaryStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 4px;
  // margin-bottom: 16px;
  height: ${({ isRaiseClaim }) => (isRaiseClaim ? "65vh" : "60vh")};
  overflow-y: auto;
`;

export const PolicyCreateStyled = styled.div`
  position: relative;
  border-radius: 10px;
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .floating_buttons {
    position: absolute;
    right: 0;
    top: 10px;
    display: flex;
    width: 120px;
    justify-content: space-between;
    p {
      display: flex;
      font-size: 17px;
      cursor: pointer;
      padding: 5px 10px;
      color: ${({ theme }) => theme?.primaryColor};
      border: 0.5px solid;
      border-radius: 5px;
      svg {
        margin-left: 10px;
      }
    }
  }
  h3 {
    margin: 0;
    color: ${({ theme }) => theme?.primaryColor};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  .multiple_action_btn {
  }
`;

export const ButtonContainerStyled = styled.div`
  // margin-top: 20px;
  display: flex;
  justify-content: end;
`;

export const MemberCount = styled("p")(({ theme, index }) => ({
  color: theme?.primaryColor,
  fontWeight: "600",
  // borderTop: index ? "1px solid #a9a7a7" : "",
  paddingTop: index ? "10px" : "",
  margin: index ? "20px 0 20px 0" : "0 0 20px 0",
}));

export const Description = styled.span`
  color: ${({ theme }) => theme?.ascentColor};
  font-size: 10px;
  position: relative;
  top: -15px;
  display: flex;
`;

export const BmiCategory = styled.span`
  color: ${(props) =>
    props.bmiValue < 18.5
      ? "#a8c900"
      : props.bmiValue >= 18.5 && props.bmiValue <= 24.9
      ? "Blue"
      : props.bmiValue >= 25 && props.bmiValue <= 29.9
      ? "#cb8400"
      : "Red"};
  font-size: 11px;
  position: absolute;
  top: 66px;
  left: 25px;
  display: ${(props) => (props.bmiValue?.length === 0 ? "none" : "flex")};
`;

export const EndorsementDiffSpan = styled.span`
  color: #ea1f44;
  font-size: 12px;
  position: absolute;
  top: 66px;
  left: 25px;
  text-decoration: line-through;
  display: ${(props) => (props.bmiValue?.length === 0 ? "none" : "flex")};
`;

export const EndorsementFieldHeaderDifferentiator = styled.div`
  width: 100%;
  min-height: 2px;
  border: 2px solid #2aa495;
  margin-top: 10px;
`;

export const RemovedTitle = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  color: #d9534f; /* Bootstrap's danger color */
`;

export const RemovedMembersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border: 1px solid #d9534f;
  border-radius: 5px;
  background-color: #f8d7da;
`;

export const RemovedMember = styled.span`
  font-size: 1rem;
  color: #a94442;
  padding: 4px 8px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f8d7da;

  &::after {
    margin-right: 4px;
  }
`;
