import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  default as HttpClient,
  default as httpClient,
} from "../../api/httpClient";
import { setLeadErrorAction, setLeadSuccessAction } from "./lead.slice";
import { isEmpty } from "lodash";
import { isValidURL } from "../../HelperFunctions/HelperFunctions";

export const useGetLead = ({ page, size, searchParams }) => {
  const payload = {
    page: +page || 1,
    size: +size || 20,
    sortBy: "",
    sortOrder: "desc",
    searchParams: {
      lobId: searchParams?.lobId || "",
      name: searchParams?.name || "",
      mobileNo: searchParams?.mobileNo || "",
    },
  };

  const { data, isFetching, isError, refetch } = useQuery({
    queryKey: ["LeadListing", payload],
    queryFn: () =>
      HttpClient("lead_master_controller/listing", {
        method: "POST",
        data: payload,
      }),
  });
  return { data, isFetching, isError, refetch };
};

export const useLeadListExport = ({ setLoading }) => {
  const { data, mutate, status, isLoading } = useMutation(
    (payload) => {
      return HttpClient("lead_master_controller/listPaging/export", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data?.data?.status) {
          Swal.fire(data?.data?.message, "", data?.data?.errortype);
          // setLoading(false);
          return;
        }
        if (data?.data?.status) {
          Swal.fire({
            icon: "success",
            text: "Report Generated Successfully",
            showConfirmButton: true,
            confirmButtonText: "Download File",
          }).then((result) => {
            if (result.isConfirmed) {
              const url = data?.data?.data;
              window.open(url, "_blank");
            }
          });
          // setLoading(false);
        }
        // const url = data?.data?.data;
        // window.open(url, "_blank");
      },
    }
  );

  return { data, mutate, status, isLoading };
};

export const useGetLeadById = (id) => {
  const { data } = useQuery({
    queryKey: ["LeadById", id],
    queryFn: () => HttpClient(`lead_master_controller/list/${id}`),
    enabled: id ? true : false,
  });
  return { data };
};

export const useCreateLead = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading, isError, data } = useMutation(
    (payload) => {
      return HttpClient(`lead_master_controller/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("LeadListing");
          dispatch(setLeadSuccessAction(data?.data?.message));
        } else {
          dispatch(setLeadErrorAction(data?.data?.message));
        }
      },
    }
  );

  return { mutate, isLoading, isError, data };
};

export const useDeleteLead = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading, isError } = useMutation(
    (id) => {
      return HttpClient(`/lead_master_controller/delete/${id}`, {
        method: "DELETE",
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("LeadListing");
          dispatch(setLeadSuccessAction(data?.data?.message));
        } else {
          dispatch(setLeadErrorAction(data?.data?.message));
        }
      },
    }
  );

  return { mutate, isLoading, isError };
};

export const useUpdateLead = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading, isError } = useMutation(
    (payload) => {
      const { id, ...restPayload } = payload;
      return HttpClient(`/lead_master_controller/update/${id}`, {
        method: "PUT",
        data: restPayload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          queryClient.invalidateQueries("LeadListing");
          dispatch(setLeadSuccessAction(data?.data?.message));
        } else {
          dispatch(setLeadErrorAction(data?.data?.message));
        }
      },
    }
  );

  return { mutate, isLoading, isError };
};

export const useDownloadLeadSampleFile = (ownerType) => {
  const { data } = useQuery({
    queryKey: ["sampleFileForCustomberBulkupload", ownerType],
    queryFn: () =>
      httpClient(`lead_transaction_bulk/export?ownerType=${ownerType}`, {
        responseType: "blob",
      }),
    // refetchOnMount: false,
    // refetchOnReconnect: false,
  });
  return { data };
};

export const useLeadBulkUpload = (ownerType) => {
  const queryClient = useQueryClient();
  const { data, mutate } = useMutation(
    (payload) => {
      return httpClient(
        `lead_transaction_bulk/bulkUpload?ownerType=${ownerType}`,
        {
          method: "POST",
          data: payload,
        }
      );
    },
    {
      onSuccess: (data) => {
        if (data?.data.status) {
          Swal.fire({
            text: data?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#DC004E",
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: false,
            scrollbarPadding: false,
          });
        }
        if (!data?.data?.status) {
          // const errorItem = data?.data?.data?.find(
          //   item => item?.errors?.length,
          // );

          const validBtn = isValidURL(data?.data?.data);
          // console.log(data)
          Swal.fire({
            title: "Error !",
            icon: "error",
            text: data?.data?.message,
            showConfirmButton: validBtn,
            confirmButtonText: "Download Report",
            showCancelButton: true,
            cancelButtonText: "OK",
          }).then((res) => {
            if (res.isConfirmed) {
              setTimeout(() => {
                window.open(data?.data?.data, "_blank");
              }, [1000]);
            }
          });
        }
        queryClient.invalidateQueries("LeadListing");
      },
    }
  );
  return { data, mutate };
};

export const useGetProductList = (lob) => {
  const { data } = useQuery(
    ["productList", lob],
    () => {
      return httpClient(`common_master/product_master/list`, {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchProduct: {
            lobId: lob?.lobId,
          },
        },
      });
    },
    { enabled: !!lob }
  );
  return { productData: data?.data?.data };
};

export const useGetProductListMultipleLob = (lob, productName) => {
  const { data, isLoading } = useQuery(
    ["productList", lob, productName],
    () => {
      return httpClient(`common_master/product_master/list`, {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchProduct: {
            lobIds: lob,
            ...(productName && { productName: productName }),
          },
        },
      });
    },
    { enabled: !!lob }
  );
  return { productData: data?.data?.data, isProductLoading: isLoading };
};

export const useGetUserTypes = () => {
  const { data } = useQuery(["usersTypes"], () => {
    return httpClient(`user_type_controller/list`);
  });
  return { data };
};

export const useGetLeadList = () => {
  const { data } = useQuery(["leadList"], () => {
    return httpClient(`lead_master_controller/list`);
  });
  return { data: data?.data?.data };
};

export const useGetSourceTypeWithChannelType = (channelType) => {
  const { data } = useQuery(
    ["source_type_data_by_channel_typ", channelType],
    () => {
      return httpClient("lead_source_master/listing", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchValues: {
            channelType,
          },
        },
      });
    }
  );
  return { sourceTypeData: data?.data?.data || [] };
};

export const useGetSourceType = () => {
  const { data } = useQuery(["source_type_data_by_channel_typ"], () => {
    return httpClient("lead_source_master/listing", {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        searchValues: {},
      },
    });
  });
  return { sourceTypeData: data?.data?.data || [] };
};

export const useUpdateLeadStatus = (leadID) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return httpClient(`lead_master_controller/updateStatus/${leadID}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["lead_master_controller/listing"]);
        queryClient.invalidateQueries(["LeadById"]);
        return Swal.fire({
          text: `${res?.data?.message}`,
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#DC004E",
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false,
        });
      },
    }
  );

  return {
    updateStatus: mutate,
  };
};

export const useGetRoles = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["role_list"],
    queryFn: () => httpClient("role/list"),
  });
  return { roles: data?.data?.data, isLoading };
};

// export const useGetUserDataByRoleId = (roleId) => {
//   const { data } = useQuery({
//     queryKey: ["employees", roleId],
//     enabled: Boolean(roleId),
//     queryFn: () =>
//       HttpClient(`users/list/Paging_Sorting`, {
//         method: "POST",
//         data: {
//           page: 0,
//           size: 0,
//           sortBy: "",
//           sortOrder: "desc",
//           searchValue: {
//             firstName: "",
//             lastName: "",
//             userStatus: "",
//             mobileNumber: "",
//             emailId: "",
//             departmentId: 0,
//             roleId: roleId,
//             userTypeId: 0,
//             reportToId: 0,
//           },
//         },
//       }),
//     onSuccess: res => {
//     },
//   });
//   return { employees: data?.data?.data };
// };

// export const useGetMultipleUserDataByRoleId = ({ roleIds = [] }) => {
//   const data = useQueries({
//     queries: roleIds?.map(roleId => {
//       return (
//         !!roleId && {
//           queryKey: ["roleId", roleId],
//           queryFn: () =>
//             httpClient(`users/list/Paging_Sorting`, {
//               method: "POST",
//               data: {
//                 page: 0,
//                 size: 0,
//                 sortBy: "",
//                 sortOrder: "desc",
//                 searchValue: {
//                   firstName: "",
//                   lastName: "",
//                   userStatus: "",
//                   mobileNumber: "",
//                   emailId: "",
//                   departmentId: 0,
//                   roleId: roleId,
//                   userTypeId: 0,
//                   reportToId: 0,
//                 },
//               },
//             }),
//           staleTime: Infinity,
//         }
//       );
//     }),
//   });
//   return { data: data || [] };
// };

export const useGetCustomers = () => {
  const { data, isFetching, isError } = useQuery({
    queryKey: ["customers"],
    queryFn: () =>
      HttpClient("customer_records/listPaging", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchValue: {
            firstName: "",
            lastName: "",
            fullName: "",
            customerRefId: "",
            parentId: 0,
            emailId: "",
            mobileNumber: "",
            city: "",
            vehicleRegNo: "",
            customKey: "",
            userTypes: "",
            assignedTo: 0,
          },
        },
      }),
  });
  return { customers: data?.data?.data, isFetching, isError };
};
export const useGetDocumentManagementList = (channelType) => {
  const { data } = useQuery(["doucument_management_listing"], () => {
    return httpClient("document_management/list", {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        documentName: "",
        documentCode: "",
        documentType: "",
      },
    });
  });

  let listData = data?.data?.data;
  let options = !isEmpty(listData)
    ? listData.map((item) => ({
        label: item?.documentName,
        value: item?.documentManagementConfigurationId,
      }))
    : [];
  return { documentManagementListing: options };
};

export const useGetUserById = ({ userId }) => {
  const { data } = useQuery({
    queryKey: ["user_by_id", userId],
    queryFn: () => HttpClient(`users/getByRoleId/${userId}`),
    enabled: userId ? true : false,
  });
  return { data };
};
export const useGetMultipleUserDataByRoleId = ({ roleIds = [] }) => {
  // const { data } = useQuery({
  //   queryKey: ["user_by_id", userId],
  //   queryFn: () => HttpClient(`users/getByRoleId/${userId}`),
  //   enabled: userId ? true : false,
  // });

  const data = useQueries({
    queries: roleIds?.map((roleId) => {
      return (
        !!roleId && {
          queryKey: ["roleIds_multiple", roleId],
          queryFn: () => httpClient(`users/getByRoleId/${roleId}`),
        }
      );
    }),
  });

  return { data };
};
export const useGetMultiPolicyType = (LOBIds = []) => {
  const data = useQueries({
    queries: LOBIds?.map((LOBId) => {
      return (
        !!LOBId && {
          queryKey: ["policy_type_by_lob", LOBId],
          queryFn: () =>
            HttpClient(
              `get_data/transaction_type_master/transaction_type_master?lobId=${LOBId}`
            ),
        }
      );
    }),
  });
  return { data: data || [] };
};

export const useGetMultipleUserTypes = (sourcesOfSourcingDataList = []) => {
  const data = useQueries({
    queries: sourcesOfSourcingDataList?.map((id) => {
      return (
        !!id && {
          queryKey: ["get_multiple_user_types", id],
          queryFn: () => httpClient(`lead_source_master/listById/${id}`),
        }
      );
    }),
  });
  return { data: data || [] };
};

export const useGetLevelConfiguratorListing = () => {
  const { data } = useQuery({
    queryKey: ["level_configurator_listing"],
    queryFn: () =>
      HttpClient(`level_configurator_controller/listing`, {
        method: "POST",
        data: {
          page: 1,
          size: 10,
          sortBy: "",
          sortOrder: "",
        },
      }),
  });
  return { levelConfigListing: data?.data?.data };
};
export const useGetUserList = () => {
  const { data } = useQuery({
    queryKey: ["user_list"],
    queryFn: () =>
      HttpClient(`users/user_type/0`, {
        method: "GET",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchValue: {},
        },
      }),
  });
  return { data };
};
