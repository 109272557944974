import { combineReducers } from "@reduxjs/toolkit";
import proposalConfigSlice from "../modules/Dynamic-Claim/proposal.slice";
import themeSlice from "../modules/Dynamic-Claim/theme.slice";
import policyDetailsSlice from "../modules/endorsement/policyDetails.slice";
import riskoMeterMemberSlice from "../modules/riskoMeter/riskoMeterMember.slice";
import riskoMeterCoveragePayloadSlice from "../modules/riskoMeter/riskoMeterMember.slice";
// import riskoMeterDataSlice from "../modules/riskoMeter/riskoMeterMember.slice";
export default combineReducers({
	globalSlice: "xyz",
	proposalConfig: proposalConfigSlice,
	theme: themeSlice,
	policyDetails: policyDetailsSlice,
  riskoMeterMember: riskoMeterMemberSlice,
  setCoverageAmountAction: riskoMeterCoveragePayloadSlice,
});
