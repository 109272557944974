import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";
import { FaHandsHelping } from "react-icons/fa";
import { isEmpty } from "lodash";

const brokerageConfig = createSlice({
    name: "brokerageConfig",
    initialState: {
        brokerageError: null,
        brokerageSuccess: null,
        brokerageFieldData: {},
        brokerageActualFieldData: {},
        matchObj: [],
        bundleMatchObj: [],
        bundles: [],
        singleSelectedData: [],
        selectedChecboxNames: "",
        singleCheck: false,
        oneBundle: "",
        singleItem: {},
        dataForSinglePayload: [],
        singleCheckboxObj: [],
        isSingleDropdownClicked: false,
        isPreviewFile: {},
        bundleObj: [],
        bundleData: [],
        minimumRange: 0,
        maximumRange: 0,
        path: "",
        bundleFieldData: {},
        singleRawData: [],
        selectedChoice: "",
        bundleOperator: "",
        bundleRawData: [],
        bundleRawDataRange: {},
        brokerageCommissionType: {},
        bundleAdditionalFiltersPayloadObj: {},
        bundleRawDataForRange: [],
        singleCheckboxObjArray: {},
        additionalFiltersForSinglePayloads: {},
        bundleCheckboxObjArray: {},
        customInput: "",
        masterApiAvailable: "",
        additionalFiltersBundle: {},
        bundleFiledObj: {},
        bundleField: {},
        singleCustomInput: "",
        premiumBearingDropdownAccordingToCardInFocus: {},
        oneBundleObjWithCard: "",
        selectedBrokerageData: {},
        bundleChangedAdditionalFilters: {},
        isSkip: false,
        checkboxClicked: false,
        mergedArrayForAddAndUpdateOfChipsSelector: [],
        bundleKey: "",
        updateBrokerage: "",
        completedSteps: {},
        bundleCheckboxObjectArrayForUpdate: {},
        bundleRawDataRangeWithSlugAndRule: []
    },
    reducers: {
        setBrokerageSuccessAction: (state, { payload }) => {
            //in Use
            state.brokerageSuccess = payload;
            state.brokerageError = null;
        },
        setBrokerageErrorAction: (state, { payload }) => {
            state.loading = null;
            state.brokerageError = serializeError(payload);
            state.brokerageSuccess = null;
        },
        setFieldDataAction: (state, { payload }) => {
            state.brokerageFieldData = payload;
        },
        setActualFieldDataAction: (state, { payload }) => {
            state.brokerageActualFieldData = payload;
        },
        setMatchObjAction: (state, { payload }) => {
            state.matchObj = payload;
        },
        setBundleMatchAction: (state, { payload }) => {
            state.bundleMatchObj = payload;
        },
        setBundleAction: (state, { payload }) => {
            state.bundles = payload;
        },
        setSingleItem: (state, { payload }) => {
            state.singleItem = payload;
        },
        setSingleSelectedAction: (state, { payload }) => {
            state.singleSelectedData = [...payload];
        },
        setSelectedChecboxNames: (state, { payload }) => {
            state.selectedChecboxNames = payload;
        },
        resetSelectedChecboxNames: state => {
            state.selectedChecboxNames = "";
        },
        resetSingleSelectedAction: state => {
            state.singleSelectedData = [];
        },
        setCheckAction: (state, { payload }) => {
            state.singleCheck = payload;
        },
        setOneBundledSelectedAction: (state, { payload }) => {
            state.oneBundle = payload;
        },
        // setSingleNameAndIdForPayload: (state, { payload }) => {
        //   state.dataForSinglePayload = payload;
        // },
        // setSingleObjData: (state, { payload }) => {
        //   state.singleCheckboxObj = payload;
        // },
        // setSingleDropdownClicked: (state, { payload }) => {
        //   state.isSingleDropdownClicked = payload;
        // },
        setFileUploadAction: (state, { payload }) => {
            state.isPreviewFile = payload;
        },
        // setBundleObj: (state, { payload }) => {
        //   state.bundleObj = payload;
        // },
        // setBundleDropdownValues: (state, { payload }) => {
        //   state.bundleData = payload;
        // },
        setMinRange: (state, { payload }) => {
            state.minimumRange = payload;
        },
        setMaxRange: (state, { payload }) => {
            state.maximumRange = payload;
        },
        setPath: (state, { payload }) => {
            state.path = payload;
        },
        setBundleFieldDataObj: (state, { payload }) => {
            state.bundleFieldData = {
                ...state.bundleFieldData,
                ...payload,
            };
        },
        setBundleAdditionalFiltersPayload: (state, { payload }) => {
            state.bundleAdditionalFiltersPayloadObj = {
                ...state.bundleAdditionalFiltersPayloadObj,
                ...payload,
            };
        },
        // setSingleRawDataArray: (state, { payload }) => {
        //   state.singleRawData = payload;
        // },
        setRadioFormanuallyOrBulk: (state, { payload }) => {
            state.selectedChoice = payload;
        },
        setOperator: (state, { payload }) => {
            //in Use
            state.bundleOperator = payload;
        },
        setBundleRawDataArray: (state, { payload }) => {
            state.bundleRawData = payload;
        },
        setBundleRawDataForRange: (state, { payload }) => {
            state.bundleRawDataRange = {
                ...state.bundleRawDataForRange,
                ...payload,
            };
        },
        setCommissionType: (state, { payload }) => {
            // in use
            state.brokerageCommissionType = payload;
        },
        setSingleCheckBoxObjectsArray: (state, { payload }) => {
            state.singleCheckboxObjArray = {
                ...state.singleCheckboxObjArray,
                ...payload,
            };
        },
        // setAdditionalFiltersForSinglePayload: (state, { payload }) => {
        //   state.additionalFiltersForSinglePayloads = {
        //     ...state.additionalFiltersForSinglePayloads,
        //     ...payload,
        //   };
        // },
        setBundleCheckboxObjArray: (state, { payload }) => {
            // in use
            state.bundleCheckboxObjArray = {
                ...state.bundleCheckboxObjArray,
                ...payload,
            };
        },
        setBundleCheckboxObjArrayOnInitialMount: (state, { payload }) => {
            state.bundleCheckboxObjArray = {};
        },
        resetBundleCheckBoxObjArrayForDeleteChip: (state, { payload }) => {
            delete state.bundleCheckboxObjArray[payload];
        },
        setBundleCheckboxObjArrayForUpdate: (state, { payload }) => {
            state.bundleCheckboxObjectArrayForUpdate = {
                ...state.bundleCheckboxObjectArrayForUpdate,
                ...payload,
            };
        },
        resetBundleCheckboxObjArrayForUpdateForDeleteChip: (state, { payload }) => {
            delete state.bundleCheckboxObjectArrayForUpdate[payload];
        },
        setCustomInput: (state, { payload }) => {
            state.customInput = payload;
        },
        setIsMasterApiAvailableWithFieldSlug: (state, { payload }) => {
            state.masterApiAvailable = payload;
        },
        setAdditionalFiltersForBundlePayload: (state, { payload }) => {
            //in use
            state.additionalFiltersBundle = {
                ...state.additionalFiltersBundle,
                ...payload,
            };
        },
        resetAdditionalFiltersForBundlePayload: (state, { payload }) => {
            delete state.additionalFiltersBundle[payload]
        },
        setbundleFieldDataPayload: (state, { payload }) => {
            // in use
            state.bundleFiledObj = {
                ...state.bundleFiledObj,
                ...payload,
            };
        },
        setbundleFieldDataPayloadOnSave: (state, { payload }) => {
            state.bundleField = {
                ...state.bundleField,
                ...payload,
            };
        },
        resetbundleFieldDataPayloadOnSave: (state, { payload }) => {
            delete state.bundleField[payload];
        },
        setSingleCustomInput: (state, { payload }) => {
            state.singleCustomInput = +payload;
        },
        setOneBundleObjWithCard: (state, { payload }) => {
            state.oneBundleObjWithCard = payload;
        },
        setPremiumDataWithRule: (state, { payload }) => {
            state.selectedBrokerageData = payload;
        },
        setUpdatedBundleAdditionalFiltersPayload: (state, { payload }) => {
            state.bundleChangedAdditionalFilters = JSON.parse(payload);
        },
        setSkippedAction: (state, { payload }) => {
            // in use
            state.isSkip = payload;
        },
        setCheckboxClicked: (state, { payload }) => {
            state.checkboxClicked = payload;
        },
        setMergedArrayForAddAndUpdateOfChips: (state, { payload }) => {
            state.mergedArrayForAddAndUpdateOfChipsSelector = payload;
        },
        setOneBundleKey: (state, { payload }) => {
            state.bundleKey = payload;
        },
        setUpdateBrokerage: (state, { payload }) => {
            state.updateBrokerage = payload;
        },
        setCompletedSteps: (state, { payload }) => {
            state.completedSteps = payload;
        },
        setBundleRawDataForRangeWithSlugAndRule: (state, { payload }) => {
            state.bundleRawDataRangeWithSlugAndRule = {
                ...state.bundleRawDataRangeWithSlugAndRule,
                ...payload
            }
        }
    },
});

export const {
    setCheckboxClicked,
    setBundleAdditionalFiltersPayload,
    setBrokerageSuccessAction,
    setBrokerageErrorAction,
    setFieldDataAction,
    setActualFieldDataAction,
    setMatchObjAction,
    setBundleMatchAction,
    setBundleAction,
    setSingleSelectedAction,
    resetSingleSelectedAction,
    setOneBundledSelectedAction,
    setCheckAction,
    setSingleItem,
    resetSelectedChecboxNames,
    setSelectedChecboxNames,
    setSingleNameAndIdForPayload,
    // setSingleObjData,
    // setSingleDropdownClicked,
    setFileUploadAction,
    setBundleObj,
    // setBundleDropdownValues,
    setMinRange,
    setMaxRange,
    setPath,
    setBundleFieldDataObj,
    setSingleRawDataArray,
    setRadioFormanuallyOrBulk,
    setOperator,
    setBundleRawDataArray,
    setBundleRawDataForRange,
    setCommissionType,
    setBundleRawDataRangeForSlug,
    setSingleCheckBoxObjectsArray,
    setAdditionalFiltersForSinglePayload,
    setBundleCheckboxObjArray,
    setCustomInput,
    setIsMasterApiAvailableWithFieldSlug,
    setAdditionalFiltersForBundlePayload,
    setbundleFieldDataPayload,
    setbundleFieldDataPayloadOnSave,
    setSingleCustomInput,
    setConditionalPremiumBearableFields,
    setOneBundleObjWithCard,
    resetBundleCheckBoxObjArrayForDeleteChip,
    setPremiumDataWithRule,
    setUpdatedBundleAdditionalFiltersPayload,
    setSkippedAction,
    resetbundleFieldDataPayloadOnSave,
    setMergedArrayForAddAndUpdateOfChips,
    setOneBundleKey,
    setUpdateBrokerage,
    setBundleCheckboxObjArrayOnInitialMount,
    setCompletedSteps,
    setBundleCheckboxObjArrayForUpdate,
    resetBundleCheckboxObjArrayForUpdateForDeleteChip,
    setBundleRawDataForRangeWithSlugAndRule,
    resetAdditionalFiltersForBundlePayload
} = brokerageConfig.actions;
export default brokerageConfig.reducer;
