import styled from "styled-components";

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  border-radius: 5px;
  background-color: ${(props) => (props?.bgColor ? props?.bgColor : "#ededed")};
  padding: 4px;
  svg {
    font-size: 22px;
    color: #178e80;
    padding: 2px;
    border: 1px solid #cccccc;
    background-color: #fcfcfc;
    border-radius: 5px;
  }
`;
