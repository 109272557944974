import moment from "moment";
import * as yup from "yup";

export function generateDynamicYupSchema(dynamicArray) {
  console.log(dynamicArray, "dynamicArray");
  const yupValidate = yup.object().shape(
    dynamicArray?.reduce((acc, value) => {
      let validationRule = "";
      if (value.field === "DROPDOWN") {
        const fieldValue =
          value.label.length > 30
            ? value.label.slice(0, 30) + "..."
            : value.label;
        validationRule = yup
          .mixed()
          .required(
            `${
              value.label === "Do You Have Any Previous Insurance Policy?"
                ? "Previous Insurance Policy"
                : fieldValue
            } is required`
          );
      } else if (value.field === "MULTISELECT") {
        const fieldValue =
          value.label.length > 25
            ? value.label.slice(0, 30) + "..."
            : value.label;
        validationRule = yup
          .array()
          .min(1, `${fieldValue} is required`)
          .required(`${fieldValue} is required`);
      } else if (value.field === "DATE") {
        validationRule = yup
          .date()
          .typeError("Invalid Date!")
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .required(`${value.label} is required`)
          .test(`${value.label}-is-valid-date`, (value, ctx) => {
            if (new Date(value) < new Date("1900-01-01")) {
              return ctx.createError({
                message: "Date cannot be before 01/01/1900",
              });
            }
            return true;
          });
      } else if (value.field === "FILE") {
        validationRule = yup
          .string()
          .nullable()
          .required(`${value.label} is required`);
      } else {
        if (value?.key === "alternateId") {
          validationRule = yup.lazy((value) => {
            if (value === undefined || value === null || value === "") {
              return yup.mixed().nullable();
            } else {
              return yup
                .string()
                .required(`${value.label} is required`)
                .matches(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u,
                  "Please enter valid Email"
                )
                .test(`${value?.label}-is-valid-email`, (value) => {
                  if (
                    value &&
                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      value
                    )
                  ) {
                    return false;
                  }
                  return true;
                });
            }
          });
        } else if (value.validation_group === "EMAIL") {
          validationRule = yup
            .string()
            .required(`${value.label} is required`)
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u,
              "Please enter valid Email"
            );
        } else if (value?.key === "alternateMobileno") {
          validationRule = yup.lazy((value) => {
            if (value === undefined || value === null || value === "") {
              return yup.mixed().nullable();
            } else {
              return yup
                .string()
                .required(`${value.label} is required`)
                .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
                .min(10, "Please enter 10 digits")
                .max(10, "Please enter 10 digits")
                .test(`${value?.label}-is-valid-mobile-number`, (value) => {
                  if (value && !/^[6-9][0-9]{9}$/.test(value)) {
                    return false;
                  }
                  return true;
                });
            }
          });
        } else if (value.validation_group === "MOBILE") {
          validationRule = yup
            .string()
            .required(`${value.label} is required`)
            .min(10, "Please enter 10 digits")
            .max(10, "Please enter 10 digits")
            .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number");
        } else if (value.validation_group === "ADHAAR") {
          validationRule = yup
            .string()
            .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid");
        } else if (value.validation_group === "PAN") {
          validationRule = yup
            .string()
            .matches(
              /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
              "PAN number invalid"
            );
        } else if (value.validation_group === "PINCODE") {
          validationRule = yup
            .string()
            .nullable()
            .required(`${value.label} is required`)
            .min(6, "Must be 6 digits")
            .max(6, "Must be 6 digits")
            .matches(/^[0-9]+$/, "Must be only digits");
        } else if (value.field === "HIDDEN") {
          if (value.key === "customerId") {
            validationRule = yup.string().nullable().optional();
          }
        } else if (value.field === "DATETIME") {
          console.log("value", value);
          validationRule = yup
            .string()
            .required(`${value.label} is required`)
            .test(
              `${value.label}-is-valid-date`,
              `${value.label} is Invalid Date !`,
              (value, ctx) => {
                // return moment(value, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ", true).isValid();
                return value?.split(" ")[3] !== "0000"; //Please do not try, if try do not complaint
              }
            );
        } else {
          validationRule = yup.string().required(`${value.label} is required`);
        }
      }
      acc[value.key] = validationRule;
      console.log(acc,'acc')
      return acc;
    }, {})
  );

  return yupValidate;
}

export function generateDynamicOptionalYupSchema(dynamicArray) {
  const yupValidate = yup.object().shape(
    dynamicArray?.reduce((acc, value) => {
      let validationRule = "";
      if (value.field === "DATE") {
        validationRule = yup.lazy((value) => {
          if (value) {
            return yup
              .date()
              .typeError("Invalid Date!")
              .nullable()
              .transform((v, o) => (o === "" ? null : v))
              .required(`${value.label} is required`)
              .test(`${value.label}-is-valid-date`, (value, ctx) => {
                if (new Date(value) < new Date("1900-01-01")) {
                  return ctx.createError({
                    message: "Date cannot be before 01/01/1900",
                  });
                }
                return true;
              });
          }
          return yup.mixed().notRequired();
        });
      } else if (value.field === "DATETIME") {
        console.log("value", value);
        validationRule = yup
          .string()
          .required(`${value.label} is required`)
          .test(
            `${value.label}-is-valid-date`,
            `${value.label} is Invalid Date !`,
            (value, ctx) => {
              // return moment(value, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ", true).isValid();
              return value?.split(" ")[3] !== "0000"; //Please do not try, if try do not complaint
            }
          );
      } else {
        if (value.validation_group === "EMAIL") {
          validationRule = yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .required(`${value.label} is required`)
                .matches(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                  "Please enter valid Email"
                );
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "MOBILE") {
          validationRule = yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .required(`${value.label} is required`)
                .min(10, "Please enter 10 digits")
                .max(10, "Please enter 10 digits")
                .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number");
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "ADHAAR") {
          validationRule = yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid");
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "PAN") {
          validationRule = yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .matches(
                  /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                  "PAN number invalid"
                );
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "PINCODE") {
          validationRule = yup.lazy((val) => {
            if (val) {
              if (value.key == "statetwo") {
                return yup.mixed().nullable();
              } else {
                return yup
                  .string()
                  .nullable()
                  .min(6, "Must be 6 digits")
                  .max(6, "Must be 6 digits")
                  .matches(/^[0-9]+$/, "Must be only digits");
              }
            }
            return yup.mixed().notRequired();
          });
        }
      }
      acc[value.key] = validationRule;
      return acc;
    }, {})
  );

  return yupValidate;
}

export function generateInputFields({ labels, category }) {
  //generates dynamic proposal input fields
  const fields =
    labels?.length > 0
      ? labels?.map((item, index) => {
          const modifiedString = item.field_name
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
          return {
            label: modifiedString,
            key: item?.field_slug,
            field: category?.[index]?.category,
            length: category?.[index]?.length,
            mandatory: category?.[index]?.mandatory,
          };
        })
      : [];

  return fields;
}
