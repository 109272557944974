import { useQuery } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import { setActualFieldDataAction } from "./brokerage.slice";

const useGetTransactionFieldData = (lobId) => {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError } = useQuery(
    {
      queryKey: ["proposal_view_labels", lobId],
      queryFn: () =>
        HttpClient(`segregation/listings/${lobId}`, { method: "POST" }),
      enabled: !!lobId,
    },
    {
      onSuccess: (data) => {
        dispatch(setActualFieldDataAction(data));
      },
    }
  );

  return { data, isLoading, isFetching, isError };
};

export default useGetTransactionFieldData;
