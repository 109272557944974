import { CallMade } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import _, { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Button } from "../../../components";
import PNGImage from "../../../utils/png.png";
import { getFilePath } from "../../../HelperFunctions/HelperFunctions";

const AdditionalDocFilePicker = ({
  control,
  name,
  mainArrayIndex,
  setValue,
  watch,
  unregister,
  additionalFile,
  document,
  trigger,
  isReadOnly,
}) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const maxSize = 2 * 1024 * 1024;
  useEffect(() => {
    setIsFileSelected(false);
    unregister(name);
    setValue(name, null);
  }, [
    mainArrayIndex,
    JSON.stringify(watch(`document[${mainArrayIndex}].docType`)),
  ]);

  const [extention, setExtention] = useState({});
  useEffect(() => {
    if (Array.isArray(document)) {
      const newExtensionFormat = {};
      document?.forEach((doc) => {
        doc?.docType?.value?.forEach((fileType) => {
          if (fileType === "doc") {
            newExtensionFormat["application/msword"] = [".doc"];
            setExtention(newExtensionFormat);
          } else if (fileType === "text") {
            newExtensionFormat["text/plain"] = [".txt"];
            setExtention(newExtensionFormat);
          } else if (fileType === "xlsx") {
            newExtensionFormat["application/vnd.ms-excel"] = [".xls"];
            newExtensionFormat[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ] = [".xlsx"];
            setExtention(newExtensionFormat);
          } else if (fileType === "pdf") {
            newExtensionFormat["text/pdf"] = [".pdf"];
            setExtention(newExtensionFormat);
          } else if (fileType === "png") {
            newExtensionFormat["image/png"] = [".png"];
            setExtention(newExtensionFormat);
          } else if (fileType === "jpeg") {
            newExtensionFormat["image/jpeg"] = [".jpeg"];
            newExtensionFormat["image/jpg"] = [".jpg"];
            setExtention(newExtensionFormat);
          } else if (fileType === "jpg") {
            newExtensionFormat["image/jpg"] = [".jpg"];
            setExtention(newExtensionFormat);
          }
        });
      });
    }
  }, [JSON.stringify(document)]);
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (!isEmpty(acceptedFiles)) {
      if (acceptedFiles[0]?.size > maxSize) {
        Swal.fire({
          text: "File Size Must Be Less than 2 MB",
          icon: "error",
        });
        return;
      }
    }
    if (!_.isEmpty(rejectedFiles)) {
      // Show SweetAlert error for invalid file type
      Swal.fire({
        text: "Document Format Not Supported",
        icon: "error",
      });
      return;
    }

    // if (isFileSelected) {
    //   // Show SweetAlert error for adding more than one file
    //   Swal.fire({
    //     text: "You can only upload one file",
    //     icon: "error",
    //   });
    //   return;
    // }

    const base64Files = await Promise.all(
      acceptedFiles.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              name: file.name,
              base64: reader.result.split(",")[1], // Extract the Base64 data
            });
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      })
    );

    const firstDocType = document.length > 0 ? document[0].docType : null;

    // Get the id from the first docType object
    const docTypeId = firstDocType?.id || null;
    setValue(name, {
      ...base64Files[0],
      docManagementConfigId: docTypeId,
    });

    setIsFileSelected(true);
    trigger([name]);
  };

  // const FileExtensionKey = Object.keys(extention)?.[0];
  // const FileExtensionValue = extention?.[FileExtensionKey];
  // console.log({ extention, FileExtensionKey, FileExtensionValue });
  // console.log(`{${FileExtensionKey}:["${FileExtensionValue}"]}`);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // The extension object must have only one key and value pair for different file types
    // accept: `{${FileExtensionKey}:["${FileExtensionValue}"]}`,
    accept: extention,
  });
  const removeSelection = (fileObject) => {
    setIsFileSelected(false);
    unregister(name);
    setValue(name, null);
  };

  const selectedFile =
    Array.isArray(additionalFile) && additionalFile.length
      ? additionalFile.find((_, index) => index === mainArrayIndex)
      : [];
  const isValidURL = (text) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(text);
  };
  const handleRealFilePreview = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };
  const PreviewButton = (
    <RealPreviewStyled readOnlyStyles={isReadOnly}>
      <Button
        bgColor="transparent"
        textColor="#11555F"
        id="add_broker"
        variant="outlined"
        borderColor="#11555F"
        endIcon={<CallMade />}
        onClick={() => handleRealFilePreview(watch(name))}
      >
        Preview an Uploaded File
      </Button>
    </RealPreviewStyled>
  );
  return isReadOnly ? (
    PreviewButton
  ) : (
    <FilePickerContainerStyled>
      <div>
        <Controller
          name={name}
          control={control}
          render={() => (
            <div
              {...getRootProps()}
              id="customerAddDocFilePicker"
              className="file-picker"
            >
              <input {...getInputProps()} />
              <FileUploadOutlinedIcon />
              <div>
                <p className="upload-img">
                  <b>Browse </b>
                </p>
                <p className="warnings">
                  {`File Formats .${document[mainArrayIndex]?.docType?.value}`}
                </p>
                <p className="warnings">{`File Size : 2 MB`}</p>
                {/* {error && <p className="warnings">File size too large</p>} */}
              </div>
            </div>
          )}
        />
      </div>
      <div>
        {selectedFile?.files && typeof selectedFile?.files !== "string" ? (
          <SelectedFileStyled>
            <p>Uploaded :</p>
            <FileStyled>
              <div>
                <img
                  src={getFilePath(selectedFile?.files?.name)}
                  alt="file logo"
                />{" "}
                {/* Use fileImage instead of calling getFilePath again */}
                <p>{selectedFile?.files?.name?.substring(0, 50)}</p>
              </div>
              <CloseOutlinedIcon
                onClick={() => removeSelection(mainArrayIndex)}
              />
            </FileStyled>
          </SelectedFileStyled>
        ) : // Immediately invoked function expression to handle the return
        isValidURL(watch(name)) ? (
          <>{PreviewButton}</>
        ) : (
          <></> // Empty fragment
        )}
      </div>
    </FilePickerContainerStyled>
  );
};

export default AdditionalDocFilePicker;

const FilePickerContainerStyled = styled.div`
  display: flex;
  gap: 20px;
  & > div:first-of-type {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px dashed rgb(228, 228, 228);
    padding: 6px;
    & > div > p {
      font-weight: 600;
      font-size: 15px;
      margin: 0 0 0 20px;
    }
    .warnings {
      font-weight: 500;
      margin: 0px 0px 0px 0px;
      font-size: 12px;
      color: red;
    }
  }
  svg {
    font-size: 1.9rem;
  }
  b {
    color: rgb(23, 142, 128);
    text-decoration: underline;
  }

  .upload-img {
    margin: 0;
  }

  .file-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

const SelectedFileStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 8px 0px 8px p {
    margin: 0;
  }
  & > p {
    padding: 10px;
    
    color: rgb(17, 85, 95);
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const FileStyled = styled.div`
  border-radius: 10px;
  border: 0.25px solid #e4e4e4;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    p {
      margin: 5px;
      text-transform: capitalize;
      font-size: 12px;
      letter-spacing: 0.75px;
    }
  }
  svg {
    cursor: pointer;
    font-size: 20px;
    margin-left: 50px;
    color: #000;
  }
`;
export const RealPreviewStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) =>
    props.isInDatagrid || props.readOnlyStyles ? "0" : "30px"};
  margin-right: 20px;
  justify-content: ${(props) =>
    props.readOnlyStyles ? "flex-start" : "flex-end"};
  margin-left: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  position: ${(props) => (props.readOnlyStyles ? "absolute" : "0")};
  right: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  bottom: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
`;
