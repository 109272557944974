import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    id: null,
    proposalCreateValues: [],
    proposalEditValues: [],
    proposalError: null,
    proposalSuccess: null,
    proposalPartialUpdateSuccess: false,
    uploadedFileURL: {},
    isPrevPolicyVisible: false,
    inactivePolicyEntrySuccess: false,
    inactivePolicyEntryError: false,
    brokerage: {
      companyId: "",
      transactionTypeId: "",
      transactionId: "",
      userId: "",
    },
    ocrLoader: null,
    policyEditIndex: 0,
    policyViewCards: {},
    claimDeleteId: "",
  },
  reducers: {
    setProposalPartialUpdateSuccess: (state, action) => {
      state.proposalPartialUpdateSuccess = action.payload;
    },
    setIdAction: (state, action) => {
      state.id = action.payload;
    },
    setInactivePolicyEntrySuccessAction: (state, { payload }) => {
      state.inactivePolicyEntrySuccess = payload;
      state.inactivePolicyEntryError = null;
    },
    setInactivePolicyEntryErrorAction: (state, { payload }) => {
      state.inactivePolicyEntryError = serializeError(payload);
      state.inactivePolicyEntrySuccess = null;
    },
    setProposalCreateValuesAction: (state, action) => {
      if (action.payload === "reset") {
        state.proposalCreateValues = [];
      } else {
        state.proposalCreateValues = [
          { ...state.proposalCreateValues?.[0], ...action.payload },
        ];
      }
    },
    setProposalEditValuesAction: (state, action) => {
      state.proposalEditValues = [
        { ...state.proposalEditValues, ...action.payload },
      ];
    },
    setProposalSuccessAction: (state, { payload }) => {
      state.proposalSuccess = payload;
      state.proposalError = null;
    },
    setProposalErrorAction: (state, { payload }) => {
      state.proposalError = serializeError(payload);
      state.proposalSuccess = null;
    },
    setBrokerage: (state, { payload }) => {
      state.brokerage = { ...state.brokerage, ...payload };
    },
    setUploadedFileURLAction: (state, { payload }) => {
      state.uploadedFileURL = { ...state.uploadedFileURL, ...payload };
    },

    removeFileURLAction: (state, { payload }) => {
      const { parent, index, fieldKey } = payload;
      const keyName = `${parent}.[${index}].${fieldKey}`;
      const { [keyName]: _, ...remainingEntries } = state.uploadedFileURL;

      // Filter and sort keys that match the pattern 'otherDocs.[n].otherDocu'
      const filteredKeys = Object.keys(remainingEntries).filter(
        key => key.startsWith(parent) && key.includes(fieldKey),
      );

      // Sort the filtered keys based on the numerical index
      const sortedKeys = filteredKeys.sort((a, b) => {
        const aIndex = parseInt(a.match(/\[(\d+)\]/)?.[1], 10);
        const bIndex = parseInt(b.match(/\[(\d+)\]/)?.[1], 10);
        return aIndex - bIndex;
      });

      // Re-index the filtered keys
      const reindexedEntries = sortedKeys.reduce((acc, key, index) => {
        const newKey = key.replace(/\[\d+\]/, `[${index}]`);
        acc[newKey] = remainingEntries[key];
        return acc;
      }, {});

      // Update the state with re-indexed entries and keep other entries intact
      state.uploadedFileURL = {
        ...Object.keys(state.uploadedFileURL)
          .filter(key => !key.startsWith(parent) || !key.includes(fieldKey))
          .reduce((acc, key) => {
            acc[key] = state.uploadedFileURL[key];
            return acc;
          }, {}),
        ...reindexedEntries,
      };
    },

    setIsPrevPolicyVisibleAction: (state, { payload }) => {
      state.isPrevPolicyVisible = payload;
    },
    setOcrLoader: (state, { payload }) => {
      state.ocrLoader = payload;
    },
    setPolicyEditIndex: (state, { payload }) => {
      state.policyEditIndex = payload;
    },
    setPolicyViewCards: (state, { payload }) => {
      state.policyViewCards = payload;
    },
    setDeleteID: (state, { payload }) => {
      state.claimDeleteId = payload;
    },
    resetAllData: state => {
      state.id = null;
      state.proposalCreateValues = [];
      state.proposalEditValues = [];
      state.proposalError = null;
      state.proposalSuccess = null;
      state.proposalPartialUpdateSuccess = false;
      state.uploadedFileURL = {};
      state.isPrevPolicyVisible = false;
      state.inactivePolicyEntrySuccess = false;
      state.inactivePolicyEntryError = false;
      state.brokerage = {
        companyId: "",
        transactionTypeId: "",
        transactionId: "",
        userId: "",
      };
      state.ocrLoader = null;
      state.policyEditIndex = 0;
      state.policyViewCards = {};
    },
    clearFile: state => {
      state.uploadedFileURL = {};
    },
  },
});

export const {
  setProposalPartialUpdateSuccess,
  setProposalCreateValuesAction,
  setProposalEditValuesAction,
  setIdAction,
  setProposalSuccessAction,
  setProposalErrorAction,
  setBrokerage,
  setUploadedFileURLAction,
  removeFileURLAction,
  setIsPrevPolicyVisibleAction,
  setInactivePolicyEntryErrorAction,
  setInactivePolicyEntrySuccessAction,
  setOcrLoader,
  setPolicyEditIndex,
  setPolicyViewCards,
  resetAllData,
  setDeleteID,
  clearFile,
} = proposalSlice.actions;
export default proposalSlice.reducer;
