import {
	Card,
	CardContent,
	Grid,
	Paper,
	Tooltip,
	tooltipClasses,
} from "@mui/material";
import styled from "styled-components";
import { Button } from "../../../components";

export const dataGridStyles = {
	".MuiDataGrid-cellContent": {
		fontSize: "12px",
	},
	".MuiDataGrid-columnSeparator": {
		display: "none",
	},
	"&.MuiDataGrid-root": {
		borderRadius: "10px",
	},
	".MuiDataGrid-columnHeaders ": {
		borderTopLeftRadius: "10px",
		borderTopRightRadius: "10px",
		background: "#4373eb",
		color: "#fff",
		fontSize: "12px",
		border: "2px solid",
	},
	".MuiTablePagination-root p": {
		fontSize: "12px",
	},
};

export const headerStyles = {
	fontSize: "1.5rem",
	color: "rgb(94, 90, 219)",
	// height: "36px",
	width: "100%",
	lineHeight: "36px",
	fontWeight: "600",
	marginBottom: "15px",
};

export const Span = styled.span`
	display: block;
	font-size: 15px;
	color: #808080;
`;

export const Container = styled(Grid)`
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
`;

export const DocumentCard = styled(Paper)`
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 140px;
	width: 155px;
	padding: 10px;
	border-radius: 8px !important;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px 0px !important;

	&:hover {
		transform: scale(1.05);
		box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px 0px !important;
		transition: 0.5s;
	}
`;

export const StyledContent = styled(CardContent)`
	padding: 15px !important;
`;
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	#fileName {
		font-weight: 600;
		font-size: 12px;
		word-break: break-word;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	#icons {
		display: flex;
		gap: 5px;
		flex-direction: column;
		cursor: pointer;
	}
`;

export const FileWrapper = styled.div`
	display: flex;
	gap: 8px;
	width: 100%;
`;

export const DocumentDetail = styled.div`
	display: flex;
	flex-direction: column;
`;

export const DocumentInfo = styled.span`
	font-size: 11px;
	${({ docNo }) =>
		docNo &&
		`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    font-weight: 700;`};
`;

export const File = styled.div`
	// margin: 0 0 -14px -15px;
`;

export const TimeStamp = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;

	#upload-label {
		font-size: 11px;
	}

	#upload-timestamp {
		font-size: 11px;
	}
`;

export const StyledButton = styled(Button)`
	margin-left: ${({ isMargin }) => (isMargin ? "10px !important" : "0")};
	color: #5e5adb !important;
	border-radius: 30px !important;
	border: 1px solid #4373eb !important;
	font-weight: bold !important;
	${({ isActive }) =>
		isActive
			? `background-color: #5e5adb !important;
  color: #fff !important;`
			: `background-color: #fff !important;
  color: #5e5adb !important;`}
	&:hover {
		background-color: #5e5adb !important;
		color: #fff !important;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 10px;
	justify-content: space-between;
`;

export const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f6f6f6",
		color: "rgba(0, 0, 0, 0.87)",
		// boxShadow: theme.shadows[1],
		padding: "8px",
		margin: 0,
		position: "relative",
		border: "1px solid lightgrey",
	},
}));

export const MoreActions = styled.div`
	display: flex;
	cursor: pointer;
	padding: 2px;
	border-radius: 4px;
	color: black;
	background-color: white;
	outline: 1px solid lightgrey;
	&:hover {
		outline: 1px solid grey;
	}
`;
