import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useSelector } from "react-redux";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetRiskoMeter } from "../customerDashboard/service";
import { useRiskometer } from "./service";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { saveData } from "./riskoMeterMember.slice";

const validationSchema = Yup.object().shape({
  checkbox: Yup.object()
    .test("at-least-one", "At least one option must be selected", (value) => {
      return value && Object.values(value).some((val) => val === true);
    })
    .nullable(),
});

const RiskometerMedicalHistory = ({ handleSave, handleBack, activeTab }) => {
  const {
    watch,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();

  const values = getValues();

  const { mutateAsync: ageRiskometer, isLoading: customerAddLoading } =
    useRiskometer(activeTab);

  const [selectedOption, setSelectedOption] = useState("");

  const { coverageAmountPayload, resData, allData, range } = useSelector(
    (state) => state.riskoMeterMember
  );

  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge)?.length > 1;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");

  const onSubmit = async () => {
    const payload = {
      questionId: 4,
      lobId: +lobId,
      request_data: {
        familyType: coverageAmountPayload?.request_data?.familyType,
        option: selectedOption,
      },
    };

    dispatch(
      saveData({
        medicalHistory: {
          ...values,
          selectedOption,
        },
      })
    );
    const res = await ageRiskometer(payload);

    if (res?.success) {
      handleSave();
    }
  };

  const { theme } = useSelector(({ theme }) => theme);

  const {
    mutate: GetRiskometer,
    data: RiskometerData,
    isLoading,
  } = useGetRiskoMeter();

  useEffect(() => {
    const timer = setTimeout(() => {
      Object.entries(errors)?.map(([key, values]) => {
        if (_.isEmpty(watch(key)) || !_.isEmpty(watch(key))) {
          trigger(key);
        }
      });
    }, 500);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [JSON.stringify(watch()), JSON.stringify(Object.keys(errors))]);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    // Set color for maximum value label
    axis.renderer.labels.template.adapter.add("fill", (fill, target) => {
      if (target.dataItem && target.dataItem.value === 20) {
        return am4core.color("#FFF"); // Change color to black for max value
      }
      return fill;
    });

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  useEffect(() => {
    if (allData?.medicalHistory?.selectedOption) {
      setSelectedOption(allData?.medicalHistory?.selectedOption);
    }
  }, [allData?.medicalHistory?.selectedOption]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <BackButton onClick={handleBack}>
          <ArrowBackIcon />
        </BackButton>
        <Title>Medical History</Title>
        <Subtitle>
          Do any member(s) have any existing illnesses for which they take
          regular medication?
        </Subtitle>
        <form className="space-y-4">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "16px",
            }}
          >
            {[
              "No Issues",
              "Minor Issues",
              "Chronic Issues",
              "Serious Conditions",
              "Critical Illness",
              "Pre-existing Conditions",
            ].map((option, index) => (
              <OptionContainer
                key={index}
                isSelected={selectedOption === option}
                onClick={() => setSelectedOption(option)}
              >
                <input
                  type="radio"
                  id={`option${index}`}
                  name="familyHistory"
                  className="mr-2"
                  style={{ display: "none" }}
                  onChange={() => setSelectedOption(option)}
                />
                <label htmlFor={`option${index}`} style={{ fontSize: "16px" }}>
                  {option}
                </label>
              </OptionContainer>
            ))}
          </div>
          {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
          <div style={{ display: "flex", marginTop: "25px" }}>
            <AddButton type="button" onClick={() => onSubmit()}>
              Continue
            </AddButton>
          </div>
        </form>
      </Container>
      {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />}
    </div>
  );
};

export default RiskometerMedicalHistory;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
`;

const Container = styled.div`
  padding: 45px;
  width: 125vw;
  max-width: 800px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const Subtitle = styled.h3`
  text-align: center;
  font-size: 16px;
  padding-top: 30px;
`;

const OptionContainer = styled.div`
  border: ${({ isSelected, theme }) =>
    isSelected ? `1px solid ${theme?.ascentColor}` : "1px solid #e0e0e0"};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? `${theme.lightAscentColor}` : "white"};
  min-width: 240px;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin: 10px auto;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 256px;
`;
