import {
	useMutation,
	useQueries,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import {
	setCustomerErrorAction,
	setCustomerSuccessAction,
} from "./customer.slice";
import httpClient from "../../api/httpClient";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

export const useGetCustomerList = (pagination) => {
	const location = useLocation();
	const payload = {
		// can be a better way to do this. temp fix for -> #3267
		page:
			pagination?.customerRefId ||
			pagination?.first_name ||
			pagination?.lastName ||
			pagination?.customerName ||
			pagination?.mobileNumber ||
			pagination?.emailId ||
			pagination?.vehicleRegNo
				? 1
				: +pagination?.page + 1 || 1,
		size: +pagination?.pageSize || 20,
		sortBy: "customerId",
		sortOrder: "DESC",
		searchValue: {
			firstName: pagination?.first_name ? pagination?.first_name : "",
			lastName: pagination?.lastName || "",
			fullName: pagination?.customerName || "",
			customerRefId: pagination?.customerRefId ? pagination?.customerRefId : "",
			mobileNumber: pagination?.mobileNumber || "",
			parentId: 0,
			emailId: pagination?.emailId || "",
			city: "",
			vehicleRegNo: pagination?.vehicleRegNo || "",
		},
	};
	const { data, refetch, isFetching } = useQuery({
		queryKey: ["customer_record", payload],
		queryFn: () =>
			httpClient("customer_records/listPaging", {
				method: "POST",
				data: payload,
			}),
		enabled: location?.pathname === "/customer",
	});
	return { data, refetch, isFetching };
};

export const useGetCustomerListExport = ({ pagination, searchPayload }) => {
	const payload = {
		page: +pagination?.page + 1 || 1,
		size: +pagination?.pageSize || 20,
		sortBy: "customerId",
		sortOrder: "DESC",
		searchValue: {
			firstName: searchPayload?.first_name ? searchPayload?.first_name : "",
			lastName: searchPayload?.lastName || "",
			fullName: searchPayload?.customerName || "",
			customerRefId: searchPayload?.customerRefId
				? searchPayload?.customerRefId
				: "",
			mobileNumber: searchPayload?.mobileNumber || "",
			parentId: 0,
			emailId: searchPayload?.emailId || "",
			city: "",
			vehicleRegNo: searchPayload?.vehicleRegNo || "",
		},
	};

	const { data, mutate, isSuccess } = useMutation(
		() => {
			return httpClient("customer_records/listPaging/export", {
				method: "POST",
				data: payload,
			});
		},
		{
			onSuccess: (data) => {
				const url = data?.data?.data;
				window.open(url, "_blank");
			},
		}
	);

	return { data, mutate, isSuccess };
};

export const useGetCustomerRecordsById = (id) => {
	const { data } = useQuery(
		["customer_record", id],
		() => {
			return httpClient(`customer_records/List/${id}`, { method: "GET" });
		},
		{
			enabled: Boolean(id),
		}
	);
	return { data };
};

export const useGetCustomerRecord = (customerModule) => {
	const { data } = useQuery(
		["customerRecord"],
		() => {
			return httpClient(`customer_records/List`, { method: "GET" });
		},
		{ enabled: customerModule }
	);
	return { data };
};

export const useCreateCustomer = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { data, mutate, isLoading, isError, isFetching } = useMutation(
		(payload) => {
			return httpClient("/customer_records/add", {
				method: "POST",
				data: payload,
			});
		},
		{
			onSuccess: (data) => {
				if (!data.success) {
					dispatch(setCustomerErrorAction(data.error));
					return;
				}
				queryClient.invalidateQueries(["customer_record"]);
				dispatch(setCustomerSuccessAction(data.message));
			},
		}
	);
	return { data, mutate, isLoading, isError };
};

export const useCreateCustomerThroughPolicyEntry = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { data, mutateAsync, isLoading, isError, isFetching } = useMutation(
		(payload) => {
			return httpClient("/customer_records/add", {
				method: "POST",
				data: payload,
			});
		},
		{
			onSuccess: (data) => {
				if (!data.success) {
					dispatch(setCustomerErrorAction(data.error));
					return;
				}
				queryClient.invalidateQueries(["customer_record"]);
				dispatch(setCustomerSuccessAction(data.message));
			},
		}
	);
	return { data, mutateAsync, isLoading, isError };
};
export const useCreateMultipleCustomer = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { data, mutate, isLoading, isError, isFetching } = useMutation(
		(payload) => {
			return httpClient("/customer_records/add-multiple", {
				method: "POST",
				data: payload,
			});
		},
		{
			onSuccess: (data) => {
				if (!data.success) {
					dispatch(setCustomerErrorAction(data.error));
					return;
				}
				queryClient.invalidateQueries(["customer_record"]);
				dispatch(setCustomerSuccessAction(data.message));
			},
		}
	);
	return { data, mutate, isLoading, isError };
};

export const useCreateMultipleCustomerThroughPolicyEntry = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { data, mutateAsync, isLoading, isError, isFetching } = useMutation(
		(payload) => {
			return httpClient("/customer_records/add-multiple", {
				method: "POST",
				data: payload,
			});
		},
		{
			onSuccess: (data) => {
				if (!data.success) {
					dispatch(setCustomerErrorAction(data.error));
					return;
				}
				queryClient.invalidateQueries(["customer_record"]);
				dispatch(setCustomerSuccessAction(data.message));
			},
		}
	);
	return { data, mutateAsync, isLoading, isError };
};

export const useGetCustomerMembers = ({ parentId, memberCount }) => {
	const payload = {
		page: 0,
		size: 0,
		sortBy: "",
		sortOrder: "",
		searchValue: {
			firstName: "",
			customerRefId: "",
			parentId: parentId,
			emailId: "",
			mobileNumber: "",
			city: "",
		},
	};
	const { data } = useQuery(["cutomerMember", parentId, memberCount], () =>
		httpClient(`customer_records/listPaging`, {
			method: "POST",
			data: payload,
		})
	);
	return { data: data?.data?.data || [] };
};

export const useGetManufacturerDataById = (lobid) => {
	const { data, isLoading } = useQuery({
		queryKey: ["getManufacturerData", lobid],
		queryFn: () =>
			httpClient("lob_manufacturer/get-manufacturer", {
				method: "POST",
				data: { lobid },
			}),
		enabled: lobid ? true : false,
	});

	return { data: data || [] };
};

export const useGetMultipleManufacturerDataById = (id) => {
	const { data } = useQuery({
		queryKey: ["getMultipleManufacturerData", id],
		queryFn: () =>
			httpClient("lob_manufacturer/get-manufacturer", {
				method: "POST",
				data: { lobid: id },
			}),
		staleTime: Infinity,
		enabled: !!id,
	});

	return { data };
};

export const useGetMultipleStateCityFromPincode = ({ pincode = [] }) => {
	const data = useQueries({
		queries: pincode?.map((pincode) => {
			return (
				!_.isEmpty(pincode) &&
				pincode?.length === 6 && {
					queryKey: ["pincode", pincode],
					queryFn: () => httpClient(`pincode/list/${pincode}`),
					staleTime: Infinity,
				}
			);
		}),
	});
	return { data: data || [] };
};

// result?.[idx]?.data?.data
export const useGetModelDataForCustomer = () => {
	const { data, mutate, isLoading } = useMutation((payload) => {
		return httpClient("/lob_manufacturer/get-model", {
			method: "POST",
			data: payload,
		});
	});
	return { data, mutate, isLoading };
};

export const useGetMultipleModelDataForCustomer = ({
	id,
	selectedManufactureId,
}) => {
	const { data } = useQuery({
		queryKey: ["getMultipleModalData", id, selectedManufactureId],
		queryFn: () =>
			httpClient("lob_manufacturer/get-model", {
				method: "POST",
				data: { lobid: id, manufacturer_id: selectedManufactureId },
			}),
		staleTime: Infinity,
		enabled: !!id && !!selectedManufactureId,
	});

	return { data };
};

export const useGetUserByIdForCustomer = ({ memberDetails = [] }) => {
	const data = useQueries({
		queries: memberDetails?.map((item, index) => {
			let selectedUserTypeID = item?.userType?.value;
			return (
				selectedUserTypeID && {
					queryKey: ["getUserTypeInCustomer", selectedUserTypeID],
					queryFn: () =>
						httpClient(`users/user_type/${selectedUserTypeID}`, {
							method: "GET",
						}),
					staleTime: Infinity,
				}
			);
		}),
	});

	return { data: data || [] };
};

export const useGetVersionDataForCustomer = () => {
	const { data, mutate, isLoading } = useMutation((payload) => {
		return httpClient("/lob_manufacturer/get-version", {
			method: "POST",
			data: payload,
		});
	});
	return { data, mutate, isLoading };
};

export const useGetMultipleVersionDataForCustomer = ({
	id,
	selectedModalId,
}) => {
	const { data } = useQuery({
		queryKey: ["getMultipleVersionData", id, selectedModalId],
		queryFn: () =>
			httpClient("/lob_manufacturer/get-version", {
				method: "POST",
				data: { lobid: id, model_id: selectedModalId },
			}),
		staleTime: Infinity,
		enabled: !!id && !!selectedModalId,
	});

	return { data };
};

export const useGetCustomerDocumentList = () => {
	const { data } = useQuery({
		queryKey: ["customer_doc_list"],
		queryFn: () =>
			httpClient("customer_additional_document/list", {
				method: "GET",
			}),
	});
	return { data };
};

export const useGetCustomerDocumentListById = (id) => {
	const { data } = useQuery(
		["customer_doc_list", id],
		() => {
			return httpClient(`customer_additional_document/listByCustomerId/${id}`, {
				method: "GET",
			});
		},
		{
			enabled: Boolean(id),
		}
	);
	return { data };
};

export const useAddVehicleDetails = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { data, mutate, isLoading, isError } = useMutation(
		(payload) => {
			return httpClient("customer_vehicle_details/add", {
				method: "POST",
				data: payload,
			});
		},
		{
			onSuccess: (data) => {
				if (!data.success) {
					dispatch(setCustomerErrorAction(data.error));
					return;
				}
				queryClient.invalidateQueries(["vehicle_details"]);
				dispatch(setCustomerSuccessAction(data.message));
			},
		}
	);

	return { data, mutate, isLoading, isError };
};

export const useAddAdditionalDetails = ({ type }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { data, mutate, isLoading, isError } = useMutation(
		(payload) => {
			return httpClient("customer_additional_document/add", {
				method: "POST",
				data: payload,
			});
		},
		{
			onSuccess: (data) => {
				if (!data.success) {
					if (type === "addForm") {
						Swal.fire({
							text: "Customer Created, Document Upload Failed . Kindly Reupload the Documents through Listing Table",
							icon: "info",
							confirmButtonText: "OK",
							confirmButtonColor: "#DC004E",
							reverseButtons: true,
							focusConfirm: false,
							allowEscapeKey: false,
							allowOutsideClick: false,
							focusCancel: false,
							scrollbarPadding: false,
						}).then((result) => {
							if (result.isConfirmed) {
								dispatch(setCustomerErrorAction(false));
								navigate("/customer");
							}
						});
					} else {
						dispatch(setCustomerErrorAction(data.error));
						return;
					}
				} else if (type === "addForm") {
					Swal.fire({
						text: "Document and Customer Added Successfully",
						icon: "success",
						confirmButtonText: "OK",
						confirmButtonColor: "#DC004E",
						reverseButtons: true,
						focusConfirm: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						focusCancel: false,
						scrollbarPadding: false,
					}).then((result) => {
						if (result.isConfirmed) {
							dispatch(setCustomerSuccessAction(false));
							navigate("/customer");
							// queryClient.invalidateQueries(["customer_doc_list"]);
						}
					});
				} else {
					queryClient.invalidateQueries(["customer_doc_list"]);
					dispatch(setCustomerSuccessAction(data.message));
				}
			},
		}
	);

	return { data, mutate, isLoading, isError };
};
export const useUpdateAdditionalDetails = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { data, mutate, isLoading, isError } = useMutation(
		(payload) => {
			const { id, ...rest } = payload;
			return httpClient(`customer_additional_document/update`, {
				method: "PUT",
				data: rest,
			});
		},
		{
			onSuccess: (data) => {
				if (!data.success) {
					dispatch(setCustomerErrorAction(data.error));
					return;
				}
				queryClient.invalidateQueries(["customer_doc_list"]);
				dispatch(setCustomerSuccessAction(data.message));
			},
		}
	);

	return { data, mutate, isLoading, isError };
};

export const useGetDocumentDataForCustomer = () => {
	const location = useLocation();
	const { data, isFetching, refetch } = useQuery({
		queryKey: ["doc_data_for_customer"],
		queryFn: () =>
			httpClient("document_management/list", {
				method: "POST",
				data: {
					page: 0,
					size: 0,
					sortBy: "",
					sortOrder: "",
					documentName: "",
					documentCode: "",
					documentType: "",
				},
			}),
		enabled:
			location?.pathname !== "/customer-vehicle-lists" &&
			location?.pathname !== "/customer-add",
	});

	return { data, isFetching, refetch };
};
// /customer_vehicle_details/update/{id}

export const useUpdateVehicleDetails = () => {
	const queryClient = useQueryClient();
	const { mutate, status } = useMutation(
		({ ID, payload }) => {
			return httpClient(`/customer_vehicle_details/update/${ID}`, {
				method: "PUT",
				data: payload,
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("customerVehicleDetailListing");
			},
		}
	);
	return { UpdateVehicleDetails: mutate, UpdateVehicleDetailsStatus: status };
};
export const useDeleteVehicleDetails = () => {
	const queryClient = useQueryClient();
	const { mutate } = useMutation(
		(id) => {
			return httpClient(`/customer_vehicle_details/delete/${id}`, {
				method: "DELETE",
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("customerVehicleDetailListing");
			},
		}
	);
	return { deleteVehicleDetails: mutate };
};

export const useGetVehicleDetails = (id) => {
	const { data } = useQuery(["customerVehicleDetailListing", id], () =>
		httpClient(`/customer_vehicle_details/listByCustomerId/${id}`)
	);
	return { data };
};
export const useGetVehicleDetailsbyId = (id) => {
	const { data } = useQuery(
		["customerVehicleDetailListingbyID", id],
		() => httpClient(`/customer_vehicle_details/list/${id}`),
		{ enabled: id ? true : false }
	);
	return { data };
};

export const useGetFamilyListing = ({ customerID, page, size }) => {
	const payload = {
		page: +page,
		size: +size,
		sortBy: "",
		sortOrder: "",
		searchValue: {
			firstName: "",
			customerRefId: "",
			parentId: customerID,
			emailId: "",
			mobileNumber: "",
			city: "",
		},
	};
	const { data } = useQuery(["cutomerFamilyList", payload], () =>
		httpClient(`customer_records/listPaging`, {
			method: "POST",
			data: payload,
		})
	);
	return { data };
};
export const useDeleteCustomer = () => {
	const queryClient = useQueryClient();
	const { mutate } = useMutation(
		(id) => {
			return httpClient(`customer_records/delete/${id}`, { method: "DELETE" });
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("customer_record");
			},
		}
	);
	return { mutate };
};

export const useGetUserGroupData = () => {
	const { data, isFetching, isError } = useQuery({
		queryKey: ["user_group_master"],
		queryFn: () =>
			httpClient("master_data/listing", {
				method: "POST",
				data: {
					masterId: 0,
					lobId: 0,
					masterSlug: "user_group_master",
				},
			}),
	});
	return { data, isFetching, isError };
};

export const useGetMaritialData = () => {
	const { data, isFetching, isError } = useQuery({
		queryKey: ["maritial_status_master"],
		queryFn: () =>
			httpClient("master_data/listing", {
				method: "POST",
				data: {
					masterId: 0,
					lobId: 0,
					masterSlug: "maritial_status_master",
				},
			}),
	});
	return { data, isFetching, isError };
};

export const useGetCustomerReferredFromData = () => {
	const { data, isFetching, isError } = useQuery({
		queryKey: ["lead_source_master"],
		queryFn: () =>
			httpClient("master_data/listing", {
				method: "POST",
				data: {
					masterId: 0,
					lobId: 0,
					masterSlug: "lead_source_master",
				},
			}),
	});
	return { data, isFetching, isError };
};

export const useGetTitleFromData = () => {
	const { data, isFetching, isError } = useQuery({
		queryKey: ["salutation_master"],
		queryFn: () =>
			httpClient("master_data/listing", {
				method: "POST",
				data: {
					masterId: 0,
					lobId: 0,
					masterSlug: "salutation_master",
				},
			}),
	});
	return { data, isFetching, isError };
};

export const useGetNationalityFromData = () => {
	const { data, isFetching, isError } = useQuery({
		queryKey: ["nationality_master"],
		queryFn: () =>
			httpClient("master_data/listing", {
				method: "POST",
				data: {
					masterId: 0,
					lobId: 0,
					masterSlug: "nationality_master",
				},
			}),
	});
	return { data, isFetching, isError };
};

export const useGetDataFromCustomerId = (cutomerId) => {
	const { data } = useQuery({
		queryKey: ["customerDataFromById", cutomerId],
		queryFn: () =>
			httpClient(`/customer_records/List/${cutomerId}`, {
				method: "GET",
			}),
		enabled: Boolean(cutomerId),
	});
	return { data };
};

export const useUpdateDataFromCustomerId = (cutomerId) => {
	const queryClient = useQueryClient();
	const { mutate, data, isError, isLoading } = useMutation(
		(payload) => {
			return httpClient(`customer_records/update/${cutomerId}`, {
				method: "PUT",
				data: payload,
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("cutomerFamilyList");
			},
		}
	);
	return { mutate, data, isError, isLoading };
};

export const useDeleteFamilyMember = () => {
	const queryClient = useQueryClient();
	const { mutate } = useMutation(
		(id) => {
			return httpClient(`customer_records/delete/${id}`, { method: "DELETE" });
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("cutomerFamilyList");
			},
		}
	);
	return { mutate };
};

export const useGetEndorsementListForCustomer = (searchData) => {
	const payload = {
		page: +searchData?.page || 0,
		size: +searchData?.size || 0,
		sortBy: "",
		sortOrder: "",
		searchParams: {
			lobId: searchData?.searchParams?.lobId || 0,
			policyNo: "",
			firstName: "",
			emailId: "",
			mobileNo: "",
			branchName: "",
			lastName: "",
			insuranceCompanyname: "",
			customerId: +searchData?.searchParams?.customerId,
			// customerId: 1756,
		},
	};
	const { data, isFetching, refetch } = useQuery({
		queryKey: ["endorsementListForCustomer", payload],
		queryFn: () =>
			httpClient("endorsements_details/listPaging", {
				method: "POST",
				data: payload,
			}),
	});
	return { data, isFetching, refetch };
};

export const getClaimSearchDataForCustomer = (searchData) => {
	const payload = {
		page: +searchData?.page || 1,
		size: +searchData?.size || 20,
		sortBy: "",
		sortOrder: "",
		searchParams: {
			lobId: searchData?.searchParams?.lobId || 0,
			policyNo: searchData?.searchParams?.policyNo || "",
			firstName: searchData?.searchParams?.firstName || "",
			emailId: searchData?.searchParams?.emailId || "",
			mobileNo: searchData?.searchParams?.mobileNo || "",
			branchName: searchData?.searchParams?.branchName || "",
			lastName: "",
			insuranceCompanyname: "",
			customerId: +searchData?.searchParams?.customerId,
			// customerId: 849,
		},
	};
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, refetch, isFetching } = useQuery({
		queryKey: ["claimSearchForCustomer", payload],
		queryFn: () =>
			httpClient("/claim_details/listPaging", {
				method: "POST",
				data: payload,
			}),
	});
	return { data, refetch, isFetching };
};
