export const genderObject = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
  { label: "Other", value: "OTHER" },
];

export const channelTypeOptions = [
  { label: "Online", value: "Online" },
  { label: "Offline", value: "Offline" },
];

export const leadPriorityOptions = [
  { label: "High", value: "High" },
  { label: "Normal", value: "Normal" },
  { label: "Low", value: "Low" },
];
export const sourcingUserTypeOptions = [
  { label: "Employee", value: "EMPLOYEE" },
  { label: "Customer", value: "CUSTOMER" },
  { label: "Reference", value: "REFERENCE" },
];
