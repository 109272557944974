import React, { useState } from "react";
import { Tabs, Tab, Box, Button, Container } from "@mui/material";
import styled from "styled-components";
import RiskometerMembers2 from "./riskometerMembers2";
import RiskometerMembersAge from "./riskometerMembersAge";
import RiskometerCoverage from "./riskometerCoverage";
import RiskometerAnnualIncome from "./riskometerAnnualIncome";
import RiskometerMedicalHistory from "./riskometerMedicalHistory";
import RiskometerStateCity from "./riskometerStateCity";
import RiskometerLifeStyle from "./riskometerlifeStyle";
import RiskometerFamilyHistory from "./riskometerFamilyHistory";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeLast } from "./riskoMeterMember.slice";
import { useSelector } from "react-redux";
import { useGetAnnualIncome } from "./service";
import TermLifestyleSurvey from "./RiskometerTerm/termLifeStyle";
import TermRetirementAge from "./RiskometerTerm/termRetirementAge";
import TermExpenses from "./RiskometerTerm/termExpenses";
import TermCurrentLiabilities from "./RiskometerTerm/termCurrentLiabilities";

const StyledTab = styled(Tab)`
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  padding: 12px 16px;
  color: #bdbdbd;
  &.Mui-selected {
    color: #bdbdbd;
  }
  &.Mui-focusVisible {
    outline: none;
  }
  &.Mui-disabled {
    color: #bdbdbd;
    pointer-events: none;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const StepContent = styled(Box)`
  margin-top: 20px;
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;

const Riskometer = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [savedData, setSavedData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobName = queryParams.get("lobName");

  const lobId = queryParams.get("lobId");
  const { data: annualIncomeData } = useGetAnnualIncome(lobId);

  const selfUserData = annualIncomeData?.data?.data;
  const { range } = useSelector((state) => state.riskoMeterMember);

  // Define the number of tabs based on the lobName
  const totalTabs = (() => {
    switch (lobName) {
      case "Health":
        return 8;
      case "Term":
        return 8;
      case "Personal Accident":
        return 4;
      case "Critical Illness":
        return 4;
      default:
        return 4;
    }
  })();

  // const handleTabChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  const handleTabChange = (event, newValue) => {
    // setActiveTab(newValue);
  };

  const handleSave = () => {
    setSavedData(activeTab);
    if (activeTab < totalTabs - 1) {
      setActiveTab(activeTab + 1);
    }
  };

  const handleBack = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
      dispatch(removeLast());
    }
  };

  const renderStepComponent = () => {
    switch (lobName) {
      case "Health":
        return renderHealthTabs();
      case "Term":
        return renderTermTabs();
      case "Personal Accident":
        return renderPersonalAccidentTabs();
      case "Critical Illness":
        return renderCriticalIllnessTabs();
      default:
        return null;
    }
  };

  const renderHealthTabs = () => {
    switch (activeTab) {
      case 0:
        return (
          <RiskometerMembers2
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 1:
        return (
          <RiskometerMembersAge
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 2:
        return (
          <RiskometerCoverage
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 3:
        return (
          <RiskometerAnnualIncome
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
            selfUserData={selfUserData}
          />
        );
      case 4:
        return (
          <RiskometerMedicalHistory
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 5:
        return (
          <RiskometerStateCity
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 6:
        return (
          <RiskometerLifeStyle
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 7:
        return (
          <RiskometerFamilyHistory
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      default:
        return null;
    }
  };

  const renderTermTabs = () => {
    switch (activeTab) {
      case 0:
        return (
          <RiskometerMembers2
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 1:
        return (
          <RiskometerMembersAge
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 2:
        return (
          <TermLifestyleSurvey
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 3:
        return (
          <RiskometerAnnualIncome
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 4:
        return (
          <RiskometerCoverage
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 5:
        return (
          <TermRetirementAge
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 6:
        return (
          <TermExpenses
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 7:
        return (
          <TermCurrentLiabilities
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      default:
        return null;
    }
  };

  const renderPersonalAccidentTabs = () => {
    switch (activeTab) {
      case 0:
        return (
          <RiskometerMembers2
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 1:
        return (
          <RiskometerMembersAge
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 2:
        return (
          <RiskometerCoverage
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 3:
        return (
          <RiskometerAnnualIncome
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      default:
        return null;
    }
  };

  const renderCriticalIllnessTabs = () => {
    switch (activeTab) {
      case 0:
        return (
          <RiskometerMembers2
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 1:
        return (
          <RiskometerMembersAge
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 2:
        return (
          <RiskometerCoverage
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      case 3:
        return (
          <RiskometerAnnualIncome
            handleSave={handleSave}
            handleBack={handleBack}
            activeTab={activeTab}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledContainer>
      <TabsWrapper>
        {/* <StyledTabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="riskometer tabs"
          variant="scrollable"
          scrollButtons="auto"
        > */}
        {lobName === "Health" && (
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="riskometer tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <StyledTab label="Members" />
            <StyledTab label="Members Age" />
            <StyledTab label="Coverage" />
            <StyledTab label="Annual Income" />
            <StyledTab label="Medical History" />
            <StyledTab label="State/City" />
            <StyledTab label="Lifestyle" />
            <StyledTab label="Family History" />
          </StyledTabs>
        )}
        {lobName === "Term" && (
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="riskometer tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <StyledTab label="Members" />
            <StyledTab label="Members Age" />
            <StyledTab label="Lifestyle" />
            <StyledTab label="Annual Income" />
            <StyledTab label="Coverage" />
            <StyledTab label="Retirement Age" />
            <StyledTab label="Expenses" />
            <StyledTab label="Current Liabilities" />
          </StyledTabs>
        )}
        {/* </StyledTabs> */}
      </TabsWrapper>

      <StepContent>{renderStepComponent()}</StepContent>
    </StyledContainer>
  );
};

export default Riskometer;

const StyledContainer = styled(Container)`
  background-color: #fff;
  margin: 20px 0;
  padding: 30px 20px 30px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const TabsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-self: center;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  .MuiTabs-flexContainer {
    border-bottom: 2px solid #ddd;
  }
  @media (max-width: 600px) {
    .MuiTab-root {
      font-size: 12px;
    }
  }
`;
