import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useGetRiskoMeter } from "../customerDashboard/service";
import { useRiskometer } from "./service";
import {
  resetAllData,
  resetRiskoMeterMemberPayload,
  saveData,
  setCoverageAmountAction,
} from "./riskoMeterMember.slice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import husband from "../../utils/husband.png";
import wife from "../../utils/mom.png";
import child from "../../utils/boy.png";
import daughter from "../../utils/girl.png";

import Mother from "../../utils/housewife.png";
import Father from "../../utils/old-man.png";
import Grandfather from "../../utils/grandfather.png";
import MotherInLaw from "../../utils/mother-in-law.png";
import FatherInLaw from "../../utils/father-in-law.png";
import Grandmother from "../../utils/grand-mother.png";

const createValidationSchema = (
  sonCount,
  daughterCount,
  riskoMeterMemberPayload
) => {
  const sonValidations = {};
  const daughterValidations = {};

  const getAgeValue = (age) => age && age.value; // Extract the numeric value from the dropdown selection

  for (let i = 1; i <= sonCount; i++) {
    sonValidations[`sonAge${i}`] = Yup.object()
      .required(`Son ${i}'s age is required`)
      .test(
        `sonAge${i}-valid`,
        `Son ${i}'s age must be less than parent's age`,
        function (value) {
          const parentAge =
            getAgeValue(this.parent.selfAge) ||
            getAgeValue(this.parent.fatherAge) ||
            getAgeValue(this.parent.motherAge) ||
            getAgeValue(this.parent.husbandAge) ||
            getAgeValue(this.parent.wifeAge);
          return getAgeValue(value) < parentAge;
        }
      );
  }

  for (let i = 1; i <= daughterCount; i++) {
    daughterValidations[`daughterAge${i}`] = Yup.object()
      .required(`Daughter ${i}'s age is required`)
      .test(
        `daughterAge${i}-valid`,
        `Daughter ${i}'s age must be less than parent's age`,
        function (value) {
          const parentAge =
            getAgeValue(this.parent.selfAge) ||
            getAgeValue(this.parent.fatherAge) ||
            getAgeValue(this.parent.motherAge) ||
            getAgeValue(this.parent.husbandAge) ||
            getAgeValue(this.parent.wifeAge);
          return getAgeValue(value) < parentAge;
        }
      );
  }

  return Yup.object().shape({
    selfAge: Yup.object()
      .nullable()
      .test("selfAge", "Your age is required", function (value) {
        if (riskoMeterMemberPayload.selectedMembers.includes("Self")) {
          return value && value.value !== undefined;
        }
        return true;
      }),
    wifeAge: Yup.object()
      .nullable()
      .test("wifeAge", "Wife's age is required", function (value) {
        if (riskoMeterMemberPayload.selectedMembers.includes("Wife")) {
          return value && value.value !== undefined;
        }
        return true;
      }),
    husbandAge: Yup.object()
      .nullable()
      .test("husbandAge", "Husband's age is required", function (value) {
        if (riskoMeterMemberPayload.selectedMembers.includes("Husband")) {
          return value && value.value !== undefined;
        }
        return true;
      }),
    fatherAge: Yup.object()
      .nullable()
      .test("fatherAge", "Father's age is required", function (value) {
        if (riskoMeterMemberPayload.selectedMembers.includes("Father")) {
          return value && value.value !== undefined;
        }
        return true;
      }),
    motherAge: Yup.object()
      .nullable()
      .test("motherAge", "Mother's age is required", function (value) {
        if (riskoMeterMemberPayload.selectedMembers.includes("Mother")) {
          return value && value.value !== undefined;
        }
        return true;
      }),
    "father-in-lawAge": Yup.object()
      .nullable()
      .test(
        "father-in-lawAge",
        "Father-in-law's age is required",
        function (value) {
          if (
            riskoMeterMemberPayload.selectedMembers.includes("Father-in-law")
          ) {
            return value && value.value !== undefined;
          }
          return true;
        }
      ),
    grandmotherAge: Yup.object()
      .nullable()
      .test(
        "grandmotherAge",
        "Grandmother's age is required",
        function (value) {
          if (riskoMeterMemberPayload.selectedMembers.includes("Grandmother")) {
            return value && value.value !== undefined;
          }
          return true;
        }
      ),
    grandfatherAge: Yup.object()
      .nullable()
      .test(
        "grandfatherAge",
        "Grandfather's age is required",
        function (value) {
          if (riskoMeterMemberPayload.selectedMembers.includes("Grandfather")) {
            return value && value.value !== undefined;
          }
          return true;
        }
      ),
    motherInLawAge: Yup.object()
      .nullable()
      .test(
        "motherInLawAge",
        "Mother-in-law's age is required",
        function (value) {
          if (
            riskoMeterMemberPayload.selectedMembers.includes("Mother-in-law")
          ) {
            return value && value.value !== undefined;
          }
          return true;
        }
      ),
    ...sonValidations,
    ...daughterValidations,
  });
};

const RiskometerMembersAge = ({ handleSave, handleBack, activeTab }) => {
  const { riskoMeterMemberPayload, allData, selfUserData } = useSelector(
    (state) => state.riskoMeterMember
  );

  const dispatch = useDispatch();
  const sonCount = riskoMeterMemberPayload.sonCount || 0;
  const daughterCount = riskoMeterMemberPayload.daughterCount || 0;

  const validationSchema = createValidationSchema(
    sonCount,
    daughterCount,
    riskoMeterMemberPayload
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const values = getValues();

  const { mutateAsync: ageRiskometer, isLoading: customerAddLoading } =
    useRiskometer(activeTab);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");

  const [riskometerRange, setRiskometerRange] = useState(null);

  const onSubmit = async (data) => {
    let ages = [
      data?.selfAge?.value,
      data?.wifeAge?.value,
      data?.husbandAge?.value,
      data?.fatherAge?.value,
      data?.motherAge?.value,
      data?.["father-in-lawAge"]?.value,
      data?.grandmotherAge?.value,
      data?.grandfatherAge?.value,
      data?.motherInLawAge?.value,
      ...Array.from(
        { length: sonCount },
        (_, index) => data?.[`sonAge${index + 1}`]?.value
      ),
      ...Array.from(
        { length: daughterCount },
        (_, index) => data?.[`daughterAge${index + 1}`]?.value
      ),
    ];

    const filteredAges = ages?.filter((age) => !isNaN(age));

    const oldestAge = Math.max(...filteredAges, 0);

    const adultCount = riskoMeterMemberPayload.selectedMembers.filter((m) =>
      [
        "Self",
        "Wife",
        "Father",
        "Mother",
        "Grandfather",
        "Grandmother",
        "Father-in-law",
        "Mother-in-law",
      ].includes(m)
    ).length;

    const childrenCount = sonCount + daughterCount;
    const familyType = `${adultCount}A${childrenCount}C`;

    const payload = {
      questionId: 1,
      lobId: +lobId,
      request_data: {
        age: oldestAge.toString(),
        familyType: familyType,
      },
    };

    dispatch(setCoverageAmountAction(payload));
    dispatch(saveData({ MembersAge: { ...values } }));
    const res = await ageRiskometer(payload);
    await setRiskometerRange(res);
    handleSave();
  };
  const ageObj = Array.from({ length: 83 }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }));

  const getOrdinal = (index) => {
    const ordinals = ["First", "Second", "Third", "Fourth", "Fifth"];
    return ordinals[index] || `${index + 1}th`;
  };

  const {
    mutate: GetRiskometer,
    data: RiskometerData,
    isLoading,
  } = useGetRiskoMeter();

  useEffect(() => {
    if (selfUserData?.gender) {
      setValue("selfAge", {
        label: selfUserData?.age,
        value: selfUserData?.age,
      });
    }
  }, [selfUserData]);

  useEffect(() => {
    if (allData && allData.MembersAge) {
      const { MembersAge } = allData;

      Object.keys(MembersAge).forEach((key) => {
        setValue(key, {
          value: MembersAge[key].value,
          label: MembersAge[key].label,
        });
      });
    }
  }, [allData]);

  return (
    <div style={{ padding: "24px" }}>
      <BackButton
        onClick={() => {
          handleBack();
          dispatch(resetAllData());
        }}
      >
        <ArrowBackIcon />
      </BackButton>

      <Container>
        <Title>Select Age of Covered Members</Title>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {riskoMeterMemberPayload?.selectedMembers?.includes("Self") && (
            <MemberContainer>
              <Avatar
                src={
                  riskoMeterMemberPayload?.gender === "Male" ? husband : wife
                }
                alt="Avatar of a man"
              />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`selfAge`}
                  label="Your Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select age"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes("Wife") && (
            <MemberContainer>
              <Avatar src={wife} alt="Avatar of a woman" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`wifeAge`}
                  label="Wife's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}
          {riskoMeterMemberPayload.selectedMembers?.includes("Husband") && (
            <MemberContainer>
              <Avatar src={husband} alt="Avatar of a man" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`husbandAge`}
                  label="Husband's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes("Son") && (
            <>
              {Array.from({ length: sonCount }).map((_, index) => (
                <MemberContainer key={`son-${index}`}>
                  <Avatar src={child} alt={`Avatar of son ${index + 1}`} />
                  <div style={{ flex: 1 }}>
                    <Dropdown
                      id={`sonAge${index + 1}`}
                      label={`${getOrdinal(index)} Son's Age`}
                      control={control}
                      selectObj={ageObj}
                      isMulti={false}
                      maxMenuHeight={200}
                      placeholder="Select"
                      errors={errors}
                      watch={watch}
                      isClearable={true}
                    />
                  </div>
                </MemberContainer>
              ))}
            </>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes("Daughter") && (
            <>
              {Array.from({ length: daughterCount }).map((_, index) => (
                <MemberContainer key={`daughter-${index}`}>
                  <Avatar
                    src={daughter}
                    alt={`Avatar of daughter ${index + 1}`}
                  />
                  <div style={{ flex: 1 }}>
                    <Dropdown
                      id={`daughterAge${index + 1}`}
                      label={`${getOrdinal(index)} Daughter's Age`}
                      control={control}
                      selectObj={ageObj}
                      isMulti={false}
                      maxMenuHeight={200}
                      placeholder="Select"
                      errors={errors}
                      watch={watch}
                      isClearable={true}
                    />
                  </div>
                </MemberContainer>
              ))}
            </>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes("Father") && (
            <MemberContainer>
              <Avatar src={Father} alt="Avatar of a Father" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`fatherAge`}
                  label="Father's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes("Mother") && (
            <MemberContainer>
              <Avatar src={Mother} alt="Avatar of a Mother" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`motherAge`}
                  label="Mother's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes(
            "Father-in-law"
          ) && (
            <MemberContainer>
              <Avatar src={FatherInLaw} alt="Avatar of a FatherInLaw" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`father-in-lawAge`}
                  label="Father-in-law's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes("Grandmother") && (
            <MemberContainer>
              <Avatar src={Grandmother} alt="Avatar of a Grandmother" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`grandmotherAge`}
                  label="Grandmother's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes("Grandfather") && (
            <MemberContainer>
              <Avatar src={Grandfather} alt="Avatar of a Grandfather" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`grandfatherAge`}
                  label="Grandfather's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}

          {riskoMeterMemberPayload.selectedMembers?.includes(
            "Mother-in-law"
          ) && (
            <MemberContainer>
              <Avatar src={MotherInLaw} alt="Avatar of a woman" />
              <div style={{ flex: 1 }}>
                <Dropdown
                  id={`motherInLawAge`}
                  label="Mother-in-law's Age"
                  control={control}
                  selectObj={ageObj}
                  isMulti={false}
                  maxMenuHeight={200}
                  placeholder="Select"
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </div>
            </MemberContainer>
          )}
          <AddButton
            type="submit"
            onClick={handleSubmit((data) => {
              onSubmit(data);
              handleSave();
            })}
          >
            Continue
          </AddButton>
        </form>
      </Container>
    </div>
  );
};

export default RiskometerMembersAge;

const Container = styled.div`
  max-width: 650px;
  margin: 3rem auto;
  border-radius: 0.375rem;
  position: relative;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -3rem;
`;

const MemberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
`;

const Avatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin: 10px auto;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;
