import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

export const useGetProposalValuesForEndorsement = () => {
  const { mutate, isError, isLoading, data } = useMutation(
    ({ userId, lobId }) => {
      return httpClient(`/transaction_summary/list/${lobId}?id=${userId}`, {
        method: "POST",
      });
    }
  );
  return { mutate, isError, data, isLoading };
};
export const useGetEndorsementDiffCheck = () => {
  const { mutate, isError, isLoading, data } = useMutation(
    (payload) => {
      return httpClient(`endorsements_data/fetch_endorsement`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (res) => {
        if (!res?.data?.status) {
          Swal.fire(
            `Error !`,
            `Something went wrong while calculating difference`,
            "error"
          );
        }
      },
    }
  );
  return { mutate, isError, data, isLoading };
};
export const useGetEndorsementByPolicyNumber = (endorsementId) => {
  const navigate = useNavigate();
  const { data, isError, isLoading } = useQuery(
    ["endorsement-list-by-endorsementId", endorsementId],
    () => {
      return httpClient(`endorsements_data/list/${endorsementId}`);
    },
    {
      onSuccess: (response) => {
        if (isEmpty(response?.data)) {
          Swal.fire({
            title: "No Data Found",
            allowOutsideClick: false,
            allowEscapeKey: false,
            icon: "error",
          }).then((res) => {
            if (res.isConfirmed) {
              navigate(`/endorsement`);
            }
          });
        }
      },
    }
  );
  return { isError, data, isLoading };
};
export const useAddEndorsement = () => {
  const navigate = useNavigate();
  const { mutate, isError, isLoading, data } = useMutation(
    (payload) => {
      return httpClient(`endorsements_data/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (response) => {
        if (response?.data?.status) {
          Swal.fire({
            title: response?.data?.data,
            text: response?.data?.message,
            icon: response.data.errortype,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showLoaderOnConfirm: true,
          }).then((res) => {
            if (res.isConfirmed) {
              navigate("/endorsement");
            }
          });
        } else {
          Swal.fire("Error ", response?.message, response?.alertType);
        }
      },
    }
  );
  return { mutate, isError, data, isLoading };
};

export const useUpdateEndorsementStage = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, isLoading, data } = useMutation(
    ({ endorsementId, stage }) => {
      return httpClient(`endorsements_data/stage_update/${endorsementId}`, {
        method: "PUT",
        data: {
          stage,
          remark: "",
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res?.data?.status) {
          Swal.fire(res?.data?.message, "", res?.data?.errortype);
          queryClient.invalidateQueries(["endorsements_data/listing"]);
        }
      },
    }
  );
  return { mutate, isError, data, isLoading };
};

export const useAddEndorsementForPremiumDetails = () => {
  const queryClient = useQueryClient();
  const { mutate, isError, isLoading, data } = useMutation(
    (payload) => {
      return httpClient(`endorsements_data/add_premium_data`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (response) => {
        if (response?.data?.status) {
          Swal.fire({
            title: response?.data?.data,
            text: response?.data?.message,
            icon: response.data.errortype,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showLoaderOnConfirm: true,
          }).then((res) => {
            if (res.isConfirmed) {
              queryClient.invalidateQueries(["endorsements_data/listing"]);
            }
          });
        } else {
          Swal.fire("Error ", response?.message, response?.alertType);
        }
      },
    }
  );
  return { mutate, isError, data, isLoading };
};

export const useAddonSpecificData = (payload) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["addon_master/listPaging", payload],
    queryFn: () =>
      httpClient(`addon_master/listPaging`, {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortOrder: "",
          searchValue: {
            lobId: payload?.lobId ?? 0,
            addonName: "",
            companyId: payload?.companyId ?? 0,
            productId: payload?.productId ?? 0,
          },
        },
      }),
    enabled: !!payload,
  });
  return { data, isLoading, isError };
};

export const useGetPolicyDetailsForEndorsement = () => {
  const navigate = useNavigate();
  const { mutate, data } = useMutation(
    (payload) => {
      return httpClient(`transaction_field_master/listPaging`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!isEmpty(data?.data?.data)) {
          const lobId = data?.data?.data?.[0]?.lob_id;
          const transactionId = data?.data?.data?.[0]?.transaction_id;
          const mongoId = data?.data?.data?.[0]?.transaction_temp_id;
          if (!isNaN(lobId) && !isNaN(transactionId)) {
            navigate(
              `/endorsement-add?transactionId=${transactionId}&lobId=${lobId}&mongoId=${mongoId}`
            );
          } else {
            Swal.fire({
              text: "Policy number does not exist.",
              icon: "warning",
              confirmButtonText: "OK",
              confirmButtonColor: "#3085d6",
            });
          }
        } else {
          Swal.fire({
            text: "Policy number does not exist.",
            icon: "warning",
            confirmButtonText: "OK",
            confirmButtonColor: "#3085d6",
          });
        }
      },
    }
  );

  return { data, mutate };
};
