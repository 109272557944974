// FORMS
export const PREVIOUS_POLICY_DETAILS = "Previous Policy Detail";
export const INSURANCE_COMPANY_DETAILS = "Insurance Company Detail";
export const SOURCING_DETAILS = "Sourcing Details";
export const POLICY_DETAILS = "Policy Details";
export const PROPOSER_DETAILS = "Proposer Details";
export const INSURED_MEMBER_DETAILS = "Insured Member Details";
export const NOMINEE_DETAILS = "Nominee Details";
export const PREMIUM_DETAILS = "Premium Details";
export const PAYMENT_DETAILS = "Payment Details";
export const VEHICLE_DETAILS = "Vehicle Details";
export const CARGO_DETAILS = "Cargo Details";
export const PROPERTY_DETAILS = "Property Details";
export const EMPLOYEE_DETAILS = "Company Details";
export const CONTRACTOR_DETAILS = "Contractor Detail"; // conditional form rendering not required, changing the value for now so that its logic does not trigger, will remove the respective logic later
export const PI_DETAILS = "PI Details";
export const INSURED_MEMBER_TRAVELLING_DETAILS =
    "Insured Member Travelling Details";
export const LIFE_ASSURED_DETAILS = "Life Assured Details";
export const PET_DETAILS = "Pet Details";
export const OFFICE_DETAILS = "Office Details";

// LOB SLUGS
export const HEALTH_LOB = "health";
export const CAR_LOB = "car";
export const BIKE_LOB = "bike";
export const TERM_LOB = "term";
export const GCV_LOB = "gcv";
export const PCV_LOB = "pcv";
export const PROPERTY_LOB = "property_insurance";
export const MARINE_LOB = "marine";
export const PROFESSIONAL_INDEMNITY_LOB = "professional_indemnity";
export const WORKMENS_COMPENSATION_LOB = "workmen_comp";
export const OFFICE_PACKAGE_LOB = "office_package";
export const TRAVEL_LOB = "travel";
export const JEWELLERS_BLOCK_LOB = "jewellers_block";
export const GROUP_HEALTH_LOB = "group_health";
export const INVESTMENT_LOB = "investment";
export const SHOP_LOB = "shop";
export const PET_LOB = "pet";

// PLAN TYPES
export const PLAN_TYPE_LAGHU_UDHYAM = "Bharat Laghu Udyam Suraksha";
export const PLAN_TYPE_SOOKSHMA_UDHYAM = "Bharat Sookshma Udyam Suraksha";
export const PLAN_TYPE_STD_FIRE_SPECIAL_PERILS =
    "STANDARD FIRE AND SPECIAL PERILS POLICY";
export const PLAN_TYPE_GRIHA_RAKSHA = "Bharat Griha Raksha Policy";

// BUSINESS TYPES
export const NEW = "New";
export const RENEWAL = "Renewal";
export const RENEWAL_BREAK_IN = "Renewal Break-In";
export const RENEWAL_ROLL_OVER = "Renewal Roll-Over";
export const ROLL_OVER = "Roll-Over";

// POLICY TYPES
export const COMPREHENSIVE = "Comprehensive";

export const SAOD = "SAOD" || "OD Only";

export const SATP = "SATP" || "TP Only";

export const COMPREHENSIVE_BUNDLED_1_PLUS_3 =
    "COMPREHENSIVE BUNDLED ( 1 Year OD + 3 Year TP )";

export const COMPREHENSIVE_BUNDLED_1_PLUS_5 =
    "COMPREHENSIVE BUNDLED ( 1Year OD +5 Year TP )";

export const COMPREHENSIVE_BUNDLED_5_PLUS_5 =
    "COMPREHENSIVE BUNDLED ( 5Year OD +5 Year TP )";

export const COMPREHENSIVE_BUNDLED_3_PLUS_3 =
    "COMPREHENSIVE BUNDLED ( 3 Year OD +3 Year TP )";

export const COMPREHENSIVE_BUNDLED_1_PLUS_1 =
    "Comprehensive Bundled (1 Year OD + 1 Year TP)";

export const COMPREHENSIVE_1_PLUS_1 = "COMPREHENSIVE ( 1 Year OD + 1 Year TP )";
export const COMPREHENSIVE_2_PLUS_2 = "COMPREHENSIVE ( 2 Year OD + 2 Year TP )";
export const COMPREHENSIVE_3_PLUS_3 = "COMPREHENSIVE ( 3 Year OD + 3 Year TP )";

export const TP_LONG_TERM_3_YEARS = "TP Long Term ( 3 Years )";
export const TP_LONG_TERM_5_YEARS = "TP Long Term ( 5 Years )";

export const SHORT_TERM_3_MONTHS_OD_TP = "Short term 3 months (OD + TP)";
export const SHORT_TERM_6_MONTHS_OD_TP = "Short term 6 months (OD + TP)";

export const TP_1_YEAR = "TP ( 1 Year )";

// MULTI-FORM SECTIONS
export const multiSectionMapping = {
    members: "members",
    payments: "payment",
    nominee: "nominee",
    sourcing: "sourcing",
};

// VALIDATION REGEX
export const aadharRegex = /^\d{4}\d{4}\d{4}$/;
export const panRegex =
    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
export const voterIdRegex = /^[A-Z]{3}[0-9]{7}$/;
export const passportRegex = /^[A-Z][1-9][0-9][0-9]{4}[1-9]$/;
export const drivingLicenseRegex =
    /^[A-Z]{2}([0-9]{2}(19[0-9]{2}|20[0-9]{2})[0-9]{7}|[- ][0-9]{2}(19[0-9]{2}|20[0-9]{2})[0-9]{7}|[0-9]{2}[- ](19[0-9]{2}|20[0-9]{2})[0-9]{7})$/;

// CONSTANTS
export const unionTerritories = [
    "Andaman Nicobar",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "JAMMU & KASHMIR",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
];

export const companyFields = [
    "comapnyName",
    "natureofBusiness",
    "companygst",
    "cinNumber",
    "panNumber",
    "companyAddress",
    "companystartdate",
];

export const individualFields = [
    "title",
    "firstName",
    "middleName",
    "lastName",
    "gender",
    "dob",
    "age",
    "maritialStatus",
    "nationality",
    "ckycId",
    "anniversary",
    "education",
    "occupation",
    "panCardno",
    "drivingLicence",
    "addressLineone",
    "addressLinetwo",
    // "addresspdone",
    // "addresspdtwo",
];

export const floaterCoverFields = [
    "floaterCovershookshma",
    "addressLinetwooinsured",
    "pincodetwooinsured",
    "statetwoinsured",
    "citytwoinsured",
];

export const grihaRakshaFields = [
    "ownertenantGriha",
    "standaloneGriha",
    "multistoreyGriha",
    "carpetGriha",
    "rateofcostGriha",
    "suminsuredcontentGriha",
    "suminsuredhouseGriha",
];

export const commonForGrihaRakshaFields = [
    "locationofRisk",
    "addressLineoneinsured",
    "addressLinetwoinsured",
    "pincodeinsured",
    "stateinsured",
    "cityinsured",
    "installationofFire",
    "whetherSecurity",
];

export const piIndividualFields = [
    "titlepi",
    "insuredName",
    "emailIdpi",
    // "namepi",
    // "mobileNopi",
    // "profession",
    // "specilization",
    // "titlepi",
    // "dobpi",
    // "addressLine",
    // "emailIdpi",
    // "city",
    // "state",
    // "pinCode",
    // "insuredName",
    // "territory",
    // "jurisdiction",
    // "numberOfprac",
    // "gstDetails",
    // "sInsured",
    // "anyClaim",
    // "limitOfIndemnity",
];

export const piCompanyFields = [
    "companyName",
    "companygst",
    "companypan",
    "addressper",
    "addressLine",
    "companyAddressLine",
    "pinCode",
    "state",
    "city",
    "territoryPI",
    // "companyName",
    // "companyMobileNo",
    // "companyEmailID",
    // "companyAddressLine",
    // "city",
    // "state",
    // "pinCode",
    // "territory",
    // "jurisdiction",
    // "registrationNumber",
    // "yearOfregistration",
    // "companyRegistration",
    // "gstDetails",
    // "sInsured",
    // "limitOfIndemnity",
    // "totalNumberofemployee",
    // "companyTurnover",
    // "industryCategory",
];

export const hidePaymentFieldsForCashPayment = [
    "micrCode",
    "chequeNo",
    "transactionId Id",
    "ifscCode",
    "accNumber",
    "accountType",
    "bankBranchname",
    "banName",
    "paymentFile",
];
export const hidePaymentFieldsForOnlinePayment = ["micrCode", "chequeNo"];

export const readOnlyFieldsForSelfInsuredMembers = [
    "insuredmemberName",
    "insuredsumInsured",
    "genderIMD",
    "dobIMD",
    "insuredContactnumber",
    "insuredeiaRepository",
    "insuredeiaNumber",
];

export const readOnlyFieldsForSelfNominee = [
    "titleNd",
    "nomineeFullname",
    "nomineeGender",
    "nomineeDob",
];

export const readOnlyFieldsForSelfTravelInsured = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "gender",
];

export const readOnlyFieldsForSelfLifeAssured = [
    "lifeassuredFname",
    "lifeassuredMname",
    "lifeassuredLname",
    "insuredContactnumber",
    "assuredmobileNotwo",
    "assuredEmail",
    "assuredEmailtwo",
    "assuredAnnualincome",
    "assuredAddressone",
    "assuredAddresstwo",
    "assuredLandmark",
    "insuredeiaNumber",
    "otherOccupation",
    "remarkihd",
    "assuredeiaRepository",
    "dobIMD",
    "lifeassuredTitle",
    "genderIMD",
    "addresschecktwo",
    "assuredaddressProof",
    "addressvalueN",
    "addressproofN",
    "assuredidProof",
    "identityvalueN",
    "uploadIdentitydoc",
    "assuredincomeProof",
    "uploadIncomeN",
    "assuredmaritalStatus",
    "assuredAnnivrsary",
    "nationalityAssured",
    "address",
    "assuredPincode",
    "addressAssuredone",
    "addressAssuredtwo",
    "landmarkAssured",
    "pinCode",
    "city",
    "cityInvestment",
    "educationTerm",
    "occupationTerm",
    "pincodeInvestment",
    "educationInvestment",
    "occupationInvestment",
];

export const readOnlyFieldsForLifeAssuredOtherThanSelf = [
    "relWithprop",
    "lifeassuredFname",
    "lifeassuredMname",
    "lifeassuredLname",
    "genderIMD",
    "dobIMD",
    "identityvalueN",
];

export const readOnlyFieldsForProposer = [
    "customerRefId",
    "gender",
    "dob",
    "firstName",
    "middleName",
    "lastName",
    "nationality",
    "idProof",
    "identityvalue",
];

export const readOnlyFieldsForPolicyDetails = [
    "proposalNum",
    "policyNo",
    "icName",
    "productName",
    "policyTerm",
    "productName",
    "paymentTerm",
    "policystDate",
    "policyExpirydate",
    "bookDate",
    "productName",
];
