import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useGetMasterTableDataFormWise } from "./getMasterTableData";
import useGetTransactionFieldData from "./getTransactionFieldData";
import {
  resetAllData,
  setPolicyViewCards,
  setProposalEditValuesAction,
  setProposalErrorAction,
  setProposalPartialUpdateSuccess,
  setProposalSuccessAction,
} from "./proposal.slice";
import {
  useCheckIfPolicyIsPresent,
  useCreateFinalSubmit,
  useCreateProposalData,
  useCreateProposalMongoId,
  useGetBrokerageCalculate,
  useGetFuelData,
  useGetManufacturerData,
  useGetModelData,
  useGetProposalDataById,
  useGetRestructuredBulkUploadProposalData,
  useGetRewardCalculate,
  useGetTransactionType,
  useGetVersionData,
  useGetVersionDetails,
  useUpdateExistingProposalData,
  useUpdateFinalSubmit,
} from "./proposalSummaryService";
import {
  CONTRACTOR_DETAILS,
  EMPLOYEE_DETAILS,
  POLICY_DETAILS,
  PREVIOUS_POLICY_DETAILS,
  BIKE_LOB,
  CAR_LOB,
  GCV_LOB,
  HEALTH_LOB,
  PCV_LOB,
  PROPERTY_LOB,
  TRAVEL_LOB,
  WORKMENS_COMPENSATION_LOB,
  PET_LOB,
  OFFICE_PACKAGE_LOB,
  SOURCING_DETAILS,
  PAYMENT_DETAILS,
  PREMIUM_DETAILS,
} from "./proposalCreateConstants";
import { useGetLobById } from "./getLogMaster";
import { useGetLevelConfiguratorListing } from "./leadService";

function useProposalSummary() {
  const [userId, setUserId] = useState();
  const location = useLocation();

  const pathName = location.pathname;

  const isProposalSummaryRoute = pathName === "/summary";
  const isEndorsementRoute = pathName.toLowerCase().includes("endorsement");

  const dispatch = useDispatch();

  const { proposalError, proposalSuccess, proposalPartialUpdateSuccess } =
    useSelector(state => state.proposalConfig);
  const navigate = useNavigate();

  const [showEditButton, setShowEditButton] = useState(true);
  const [editCard, setEditCard] = useState({});
  const [activeStep, setActiveStep] = useState(1); // active step decides which is the current active step(shrink/ full mode)
  const [completed, setCompleted] = useState({}); // stores which all steps are completed(along with validated fields)
  const [current, setCurrent] = useState({ 0: true }); //current step in focus
  const [isFirstView, setIsFirstView] = useState({ 0: true }); //is the current form opened for first time?
  const [mergedSchema, setMergedSchema] = useState([]); // dynamic yup schema
  const [currentFormMasterSlugs, setCurrentFormMasterSlugs] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0); // sets index of current card
  const [submittedAt, setSubmittedAt] = useState(""); // sets new value at the time of submit
  const [stopNav, setStopNav] = useState(false);
  const [members, setMembers] = useState({
    members: 1,
    payment: 1,
    employee: 1,
    nominee: 1,
    sourcing: 1,
    addOnPremiums: 1,
    travelMember: 1,
    petMember: 1,
  });

  const cardsRef = useRef(null);
  const buttonRef = useRef(null);

  const handleEditButton = () => {
    setShowEditButton(false);
  };

  const queryParams = new URLSearchParams(location.search);

  const lobId = queryParams.get("lob");
  const prefillDataMongoID = queryParams.get("mongoId");
  const mongoId = queryParams.get("mongoId");

  const { data: lobData } = useGetLobById(lobId);
  console.log('lobData', lobData)
  const currentLobSlug = lobData?.data?.data?.lobslug;
  const isHealthLob = currentLobSlug === HEALTH_LOB;
  const isCarLob = currentLobSlug === CAR_LOB;
  const isBikeLob = currentLobSlug === BIKE_LOB;
  const isPropertyLob = currentLobSlug === PROPERTY_LOB;
  const isTravelLob = currentLobSlug === TRAVEL_LOB;
  const isPcvLob = currentLobSlug === PCV_LOB;
  const isGcvLob = currentLobSlug === GCV_LOB;
  const isPetLob = currentLobSlug === PET_LOB;
  const isWorkmensCompensationLob =
    currentLobSlug === WORKMENS_COMPENSATION_LOB;
  const isOfficePackageLob = currentLobSlug === OFFICE_PACKAGE_LOB;

  const { data, isLoading, isError } = useGetTransactionFieldData(lobId);

  const { mutate } = useCreateProposalData();

  const {
    mutate: generateMongoId,
    isLoading: isCreateProposalDataLoading,
    isError: err,
  } = useCreateProposalMongoId(setSubmittedAt);

  const {
    mutate: updateExistingData,
    isLoading: isUpdateExistingProposalDataLoading,
    isError: isErr,
  } = useUpdateExistingProposalData(setSubmittedAt, stopNav);

  const { mutate: createFinalSubmit, isLoading: isCreateFinalSubmitLoading } =
    useCreateFinalSubmit();

  const {
    mutate: updateFinalSubmit,
    isLoading: isUpdateFinalSubmitLoading,
    data: proposalUpdate,
  } = useUpdateFinalSubmit(stopNav);
  // const isUpdate = location.pathname === "/edit";

  const { data: manufacturerData } = useGetManufacturerData(lobId);
  const { data: PolicyType } = useGetTransactionType(lobId);
  const { data: modelData, mutate: getModalData } = useGetModelData();
  const { data: versionData, mutate: getVersionData } = useGetVersionData();
  const { data: fuelData, mutate: getFuelData } = useGetFuelData();
  const { data: mmvData, mutate: getMmvData } = useGetVersionDetails();
  const { data: policyPresent, mutate: checkPolicyPresent } =
    useCheckIfPolicyIsPresent();
  const { data: prevPolicyPresent, mutate: checkPrevPolicyPresent } =
    useCheckIfPolicyIsPresent();
  const { data: proposalPresent, mutate: checkProposalPresent } =
    useCheckIfPolicyIsPresent();

  const {
    data: proposalData,
    isError: errorTransacMongo,
    isLoading: isProposalDataLoading,
  } = useGetProposalDataById(
    pathName.includes("edit") ? prefillDataMongoID : mongoId,
  );

  const { levelConfigListing = [] } = useGetLevelConfiguratorListing();

  const sequenceData = data?.data?.category_sequence?.map(
    value => value && Object.values(value)[0],
  );

  const matchedObject =
    (data?.success &&
      _.zipObject(
        sequenceData,
        sequenceData.map(key => data?.data?.data[key]),
      )) ||
    {};
  const levelConfigListingOfLead = useMemo(() => {
    return levelConfigListing?.sort((a, b) => a?.level - b?.level);
    // filter(
    //   item => item?.isEditableOnProposal === "Y",
    // )
  }, [levelConfigListing?.length]);
  const summaryData = proposalData?.data?.data?.[0]?.data || {};

  const extraCardTitles =
    (data?.success && Object.keys(matchedObject))?.filter(item => {
      if ((item === SOURCING_DETAILS || item === PAYMENT_DETAILS || item === PREMIUM_DETAILS) && isEndorsementRoute) {
        return false;
      }
      return true;
    }) || [];
  const prevPolicyCardIndex = extraCardTitles?.findIndex(
    value => value === PREVIOUS_POLICY_DETAILS,
  );

  const contractorDetailsExists = matchedObject[CONTRACTOR_DETAILS];

  const contractorDetailsCardIndex = extraCardTitles?.findIndex(
    value => value === CONTRACTOR_DETAILS,
  );

  const isPrevPolicyFromApi =
    ((isCarLob || isBikeLob) &&
      summaryData[POLICY_DETAILS] &&
      [
        "Renewal",
        "Roll-Over",
        "Renewal Roll-Over",
        "Renewal Break-In",
      ].includes(summaryData[POLICY_DETAILS]?.policyType)) ||
    (isHealthLob &&
      summaryData[POLICY_DETAILS] &&
      ["Renewal", "Portability"].includes(
        summaryData[POLICY_DETAILS]?.policyType,
      )) ||
    ((isGcvLob || isPcvLob || isPetLob || isTravelLob) &&
      summaryData[POLICY_DETAILS] &&
      ["Renewal"].includes(summaryData[POLICY_DETAILS]?.policyType)) ||
    ((isPropertyLob || isOfficePackageLob || isWorkmensCompensationLob) &&
      summaryData[POLICY_DETAILS] &&
      ["Renewal"].includes(summaryData[POLICY_DETAILS]?.businessType)) ||
    (summaryData[POLICY_DETAILS] &&
      summaryData[POLICY_DETAILS]?.pastInsurance === "Yes");

  const insureYourLiabilityToContractor =
    summaryData[EMPLOYEE_DETAILS] &&
    summaryData[EMPLOYEE_DETAILS]?.members?.[0]?.compensationAct;

  const cardTitlesPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? extraCardTitles?.filter(
        (_, index) => index !== contractorDetailsCardIndex,
      )
      : !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
        ? extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex)
        : isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
          ? extraCardTitles
          : extraCardTitles?.filter(
            (_, index) =>
              index !== prevPolicyCardIndex &&
              index !== contractorDetailsCardIndex,
          )
    : isPrevPolicyFromApi
      ? extraCardTitles
      : extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex);

  const isBulkUploadData =
    !!proposalData?.data?.data?.bulkUpload ||
    !!proposalData?.data?.data?.[0]?.bulkUpload ||
    !!proposalData?.data?.data?.[0]?.data?.bulkdata;
  const { mutate: restructureBulkUploadData } =
    useGetRestructuredBulkUploadProposalData(isBulkUploadData);

  useEffect(() => {
    const payload = {
      lobid: lobId,
      transactionTemoId: prefillDataMongoID,
    };
    if (isBulkUploadData) {
      restructureBulkUploadData(payload);
    }
  }, [isBulkUploadData]);

  const {
    data: brokerageData,
    mutate: brokeragePost,
    isLoading: brokerageLoading,
  } = useGetBrokerageCalculate();

  const {
    data: rewardData,
    mutate: rewardPost,
    isLoading: rewardLoading,
  } = useGetRewardCalculate();

  function extractMasterSlugs(obj) {
    return Object.keys(obj).reduce((result, key) => {
      result[key] = obj[key]?.reduce((slugs, item) => {
        if (item?.master_slug !== "string" || item?.master_slug !== "NA") {
          slugs.push(item?.master_slug);
        }
        return slugs;
      }, []);
      return result;
    }, {});
  }
  const formWiseAllMasterSlugs = extractMasterSlugs(matchedObject || {});
  const activeFormTitle = Object.keys(matchedObject || {})[activeStep - 1];

  useEffect(() => {
    const filteredMasterSlugs = Object.entries(formWiseAllMasterSlugs)
      .filter(
        ([key]) =>
          key?.toLowerCase().split(" ").join("") ===
          activeFormTitle?.toLowerCase().split(" ").join(""),
      )
      .map(([, value]) => value);
    if (filteredMasterSlugs?.length > 0) {
      setCurrentFormMasterSlugs(filteredMasterSlugs[0]);
    } else {
      setCurrentFormMasterSlugs([]);
    }
  }, [activeFormTitle, activeStep]);

  useMemo(() => {
    if (extraCardTitles) {
      const titleObject = {};

      extraCardTitles.forEach(title => {
        titleObject[title] = false;
      });

      setEditCard(titleObject);
    }
  }, [extraCardTitles?.length]);

  const masterTableSlugs =
    data?.success &&
    Object.values(data?.data?.data)
      .flat()
      .filter(value => value.is_master_api_available === "Y")
      .map(value => ({
        masterSlug: value.master_slug,
        fieldSlug: value.field_slug,
        lobId,
      }));

  const { data: masterTableData } = useGetMasterTableDataFormWise({
    tableName: masterTableSlugs || [],
    currentFormMasterSlugs: currentFormMasterSlugs,
    activeFormTitle: activeFormTitle,
  });

  const extraLabels = extraCardTitles?.map(field => {
    return data?.data?.data[field];
  });

  const extraCategory = extraCardTitles?.map(field => {
    return data?.data?.data[field].map(value => {
      return {
        field_type: value.field_type,
        length: value.field_length,
        mandatory: value.is_mandatory,
        validation_group: value.validation_group,
        is_editable: value?.is_editable,
      };
    });
  });

  const extraSlugs = extraCardTitles?.map(field => {
    return data?.data?.data[field].map(value => {
      return value.field_slug;
    });
  });

  const extraProposalCreate = extraCardTitles?.map((_, mainTitleIndex) => {
    //generates dynamic fields for mapping
    return extraLabels?.length > 0
      ? extraLabels[mainTitleIndex]?.map((item, index) => {
        const modifiedString =
          item?.field_name !== null &&
          item?.field_name
            ?.replace(/_/g, " ")
            ?.replace(/\b\w/g, char => char.toUpperCase());
        return {
          label: modifiedString,
          key: item?.field_slug,
          field: extraCategory[mainTitleIndex]?.[index]?.field_type,
          length: extraCategory[mainTitleIndex]?.[index]?.length,
          mandatory: extraCategory[mainTitleIndex]?.[index]?.mandatory,
          validation_group:
            extraCategory[mainTitleIndex]?.[index]?.validation_group,
          is_editable: extraCategory[mainTitleIndex]?.[index]?.is_editable,
          description: item?.field_description,
          is_premium_bearing: item?.is_premium_bearing,
          is_endorsable: item?.is_endorsable,
          category_name: item?.category_name,
          is_payment_key: item?.is_payment_key,
          is_member_key: item?.is_member_key,
          is_nominee_key: item?.is_nominee_key,
          is_add_on_premium_key: item?.is_add_on_premium_key,
        };
      })
      : [];
  });

  const isPolicyPresent = policyPresent?.data?.status;
  const isPrevPolicyPresent = prevPolicyPresent?.data?.status;
  const isProposalPresent = proposalPresent?.data?.status;

  const mandatoryFields = Array.isArray(extraProposalCreate)
    ? extraProposalCreate.map(value => {
      return value.filter(fields => fields.mandatory === "Y");
    })
    : [];

  const optionalFields = Array.isArray(extraProposalCreate)
    ? extraProposalCreate.map(value => {
      return value.filter(fields => fields.mandatory !== "Y");
    })
    : [];

  const dropdownFields = extraProposalCreate
    ?.flat()
    ?.map(value => {
      if (value.field === "DROPDOWN") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  const multiSelectFields = extraProposalCreate
    ?.flat()
    ?.map(value => {
      if (value.field === "MULTISELECT") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  const dateFields = extraProposalCreate
    ?.flat()
    ?.map(value => {
      if (value.field === "DATE") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  const fileFields = extraProposalCreate
    ?.flat()
    ?.map(value => {
      if (value.field === "FILE") {
        return value?.key;
      }
      return null;
    })
    ?.filter(Boolean);

  useMemo(() => {
    if (proposalData) {
      dispatch(setProposalEditValuesAction(proposalData?.data?.data?.data));
    }
  }, [proposalData]);

  if (proposalError) {
    const listItems = Array.isArray(proposalError)
      ? proposalError
        ?.map(item => `<li style="margin-top: 10px;">${item}</li>`)
        ?.join("")
      : proposalError;
    Swal.fire({
      html: `<ul style="max-height: 100px;">${listItems}</ul>`,
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    }).then(result => {
      if (result.isConfirmed) {
        const newEditCardValues = {};
        for (let key in editCard) {
          newEditCardValues[key] = false;
        }
        setEditCard(newEditCardValues);
        dispatch(setProposalErrorAction(null));
      }
    });
  }
  useEffect(() => {
    if (errorTransacMongo) {
      Swal.fire({
        text: "Failed to load data",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [errorTransacMongo]);

  if (err) {
    Swal.fire({
      text: "Failed to load data",
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    });
  }

  if (isErr) {
    Swal.fire({
      text: "Failed to load data",
      icon: "warning",
      confirmButtonText: "OK",
      confirmButtonColor: "#DC004E",
      reverseButtons: true,
      focusConfirm: false,
      allowOutsideClick: false,
      focusCancel: false,
      scrollbarPadding: false,
    });
  }

  useEffect(() => {
    if (proposalSuccess) {
      Swal.fire({
        text: proposalSuccess,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then(result => {
        if (result.isConfirmed) {
          const newEditCardValues = {};
          for (let key in editCard) {
            newEditCardValues[key] = false;
          }
          setEditCard(newEditCardValues);
          dispatch(setProposalSuccessAction(null));
        }
      });
    }
  }, [proposalSuccess]);

  useEffect(() => {
    if (proposalUpdate?.data?.status) {
      const newEditCardValues = {};
      for (let key in editCard) {
        newEditCardValues[key] = false;
      }
      setEditCard(newEditCardValues);
    }
  }, [proposalUpdate]);

  const lastFormSubmitted =
    !_.isEmpty(
      summaryData[
      cardTitlesPrevPolicyToggle[cardTitlesPrevPolicyToggle?.length - 1]
      ],
    ) ||
    summaryData?.hasOwnProperty(
      cardTitlesPrevPolicyToggle[cardTitlesPrevPolicyToggle?.length - 1],
    );

  useEffect(() => {
    if (proposalPartialUpdateSuccess) {
      //sets which steps are completed
      const newCompleted = { ...completed };
      newCompleted[activeStep - 1] = true;
      setCompleted(newCompleted);

      //sets the current step
      const newCurrent = current;
      newCurrent[currentCardIndex] = true;
      setCurrent(newCurrent);

      //sets the first view of respective card
      if (allStepsCompleted()) {
        setIsFirstView({});
      } else {
        setIsFirstView({ [currentCardIndex]: true });
      }

      // if (allStepsCompleted()) {
      //   setActiveStep(extraCardTitles?.length);
      // } else {
      //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // }

      if (allStepsCompleted() || lastFormSubmitted) {
        // scrollToId(extraCardTitles?.length, true);
      } else {
        scrollToId(currentCardIndex + 1);
      }

      dispatch(setProposalPartialUpdateSuccess(false));
    }
  }, [proposalPartialUpdateSuccess]);

  // useEffect(() => {
  //   if (rewardData?.data?.status) {
  //     setTimeout(() => {
  //       Swal.fire({
  //         text: rewardData?.data?.message,
  //         icon: "success",
  //       });
  //     }, 2000);
  //   }
  // }, [rewardData?.data?.status]);

  function scrollToId(itemId, scrollToBottom) {
    if (scrollToBottom && buttonRef.current) {
      buttonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      return;
    }
    if (itemId <= extraCardTitles?.length) {
      const map = getMap();
      const node = map.get(itemId);
      const rect = node?.getBoundingClientRect();
      const offset = rect?.top + window.scrollY - 60;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }

  function getMap() {
    if (!cardsRef.current) {
      cardsRef.current = new Map();
    }
    return cardsRef.current;
  }

  function getOptionKeyByValue(value, fieldSlug) {
    const option = masterTableData
      ?.find(value => {
        return value?.data?.data?.[0]?.fieldSlug === fieldSlug;
      })
      ?.data?.data?.[0]?.data?.find(option => option.optionValue === value);
    return option ? option.optionKey : null;
  }

  function getPolicyKeyByValue(type) {
    const transaction = PolicyType?.data?.data?.find(
      transaction => transaction.type === type,
    );
    return transaction ? transaction.transactionTypeId : null;
  }

  function allStepsCompleted() {
    if (contractorDetailsExists) {
      if (isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes") {
        return completed?.[extraCardTitles?.length];
      } else if (
        (isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes") ||
        (!isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes")
      ) {
        return completed?.[extraCardTitles?.length - 1];
      } else {
        return completed?.[extraCardTitles?.length - 2];
      }
    } else {
      if (isPrevPolicyFromApi) {
        return completed?.[extraCardTitles?.length];
      } else {
        return completed?.[extraCardTitles?.length - 1];
      }
    }
  }

  useEffect(() => {
    const numberOfFormsSubmitted = +Object.keys(summaryData)?.length + 1 || 1;
    const currentObject = {};
    for (let i = 0; i < numberOfFormsSubmitted; i++) {
      currentObject[i] = true;
    }

    const showSummaryObject = {};
    for (let i = 1; i < numberOfFormsSubmitted; i++) {
      if (!_.isEmpty(summaryData[cardTitlesPrevPolicyToggle[+i - 1]])) {
        showSummaryObject[i] = true;
      }
    }

    if (contractorDetailsExists) {
      if (
        isPrevPolicyFromApi &&
        _.isEmpty(summaryData[PREVIOUS_POLICY_DETAILS]) &&
        insureYourLiabilityToContractor !== "Yes"
      ) {
        setActiveStep(prevPolicyCardIndex + 1);
      } else if (
        insureYourLiabilityToContractor === "Yes" &&
        _.isEmpty(summaryData[CONTRACTOR_DETAILS]) &&
        !isPrevPolicyFromApi
      ) {
        setActiveStep(contractorDetailsCardIndex);
      }
      // else if (
      //   isPrevPolicyFromApi  &&
      //   _.isEmpty(summaryData[PREVIOUS_POLICY_DETAILS]) &&
      //   insureYourLiabilityToContractor === "Yes" &&
      //   _.isEmpty(summaryData[CONTRACTOR_DETAILS])
      // ) {
      //   setActiveStep(prevPolicyCardIndex + 2);
      // }
      else {
        setActiveStep(numberOfFormsSubmitted);
      }
    } else {
      if (
        isPrevPolicyFromApi &&
        _.isEmpty(summaryData[PREVIOUS_POLICY_DETAILS])
      ) {
        setActiveStep(prevPolicyCardIndex + 1);
      } else {
        setActiveStep(numberOfFormsSubmitted);
      }
    }

    if (summaryData) {
      setCurrent(currentObject);
      dispatch(setPolicyViewCards(showSummaryObject));
      setCompleted(showSummaryObject);
      setCurrentCardIndex(numberOfFormsSubmitted - 1);
    }
  }, [JSON.stringify(summaryData), submittedAt]);

  useEffect(() => {
    return () => {
      dispatch(resetAllData());
    };
  }, []);

  return {
    isLoading,
    isCreateProposalDataLoading,
    isUpdateExistingProposalDataLoading,
    isCreateFinalSubmitLoading,
    isError,
    mutate,
    lobId,
    masterTableData,
    prefillDataMongoID,
    proposalData,
    isProposalDataLoading,
    proposalSuccess,
    generateMongoId,
    updateExistingData,
    createFinalSubmit,
    updateFinalSubmit,
    showEditButton,
    setShowEditButton,
    handleEditButton,
    manufacturerData,
    modelData,
    versionData,
    fuelData,
    mmvData,
    getModalData,
    getVersionData,
    getFuelData,
    getMmvData,
    getOptionKeyByValue,
    getPolicyKeyByValue,
    extraCardTitles,
    extraLabels,
    extraSlugs,
    extraCategory,
    isUpdateFinalSubmitLoading,
    editCard,
    setEditCard,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    current,
    setCurrent,
    isFirstView,
    setIsFirstView,
    mergedSchema,
    setMergedSchema,
    extraProposalCreate,
    mandatoryFields,
    dropdownFields,
    multiSelectFields,
    allStepsCompleted,
    setCurrentCardIndex,
    getMap,
    buttonRef,
    dateFields,
    brokeragePost,
    brokerageData,
    brokerageLoading,
    rewardPost,
    rewardData,
    rewardLoading,
    isPolicyPresent,
    checkPolicyPresent,
    checkProposalPresent,
    isPrevPolicyPresent,
    isProposalPresent,
    checkPrevPolicyPresent,
    fileFields,
    members,
    setMembers,
    isProposalSummaryRoute,
    optionalFields,
    setUserId,
    userId,
    matchedObject,
    setSubmittedAt,
    submittedAt,
    lastFormSubmitted,
    setStopNav,
    isPrevPolicyFromApi,
    levelConfigListingOfLead,
  };
}

export default useProposalSummary;
