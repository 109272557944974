import {
    TextField as _TextField,
    InputLabel as _InputLabel,
    styled,
    IconButton,
    alpha,
    Box,
  } from "@mui/material";
  
  export const InputLabel = styled(_InputLabel)(
    ({ bordered, hasCustomLabel, removeBottomMargin }) => ({
      fontSize: "12px",
      marginBottom: bordered && !removeBottomMargin ? "5px" : 0,
      fontWeight: "500",
      position: "relative",
      display: "inline-block",
      "& .MuiInputLabel-asterisk": {
        color: "red",
        ...(hasCustomLabel && {
          position: "absolute",
          top: "0",
          right: "12px",
        }),
      },
    }),
  );
  export const CrossIconButton = styled(IconButton)({
    color: "#6E8185",
    padding: 0,
  });
  
  export const RadioLabel = styled("label")(({ length }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: length > 3 && "100px",
    flex: length < 3 ? "1 1 0" : "unset",
    padding: "0 16px",
    //   border: "1px solid #E5E7EB",
    border: "1px solid rgb(169, 167, 167)",
    borderRadius: "5px",
    height: "33px",
    gap: "10px",
    //   transition: "all 0.3s ease",
  }));
  export const RadioInput = styled("input")(({ theme, color }) => ({
    display: "none",
    "&:checked + label ": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    "&:checked + label > .radio-button": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    ...(color === "opposite" && {
      "&:checked + label:nth-of-type(1) > .radio-button ": {
        backgroundColor: "#26BFA6",
        borderColor: "#26BFA6",
      },
      "&:checked + label:nth-of-type(2) > .radio-button ": {
        backgroundColor: "#FF5744",
        borderColor: "#FF5744",
      },
      "&:checked + label:nth-of-type(1)": {
        backgroundColor: "#D9FFF2",
      },
      "&:checked + label:nth-of-type(2)": {
        backgroundColor: "#FFEEEC",
      },
    }),
  }));
  export const RadioWrapper = styled(Box)(({ disableWrap }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    flexWrap: !disableWrap && "wrap",
  }));
  export const RadioButton = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    //   border: `1px solid #E5E7EB`,
    border: "1px solid rgb(169, 167, 167)",
    //   transition: "all 0.3s ease",
    boxShadow: `0px 4px 4px 0px ${alpha("#E5E7EB", 0.25)}`,
    "&:after": {
      content: '""',
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: "white",
      display: "block",
    },
  }));
  
  