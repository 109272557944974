import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Tooltip } from "@mui/material";
import _, { compact, isEmpty, isNil, isNull, isNumber } from "lodash";
import moment from "moment";
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
// import InlineLoader from "../../../components/loader/InlineLoader";
import Swal from "sweetalert2";
import {
  INSURED_MEMBER_DETAILS,
  NOMINEE_DETAILS,
  PAYMENT_DETAILS,
  POLICY_DETAILS,
  PREVIOUS_POLICY_DETAILS,
  PROPOSER_DETAILS,
  SOURCING_DETAILS,
  VEHICLE_DETAILS,
  PLAN_TYPE_SOOKSHMA_UDHYAM,
  PROPERTY_DETAILS,
  PLAN_TYPE_LAGHU_UDHYAM,
  PLAN_TYPE_STD_FIRE_SPECIAL_PERILS,
  PLAN_TYPE_GRIHA_RAKSHA,
  EMPLOYEE_DETAILS,
  CONTRACTOR_DETAILS,
  PI_DETAILS,
  grihaRakshaFields,
  commonForGrihaRakshaFields,
  unionTerritories,
  companyFields,
  floaterCoverFields,
  piIndividualFields,
  piCompanyFields,
  LIFE_ASSURED_DETAILS,
  PREMIUM_DETAILS,
  TP_1_YEAR,
  TP_LONG_TERM_5_YEARS,
  TP_LONG_TERM_3_YEARS,
  SATP,
  HEALTH_LOB,
  BIKE_LOB,
  TERM_LOB,
  PROPERTY_LOB,
  MARINE_LOB,
  TRAVEL_LOB,
  INVESTMENT_LOB,
  WORKMENS_COMPENSATION_LOB,
  PROFESSIONAL_INDEMNITY_LOB,
  CAR_LOB,
  GCV_LOB,
  PCV_LOB,
  PET_LOB,
  INSURED_MEMBER_TRAVELLING_DETAILS,
  PET_DETAILS,
} from "./proposalCreateConstants";
import {
  AddedMember,
  AddedMembersContainer,
  AddedTitle,
  ButtonContainerStyled,
  EndorsementFieldHeaderDifferentiator,
  MemberCount,
  PolicyCreateStyled,
  ProposalCreateSummaryStyled,
  RemovedMember,
  RemovedMembersContainer,
  RemovedTitle,
} from "./policyEntryStyles";
import {
  useCreateCustomerThroughPolicyEntry,
  useCreateMultipleCustomerThroughPolicyEntry,
  useGetCustomerMembers,
} from "../Customer/service";
import {
  useGetAddonLobWise,
  useGetBankDataFromIfsc,
  useGetBranchLocation,
  useGetCustomer,
  useGetCustomerById,
  useGetICBranchDetails,
  useGetProductsListPolicyEntry,
  useGetStateCityFromPincode,
  useGetUserById,
} from "./proposalSummaryService";
import {
  removeFileURLAction,
  setBrokerage,
  setIsPrevPolicyVisibleAction,
  setPolicyEditIndex,
  setPolicyViewCards,
  setProposalCreateValuesAction,
  setUploadedFileURLAction,
} from "./proposal.slice";
import { useGetLobById } from "./getLogMaster";
import { useGetCompaniesAccordingToLOB } from "../../hooks/useGetMasterTableData";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import InlineLoader from "../../components/loader/InlineLoader";
import { Button } from "../../components";
import { findDifferences } from "./customHooks/findDifference";
import { RenderFormFieldsEndorsement } from "./renderFormFieldsEndorsement";
import { formatPayloadForEndorsement } from "./customHooks/formatPayload";
import { payloadCheck } from "./payloadcheck";
import { isNewData } from "./customHooks/isNewData";
import {
  useAddEndorsement,
  useAddEndorsementForPremiumDetails,
  useAddonSpecificData,
  useGetEndorsementDiffCheck,
} from "./service";
import { isMemberDeleted } from "./customHooks/isMemberDeleted";
import { endorsementStageObj } from "./customHooks/constants";
import Dropdown from "../../components/Dropdown/Dropdown";
import RenderNewChanges from "./RenderNewChanges";
import { getTooltipTitle } from "./customHooks/GetTooltip";
import {
  generateDynamicOptionalYupSchema,
  generateDynamicYupSchema,
} from "./customHooks/generateYupValidation";

function FieldListComponentForEndorsement({
  lobId,
  extraCardTitles,
  masterTableData,
  updateExistingData,
  generateMongoId,
  proposalData,
  isLoading,
  isCreateProposalDataLoading,
  isUpdateExistingProposalDataLoading,
  getModalData,
  getVersionData,
  getFuelData,
  manufacturerData,
  modelData,
  versionData,
  fuelData,
  editCard,
  setEditCard,
  activeStep,
  setActiveStep,
  current,
  mergedSchema,
  setMergedSchema,
  extraProposalCreate,
  mandatoryFields,
  dropdownFields,
  multiSelectFields,
  getOptionKeyByValue,
  setCurrentCardIndex,
  getMap,
  dateFields,
  checkPolicyPresent,
  isPolicyPresent,
  isPrevPolicyPresent,
  checkPrevPolicyPresent,
  fileFields,
  members,
  setMembers,
  optionalFields,
  setUserId,
  matchedObject,
  setSubmittedAt,
  endorsementData,
  lineofBusiness,
}) {
  const [isPremiumDetailsReadOnly, setIsPremiumDetailsReadOnly] =
    useState(false);
  const [formName, setFormName] = useState("");

  const endorsementDetailsRef = useRef([]);
  const [showAddonBifurcation, setShowAddonBifurcation] = useState(false);
  // const PROPOSER_FIELDS = useMemo(() => {
  //   matchedObject[PROPOSER_DETAILS]?.map(item => item?.field_slug) || [];
  // }, [matchedObject]);
  const PREMIUM_FIELDS = useMemo(() => {
    return (
      matchedObject[PREMIUM_DETAILS]?.filter(
        i =>
          i?.is_add_on_premium_key !== "Y" && i?.field_slug !== "payFrequency",
      ).map(item => item?.field_slug) || []
    );
  }, [matchedObject]);
  // Ensure each component gets its own ref
  const setRef = index => node => {
    endorsementDetailsRef.current[index] = node;
  };

  const PaymentDetailsRef =
    endorsementDetailsRef.current.length &&
    endorsementDetailsRef.current.find(
      value => value?.innerHTML === "Premium Details",
    );

  const scrollToPremiumDetails = () => {
    const element = PaymentDetailsRef;

    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;

      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
    }
  };

  const {
    mutate: getEndorsementDifference,
    data: endorsementDiffData,
    isError: endorsementDiffError,
    isLoading: endorsementDiffLoading,
  } = useGetEndorsementDiffCheck();
  const {
    mutate: AddEndorsement,
    data: endorsementAddData,
    isError: endorsementAddError,
    isLoading: endorsementAddLoading,
  } = useAddEndorsement();
  const { data: lobData } = useGetLobById(lobId);
  // //console.log('lobData', lobData)
  const currentLobSlug = lobData?.data?.data?.lobslug;
  const isHealthLob = currentLobSlug === HEALTH_LOB;
  const isCarLob = currentLobSlug === CAR_LOB;
  const isBikeLob = currentLobSlug === BIKE_LOB;
  const isTermLob = currentLobSlug === TERM_LOB;
  const isPropertyInsuranceLob = currentLobSlug === PROPERTY_LOB;
  const isMarineLob = currentLobSlug === MARINE_LOB;
  const isTravelLob = currentLobSlug === TRAVEL_LOB;
  const isWorkmensCompensationLob =
    currentLobSlug === WORKMENS_COMPENSATION_LOB;
  const isInvestmentLob = currentLobSlug === INVESTMENT_LOB;
  const isGcvLob = currentLobSlug === GCV_LOB;
  const isPcvLob = currentLobSlug === PCV_LOB;
  const isPetLob = currentLobSlug === PET_LOB;
  const isProfessionalIndemnityLob =
    currentLobSlug === PROFESSIONAL_INDEMNITY_LOB;
  const isBusinessTypeConditionsLobWise =
    isCarLob || isBikeLob || isGcvLob || isPcvLob;

  const { mutate: getAddons, data: addonData } = useGetAddonLobWise();

  // const { data: addonData } = useAddonSpecificData({
  //   lobId: lobId,
  //   productId: endorsementData?.[0]?.Productid,
  //   companyId: endorsementData?.[0]?.icId,
  // });
  // const [formDifferences, setFormDifferences] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  const [activeApproved, setActiveApproved] = useState(0);
  const [files, setFiles] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [draftPayload, setDraftPayload] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const transactionIdParams = queryParams.get("TransactionId");
  const dispatch = useDispatch();
  const { proposalCreateValues, uploadedFileURL, policyViewCards } =
    useSelector(state => state.proposalConfig);
  const { theme } = useSelector(state => state.theme);
  let yupValidate = yup.object({});
  const { mandatoryFieldsFlatten, mandatoryFieldSlugs } = useMemo(() => {
    const mandatoryFieldsFlatten = mandatoryFields?.flat();
    const mandatoryFieldSlugs = mandatoryFieldsFlatten?.map(i => i?.key);
    return { mandatoryFieldsFlatten, mandatoryFieldSlugs };
  }, [mandatoryFields?.length]);

  const formSchema =
    generateDynamicYupSchema(
      extraProposalCreate?.flat()?.filter(item => {
        return (
          (item?.mandatory === "Y" &&
            item?.is_payment_key === "N" &&
            item?.is_member_key === "N" &&
            item?.is_nominee_key === "N") || //for validations
          //  &&
          // item?.field !== "FILE"
          item?.key === "alternateMobileno" ||
          item?.key === "odexpirydate" ||
          item?.key === "tpexpirydate" ||
          item?.key === "alternateId"
        );
      }),
      isBusinessTypeConditionsLobWise,
    ) ?? [];
  const familyMemberFormSchema = yup.object().shape({
    members: yup.array().of(
      generateDynamicYupSchema(
        mandatoryFieldsFlatten?.filter(item => {
          return (
            item?.is_member_key === "Y"
            // &&
            // item?.field !== "FILE"
          );
        }),
      ),
      isBusinessTypeConditionsLobWise,
    ),
  });
  const NomineeFormSchema = yup.object().shape({
    nominee: yup.array().of(
      generateDynamicYupSchema(
        mandatoryFieldsFlatten?.filter(item => {
          return (
            item?.is_nominee_key === "Y"
            // &&
            // item?.field !== "FILE"
          );
        }),
        isBusinessTypeConditionsLobWise,
      ).concat(
        yup.object().shape({
          nomineeShare: yup
            .string()
            .nullable()
            // .typeError("Date must be a valid date")
            .test(
              `nomineeShare_not_zero`,
              "Nominee Share must not be Zero",
              function (nomineeShareValue) {
                if (
                  (nomineeShareValue === "" ||
                    nomineeShareValue === " " ||
                    isNull(nomineeShareValue)) &&
                  (isBusinessTypeConditionsLobWise || isInvestmentLob)
                ) {
                  return true;
                }
                return +nomineeShareValue > 0;
              },
            ),
        }),
      ),
    ),
  });
  const completeScheme = formSchema.concat(
    familyMemberFormSchema.concat(
      NomineeFormSchema.concat(
        yup.object().shape({
          // endorsementStage: yup
          //   .mixed()
          //   .required("Endorsement Stage Is Required."),
        }),
      ),
    ),
  );
  const [validationSchema, setValidationSchema] = useState(completeScheme);

  useEffect(() => {
    const updatedScheme = completeScheme.concat(
      yup.object().shape({
        addOnPremiums: yup.array().of(
          yup.object().shape({
            amountType: showAddonBifurcation
              ? yup.mixed().required("Amount Type is Required")
              : yup.mixed().nullable(), // Not required if false
            addons: showAddonBifurcation
              ? yup.mixed().required("Addon is Required")
              : yup.mixed().nullable(), // Not required if false
            amount: showAddonBifurcation
              ? yup.string().nullable().required("Amount is Required")
              : yup.string().nullable(), // Not required if false
          }),
        ),
        ckycId: yup
          .string()
          .nullable()
          .test(
            "ckyc-number",
            "CKYC number must contain 14 digits",
            function (ckycIdValue) {
              if (ckycIdValue?.length === 14 || !ckycIdValue?.length) {
                return true;
              } else {
                return this.createError({
                  message: "CKYC number must contain 14 digits",
                });
              }
            },
          ),
        odPremium: yup
          .string()
          // .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
          // .min(10, "Please enter 10 digits")
          // .max(10, "Please enter 10 digits")
          .test(
            `${"Od-Premium"}-is-required`,
            // "OD Premium is Required",
            function (value, ctx) {
              const { businessType } = this.parent;
              const isODPremium = businessType?.label
                ?.toLowerCase()
                ?.includes("od");

              if (isBusinessTypeConditionsLobWise && isODPremium && !value) {
                return ctx.createError({
                  message: "OD Premium is Required",
                });
              }

              return true;
            },
          ),
        tpPremium: yup
          .mixed()
          // .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
          // .min(10, "Please enter 10 digits")
          // .max(10, "Please enter 10 digits")
          .test(
            `${"TP-Premium"}-is-required`,
            // "OD Premium is Required",
            function (value, ctx) {
              const { businessType } = this.parent;
              const isTPPremium = businessType?.label
                ?.toLowerCase()
                ?.includes("tp");

              if (isBusinessTypeConditionsLobWise && isTPPremium && !value) {
                return ctx.createError({
                  message: "TP Premium is Required",
                });
              }

              return true;
            },
          ),

        // coverage:
        //   mandatoryFieldSlugs?.includes("coverage") &&
        //   yup.string().test("coverage", function (value) {
        //     const { planType } = this.parent;
        //     const plan = planType?.value?.trim();
        //     if (!value || isNaN(value)) {
        //       if (
        //         currentLobSlug === INVESTMENT_LOB ||
        //         currentLobSlug === TERM_LOB
        //       ) {
        //         return this.createError({
        //           message: `Sum assured is required`,
        //           path: this.path,
        //         });
        //       } else if (
        //         currentLobSlug === CAR_LOB ||
        //         currentLobSlug === BIKE_LOB ||
        //         currentLobSlug === GCV_LOB ||
        //         currentLobSlug === PCV_LOB
        //       ) {
        //         return this.createError({
        //           message: `IDV is required`,
        //           path: this.path,
        //         });
        //       } else {
        //         return this.createError({
        //           message: `Sum insured is required`,
        //           path: this.path,
        //         });
        //       }
        //     }
        //     if (value < 30000 && currentLobSlug === PROPERTY_LOB) {
        //       return this.createError({
        //         message: "Should not be less than 30k",
        //         path: this.path,
        //       });
        //     }
        //     if (currentLobSlug === PROFESSIONAL_INDEMNITY_LOB) {
        //       if (value == 0) {
        //         return this.createError({
        //           message: "Sum insured is required",
        //           path: this.path,
        //         });
        //       }
        //       if (value > 990000000000) {
        //         return this.createError({
        //           message: "Should not exceed 99 Thousand Cr",
        //           path: this.path,
        //         });
        //       }
        //     }
        //     if (
        //       (plan === PLAN_TYPE_SOOKSHMA_UDHYAM ||
        //         plan === PLAN_TYPE_LAGHU_UDHYAM) &&
        //       currentLobSlug === PROPERTY_LOB
        //     ) {
        //       if (plan === PLAN_TYPE_SOOKSHMA_UDHYAM && value > 50000000) {
        //         return this.createError({
        //           message: "Should not exceed 5 Cr",
        //           path: this.path,
        //         });
        //       }
        //       if (plan === PLAN_TYPE_LAGHU_UDHYAM && value > 500000000) {
        //         return this.createError({
        //           message: "Should not exceed 50 Cr",
        //           path: this.path,
        //         });
        //       }
        //     }
        //     return true;
        //   }),
      }),
    );

    // Update the validation schema state
    setValidationSchema(updatedScheme);
  }, [
    showAddonBifurcation,
    JSON.stringify(validationSchema),
    JSON.stringify(completeScheme),
  ]);

  const insuredMemberDefaultValues =
    matchedObject[INSURED_MEMBER_DETAILS]?.reduce((acc, curr) => {
      acc[curr?.field_slug] = null;
      return acc;
    }, {}) ?? {};

  const nomineeDefaultValues =
    matchedObject[NOMINEE_DETAILS]?.reduce((acc, curr) => {
      acc[curr?.field_slug] = null;
      return acc;
    }, {}) ?? {};

  const paymentDefaultValues =
    matchedObject[PAYMENT_DETAILS]?.reduce((acc, curr) => {
      acc[curr?.field_slug] = null;
      return acc;
    }, {}) ?? {};

  const travelInsuredMemberDefaultValues =
    matchedObject[INSURED_MEMBER_TRAVELLING_DETAILS]?.filter(
      item => item?.is_member_key === "Y",
    )?.reduce((acc, curr) => {
      acc[curr?.field_slug] = null;
      return acc;
    }, {}) ?? {};

  const addonDefaultValues =
    matchedObject[PREMIUM_DETAILS]?.filter(
      item => item?.is_add_on_premium_key === "Y",
    )?.reduce((acc, curr) => {
      acc[curr?.field_slug] = null;
      return acc;
    }, {}) ?? {};

  const {
    register,
    setValue,
    control,
    getValues,
    watch,
    unregister,
    formState: { errors },
    trigger,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      members: [insuredMemberDefaultValues],
      nominee: [nomineeDefaultValues],
      payments: [paymentDefaultValues],
      addOnPremiums: [addonDefaultValues],
    },
    mode: "onChange",
    reValidateMode: "onChange",
    // shouldUnregister: true,
  });

  const {
    fields: memberFieldArray,
    append: appendMember,
    remove: removeMember,
  } = useFieldArray({
    control,
    name: "members",
  });

  const {
    fields: nomineeFieldArray,
    append: appendNominee,
    remove: removeNominee,
  } = useFieldArray({
    control,
    name: "nominee",
  });

  const {
    fields: paymentFieldArray,
    append: appendPayment,
    remove: removePayment,
  } = useFieldArray({
    control,
    name: "payments",
  });

  const {
    fields: addonFieldArray,
    append: appendAddon,
    remove: removeAddon,
  } = useFieldArray({
    control,
    name: "addOnPremiums",
  });

  const {
    fields: travelMemberFieldArray,
    append: appendTravelMember,
    remove: removeTravelMember,
  } = useFieldArray({
    control,
    name: "members",
  });

  const {
    isSameAddress,
    addressper,
    hpidN,
    addresschecktwo,
    make,
    model,
    sourcingPinCode,
    pinCode,
    pincode,
    pinCoderm,
    pincodeOne,
    assuredPincode,
    pincodeInvestment,
    pincodepd,
    policystDate,
    policyExpirydate,
    icName,
    insuranceCompanyname,
    productNamepd,
    nomineeDob,
    paymentMode,
    regDate,
    odPremium,
    tpPremium,
    tpexpirydate,
    odexpirydate,
    basePremium,
    discount,
    loadingAmount,
    totalTax,
    policyStartdate,
    policyExpirydatepd,
    title,
    premiumPaidby,
    firstName,
    addressLineone,
    addressLinetwo,
    assuredAddresstwo,
    assuredAddressone,
    assuredLandmark,
    assuredCity,
    assuredState,
    weight,
    height,
    weightImd,
    heightImd,
    sgst,
    cgst,
    ugst,
    netPremium,
    taxAmount,
    pincodeVd,
    middleNamepd,
    lastName,
    gender,
    dob,
    dobIMD,
    nationality,
    nationalityAssured,
    mobileNo,
    alternateMobileno,
    emailId: proposerEmailId,
    aadharCardno,
    panCardno,
    maritialStatus,
    policyurselfornot,
    state,
    city,
    pinCodeld,
    pincodeinsured,
    pincodetwooinsured,
    pincodeInsured,
    addressCheck,
    proposeraddress,
    policyNo,
    proposalNum,
    previousPolicynum,
    payment,
    userType,
    medicalHistory,
    anniversary,
    sourcingCity,
    sourcingState,
    pastInsurance,
    occupation,
    landmark,
    titleNd,
    nomineeRelation,
    relWithprop,
    middleName,
    titlela,
    lifeassuredTitle,
    addOnpremium,
    productName,
    vehicleRegNo,
    customerRefId,
    pincodetwo,
    pinCodetwo,
    members: watchMembers,
    nominee: watchNominee,
    sourcing: watchSourcing,
    addOnPremiums: watchAddons,
    version,
    typeofcustomer,
    planType,
    floaterCover,
    locationofRisk,
    benificiaryRelationshiptopolicyholder,
    businessType,
    fuelType,

    endorsementStage,
    transactionStageZ,
    idProof,
    addressProof,
    incomeProof,
    shippingtype,
    basisvaluation,
    policyTerm,
    contentsum,
    Buildingsum,
    suminsuredo,
    suminsuredtwo,
    suminsuredthree,
    suminsuredfour,
    coverType,
    relationshipwithProposer,
    identityvalue,
    addressvalue,
    tenure,
    policyType,
    customertype,
  } = watch();
  // using this state for nested forms (insured members, payment) for conditional field reset
  // to get exact field
  const [currentField, setCurrentField] = useState({
    field: "",
    index: null,
  });
  const customDropdownFields = [
    "firstName",
    "productName",
    "productNamepd",
    "payerName",
  ];

  const [endorsementDifference, setEndorsementDifference] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      Object.keys(errors)?.map(item => {
        if (_.isEmpty(watch(item)) || !_.isEmpty(watch(item))) {
          trigger(item);
        }
      });
    }, 500);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [JSON.stringify(Object.keys(errors))]);

  let summaryDataKeyValue =
    Array.isArray(endorsementData) &&
    endorsementData?.length &&
    endorsementData[0];
  let summaryData = {};
  const isPrevPolicyFromApi =
    summaryData[POLICY_DETAILS] && summaryData[POLICY_DETAILS]?.pastInsurance;

  const planTypeFromApi = summaryDataKeyValue?.planType;

  const proposerDetailsFromApi = summaryDataKeyValue;

  const nomineeDetailsFromApi = summaryDataKeyValue;

  const policyDetailsFromApi = summaryDataKeyValue;

  const prevPolicyDetailsFromApi = summaryDataKeyValue;

  const lifeAssuredDetailsFromApi = summaryDataKeyValue;
  const vehicleDetailsFromApi = summaryDataKeyValue;
  const propertyDetailsFromApi = summaryDataKeyValue;

  const contractorDetailsExists = matchedObject[CONTRACTOR_DETAILS];

  const employeeDetailsFromApi = summaryDataKeyValue;

  const premiumDetailsFromApi = summaryDataKeyValue;

  const insureYourLiabilityToContractor =
    employeeDetailsFromApi?.members?.[0]?.compensationAct;

  const missingFormInBulkUpload = Object.keys(matchedObject || {})?.filter(
    item => !Object.keys(summaryData)?.includes(item),
  );

  const customerIdFromApi =
    summaryData[PROPOSER_DETAILS] && summaryData[PROPOSER_DETAILS]?.customerId;

  // for bulkupload, adding empty forms in summaryData to display that particular card with edit button
  missingFormInBulkUpload?.map(i => {
    if (i === PREVIOUS_POLICY_DETAILS && isPrevPolicyFromApi === "Yes") {
      summaryData[PREVIOUS_POLICY_DETAILS] = {};
    }
    if (i === CONTRACTOR_DETAILS && insureYourLiabilityToContractor === "Yes") {
      summaryData[CONTRACTOR_DETAILS] = {};
    }
    if (
      // !_.isEmpty(summaryData[PAYMENT_DETAILS] || {}) &&
      transactionIdParams &&
      location?.pathname !== "/create"
    ) {
      if (i !== PREVIOUS_POLICY_DETAILS && i !== CONTRACTOR_DETAILS) {
        summaryData[i] = {};
      }
    }
  });

  const prevPolicyCardIndex = extraCardTitles?.findIndex(
    value => value === PREVIOUS_POLICY_DETAILS,
  );
  const contractorDetailsCardIndex = extraCardTitles?.findIndex(
    value => value === CONTRACTOR_DETAILS,
  );

  const mandatoryFieldsPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi === "Yes" && insureYourLiabilityToContractor === "Yes"
      ? mandatoryFields
      : isPrevPolicyFromApi === "Yes" &&
        insureYourLiabilityToContractor !== "Yes"
        ? mandatoryFields?.filter(
          (_, index) => index !== contractorDetailsCardIndex,
        )
        : isPrevPolicyFromApi !== "Yes" &&
          insureYourLiabilityToContractor === "Yes"
          ? mandatoryFields?.filter((_, index) => index !== prevPolicyCardIndex)
          : mandatoryFields?.filter(
            (_, index) =>
              index !== prevPolicyCardIndex &&
              index !== contractorDetailsCardIndex,
          )
    : isPrevPolicyFromApi === "Yes"
      ? mandatoryFields
      : mandatoryFields?.filter((_, index) => index !== prevPolicyCardIndex);

  const optionalFieldsPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi === "Yes" && insureYourLiabilityToContractor === "Yes"
      ? optionalFields
      : isPrevPolicyFromApi === "Yes" &&
        insureYourLiabilityToContractor !== "Yes"
        ? optionalFields?.filter(
          (_, index) => index !== contractorDetailsCardIndex,
        )
        : isPrevPolicyFromApi !== "Yes" &&
          insureYourLiabilityToContractor === "Yes"
          ? optionalFields?.filter((_, index) => index !== prevPolicyCardIndex)
          : optionalFields?.filter(
            (_, index) =>
              index !== prevPolicyCardIndex &&
              index !== contractorDetailsCardIndex,
          )
    : isPrevPolicyFromApi === "Yes"
      ? optionalFields
      : optionalFields?.filter((_, index) => index !== prevPolicyCardIndex);

  // //console.log('contractorDetailsExists', contractorDetailsExists)
  const cardTitlesPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi === "Yes" && insureYourLiabilityToContractor !== "Yes"
      ? extraCardTitles?.filter(
        (_, index) => index !== contractorDetailsCardIndex,
      )
      : isPrevPolicyFromApi !== "Yes" &&
        insureYourLiabilityToContractor === "Yes"
        ? extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex)
        : isPrevPolicyFromApi === "Yes" &&
          insureYourLiabilityToContractor === "Yes"
          ? extraCardTitles
          : extraCardTitles?.filter(
            (_, index) =>
              index !== prevPolicyCardIndex &&
              index !== contractorDetailsCardIndex,
          )
    : isPrevPolicyFromApi === "Yes"
      ? extraCardTitles
      : extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex);

  const cardFieldsPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi !== "Yes" && insureYourLiabilityToContractor === "Yes"
      ? extraProposalCreate?.filter((_, index) => index !== prevPolicyCardIndex)
      : isPrevPolicyFromApi === "Yes" &&
        insureYourLiabilityToContractor !== "Yes"
        ? extraProposalCreate?.filter(
          (_, index) => index !== contractorDetailsCardIndex,
        )
        : isPrevPolicyFromApi === "Yes" &&
          insureYourLiabilityToContractor === "Yes"
          ? extraProposalCreate
          : extraProposalCreate?.filter(
            (_, index) =>
              index !== prevPolicyCardIndex &&
              index !== contractorDetailsCardIndex,
          )
    : isPrevPolicyFromApi === "Yes"
      ? extraProposalCreate
      : extraProposalCreate?.filter(
        (_, index) => index !== prevPolicyCardIndex,
      );

  const activeFormTitle = cardTitlesPrevPolicyToggle[activeStep - 1];

  useEffect(() => {
    const isNested = ["members", "payment", "nominee", "sourcing"]?.includes(
      Object.keys(errors)?.[0],
    );
    const errorKeys = !isNested
      ? Object.keys(errors)
      : Object.keys(_.compact(Object.values(errors || {})?.[0])?.[0] || {});

    const currentFormKeys = Object.values(matchedObject)
      ?.flat()
      ?.map(item => item?.field_slug);
    const sequencedErrorKeys = currentFormKeys?.filter(key =>
      errorKeys?.includes(key),
    );
    if (!isNested) {
      document
        .getElementById(sequencedErrorKeys?.[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      const nestedSection = Object.keys(errors)?.[0];
      const bbb = Object.values(errors || {})?.[0];
      (bbb || [])?.map((item, index) => {
        document
          .getElementById(
            `${nestedSection}.[${index}].${sequencedErrorKeys?.[0]}`,
          )
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    }
  }, [errors]);

  const currentFormData = summaryData?.[activeFormTitle];

  const proposerDetailsFields = (matchedObject || {})[PROPOSER_DETAILS]?.map(
    item => item?.field_slug,
  );

  const CustomerTypeCompany =
    typeofcustomer && typeofcustomer?.value === "Company";

  const noFloaterCover = floaterCover && floaterCover?.value === "No";

  const policyDetailsPlanType =
    planType?.value?.trim() || summaryData[POLICY_DETAILS]?.planType?.trim();

  const policyDetailsBusinessType = summaryData[POLICY_DETAILS]?.businessType;

  const propertyDetailsFields =
    matchedObject[PROPERTY_DETAILS] &&
    matchedObject[PROPERTY_DETAILS]?.map(item => item?.field_slug);

  const fieldsOtherThanGrihaRaksha = propertyDetailsFields?.filter(
    key =>
      !grihaRakshaFields.includes(key) &&
      !commonForGrihaRakshaFields.includes(key),
  );

  const hideGrihaFields = [
    PLAN_TYPE_SOOKSHMA_UDHYAM,
    PLAN_TYPE_LAGHU_UDHYAM,
    PLAN_TYPE_STD_FIRE_SPECIAL_PERILS,
  ];

  const indexOfSelfInsured =
    Array.isArray(watchMembers) &&
    watchMembers
      ?.map((item, index) =>
        item?.relWithprop?.label === "Self" ? index : undefined,
      )
      ?.filter(item => item != undefined)?.[0];

  const indexOfSelfTravelInsured =
    Array.isArray(watchMembers) &&
    watchMembers
      ?.map((item, index) =>
        item?.relationshipwithProposer?.label === "Self" ? index : undefined,
      )
      ?.filter(item => item != undefined)?.[0];

  const indexOfSelfNominee = watchNominee
    ?.map((item, index) =>
      item?.nomineeRelation?.label === "Self" ? index : undefined,
    )
    ?.filter(item => item != undefined)?.[0];

  const indexOfSelfPayment = payment
    ?.map((item, index) =>
      item?.paymentPaid?.label === "Self" ? index : undefined,
    )
    ?.filter(item => item != undefined)?.[0];

  // const isUnionTerritory = unionTerritories?.some(
  //   item =>
  //     item?.replace(/\s/g, "")?.toLowerCase().trim() ==
  //     (proposerDetailsFromApi?.sourcingState || "")
  //       ?.replace(/\s/g, "")
  //       ?.toLowerCase()
  //       .trim(),
  // );

  // useEffect(() => {
  //   if (isUnionTerritory) {
  //     setValue("sgst", 0);
  //     if (sgst > 0) {
  //       setValue("sgst", 0);
  //     }
  //     setValue("cgst", 0);
  //     if (cgst > 0) {
  //       setValue("cgst", 0);
  //     }
  //   }
  //   if (!isUnionTerritory) {
  //     setValue("ugst", 0);
  //     if (ugst > 0) {
  //       setValue("ugst", 0);
  //     }
  //   }
  // }, [isUnionTerritory, sgst, ugst, cgst]);
  function extractNumbersFromString(str) {
    // Regular expression to match one or more digits
    const regex = /\d+/g;
    const numbers = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      numbers.push(parseInt(match[0], 10));
    }
    return numbers;
  }
  const insuredMemberDetailsFromApi =
    summaryData[INSURED_MEMBER_DETAILS] && summaryData[INSURED_MEMBER_DETAILS];

  // const mandatoryFieldSlugs =
  //   !CustomerTypeCompany && activeFormTitle === PROPOSER_DETAILS
  //     ? mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]
  //         ?.map(value => value?.key)
  //         ?.filter(key => !companyFields.includes(key))
  //     : hideGrihaFields?.includes(policyDetailsPlanType) &&
  //         activeFormTitle === PROPERTY_DETAILS
  //       ? mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]
  //           ?.map(value => value?.key)
  //           ?.filter(key => !grihaRakshaFields.includes(key))
  //       : policyDetailsPlanType === PLAN_TYPE_GRIHA_RAKSHA &&
  //           activeFormTitle === PROPERTY_DETAILS
  //         ? mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]
  //             ?.map(value => value?.key)
  //             ?.filter(key => !fieldsOtherThanGrihaRaksha.includes(key))
  //         : mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.map(
  //             value => value?.key,
  //           );

  const optionalFieldSlugs = optionalFieldsPrevPolicyToggle?.[
    activeStep - 1
  ]?.map(value => value.key);

  useEffect(() => {
    getAddons({
      page: 0,
      size: 0,
      sortBy: "",
      sortOrder: "",
      searchValue:
        isTermLob || isInvestmentLob
          ? {
            lobId: lobId,
            companyId: "",
            productId: "",
            addonName: "",
          }
          : {
            lobId: lobId,
            companyId: policyDetailsFromApi?.Icid,
            productId: policyDetailsFromApi?.Productid,
            addonName: "",
          },
    });
  }, [
    lobId,
    policyDetailsFromApi?.Icid,
    policyDetailsFromApi?.Productid,
    isTermLob,
    isInvestmentLob,
  ]);

  useEffect(() => {
    dispatch(setProposalCreateValuesAction(summaryData));
  }, [JSON.stringify(summaryData), isPrevPolicyFromApi]);

  // Clear File Logic
  const documentTypes = ["aadhaar", "pan card", "voter", "driving", "passport"];

  function containsSpecificProof(proof) {
    return documentTypes.some(doc => proof?.toLowerCase()?.includes(doc));
  }
  function containsOtherProof(proof) {
    return !documentTypes.some(doc => proof?.toLowerCase()?.includes(doc));
  }

  // const setDocData = obj => {
  //   if (!_.isEmpty(obj)) {
  //     return {
  //       documentPath: obj?.documentPath || obj?.docPath,
  //       documentName: obj?.documentName,
  //     };
  //   }
  // };

  // const aadharDoc =
  //   setDocData(
  //     leadData?.documentDetailsList?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("aadhaar"),
  //     ),
  //   ) ||
  //   setDocData(
  //     customerDocs?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("aadhaar"),
  //     ),
  //   );

  // const panDoc =
  //   setDocData(
  //     leadData?.documentDetailsList?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("pan card"),
  //     ),
  //   ) ||
  //   setDocData(
  //     customerDocs?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("pan card"),
  //     ),
  //   );
  // const voterDoc =
  //   setDocData(
  //     leadData?.documentDetailsList?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("voter"),
  //     ),
  //   ) ||
  //   setDocData(
  //     customerDocs?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("voter"),
  //     ),
  //   );

  // const drivingDoc =
  //   setDocData(
  //     leadData?.documentDetailsList?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("driving"),
  //     ),
  //   ) ||
  //   setDocData(
  //     customerDocs?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("driving"),
  //     ),
  //   );

  // const passportDoc =
  //   setDocData(
  //     leadData?.documentDetailsList?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("passport"),
  //     ),
  //   ) ||
  //   setDocData(
  //     customerDocs?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("passport"),
  //     ),
  //   );

  // const otherDoc =
  //   setDocData(
  //     leadData?.documentDetailsList?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("other"),
  //     ),
  //   ) ||
  //   setDocData(
  //     customerDocs?.find(doc =>
  //       doc?.documentName?.toLowerCase()?.includes("other"),
  //     ),
  //   );

  const isSpecificDocument =
    containsSpecificProof(idProof?.label) ||
    containsSpecificProof(addressProof?.label);

  // const isSpecificLifeAssuredDocument =
  //   containsOtherProof(assuredaddressProof?.label) ||
  //   containsOtherProof(assuredidProof?.label);

  const clearProofValueAndDocument = (proofValueKey, proofDocKey) => {
    // watch(proofValueKey) && setValue(proofValueKey, null);
    watch(proofDocKey) && setValue(proofDocKey, null);
    const newFiles = { ...files };
    delete newFiles[proofDocKey];
    setFiles(newFiles);
    dispatch(
      setUploadedFileURLAction({
        [proofDocKey]: null,
      }),
    );
  };

  useEffect(() => {
    if (idProof?.label !== proposerDetailsFromApi?.idProof) {
      // if (containsSpecificProof(idProof?.label)) {
      //   if (
      //     (idProof?.label?.toLowerCase()?.includes("aadhaar") &&
      //       !aadharDoc?.documentPath) ||
      //     (idProof?.label?.toLowerCase()?.includes("pan card") &&
      //       !panDoc?.documentPath) ||
      //     (idProof?.label?.toLowerCase()?.includes("voter") &&
      //       !voterDoc?.documentPath) ||
      //     (idProof?.label?.toLowerCase()?.includes("driving") &&
      //       !drivingDoc?.documentPath) ||
      //     (idProof?.label?.toLowerCase()?.includes("passport") &&
      //       !passportDoc?.documentPath) ||
      //     (idProof?.label?.toLowerCase()?.includes("other") &&
      //       !otherDoc?.documentPath)
      //   ) {
      //     clearProofValueAndDocument("identityvalue", "uploadIdentity");
      //     if (idProof?.label?.toLowerCase()?.includes("aadhaar")) {
      //       setValue("identityvalue", aadharCardno);
      //     } else {
      //       !!identityvalue && setValue("identityvalue", null);
      //     }
      //   } else {
      //     if (!idProof?.label?.toLowerCase()?.includes("aadhaar")) {
      //       identityvalue && setValue("identityvalue", null);
      //     }
      //   }
      // } else {
      clearProofValueAndDocument("identityvalue", "uploadIdentity");
      !!identityvalue && setValue("identityvalue", null);
      // }
    }
  }, [idProof?.label, proposerDetailsFromApi?.idProof]);

  useEffect(() => {
    if (addressProof?.label !== proposerDetailsFromApi?.addressProof) {
      // if (containsSpecificProof(addressProof?.label)) {
      //   if (
      //     (addressProof?.label?.toLowerCase()?.includes("aadhaar") &&
      //       !aadharDoc?.documentPath) ||
      //     (addressProof?.label?.toLowerCase()?.includes("pan card") &&
      //       !panDoc?.documentPath) ||
      //     (addressProof?.label?.toLowerCase()?.includes("voter") &&
      //       !voterDoc?.documentPath) ||
      //     (addressProof?.label?.toLowerCase()?.includes("driving") &&
      //       !drivingDoc?.documentPath) ||
      //     (addressProof?.label?.toLowerCase()?.includes("passport") &&
      //       !passportDoc?.documentPath) ||
      //     (addressProof?.label?.toLowerCase()?.includes("other") &&
      //       !otherDoc?.documentPath)
      //   ) {
      //     clearProofValueAndDocument("addressvalue", "uploadAddress");
      //     if (addressProof?.label?.toLowerCase()?.includes("aadhaar")) {
      //       setValue("addressvalue", aadharCardno);
      //     } else {
      //       !!addressvalue && setValue("addressvalue", null);
      //     }
      //   } else {
      //     if (!addressProof?.label?.toLowerCase()?.includes("aadhaar")) {
      //       addressvalue && setValue("addressvalue", null);
      //     }
      //   }
      // } else {
      clearProofValueAndDocument("addressvalue", "uploadAddress");
      !!addressvalue && setValue("addressvalue", null);
      // }
    }
  }, [addressProof?.label, proposerDetailsFromApi?.addressProof]);

  useEffect(() => {
    if (incomeProof?.value !== proposerDetailsFromApi?.incomeProof) {
      clearProofValueAndDocument("incomevalue", "uploadIncome");
    }
  }, [incomeProof?.value, proposerDetailsFromApi?.incomeProof]);

  useEffect(() => {
    setValue("pastInsurance", {
      label: isPrevPolicyFromApi || "No",
      value: isPrevPolicyFromApi || "No",
    });
  }, [isPrevPolicyFromApi]);

  useEffect(() => {
    if (policystDate === undefined) return;
    if (
      moment(policyDetailsFromApi?.policystDate).format("DD-MM-YYYY") !==
      moment(policystDate).format("DD-MM-YYYY")
    ) {
      setValue("policyExpirydate", null);
      setValue("policyPeriod", null);
    }
  }, [policyDetailsFromApi?.policystDate, policystDate]);

  useEffect(() => {
    if (isBusinessTypeConditionsLobWise) {
      if (businessType?.label === "SAOD") {
        setValue("policyExpirydate", odexpirydate);
      } else if (
        [TP_1_YEAR, TP_LONG_TERM_5_YEARS, TP_LONG_TERM_3_YEARS, SATP]?.includes(
          businessType?.label,
        )
      ) {
        setValue("odexpirydate", null);
        setValue("policyExpirydate", tpexpirydate);
      } else {
        setValue("policyExpirydate", odexpirydate || tpexpirydate);
      }
    }
  }, [
    isBusinessTypeConditionsLobWise,
    businessType?.label,
    odexpirydate,
    tpexpirydate,
  ]);

  useEffect(() => {
    if (
      activeFormTitle === VEHICLE_DETAILS &&
      fuelType?.label &&
      fuelType?.label === "ELECTRIC"
    ) {
      setValue("pucNumber", null);
      setValue("pucExpiryDate", null);
      setValue("pucStartdate", null);
    }
  }, [fuelType?.label]);

  useEffect(() => {
    if (isBusinessTypeConditionsLobWise) {
      if (
        [TP_1_YEAR, TP_LONG_TERM_5_YEARS, TP_LONG_TERM_3_YEARS, SATP]?.includes(
          policyDetailsBusinessType,
        )
      ) {
        setValue("odPremium", 0);
      }
    }
  }, [isBusinessTypeConditionsLobWise, policyDetailsBusinessType, activeStep]);

  // Object.values(summaryData)?.map(value =>
  //   Object.entries(value)?.map(
  //     value => (summaryDataKeyValue[`${value[0]}`] = value[1]),
  //   ),
  // );

  useEffect(() => {
    const memberCount = summaryDataKeyValue?.members?.length || 1;
    setMembers(prev => ({
      ...prev,
      members: memberCount,
    }));
    const paymentCount = summaryDataKeyValue?.payments?.length || 1;
    setMembers(prev => ({
      ...prev,
      payment: paymentCount,
    }));

    const employeeCount = summaryDataKeyValue?.members?.length || 1;
    setMembers(prev => ({
      ...prev,
      employee: employeeCount,
    }));
    const nomineeCount = summaryDataKeyValue?.nominee?.length || 1;
    setMembers(prev => ({
      ...prev,
      nominee: nomineeCount,
    }));
    const sourceCount = summaryDataKeyValue?.sourcing?.length || 1;
    setMembers(prev => ({
      ...prev,
      source: sourceCount,
    }));
    const addonCount =
      (Array.isArray(summaryDataKeyValue?.addOnPremiums) &&
        summaryDataKeyValue?.addOnPremiums?.length) ||
      1;
    setMembers(prev => ({
      ...prev,
      useAddEndorsementForPremiumDetails: addonCount,
      addOnPremiums: addonCount,
    }));
    summaryDataKeyValue?.addOnPremiums?.length
      ? setShowAddonBifurcation(true)
      : setShowAddonBifurcation(false);
  }, [JSON.stringify(summaryDataKeyValue)]);

  useEffect(() => {
    const finalObj = {};
    if (dropdownFields?.length && dateFields?.length) {
      Object.entries(summaryDataKeyValue || {})?.map(([key, value]) => {
        const multiSectionMapping = {
          members: "members",
          nominee: "nominee",
          sourcing: "sourcing",
          addOnPremiums: "addOnPremiums",
          payments: "payments",
        };
        const section = multiSectionMapping[key];
        if (
          [
            "members",
            "payments",
            "sourcing",
            "addOnPremiums",
            "nominee",
          ].includes(key)
        ) {
          const arr = [];
          Object.values(value || {})?.map((item, index) => {
            let obj = {};
            Object.entries(item || {})?.map(([memberKey, memberValue]) => {
              if (
                dropdownFields?.includes(memberKey) &&
                !customDropdownFields?.includes(memberKey)
              ) {
                obj[memberKey] = memberValue
                  ? {
                    label: memberValue,
                    value: memberValue,
                  }
                  : null;
              } else if (multiSelectFields?.includes(key)) {
                if (memberValue) {
                  const items = memberValue?.split("|")?.map(item => ({
                    label: item,
                    value: item,
                  }));
                  obj[memberKey] = items;
                } else {
                  obj[memberKey] = null;
                }
              } else if (fileFields?.includes(memberKey)) {
                dispatch(
                  setUploadedFileURLAction({
                    [`${section}[${index}].${memberKey}`]: memberValue,
                  }),
                );
                obj[memberKey] = memberValue;
              } else if (dateFields?.includes(memberKey)) {
                const isValidDate = moment(
                  memberValue,
                  moment.ISO_8601,
                  true,
                ).isValid();
                const isValidNumber = /^[0-9]+$/.test(memberValue);
                if (isValidDate && !isValidNumber && memberValue) {
                  obj[memberKey] = new Date(memberValue);
                } else {
                  obj[memberKey] = null;
                }
              } else {
                if (
                  !dateFields?.includes(memberKey) &&
                  !fileFields?.includes(memberKey)
                ) {
                  obj[memberKey] = memberValue;
                }
              }
            });
            arr?.push(obj);
          });
          if (arr?.length) {
            finalObj[section] = arr;
          }
        }
      });
    }
    !isEmpty(finalObj) && reset(finalObj);
  }, [
    JSON.stringify(summaryDataKeyValue),
    JSON.stringify(dropdownFields),
    JSON.stringify(dateFields),
  ]);

  useEffect(() => {
    if (dropdownFields?.length && dateFields?.length) {
      Object.entries(summaryDataKeyValue || {})?.map(([key, value]) => {
        if (
          ![
            "members",
            "payments",
            "nominee",
            "sourcing",
            "addOnPremiums",
          ].includes(key)
        ) {
          if (dateFields?.includes(key)) {
            const isValidDate = moment(value, moment.ISO_8601, true).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);
            if (isValidDate && !isValidNumber) {
              setValue(key, new Date(moment(value)));
            } else {
              setValue(key, null);
            }
          } else if (dropdownFields?.includes(key)) {
            setValue(
              key,
              value
                ? {
                  label: value,
                  value: value,
                }
                : null,
            );
          } else if (multiSelectFields?.includes(key)) {
            if (value) {
              const items = value?.split("|")?.map(item => ({
                label: item,
                value: item,
              }));
              setValue(key, items);
            } else {
              setValue(key, null);
            }
          } else if (fileFields?.includes(key)) {
            dispatch(
              setUploadedFileURLAction({
                [key]: value,
              }),
            );
            setValue(`${key}`, value);
          } else {
            !fileFields?.includes(key) &&
              !dateFields?.includes(key) &&
              setValue(key, value);
          }
        }
      });
    }
  }, [
    JSON.stringify(summaryDataKeyValue),
    JSON.stringify(dropdownFields),
    JSON.stringify(dateFields),
  ]);

  const clearCustomerData = () => {
    proposerDetailsFields?.map(item => {
      if (item != "customerId" && item != "typeofcustomer") {
        dropdownFields?.includes(item) || dateFields?.includes(item)
          ? setValue(item, null)
          : multiSelectFields?.includes(item)
            ? setValue(item, [])
            : setValue(item, "");
      }
    });
  };

  // Permanent Address CHANGE RESET LOGIC
  // useEffect(() => {
  //   if (addressCheck === "No" && summaryDataKeyValue?.addressCheck === "Yes") {
  //     setValue("addresspdone", null);
  //     setValue("addresspdtwo", null);
  //     setValue("pinCodetwo", null);
  //     setValue("statetwo", null);
  //     setValue("citytwo", null);
  //     setValue("landmarkpdtwo", null);
  //   }
  // }, [addressCheck]);
  // useEffect(() => {
  //   if (
  //     isSameAddress === "No" &&
  //     summaryDataKeyValue?.isSameAddress === "Yes"
  //   ) {
  //     setValue("addressLineonev", null);
  //     setValue("addressLinetwov", null);
  //     setValue("pincodeVd", null);
  //     setValue("stateVd", null);
  //     setValue("cityVd", null);
  //   }
  // }, [isSameAddress]);
  // Amit said to remove this.
  useEffect(() => {
    if (formName !== PROPOSER_DETAILS) {
      Object.values(watchNominee || {})?.forEach((item, index) => {
        Object.entries(item || {})?.map(() => {
          if (
            index === indexOfSelfNominee &&
            watch(`nominee[${index}].nomineeRelation.value`) === "Self"
          ) {
            setValue(
              `nominee[${indexOfSelfNominee}].titleNd`,
              watch("title")
                ? {
                  label: watch("title")?.label,
                  value: watch("title")?.value,
                }
                : null,
            );
            setValue(
              `nominee[${indexOfSelfNominee}].nomineeGender`,
              watch("gender")
                ? {
                  label: watch("gender")?.label,
                  value: watch("gender")?.value,
                }
                : null,
            );
            setValue(
              `nominee[${indexOfSelfNominee}].nomineeDob`,
              new Date(watch("dob")),
            );
            setValue(
              `nominee[${indexOfSelfNominee}].nomineeRemark`,
              watch("remark"),
            );
            setValue(
              `nominee[${indexOfSelfNominee}].nomineeFullname`,
              `${watch("firstName")?.trim() ?? ""} ${watch("middleName")?.trim() ?? ""} ${watch("lastName")?.trim() ?? ""} `,
            );
          }
        });
      });
    }
  }, [
    // JSON.stringify(watch(PROPOSER_FIELDS)),
    formName,
    watchNominee?.[currentField?.index]?.nomineeRelation?.label,
  ]);
  useEffect(() => {
    if (isTermLob || isInvestmentLob) return;
    if (formName !== PROPOSER_DETAILS) {
      Object.values(watchMembers || {})?.forEach((item, index) => {
        Object.entries(item || {})?.map(() => {
          if (watch(`members[${index}].relWithprop`)?.value === "Self") {
            setValue(
              `members[${indexOfSelfInsured}].insuredmemberName`,

              `${watch("firstName") ?? ""} ${watch("middleName") ?? ""} ${watch("lastName") ?? ""} `,
            );
            setValue(
              `members[${indexOfSelfInsured}].genderIMD`,
              watch("gender")
                ? {
                  label: watch("gender")?.label,
                  value: watch("gender")?.value,
                }
                : null,
            );
            setValue(
              `members[${indexOfSelfInsured}].dobIMD`,
              new Date(watch("dob")),
            );
            setValue(
              `members[${indexOfSelfInsured}].insuredContactnumber`,
              watch("mobileNo"),
            );
            setValue(
              `members[${indexOfSelfInsured}].remarkihd`,
              watch("remark"),
            );
            setValue(
              `members[${indexOfSelfInsured}].insuredeiaNumber`,
              watch("eiaNumber"),
            );
            setValue(
              `members[${indexOfSelfInsured}].insuredsumInsured`,

              watch("coverage"),
            );
            setValue(
              `members[${indexOfSelfInsured}].insuredeiaRepository`,
              watch("eiaRepository")
                ? {
                  label: watch("eiaRepository")?.label,
                  value: watch("eiaRepository")?.value,
                }
                : null,
            );
          } else if (
            watch(`members[${index}].relationshipwithProposer`)?.value ===
            "Self" &&
            isTravelLob
          ) {
            setValue(
              `members[${indexOfSelfTravelInsured}].firstName`,
              `${watch("firstName") ?? ""}`,
            );
            setValue(
              `members[${indexOfSelfTravelInsured}].lastName`,
              `${watch("lastName") ?? ""} `,
            );
            setValue(
              `members[${indexOfSelfTravelInsured}].gender`,
              watch("gender")
                ? {
                  label: watch("gender")?.label,
                  value: watch("gender")?.value,
                }
                : null,
            );
            setValue(
              `members[${indexOfSelfTravelInsured}].dateOfBirth`,
              new Date(watch("dob")),
            );
          } else if (watch(`members[${index}].relWithprop`)?.value !== "Self") {
            if (
              watch(`members[${index}].relWithprop`)?.value &&
              ["Family Floater", "Individual"]?.includes(
                watch("planType")?.label,
              )
            ) {
              setValue(
                `members[${index}].insuredsumInsured`,
                watch("coverage"),
              );
            }
            //  else {
            //   watch(`members[${index}].insuredsumInsured`) &&
            //     setValue(`members[${index}].insuredsumInsured`, null);
            // }
          }
        });
      });
    }
  }, [
    // JSON.stringify(watch(PROPOSER_FIELDS)),
    formName,
    watchMembers?.[currentField?.index]?.relWithprop?.value,
    watchMembers?.[currentField?.index]?.relationshipwithProposer?.value,
    currentField?.index,
    indexOfSelfInsured,
    // indexOfSelfTravelInsured,
    // proposerDetailsFromApi,
    // policyDetailsFromApi,
  ]);

  // useEffect(() => {
  //   Object.values(watchMembers || {})?.forEach((item, index) => {
  //     Object.entries(item || {})?.map(() => {
  //       if (watch(`members[${index}].relWithprop.value`) === "Self") {
  //         setValue(
  //           `members[${indexOfSelfInsured}].insuredmemberName`,
  //           `${proposerDetailsFromApi?.firstName}` +
  //             `${
  //               proposerDetailsFromApi?.middleName
  //                 ? " " + proposerDetailsFromApi?.middleName
  //                 : ""
  //             }` +
  //             `${
  //               proposerDetailsFromApi?.lastName
  //                 ? " " + proposerDetailsFromApi?.lastName
  //                 : ""
  //             }`,
  //         );
  //         setValue(`members[${indexOfSelfInsured}].genderIMD`, {
  //           label: proposerDetailsFromApi?.gender,
  //           value: proposerDetailsFromApi?.gender,
  //         });
  //         setValue(
  //           `members[${indexOfSelfInsured}].dobIMD`,
  //           new Date(proposerDetailsFromApi?.dob),
  //         );
  //         setValue(
  //           `members[${indexOfSelfInsured}].insuredContactnumber`,
  //           proposerDetailsFromApi?.mobileNo,
  //         );
  //       }
  //     });
  //   });
  // }, [
  //   watchMembers?.[currentField?.index]?.relWithprop?.value,
  //   indexOfSelfInsured,
  // ]);

  // useEffect(() => {
  //   Object.values(payment || {})?.forEach((item, index) => {
  //     Object.entries(item || {})?.map(() => {
  //       if (
  //         index === indexOfSelfPayment &&
  //         watch(`payment[${index}].paymentPaid.value`) === "Self"
  //       ) {
  //         setValue(
  //           `payment[${indexOfSelfPayment}].payerName`,
  //           `${proposerDetailsFromApi?.firstName}` +
  //             `${
  //               proposerDetailsFromApi?.middleName
  //                 ? " " + proposerDetailsFromApi?.middleName
  //                 : ""
  //             }` +
  //             `${
  //               proposerDetailsFromApi?.lastName
  //                 ? " " + proposerDetailsFromApi?.lastName
  //                 : ""
  //             }`,
  //         );
  //       }
  //     });
  //   });
  // }, [JSON.stringify(payment)]);

  const vehicleDataPrefilFromVersion =
    versionData?.data?.find(item => item?.version_name === version?.value) ||
    {};
  useEffect(() => {
    if (version?.value) {
      setValue(
        "cc",
        vehicleDataPrefilFromVersion?.cubic_capacity ||
        vehicleDetailsFromApi?.cc ||
        "",
      );
      setValue(
        "kwh",
        vehicleDataPrefilFromVersion?.kw || vehicleDetailsFromApi?.kwh || "",
      );
      setValue(
        "seatingCapacity",
        vehicleDataPrefilFromVersion?.seating_capacity ||
        vehicleDetailsFromApi?.seatingCapacity ||
        "",
      );
      setValue(
        "vehicleSegment",
        vehicleDataPrefilFromVersion?.segment_type
          ? {
            label: vehicleDataPrefilFromVersion?.segment_type,
            value: vehicleDataPrefilFromVersion?.segment_type,
          }
          : vehicleDetailsFromApi?.vehicleSegment
            ? {
              label: vehicleDetailsFromApi?.vehicleSegment,
              value: vehicleDetailsFromApi?.vehicleSegment,
            }
            : null,
      );
      setValue(
        "fuelType",
        vehicleDataPrefilFromVersion?.fuel_type
          ? {
            label: vehicleDataPrefilFromVersion?.fuel_type,
            value: vehicleDataPrefilFromVersion?.fuel_type,
          }
          : vehicleDetailsFromApi?.fuelType
            ? {
              label: vehicleDetailsFromApi?.fuelType,
              value: vehicleDetailsFromApi?.fuelType,
            }
            : null,
      );
    }
  }, [version?.value, vehicleDataPrefilFromVersion]);

  useEffect(() => {
    if (
      summaryData[SOURCING_DETAILS] &&
      summaryData[SOURCING_DETAILS]?.userId
    ) {
      setUserId(summaryData[SOURCING_DETAILS]?.userId);
    }
  }, [JSON.stringify(summaryData[SOURCING_DETAILS])]);

  //this entire thing needs debounce
  function extractVehicleCode(inputStr) {
    // Replace any special character or multiple spaces with a single space
    const resultStr = inputStr.replace(/[^a-zA-Z0-9]+/g, " ");
    const modifiedStr =
      resultStr.slice(0, 2) +
      (resultStr[2] !== " " ? " " : "") +
      resultStr.slice(2);
    return modifiedStr.slice(0, 5).toUpperCase().replace(/\s+/g, "-");
  }

  useEffect(() => {
    if (vehicleRegNo) {
      const rtoNumber = extractVehicleCode(vehicleRegNo);

      let masterTableField = masterTableData?.find(
        value => value?.data?.data?.[0]?.fieldSlug === "rto",
      );

      const isGivenRtoPresent = masterTableField?.data?.data?.[0]?.data?.find(
        value => value.optionKey === rtoNumber,
      );

      const regex = /^[a-zA-Z]{2}-\d{2}$/;
      regex.test(rtoNumber) && isGivenRtoPresent
        ? setValue("rto", { label: rtoNumber, value: rtoNumber, id: rtoNumber })
        : vehicleDetailsFromApi?.rto
          ? setValue("rto", {
            label: vehicleDetailsFromApi?.rto,
            value: vehicleDetailsFromApi?.rto,
            id: vehicleDetailsFromApi?.rto,
          })
          : setValue("rto", null);
    }
  }, [vehicleRegNo]);

  useEffect(() => {
    if (medicalHistory?.label === "No") {
      setValue("uploadDocument", null);
      const newFiles = { ...files };
      delete newFiles.uploadDocument;
      setFiles(newFiles);
      dispatch(setUploadedFileURLAction({ uploadDocument: null }));
    }
  }, [medicalHistory?.label]);

  // to remove selected file conditionally for insured members
  useEffect(() => {
    if (
      watch(`members[${currentField?.index}].medicalHistoryim`)?.label &&
      watch(`members[${currentField?.index}].medicalHistoryim`)?.label !== "Yes"
    ) {
      setValue(`members[${currentField?.index}].uploadDocumentim`, null);
      const newFiles = { ...files };
      delete newFiles[`members[${currentField?.index}].uploadDocumentim`];
      setFiles(newFiles);
      dispatch(
        setUploadedFileURLAction({
          [`members[${currentField?.index}].uploadDocumentim`]: null,
        }),
      );
    }
  }, [watchMembers?.[currentField?.index]?.medicalHistoryim?.value]);

  useEffect(() => {
    if (policyNo || !!policyNo?.length) {
      checkPolicyPresent({
        lobId,
        policyNumber: policyNo,
      });
    }
    if (previousPolicynum || !!previousPolicynum?.length) {
      checkPrevPolicyPresent({
        lobId,
        previousPolicynum: previousPolicynum,
      });
    }
  }, [policyNo, previousPolicynum]);

  const memberBMIData =
    Array.isArray(watchMembers) &&
    watchMembers.map(item => ({
      weight: item?.weightImd,
      height: item?.heightImd,
    }));

  useEffect(() => {
    //bmi calculation
    if (weight && height) {
      const heightInMeters = height / 100;
      const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      setValue("bmi", bmi);
    }
    //bmi calculation
    if (weightImd && heightImd) {
      const heightInMeters = heightImd / 100;
      const bmi = (weightImd / (heightInMeters * heightInMeters)).toFixed(2);
      setValue("bmiImd", bmi);
    }
    if (weightImd && heightImd) {
      const heightUnit = watch(`heighttype`)?.label;
      if (watch(`weightImd`) && watch(`heightImd`) && heightUnit === "In Cm") {
        const heightInMeters = watch(`heightImd`) / 100;
        const bmi = (
          watch(`weightImd`) /
          (heightInMeters * heightInMeters)
        ).toFixed(2);
        setValue(`bmiImd`, bmi);
      }
      if (
        watch(`weightImd`) &&
        watch(`heightImd`) &&
        heightUnit === "In Feet"
      ) {
        const heightInFeetInch = watch(`heightImd`);
        const isInchPresent = !!heightInFeetInch?.split("'")?.[1];
        const inchPartToFeet =
          heightInFeetInch?.split("'")?.[1]?.toString()?.replace(`"`, "") / 12;
        const heightInFeet = isInchPresent
          ? +heightInFeetInch?.split("'")?.[0] + +inchPartToFeet
          : +heightInFeetInch?.split("'")?.[0];
        const finalHeightInMeters = heightInFeet * 0.3048;

        const bmi = (
          watch(`weightImd`) /
          (finalHeightInMeters * finalHeightInMeters)
        ).toFixed(2);
        setValue(`bmiImd`, bmi);
      }
    }
    if (watchMembers?.length) {
      watchMembers?.forEach((member, index) => {
        const heightUnit = watch(`members[${index}].heighttype`)?.label;
        if (
          watch(`members[${index}].weightImd`) &&
          watch(`members[${index}].heightImd`) &&
          heightUnit === "In Cm"
        ) {
          const heightInMeters = watch(`members[${index}].heightImd`) / 100;
          const bmi = (
            watch(`members[${index}].weightImd`) /
            (heightInMeters * heightInMeters)
          ).toFixed(2);
          setValue(`members[${index}].bmiImd`, bmi);
        }
        if (
          watch(`members[${index}].weightImd`) &&
          watch(`members[${index}].heightImd`) &&
          heightUnit === "In Feet"
        ) {
          const heightInFeetInch = watch(`members[${index}].heightImd`);
          const isInchPresent = !!heightInFeetInch?.split("'")?.[1];
          const inchPartToFeet =
            heightInFeetInch?.split("'")?.[1]?.toString()?.replace(`"`, "") /
            12;
          const heightInFeet = isInchPresent
            ? +heightInFeetInch?.split("'")?.[0] + +inchPartToFeet
            : +heightInFeetInch?.split("'")?.[0];
          const finalHeightInMeters = heightInFeet * 0.3048;

          const bmi = (
            watch(`members[${index}].weightImd`) /
            (finalHeightInMeters * finalHeightInMeters)
          ).toFixed(2);
          setValue(`members[${index}].bmiImd`, bmi);
        }
      });
    }
  }, [weight, height, JSON.stringify(watchMembers), weightImd, heightImd]);
  // useEffect(() => {
  //   if (
  //     insuredMemberDetailsFromApi?.[currentField?.index]?.heighttype !==
  //     watchMembers?.[currentField?.index]?.heighttype
  //   ) {
  //     setValue(`members[${currentField?.index}].bmiImd`, null);
  //     setValue(`members[${currentField?.index}].heightImd`, null);
  //   }
  // }, [watch(`members[${currentField?.index}].heighttype`)]);

  useEffect(() => {
    const timer = setTimeout(() => {
      Object.entries(errors)?.map(([key, values]) => {
        if (Array.isArray(values)) {
          values?.map((arr, arrIndex) => {
            Object.keys(arr)?.map(arrKey => {
              if (
                _.isEmpty(watch(`${key}.[${arrIndex}].${arrKey}`)) ||
                !_.isEmpty(watch(`${key}.[${arrIndex}].${arrKey}`))
              ) {
                trigger(`${key}.[${arrIndex}].${arrKey}`);
              }
            });
          });
        } else {
          if (_.isEmpty(watch(key)) || !_.isEmpty(watch(key))) {
            trigger(key);
          }
        }
      });
    }, 500);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [JSON.stringify(watch()), JSON.stringify(Object.keys(errors))]); // will find better way for this dependancy

  useEffect(() => {
    if (dob) {
      const dobDate = moment(dob);
      const age = moment().diff(dobDate, "years");
      setValue("age", age);
    }
    if (dobIMD) {
      const today = moment();
      const birthDate = moment(dobIMD, "YYYY-MM-DD");
      const years = today.diff(birthDate, "years");
      setValue("lifeAssuredage", years);
    }
  }, [dob, dobIMD]);

  // useEffect(() => {
  //   if (!proposerDetailsFromApi?.addressCheck && !addressCheck) {
  //     setValue("addressCheck", "Yes");
  //   }
  // }, [addressCheck, proposerDetailsFromApi?.addressCheck]);
  // useEffect(() => {
  //   if (!vehicleDetailsFromApi?.isSameAddress && !isSameAddress) {
  //     setValue("isSameAddress", "Yes");
  //   }
  // }, [isSameAddress, vehicleDetailsFromApi?.isSameAddress]);

  useEffect(() => {
    if (addressCheck) {
      if (addressCheck === "Yes") {
        setValue("citytwo", sourcingCity);
        setValue("addresspdtwo", addressLinetwo);
        setValue("pinCodetwo", sourcingPinCode);
        setValue("pincodetwo", sourcingPinCode);
        setValue("addresspdone", addressLineone);
        setValue("statetwo", sourcingState);
        setValue("landmarkpdtwo", landmark);
      }
      // else {
      //   setValue(
      //     "citytwo",
      //     proposerDetailsFromApi?.citytwo
      //       ? {
      //           label: proposerDetailsFromApi?.citytwo,
      //           value: proposerDetailsFromApi?.citytwo,
      //         }
      //       : null,
      //   );
      //   setValue("addresspdtwo", proposerDetailsFromApi?.addresspdtwo || null);
      //   setValue("pinCodetwo", proposerDetailsFromApi?.pinCodetwo || null);
      //   setValue("pincodetwo", proposerDetailsFromApi?.pincodetwo || null);
      //   setValue("addresspdone", proposerDetailsFromApi?.addresspdone || null);
      //   setValue(
      //     "statetwo",
      //     proposerDetailsFromApi?.statetwo
      //       ? {
      //           label: proposerDetailsFromApi?.statetwo,
      //           value: proposerDetailsFromApi?.statetwo,
      //         }
      //       : null,
      //   );
      //   setValue(
      //     "landmarkpdtwo",
      //     proposerDetailsFromApi?.landmarkpdtwo || null,
      //   );
      // }
    }
  }, [
    addressCheck,
    sourcingPinCode,
    sourcingState,
    sourcingCity,
    addressLineone,
    addressLinetwo,
    landmark,
  ]);

  useEffect(() => {
    if (locationofRisk) {
      if (locationofRisk?.label === "Yes") {
        setValue(
          "addressLineoneinsured",
          proposerDetailsFromApi?.addressLineone,
        );
        setValue(
          "addressLinetwoinsured",
          proposerDetailsFromApi?.addressLinetwo,
        );
        setValue("pincodeinsured", proposerDetailsFromApi?.sourcingPinCode);
        setValue("stateinsured", {
          label: proposerDetailsFromApi?.sourcingState,
          value: proposerDetailsFromApi?.sourcingState,
        });
        setValue("cityinsured", {
          label: proposerDetailsFromApi?.sourcingCity,
          value: proposerDetailsFromApi?.sourcingCity,
        });
      } else {
        setValue(
          "addressLineoneinsured",
          propertyDetailsFromApi?.addressLineoneinsured || null,
        );
        setValue(
          "addressLinetwoinsured",
          propertyDetailsFromApi?.addressLinetwoinsured || null,
        );
        setValue(
          "pincodeinsured",
          propertyDetailsFromApi?.pincodeinsured || null,
        );
        setValue("stateinsured", propertyDetailsFromApi?.stateinsured || null);
        setValue("cityinsured", propertyDetailsFromApi?.cityinsured || null);
      }
    }
  }, [locationofRisk?.label, proposerDetailsFromApi]);

  useEffect(() => {
    //   //   if (activeFormTitle === VEHICLE_DETAILS) {
    //   //     if (isSameAddress === "Yes") {
    //   //       setValue("addressLineonev", proposerDetailsFromApi?.addressLineone);
    //   //       setValue("addressLinetwov", proposerDetailsFromApi?.addressLinetwo);
    //   //       setValue("pincodeVd", proposerDetailsFromApi?.sourcingPinCode);
    //   //       setValue("stateVd", proposerDetailsFromApi?.sourcingState);
    //   //       setValue("cityVd", proposerDetailsFromApi?.sourcingCity);
    //   //     } else {
    //   //       setValue(
    //   //         "addressLineonev",
    //   //         vehicleDetailsFromApi?.addressLineonev || null,
    //   //       );
    //   //       setValue(
    //   //         "addressLinetwov",
    //   //         vehicleDetailsFromApi?.addressLinetwov || null,
    //   //       );
    //   //       setValue("pincodeVd", vehicleDetailsFromApi?.pincodeVd || null);
    //   //       setValue(
    //   //         "stateVd",
    //   //         vehicleDetailsFromApi?.stateVd
    //   //           ? {
    //   //               label: vehicleDetailsFromApi?.stateVd,
    //   //               value: vehicleDetailsFromApi?.stateVd,
    //   //             }
    //   //           : null,
    //   //       );
    //   //       setValue(
    //   //         "cityVd",
    //   //         vehicleDetailsFromApi?.cityVd
    //   //           ? {
    //   //               label: vehicleDetailsFromApi?.cityVd,
    //   //               value: vehicleDetailsFromApi?.cityVd,
    //   //             }
    //   //           : null,
    //   //       );
    //   //     }
    //   //   }
    if (isMarineLob) {
      if (isSameAddress === "Yes") {
        if (typeofcustomer?.label === "Company") {
          setValue(
            "addresspdone",
            addressLineone || proposerDetailsFromApi?.addressLineone,
          );
          setValue(
            "addresspdtwo",
            addressLinetwo || proposerDetailsFromApi?.addressLinetwo,
          );
          setValue(
            "landmarkpdtwo",
            landmark || proposerDetailsFromApi?.landmark,
          );

          setValue("addressLineonev", proposerDetailsFromApi?.addressLineone);
          setValue("addressLinetwov", proposerDetailsFromApi?.addressLinetwo);
          setValue(
            "pincodeVd",
            pincode ||
            sourcingPinCode ||
            proposerDetailsFromApi?.pincode ||
            proposerDetailsFromApi?.sourcingPinCode,
          );
          setValue(
            "stateVd",
            state ||
            sourcingState ||
            proposerDetailsFromApi?.state ||
            proposerDetailsFromApi?.sourcingState,
          );
          setValue(
            "cityVd",
            city ||
            sourcingCity ||
            proposerDetailsFromApi?.city ||
            proposerDetailsFromApi?.sourcingCity,
          );
        }
        if (typeofcustomer?.label === "Individual") {
          setValue(
            "addresspdone",
            addressLineone || proposerDetailsFromApi?.addressLineone,
          );
          setValue(
            "addresspdtwo",
            addressLinetwo || proposerDetailsFromApi?.addressLinetwo,
          );
          setValue(
            "landmarkpdtwo",
            landmark || proposerDetailsFromApi?.landmark,
          );
          setValue(
            "pincodeVd",
            sourcingPinCode || proposerDetailsFromApi?.sourcingPinCode,
          );

          // setValue("addressLineonev", proposerDetailsFromApi?.addressLineone);
          // setValue("addressLinetwov", proposerDetailsFromApi?.addressLinetwo);

          setValue(
            "stateVd",
            sourcingState || proposerDetailsFromApi?.sourcingState,
          );
          setValue(
            "cityVd",
            sourcingCity || proposerDetailsFromApi?.sourcingCity,
          );
        }
      }
    } else if (isSameAddress === "Yes") {
      setValue(
        "addressLineonev",
        watch("addressLineone") ?? proposerDetailsFromApi?.addressLineone,
      );
      setValue(
        "addressLinetwov",
        watch("addressLinetwo") ?? proposerDetailsFromApi?.addressLinetwo,
      );
      setValue(
        "pincodeVd",
        watch("sourcingPinCode") ?? proposerDetailsFromApi?.sourcingPinCode,
      );
      setValue(
        "stateVd",
        watch("sourcingState") ?? proposerDetailsFromApi?.sourcingState,
      );
      setValue(
        "cityVd",
        watch("sourcingCity") ?? proposerDetailsFromApi?.sourcingCity,
      );
      setValue(
        "landmarkpdtwo",
        watch("landmark") ?? proposerDetailsFromApi?.sourcingCity,
      );
      // else {
      //   setValue("addresspdone", null);
      //   setValue("addresspdtwo", null);
      //   setValue("landmarkpdtwo", null);

      //   setValue("addressLineonev", null);
      //   setValue("addressLinetwov", null);
      //   setValue(
      //     "pincodeVd",
      //     proposerDetailsFromApi?.pincodeVd
      //       ? proposerDetailsFromApi?.pincodeVd
      //       : null,
      //   );
      //   setValue(
      //     "stateVd",
      //     proposerDetailsFromApi?.stateVd
      //       ? {
      //           label: proposerDetailsFromApi?.stateVd,
      //           value: proposerDetailsFromApi?.stateVd,
      //         }
      //       : null,
      //   );
      //   setValue(
      //     "cityVd",
      //     proposerDetailsFromApi?.cityVd
      //       ? {
      //           label: proposerDetailsFromApi?.cityVd,
      //           value: proposerDetailsFromApi?.cityVd,
      //         }
      //       : null,
      //   );
      // }
    }
    // else {
    //   //       setValue("addressLineonev", null);
    //   //       setValue("addressLinetwov", null);
    //   //       setValue(
    //   //         "pincodeVd",
    //   //         proposerDetailsFromApi?.pincodeVd
    //   //           ? proposerDetailsFromApi?.pincodeVd
    //   //           : null,
    //   //       );
    //   //       setValue(
    //   //         "stateVd",
    //   //         proposerDetailsFromApi?.stateVd
    //   //           ? {
    //   //               label: proposerDetailsFromApi?.stateVd,
    //   //               value: proposerDetailsFromApi?.stateVd,
    //   //             }
    //   //           : null,
    //   //       );
    //   //       setValue(
    //   //         "cityVd",
    //   //         proposerDetailsFromApi?.cityVd
    //   //           ? {
    //   //               label: proposerDetailsFromApi?.cityVd,
    //   //               value: proposerDetailsFromApi?.cityVd,
    //   //             }
    //   //           : null,
    //   //       );
    //   //     }
    //   //   }
    //   if (
    //     activeFormTitle !== VEHICLE_DETAILS &&
    //     activeFormTitle !== PROPOSER_DETAILS
    //   ) {

    //  else if (
    //       isSameAddress === "No" &&
    //       summaryDataKeyValue?.isSameAddress === "Yes"
    //     ) {
    //       setValue("addressLineonev", null);
    //       setValue("addressLinetwov", null);
    //       setValue("pincodeVd", null);
    //       setValue("stateVd", null);
    //       setValue("cityVd", null);
    //     } else {
    //       setValue(
    //         "addressLineonev",
    //         proposerDetailsFromApi?.addressLineonev || null,
    //       );
    //       setValue(
    //         "addressLinetwov",
    //         proposerDetailsFromApi?.addressLinetwov || null,
    //       );
    //       setValue("pincodeVd", proposerDetailsFromApi?.pincodeVd || null);
    //       setValue("stateVd", proposerDetailsFromApi?.stateVd || null);
    //       setValue("cityVd", proposerDetailsFromApi?.cityVd || null);
    //     }
    //   }
  }, [
    isSameAddress,
    proposerDetailsFromApi,
    JSON.stringify(
      watch([
        "addressLineone",
        "addressLinetwo",
        "sourcingPinCode",
        "sourcingState",
        "sourcingCity",
        "landmark",
      ]),
    ),
  ]);

  useEffect(() => {
    if (pastInsurance?.label === "Yes") {
      dispatch(setIsPrevPolicyVisibleAction(true));
    }
    if (pastInsurance?.label === "No" || !pastInsurance?.label) {
      dispatch(setIsPrevPolicyVisibleAction(false));
    }
  }, [pastInsurance?.label]);

  const shape = {}; //globally defined because of yup lazy multiple render bug
  const paymentShape = {}; //globally defined because of yup lazy multiple render bug

  let dynamicYupValidate = yup.object().shape({
    bookDate: mandatoryFieldSlugs?.includes("bookDate")
      ? yup
        .date()
        .required("Policy Book Date is required")
        .typeError("Date must be a valid date")
        .test(`is-valid-bookDate`, (value, ctx) => {
          if (new Date(value) < new Date("1900-01-01")) {
            return ctx.createError({
              message: "Date cannot be before 01/01/1900",
            });
          }
          return true;
        })
      : yup.mixed().nullable().notRequired(),
    policystDate: mandatoryFieldSlugs?.includes("policystDate")
      ? yup
        .date()
        .required("Policy Start Date is required")
        .min(yup.ref("bookDate"), "Must be after book date")
        .typeError("Date must be a valid date")
        .test(`is-valid-policyStartDate`, (value, ctx) => {
          if (new Date(value) < new Date("1900-01-01")) {
            return ctx.createError({
              message: "Date cannot be before 01/01/1900",
            });
          }
          return true;
        })
        .test(
          `is-valid-policyStartDate`,
          "Can not be same as policy expiry date",
          function (value) {
            if (!value) return true;
            const { policyExpirydate } = this.parent;
            return !moment(value).isSame(moment(policyExpirydate));
          },
        )
      : yup.mixed().nullable().notRequired(),
    policyExpirydate: mandatoryFieldSlugs?.includes("policyExpirydate")
      ? yup
        .date()
        .required("Policy Expiry Date is required")
        .typeError("Date must be a valid date")
        .min(yup.ref("policystDate"), "Must be after start date")
        .test(`is-valid-policyExpiryDate`, (value, ctx) => {
          if (new Date(value) < new Date("1900-01-01")) {
            return ctx.createError({
              message: "Date cannot be before 01/01/1900",
            });
          }
          return true;
        })
        .test(
          `is-valid-policyStartDate`,
          "Can not be same as policy start date",
          function (value) {
            if (!value) return true;
            const { policystDate } = this.parent;
            return !moment(value).isSame(moment(policystDate));
          },
        )
      : yup.mixed().nullable().notRequired(),
    drivingLicence:
      mandatoryFieldSlugs?.includes("drivingLicence") ||
        optionalFieldSlugs?.includes("drivingLicence")
        ? yup
          .string()
          .nullable()
          .test(
            "valid-license",
            "Please enter valid driving licence number",
            function (value) {
              const regex = /^(?:[A-Z]{2}[ -]?\d{2}|\w{4}[ -]?)\d{4}\d{7}$/;
              if (value && !regex.test(value)) {
                return false;
              }
              return true;
            },
          )
        : yup.string().notRequired(),
    passportNumber:
      mandatoryFieldSlugs?.includes("passportNumber") ||
        optionalFieldSlugs?.includes("passportNumber")
        ? yup
          .string()
          .nullable()
          .test(
            "valid-passport",
            "Please enter valid passport number",
            function (value) {
              const regex = /^[A-Z][1-9][0-9]\s?[0-9]{4}[1-9]$/;
              if (value && !regex.test(value)) {
                return false;
              }
              return true;
            },
          )
        : yup.string().notRequired(),
    pucExpirydate:
      mandatoryFieldSlugs?.includes("pucStartdate") ||
        optionalFieldSlugs?.includes("pucStartdate")
        ? yup
          .date()
          .typeError("Date must be a valid date")
          .min(yup.ref("pucStartdate"), "Must be after PUC start date")
          .test(`is-valid-pucExpiryDate`, (value, ctx) => {
            if (new Date(value) < new Date("1900-01-01")) {
              return ctx.createError({
                message: "Date cannot be before 01/01/1900",
              });
            }
            return true;
          })
        : yup.mixed().nullable().notRequired(),
    odexpirydate:
      optionalFieldSlugs?.includes("odexpirydate") &&
      yup
        .date()
        .nullable()
        .typeError("Date must be a valid date")
        .test(`odexpirydate`, "OD Expiry Date is required", function (value) {
          const { businessType } = this.parent;
          if (
            isBusinessTypeConditionsLobWise &&
            ![
              TP_1_YEAR,
              TP_LONG_TERM_5_YEARS,
              TP_LONG_TERM_3_YEARS,
              SATP,
            ]?.includes(businessType?.label)
          ) {
            return value;
          } else {
            return true;
          }
        }),
    tpexpirydate:
      optionalFieldSlugs?.includes("tpexpirydate") &&
      yup
        .date()
        .nullable()
        .typeError("Date must be a valid date")
        .test(`tpexpirydate`, "TP Expiry Date is required", function (value) {
          if (isBusinessTypeConditionsLobWise) {
            return value;
          } else {
            return true;
          }
        }),
    odPremium:
      optionalFieldSlugs?.includes("odPremium") &&
      yup
        .string()
        .nullable()
        .test(`odPremium`, "OD Premium is required", function (value) {
          if (
            isBusinessTypeConditionsLobWise &&
            ![
              TP_1_YEAR,
              TP_LONG_TERM_5_YEARS,
              TP_LONG_TERM_3_YEARS,
              SATP,
            ]?.includes(policyDetailsBusinessType)
          ) {
            return value;
          } else {
            return true;
          }
        }),
    // tpPremium:
    //   optionalFieldSlugs?.includes("tpPremium") &&
    //   yup
    //     .string()
    //     .nullable()
    //     .test(`tpPremium`, "TP Premium is required", function (value) {
    //       if (isBusinessTypeConditionsLobWise) {
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    emailId: mandatoryFieldSlugs?.includes("emailId")
      ? yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
          "Please enter valid email id",
        )
      : yup.string().notRequired(),
    alternateId:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS
        ? yup.lazy(value => {
          if (value) {
            return yup
              .string()
              .email("Please enter a valid email")
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                "Please enter valid email id",
              )
              .test(`is-email-duplicate`, function (value, ctx) {
                const { emailId } = this.parent;

                if (value === emailId) {
                  return ctx.createError({
                    message: "Cannot be same as email id",
                  });
                }
                return true;
              });
          }
          return yup.mixed().notRequired();
        })
        : yup.string().notRequired(),
    panCardno: mandatoryFieldSlugs?.includes("panCardno")
      ? yup
        .string()
        .required("PAN is required")
        .matches(
          /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
          "PAN number invalid",
        )
        ?.trim()
      : yup.string().nullable().notRequired()?.trim(),
    nomineePanno: mandatoryFieldSlugs?.includes("nomineePanno")
      ? yup
        .string()
        .required("PAN is required")
        .matches(
          /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
          "PAN number invalid",
        )
        ?.trim()
      : yup
        .string()
        .matches(
          /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
          "PAN number invalid",
        )
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional(),
    members:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === INSURED_MEMBER_DETAILS ||
        cardTitlesPrevPolicyToggle?.[activeStep - 1] === EMPLOYEE_DETAILS
        ? yup.array().of(
          yup.lazy(value => {
            const objectKeys =
              value &&
              Object.keys(value)?.filter(value =>
                mandatoryFieldSlugs?.includes(value),
              );

            const getRelevantLabel = fieldSlug => {
              const relevantLabel = (multipleMembers || multipleEmployee)
                ?.flat(2)
                ?.find(value => value.key === fieldSlug);
              return relevantLabel?.label;
            };

            objectKeys?.forEach(key => {
              if (dropdownFields?.includes(key)) {
                shape[key] = yup
                  .mixed()
                  .required(`${getRelevantLabel(key)} is required`);
              } else if (multiSelectFields?.includes(key)) {
                shape[key] = yup
                  .mixed()
                  .required(`${getRelevantLabel(key)} is required`);
              } else if (dateFields?.includes(key)) {
                shape[key] = yup
                  .string()
                  .typeError("Invalid Date!")
                  .required(`${getRelevantLabel(key)} is required`)
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v));
              } else if (key === "insuredContactnumber") {
                shape[key] = yup
                  .string()
                  .required(`${getRelevantLabel(key)} is required`)
                  .length(
                    10,
                    `${getRelevantLabel(key)} must be 10 characters`,
                  );
              } else if (key === "relationId") {
                shape[key] = yup
                  .string()
                  .required("Relation ID is required")
                  .test(
                    "relationId",
                    "Relation ID must be unique for each member",
                    function () {
                      let relIds = [
                        ...Array(
                          watch("members") ? watch("members").length : 1,
                        ),
                      ]
                        .map((v, i) => watch(`members[${i}].relationId`))
                        .filter(i => i);
                      let temp2 = _.uniq([...relIds]).filter(i => i);
                      let uniqueRelIds = relIds.length !== temp2.length;

                      return !uniqueRelIds;
                    },
                  );
              } else {
                shape[key] = yup
                  .string()
                  .required(`${getRelevantLabel(key)} is required`);
              }
            });

            return yup.object().shape(shape);
          }),
        )
        : yup.mixed().notRequired(),
    payment:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === PAYMENT_DETAILS
        ? yup.array().of(
          yup.lazy(value => {
            const objectKeys =
              value &&
              Object.keys(value)?.filter(value =>
                mandatoryFieldSlugs?.includes(value),
              );

            const getRelevantLabel = fieldSlug => {
              const relevantLabel = multiplePayment
                ?.flat(2)
                ?.find(value => value.key === fieldSlug);
              return relevantLabel?.label;
            };

            objectKeys?.forEach(key => {
              if (dropdownFields?.includes(key)) {
                paymentShape[key] = yup
                  .mixed()
                  .required(`${getRelevantLabel(key)} is required`);
              } else if (multiSelectFields?.includes(key)) {
                paymentShape[key] = yup
                  .mixed()
                  .required(`${getRelevantLabel(key)} is required`);
              } else if (dateFields?.includes(key)) {
                paymentShape[key] = yup
                  .string()
                  .typeError("Invalid Date!")
                  .required(`${getRelevantLabel(key)} is required`)
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v));
              } else {
                paymentShape[key] = yup
                  .string()
                  .required(`${getRelevantLabel(key)} is required`);
              }
            });

            return yup.object().shape(paymentShape);
          }),
        )
        : yup.mixed().notRequired(),
    policyNo: mandatoryFieldSlugs?.includes("policyNo")
      ? yup
        .string()
        .required("Policy number is required")
        .test(`is-policy-duplicate`, (value, ctx) => {
          if (
            !!value?.length &&
            !!prevPolicyDetailsFromApi?.previousPolicynum?.length &&
            value === prevPolicyDetailsFromApi?.previousPolicynum
          ) {
            return ctx.createError({
              message:
                "Policy number cannot be same as previous policy number",
            });
          }
          return true;
        })
      : yup.string().notRequired(),
    previousPolicynum:
      mandatoryFieldSlugs?.includes("previousPolicynum") ||
        optionalFieldSlugs?.includes("previousPolicynum")
        ? yup.string().test(`is-prev-policy-duplicate`, (value, ctx) => {
          if (
            !!value?.length &&
            !!policyDetailsFromApi?.policyNo?.length &&
            value === policyDetailsFromApi?.policyNo
          ) {
            return ctx.createError({
              message:
                "Previous policy no. cannot be same as current policy number",
            });
          }
          return true;
        })
        : yup.string().notRequired(),
    alternateMobileno:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS
        ? yup.lazy(value => {
          if (value) {
            return yup
              .string()
              .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
              .min(10, "Mobile No. should be 10 digits")
              .max(10, "Mobile No. should be 10 digits")
              .test(`is-mobile-number-duplicate`, function (value, ctx) {
                const { mobileNo } = this.parent;
                if (value === mobileNo) {
                  return ctx.createError({
                    message: "Cannot be same as mobile number",
                  });
                }
                return true;
              });
          }
          return yup.mixed().notRequired();
        })
        : yup.string().notRequired(),
    nomineePan:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === NOMINEE_DETAILS
        ? yup.lazy(value => {
          if (value) {
            return yup
              .string()
              .matches(
                /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                "PAN number invalid",
              );
          }
          return yup.mixed().notRequired();
        })
        : yup.string().notRequired(),
    aadharCardno:
      // cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS &&
      mandatoryFieldSlugs?.includes("aadharCardno")
        ? yup
          .string()
          .required("Aadhar card no. is required")
          .matches(/^\d{4}\d{4}\d{4}$/, "Aadhaar number is invalid")
        : yup.lazy(value => {
          if (value) {
            return yup
              .string()
              .matches(/^\d{4}\d{4}\d{4}$/, "Aadhaar number is invalid");
          }
          return yup.mixed().notRequired();
        }),
    // : yup.string().notRequired(),
    nomineeAadharno:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === NOMINEE_DETAILS
        ? yup.lazy(value => {
          if (value) {
            return yup
              .string()
              .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid");
          }
          return yup.mixed().notRequired();
        })
        : yup.string().notRequired(),
    // engineNo: mandatoryFieldSlugs?.includes("engineNo")
    //   ? yup
    //       .string()
    //       .required("Engine number is required")
    //       .matches(
    //         /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //         "Invalid engine number "
    //       )
    //       .min(10, "Invalid engine number")
    //   : yup.lazy((value) => {
    //       if (value) {
    //         return yup
    //           .string()
    //           .matches(
    //             /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //             "Invalid engine number"
    //           )
    //           .min(10, "Invalid engine number");
    //       }
    //       return yup.mixed().notRequired();
    //     }),
    // chassisNo: mandatoryFieldSlugs?.includes("chassisNo")
    //   ? yup
    //       .string()
    //       .required("Chassis number is required")
    //       .matches(
    //         /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //         "Invalid chassis number"
    //       )
    //       .min(10, "Invalid chassis number")
    //   : yup.lazy((value) => {
    //       if (value) {
    //         return yup
    //           .string()
    //           .matches(
    //             /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //             "Invalid chassis number"
    //           )
    //           .min(10, "Invalid engine number");
    //       }
    //       return yup.mixed().notRequired();
    //     }),
    coverage:
      mandatoryFieldSlugs?.includes("coverage") &&
      yup.string().test("coverage", function (value) {
        const { planType } = this.parent;
        const plan = planType?.value?.trim();
        if (!value || isNaN(value)) {
          return this.createError({
            message: `Coverage is required`,
            path: this.path,
          });
        }
        if (
          plan === PLAN_TYPE_SOOKSHMA_UDHYAM ||
          plan === PLAN_TYPE_LAGHU_UDHYAM
        ) {
          if (plan === PLAN_TYPE_SOOKSHMA_UDHYAM && value > 50000000) {
            return this.createError({
              message: "Coverage should not exceed 5 Cr",
              path: this.path,
            });
          }
          if (plan === PLAN_TYPE_LAGHU_UDHYAM && value > 500000000) {
            return this.createError({
              message: "Coverage should not exceed 50 Cr",
              path: this.path,
            });
          }
        }
        return true;
      }),
  });

  useEffect(() => {
    if (premiumPaidby?.label === "Self") {
      setValue(
        "premiumPayername",
        `${firstName ? firstName : ""} ${middleName ? middleName : ""} ${lastName ? lastName : ""
        }`,
      );
    }
  }, [premiumPaidby?.label]);

  useEffect(() => {
    if (benificiaryRelationshiptopolicyholder?.label === "Self") {
      setValue(
        "beneficiaryName",
        proposerDetailsFromApi?.firstName +
        " " +
        proposerDetailsFromApi?.middleName +
        " " +
        proposerDetailsFromApi?.lastName,
      );
      setValue("benificiarycontactNumber", proposerDetailsFromApi?.mobileNo);
    } else {
      setValue("beneficiaryName", "");
      setValue("benificiarycontactNumber", "");
    }
  }, [benificiaryRelationshiptopolicyholder?.label]);

  useEffect(() => {
    //dynamically generates yup schema based on current activeStep
    let validationSchema = [
      INSURED_MEMBER_DETAILS,
      PAYMENT_DETAILS,
      EMPLOYEE_DETAILS,
    ].includes(cardTitlesPrevPolicyToggle?.[activeStep - 1])
      ? yup.object().shape({})
      : !CustomerTypeCompany && activeFormTitle === PROPOSER_DETAILS
        ? generateDynamicYupSchema(
          mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.filter(
            obj => !companyFields.includes(obj?.key),
          ),
        )
        : hideGrihaFields?.includes(policyDetailsPlanType) &&
          activeFormTitle === PROPERTY_DETAILS
          ? generateDynamicYupSchema(
            mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.filter(
              obj => !grihaRakshaFields.includes(obj?.key),
            ),
          )
          : policyDetailsPlanType === PLAN_TYPE_GRIHA_RAKSHA &&
            activeFormTitle === PROPERTY_DETAILS
            ? generateDynamicYupSchema(
              mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.filter(
                obj => !fieldsOtherThanGrihaRaksha.includes(obj?.key),
              ),
            )
            : generateDynamicYupSchema(
              mandatoryFieldsPrevPolicyToggle?.[activeStep - 1],
            );

    let optionalSchema = [
      INSURED_MEMBER_DETAILS,
      PAYMENT_DETAILS,
      EMPLOYEE_DETAILS,
    ].includes(cardTitlesPrevPolicyToggle?.[activeStep - 1])
      ? yup.object().shape({})
      : generateDynamicOptionalYupSchema(
        optionalFieldsPrevPolicyToggle?.[activeStep - 1],
      );

    //merges mandatory and non-mandatory validations
    const mergedSchema = optionalSchema
      ? validationSchema.concat(optionalSchema)
      : validationSchema;

    //merges hardcoded validations with main dynamic schema
    const completeSchema = dynamicYupValidate
      ? mergedSchema.concat(dynamicYupValidate)
      : mergedSchema;

    //merges all the schema to the main yup schema
    const mainMergedSchema = completeSchema
      ? yupValidate.concat(completeSchema)
      : yupValidate;

    setMergedSchema(mainMergedSchema);
  }, [
    activeStep,
    CustomerTypeCompany,
    mandatoryFieldsPrevPolicyToggle?.length,
    optionalFieldsPrevPolicyToggle?.length,
  ]);

  const ifscData = useGetBankDataFromIfsc(payment);
  const { data: icData } = useGetCompaniesAccordingToLOB(lobId);
  const { data: branchData } = useGetICBranchDetails(watch("icName")?.id || 0);
  const { data: locationData } = useGetBranchLocation();
  const { data: userData } = useGetUserById({ userId: getUserIdFromType() });

  const { data: pincodeData1 } = useGetStateCityFromPincode(
    sourcingPinCode,
    "sourcingPinCode",
    setValue,
  );
  const { data: pincodeDataTwo } = useGetStateCityFromPincode(
    pincodetwo || pinCodetwo,
  );
  const { data: pincodeDataTwoForNoPermanent } = useGetStateCityFromPincode(
    pinCodetwo,
    "pinCodetwo",
    setValue,
  );
  const { data: pincodeData2 } = useGetStateCityFromPincode(
    pinCode,
    "pinCode",
    setValue,
  );
  const { data: pincodeDataForLifeAssured } = useGetStateCityFromPincode(
    watch(`members[${0}].pinCode`),
    `members[${0}].pinCode`,
    setValue,
  );
  const { data: pincodeDataForLifeAssuredForNo } = useGetStateCityFromPincode(
    watch(`members[${0}].assuredPincode`),
    `members[${0}].assuredPincode`,
    setValue,
  );
  const { data: pincodeData2ForNoPermanent } = useGetStateCityFromPincode(
    pincode,
    "pincode",
    setValue,
  );
  const { data: pincodeData3 } = useGetStateCityFromPincode(
    pincodeVd,
    "pincodeVd",
    setValue,
  );
  const { data: pincodeData4 } = useGetStateCityFromPincode(
    "pinCodeld",
    pinCodeld,
    setValue,
  );
  const { data: pincodeData5 } = useGetStateCityFromPincode(
    pincodeinsured,
    "pincodeinsured",
    setValue,
  );
  const { data: pincodeData6 } = useGetStateCityFromPincode(
    pincodetwooinsured,
    "pincodetwooinsured",
    setValue,
  );
  const { data: pincodeData7 } = useGetStateCityFromPincode(
    pincodeInsured,
    "pincodeInsured",
    setValue,
  );
  const { data: pincodeData8 } = useGetStateCityFromPincode(
    pinCoderm,
    "pinCoderm",
    setValue,
  );
  const { data: pincodeData9 } = useGetStateCityFromPincode(
    pincodeOne,
    "pincodeOne",
    setValue,
  );

  const useSetStateAndCity = (pincodeData, stateKey, cityKey, flag = false) => {
    useEffect(() => {
      if (isNil(stateKey) || isNil(cityKey)) return;
      const state = pincodeData?.data?.data?.[0]?.state?.stateName;
      const city = pincodeData?.data?.data?.[0]?.city?.cityName;
      if (state) {
        setValue(stateKey, { label: state, value: state });
      } else {
        setValue(stateKey, null);
      }
      if (city) {
        setValue(cityKey, { label: city, value: city });
      } else {
        setValue(cityKey, null);
      }
    }, [pincodeData, stateKey, cityKey]);
  };
  useSetStateAndCity(pincodeData1, "sourcingState", "sourcingCity");
  useSetStateAndCity(pincodeData2, "state", "city");
  useSetStateAndCity(
    pincodeDataForLifeAssured,
    isTermLob || isInvestmentLob ? `members[${0}].state` : null,
    isTermLob || isInvestmentLob ? `members[${0}].city` : null,
  );
  useSetStateAndCity(
    pincodeDataForLifeAssuredForNo,
    isTermLob || isInvestmentLob ? `members[${0}].assuredState` : null,
    isTermLob || isInvestmentLob ? `members[${0}].assuredCity` : null,
  );
  useSetStateAndCity(pincodeData2ForNoPermanent, "state", "city");
  useSetStateAndCity(pincodeData3, "stateVd", "cityVd");
  useSetStateAndCity(pincodeData4, "stateld", "cityld");
  useSetStateAndCity(pincodeDataTwo, "statetwo", "citytwo");
  useSetStateAndCity(pincodeDataTwoForNoPermanent, "statetwo", "citytwo");
  useSetStateAndCity(pincodeData5, "stateinsured", "cityinsured");
  useSetStateAndCity(pincodeData6, "statetwoinsured", "citytwoinsured");
  useSetStateAndCity(pincodeData7, "stateInsured", "cityInsured");
  useSetStateAndCity(pincodeData8, "staterm", "cityrm");
  useSetStateAndCity(pincodeData9, "stateOne", "cityOne");

  // used to filter customer list by name, and to get data by customer ref id
  const { mutate: getAllCustomers, data: customerDataByCustomerRefId } =
    useGetCustomer();

  // used to get data by customer id when clicking on customer name dropdown
  const { data: customerDataBySelectingName } = useGetCustomerById({
    id: selectedCustomer,
  });

  const { data: customerID, mutateAsync: addCustomerViaPolicyEntry } =
    useCreateCustomerThroughPolicyEntry();
  const { mutateAsync: addCustomerMemberViaInsuredMember } =
    useCreateMultipleCustomerThroughPolicyEntry(); // for adding family members to customer through insured member

  const { data: customerMemberData } = useGetCustomerMembers({
    parentId: customerIdFromApi,
    memberCount: insuredMemberDetailsFromApi?.members?.length,
  });

  const nomineeRelationWiseCustomerMember = customerMemberData?.filter(
    item => item?.relation === nomineeRelation?.value,
  )?.[0];

  const productPayload = icName?.id
    ? { icId: icName?.id, product: productName }
    : { icId: insuranceCompanyname?.id, product: productNamepd };

  const { data: getAllProducts } =
    useGetProductsListPolicyEntry(productPayload);
  const allProducts = Array.isArray(getAllProducts?.data?.data)
    ? getAllProducts.data.data.map(value => ({
      id: value?.productId,
      value: value?.productName,
    }))
    : [];

  const allCustomers = Array.isArray(customerDataByCustomerRefId?.data?.data)
    ? customerDataByCustomerRefId?.data?.data.map(value => {
      return {
        id: value?.customerId,
        value:
          value?.firstName +
          (value?.middleName ? " " + value?.middleName : "") +
          (value?.lastName ? " " + value?.lastName : ""),
      };
    })
    : [];
  const customerDataByRefId =
    Array.isArray(customerDataByCustomerRefId?.data?.data) && customerRefId
      ? customerDataByCustomerRefId?.data?.data
      : [];

  // function prefillCustomerData(data) {
  //   //prefills customer info on proposer card
  //   const proposerFields = cardFieldsPrevPolicyToggle?.[activeStep - 1]?.map(
  //     value => value.key,
  //   );

  //   const objectKeys = Object.keys(data || {});
  //   const objectValues = Object.values(data || {});

  //   objectKeys?.forEach((key, index) => {
  //     const propertyValue = objectValues?.[index];
  //     if (proposerFields?.includes(key)) {
  //       if (propertyValue) {
  //         if (dateFields?.includes(key)) {
  //           const isValidDate = moment(
  //             propertyValue,
  //             moment.ISO_8601,
  //             true,
  //           ).isValid();
  //           const isValidNumber = /^[0-9]+$/.test(propertyValue);
  //           if (isValidDate && !isValidNumber) {
  //             setValue(key, new Date(propertyValue));
  //           } else {
  //             setValue(key, "");
  //           }
  //         } else if (dropdownFields?.includes(key)) {
  //           setValue(
  //             key,
  //             propertyValue
  //               ? {
  //                   label: propertyValue,
  //                   value: propertyValue,
  //                 }
  //               : null,
  //           );
  //         } else if (fileFields?.includes(key)) {
  //           dispatch(
  //             setUploadedFileURLAction({
  //               [key]: propertyValue,
  //             }),
  //           );
  //         } else {
  //           const isValidDate = moment(
  //             propertyValue,
  //             moment.ISO_8601,
  //             true,
  //           ).isValid();
  //           const isValidNumber = /^[0-9]+$/.test(propertyValue);
  //           if (isValidDate && !isValidNumber) {
  //             setValue(key, new Date(propertyValue));
  //           } else {
  //             setValue(key, propertyValue || null);
  //           }
  //         }
  //       }
  //     }
  //   });

  //   setValue("customerId", data?.customerId);
  //   // setValue("customerHiddenid", data?.customerId);
  //   setValue("customerRefId", data?.customerRefId);
  //   setValue("firstName", data?.firstName);
  //   setValue("lastName", data?.lastName);
  //   setValue("emailId", data?.emailId);
  //   setValue("mobileNo", data?.mobileNumber);
  //   setValue("alternateId", data?.alternateEmailId);
  //   setValue("alternateMobileno", data?.alternateMobileNumber);
  //   setValue(
  //     "gender",
  //     data?.gender ? { value: data?.gender, label: data?.gender } : null,
  //   );
  //   setValue(
  //     "maritialStatus",
  //     data?.maritialStatus
  //       ? {
  //           value: data?.maritialStatus,
  //           label: data?.maritialStatus,
  //         }
  //       : null,
  //   );
  //   setValue(
  //     "dob",
  //     new Date(moment(data?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")),
  //   );
  //   setValue(
  //     "anniversary",
  //     data?.anniversaryDate ? new Date(data?.anniversaryDate) : null,
  //   );
  //   setValue(
  //     "occupation",
  //     data?.occupation
  //       ? {
  //           value: data?.occupation?.trim(),
  //           label: data?.occupation?.trim(),
  //         }
  //       : null,
  //   );
  //   setValue("panCardno", data?.panNumber || data?.panCardno);
  //   setValue("aadharCardno", data?.aadharNumber);
  //   setValue("uccNumber", data?.uccNumber);
  //   setValue("sourcingPinCode", data?.pinCode);
  //   // setValue("addressLineone", data?.adddressLine1);
  //   setValue("addressLinetwo", data?.adddressLine2);
  //   setValue("drivingLicence", data?.drivingLicience);
  //   setValue("ckycId", data?.ckycRefId);
  //   setValue(
  //     "sourcingCity",
  //     data?.city
  //       ? {
  //           value: data?.city,
  //           label: data?.city,
  //         }
  //       : null,
  //   );
  //   setValue(
  //     "sourcingState",
  //     data?.state
  //       ? {
  //           value: data?.state,
  //           label: data?.state,
  //         }
  //       : null,
  //   );
  // }

  // useEffect(() => {
  //   if (
  //     activeFormTitle === PROPOSER_DETAILS &&
  //     _.isEmpty(customerDataByRefId) &&
  //     _.isEmpty(customerDataBySelectingName?.data?.data) &&
  //     !customerRefId &&
  //     !Object.keys(currentFormData || {}).length
  //   ) {
  //     setValue("customerId", "");
  //     clearCustomerData();
  //   }
  // }, [customerRefId, !Object.keys(currentFormData || {}).length]);

  // useEffect(() => {
  //   if (!customerRefId && customerDataBySelectingName?.data?.data) {
  //     prefillCustomerData(
  //       Array.isArray(customerDataBySelectingName?.data?.data)
  //         ? customerDataBySelectingName?.data?.data[0] || []
  //         : customerDataBySelectingName?.data?.data || {},
  //     );
  //   }
  // }, [customerDataBySelectingName?.data?.data]);

  // useEffect(() => {
  //   if (
  //     customerRefId &&
  //     (customerDataByRefId?.length == 1 ||
  //       (customerDataByRefId?.length > 1 &&
  //         customerDataByRefId?.every(
  //           item =>
  //             item?.customerRefId == customerDataByRefId?.[0]?.customerRefId,
  //         )))
  //   ) {
  //     prefillCustomerData(customerDataByRefId[0]);
  //   }
  // }, [customerDataByRefId?.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        activeFormTitle === PROPOSER_DETAILS &&
        customerRefId &&
        !customerDataByRefId?.length &&
        customerRefId != proposerDetailsFromApi?.customerRefId
      ) {
        clearCustomerData();
      }
      // if (!customerRefId) {
      //   clearCustomerData();
      // }
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [
    customerDataByRefId?.length,
    customerRefId,
    proposerDetailsFromApi?.customerRefId,
  ]);

  // const debouncedMutate = useEffect(() => debounce(getAllCustomers, 300), []);

  useEffect(() => {
    if (!customerRefId) {
      getAllCustomers({
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        searchValue: {
          firstName: firstName,
          customerRefId: "",
          parentId: "",
          emailId: "",
          mobileNumber: "",
          city: "",
        },
      });

      // if (!firstName) {
      //   clearFieldIfEmpty("firstName");
      // }
    }
  }, [firstName]);

  useEffect(() => {
    if (currentField?.field?.includes("payerName")) {
      const timer = setTimeout(() => {
        getAllCustomers({
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchValue: {
            firstName: watch(`payment.[${currentField?.index}].payerName`),
            customerRefId: "",
            parentId: "",
            emailId: "",
            mobileNumber: "",
            city: "",
          },
        });
      }, 500);
      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [
    watch(`payment.[${currentField?.index}].payerName`),
    currentField?.index,
  ]);

  useEffect(() => {
    getAllCustomers({
      page: 0,
      size: 0,
      sortBy: "",
      sortOrder: "",
      searchValue: {
        firstName: "",
        customerRefId: customerRefId,
        parentId: "",
        emailId: "",
        mobileNumber: "",
        city: "",
      },
    });

    // if (!customerRefId) {
    //   clearFieldIfEmpty("customerId");
    // }
  }, [customerRefId]);

  function getUserIdFromType() {
    let userTypeData = masterTableData?.find(
      value => value?.data?.data?.[0]?.fieldSlug === "userType",
    );

    return userTypeData?.data?.data?.[0]?.data?.find(
      value => value.optionValue === userType?.label,
    )?.optionKey;
  }

  const destructuredValues = summaryData && Object.values(summaryData);

  var valuesFromApi = Array.isArray(destructuredValues)
    ? destructuredValues?.reduce((acc, obj) => ({ ...acc, ...obj }), {})
    : [];

  useEffect(() => {
    if (maritialStatus?.value !== summaryDataKeyValue?.maritialStatus) {
      setValue("anniversary", null);
    }
  }, [maritialStatus?.value]);

  // useEffect(() => {
  //   // for life if policy for yourself
  //   if (policyurselfornot?.label === "Yes") {
  //     setValue("titlela", title);
  //     setValue("firstNameld", firstName);
  //     setValue("middleName", middleNamepd);
  //     setValue("lastNameld", lastName);
  //     setValue("genderld", gender);
  //     setValue("dobld", dob);
  //     setValue("mobileNold", mobileNo);
  //     setValue("alternateMobilenold", alternateMobileno);
  //     setValue("emailIdld", proposerEmailId);
  //     setValue("aadharCardnold", aadharCardno);
  //     setValue("panCardnold", panCardno);
  //     setValue("maritialStatusld", maritialStatus);
  //     setValue("pincodeVd", pinCode);
  //     setValue("stateVd", state);
  //     setValue("cityVd", city);
  //     setValue("occupationld", occupation);
  //     setValue("addressLineoneld", addressLineone);
  //     setValue("addressLinetwold", addressLinetwo);
  //     setValue("landmarkld", landmark);
  //   }
  //   if (policyurselfornot?.label === "No") {
  //     setValue(
  //       "titlela",
  //       valuesFromApi?.titlela ||
  //         ![null, undefined].includes(valuesFromApi?.titlela)
  //         ? valuesFromApi?.titlela
  //         : "",
  //     );
  //     setValue(
  //       "firstNameld",
  //       valuesFromApi?.firstNameld ||
  //         ![null, undefined].includes(valuesFromApi?.firstNameld)
  //         ? valuesFromApi?.firstNameld
  //         : "",
  //     );
  //     setValue(
  //       "middleName",
  //       valuesFromApi?.middleName ||
  //         ![null, undefined].includes(valuesFromApi?.middleName)
  //         ? valuesFromApi?.middleName
  //         : "",
  //     );
  //     setValue(
  //       "lastNameld",
  //       valuesFromApi?.lastNameld ||
  //         ![null, undefined].includes(valuesFromApi?.lastNameld)
  //         ? valuesFromApi?.lastNameld
  //         : "",
  //     );
  //     setValue(
  //       "genderld",
  //       valuesFromApi?.genderld ||
  //         ![null, undefined].includes(valuesFromApi?.genderld)
  //         ? valuesFromApi?.genderld
  //         : "",
  //     );
  //     setValue(
  //       "dobld",
  //       valuesFromApi?.dobld ||
  //         ![null, undefined].includes(valuesFromApi?.dobld)
  //         ? new Date(valuesFromApi?.dobld)
  //         : "",
  //     );
  //     setValue(
  //       "mobileNold",
  //       valuesFromApi?.mobileNold ||
  //         ![null, undefined].includes(valuesFromApi?.mobileNold)
  //         ? valuesFromApi?.mobileNold
  //         : "",
  //     );
  //     setValue(
  //       "alternateMobilenold",
  //       valuesFromApi?.alternateMobilenold ||
  //         ![null, undefined].includes(valuesFromApi?.alternateMobilenold)
  //         ? valuesFromApi?.alternateMobilenold
  //         : "",
  //     );
  //     setValue(
  //       "emailIdld",
  //       valuesFromApi?.emailIdld ||
  //         ![null, undefined].includes(valuesFromApi?.emailIdld)
  //         ? valuesFromApi?.emailIdld
  //         : "",
  //     );
  //     setValue(
  //       "aadharCardnold",
  //       valuesFromApi?.aadharCardnold ||
  //         ![null, undefined].includes(valuesFromApi?.aadharCardnold)
  //         ? valuesFromApi?.aadharCardnold
  //         : "",
  //     );
  //     setValue(
  //       "panCardnold",
  //       valuesFromApi?.panCardnold ||
  //         ![null, undefined].includes(valuesFromApi?.panCardnold)
  //         ? valuesFromApi?.panCardnold
  //         : "",
  //     );
  //     setValue(
  //       "maritialStatusld",
  //       valuesFromApi?.maritialStatusld ||
  //         ![null, undefined].includes(valuesFromApi?.maritialStatusld)
  //         ? valuesFromApi?.maritialStatusld
  //         : "",
  //     );
  //     setValue(
  //       "pinCodeld",
  //       valuesFromApi?.pinCodeld ||
  //         ![null, undefined].includes(valuesFromApi?.pinCodeld)
  //         ? valuesFromApi?.pinCodeld
  //         : "",
  //     );
  //     setValue(
  //       "stateVd",
  //       valuesFromApi?.stateld ||
  //         ![null, undefined].includes(valuesFromApi?.stateld)
  //         ? valuesFromApi?.stateld
  //         : "",
  //     );
  //     setValue(
  //       "cityVd",
  //       valuesFromApi?.cityld ||
  //         ![null, undefined].includes(valuesFromApi?.cityld)
  //         ? valuesFromApi?.cityld
  //         : "",
  //     );
  //     setValue(
  //       "occupationld",
  //       valuesFromApi?.occupation ||
  //         ![null, undefined].includes(valuesFromApi?.occupation)
  //         ? valuesFromApi?.occupation
  //         : "",
  //     );
  //     setValue(
  //       "addressLineoneld",
  //       valuesFromApi?.addressLineone ||
  //         ![null, undefined].includes(valuesFromApi?.addressLineone)
  //         ? valuesFromApi?.addressLineone
  //         : "",
  //     );
  //     setValue(
  //       "addressLinetwold",
  //       valuesFromApi?.addressLinetwo ||
  //         ![null, undefined].includes(valuesFromApi?.addressLinetwo)
  //         ? valuesFromApi?.addressLinetwo
  //         : "",
  //     );
  //     setValue(
  //       "landmarkld",
  //       valuesFromApi?.landmark ||
  //         ![null, undefined].includes(valuesFromApi?.landmark)
  //         ? valuesFromApi?.landmark
  //         : "",
  //     );
  //   }
  // }, [policyurselfornot]);

  useEffect(() => {
    if (paymentMode?.value === "Online") {
      setValue(
        "ifscCode",
        valuesFromApi?.ifscCode ||
          ![null, undefined].includes(valuesFromApi?.ifscCode)
          ? valuesFromApi?.ifscCode
          : "",
      );
      setValue(
        "micrCode",
        valuesFromApi?.micrCode ||
          ![null, undefined].includes(valuesFromApi?.micrCode)
          ? valuesFromApi?.micrCode
          : "",
      );
      setValue(
        "banName",
        valuesFromApi?.banName ||
          ![null, undefined].includes(valuesFromApi?.banName)
          ? valuesFromApi?.banName
          : "",
      );
      setValue(
        "bankBranchname",
        valuesFromApi?.bankBranchname ||
          ![null, undefined].includes(valuesFromApi?.bankBranchname)
          ? valuesFromApi?.bankBranchname
          : "",
      );
      setValue(
        "accountType",
        valuesFromApi?.accountType ||
          ![null, undefined].includes(valuesFromApi?.accountType)
          ? {
            label: valuesFromApi?.accountType,
            value: valuesFromApi?.accountType,
          }
          : null,
      );
      setValue(
        "accNumber",
        valuesFromApi?.accNumber ||
          ![null, undefined].includes(valuesFromApi?.accNumber)
          ? valuesFromApi?.accNumber
          : "",
      );
      setValue(
        "totalAmount",
        valuesFromApi?.totalAmount ||
          ![null, undefined].includes(valuesFromApi?.totalAmount)
          ? valuesFromApi?.totalAmount
          : "",
      );
      setValue(
        "paymentDate",
        valuesFromApi?.paymentDate ||
          ![null, undefined].includes(valuesFromApi?.paymentDate)
          ? valuesFromApi?.paymentDate
          : null,
      );
      setValue(
        "paymentFrequency",
        valuesFromApi?.paymentFrequency ||
          ![null, undefined].includes(valuesFromApi?.paymentFrequency)
          ? {
            label: valuesFromApi?.paymentFrequency,
            value: valuesFromApi?.paymentFrequency,
          }
          : null,
      );
    }
  }, [paymentMode]);

  useEffect(() => {
    if (
      currentField?.index != null &&
      currentField?.field?.includes("paymentMode")
    ) {
      const keys = [
        // "payerName",
        "totalAmount",
        "accNumber",
        "chequeNo",
        "ifscCode",
        "banName",
        "bankBranchname",
        "micrCode",
        "transactionId",
        "accountType",
        "paymentDate",
        "paymentFrequency",
        "paymentPaid",
      ];
      keys.forEach(key => {
        setValue(
          `payment.[${currentField?.index}].${key}`,
          [
            "paymentFrequency",
            // "accountType",
            // "paymentPaid",
            "paymentDate",
          ].includes(key)
            ? null
            : "",
        );
      });
    }
  }, [
    payment?.[currentField?.index]?.paymentMode?.value,
    currentField?.index,
    payment?.length,
  ]);

  useEffect(() => {
    if (
      currentField?.index != null &&
      currentField?.field?.includes("paymentPaid") &&
      payment?.[currentField?.index]?.paymentPaid?.value != "Self"
    ) {
      setValue(`payment[${currentField?.index}].payerName`, "");
    }
  }, [
    payment?.[currentField?.index]?.paymentPaid?.value,
    currentField?.index,
    payment?.length,
  ]);

  const nomineeKeys = [
    "titleNd",
    "nomineeFullname",
    "nomineeGender",
    "nomineeDob",
    "nomineeAge",
    "nomineeMinor",
    "nomineeAddress",
    "nomineeAadharno",
    "nomineePan",
    "nomineeRemark",
    "appointeeName",
  ];
  // useEffect(() => {
  //   if (
  //     (!!Object.keys(nomineeDetailsFromApi || {}).length &&
  //       nomineeDetailsFromApi?.nomineeRelation === "Self" &&
  //       nomineeRelation?.value === "Self") ||
  //     nomineeRelation?.value === "Self"
  //   ) {
  //     setValue(
  //       "nomineeFullname",
  //       proposerDetailsFromApi?.firstName +
  //         " " +
  //         proposerDetailsFromApi?.middleName +
  //         " " +
  //         proposerDetailsFromApi?.lastName,
  //     );
  //     setValue(
  //       "nomineeAddress",
  //       proposerDetailsFromApi?.addresspdone +
  //         proposerDetailsFromApi?.addresspdtwo,
  //     );
  //     setValue(
  //       "nomineeDob",
  //       proposerDetailsFromApi?.dob
  //         ? new Date(proposerDetailsFromApi?.dob)
  //         : null,
  //     );
  //     setValue("nomineeAadharno", proposerDetailsFromApi?.aadharCardno);
  //     // setValue("nomineePanno", proposerDetailsFromApi?.panCardno);
  //     setValue("nomineePan", proposerDetailsFromApi?.panCardno);
  //     setValue("nomineeRemark", proposerDetailsFromApi?.remark);
  //     setValue("appointeeName", proposerDetailsFromApi?.appointeeName);
  //     setValue("nomineeAddress", proposerDetailsFromApi?.addressLineone);
  //     setValue(
  //       "titleNd",
  //       proposerDetailsFromApi?.title
  //         ? {
  //             label: proposerDetailsFromApi?.title,
  //             value: proposerDetailsFromApi?.titleNd,
  //           }
  //         : null,
  //     );
  //     setValue(
  //       "nomineeGender",
  //       proposerDetailsFromApi?.gender
  //         ? {
  //             label: proposerDetailsFromApi?.gender,
  //             value: proposerDetailsFromApi?.gender,
  //           }
  //         : null,
  //     );
  //   } else if (Object.keys(nomineeRelationWiseCustomerMember || {})?.length) {
  //     setValue(
  //       "nomineeFullname",
  //       nomineeRelationWiseCustomerMember?.firstName ||
  //         nomineeRelationWiseCustomerMember?.fullName,
  //     );
  //     setValue(
  //       "nomineeAddress",
  //       nomineeRelationWiseCustomerMember?.adddressLine1 +
  //         nomineeRelationWiseCustomerMember?.adddressLine2,
  //     );
  //     setValue(
  //       "nomineeDob",
  //       nomineeRelationWiseCustomerMember?.dob
  //         ? new Date(
  //             moment(
  //               nomineeRelationWiseCustomerMember?.dob,
  //               "DD-MM-YYYY",
  //             ).format("YYYY-MM-DD"),
  //           )
  //         : null,
  //     );
  //     setValue(
  //       "nomineeAadharno",
  //       nomineeRelationWiseCustomerMember?.aadharNumber,
  //     );
  //     // setValue("nomineePanno", proposerDetailsFromApi?.panCardno);
  //     setValue("nomineePan", nomineeRelationWiseCustomerMember?.panNumber);
  //     setValue("nomineeRemark", nomineeRelationWiseCustomerMember?.remark);
  //     setValue(
  //       "titleNd",
  //       nomineeRelationWiseCustomerMember?.title
  //         ? {
  //             label: nomineeRelationWiseCustomerMember?.title,
  //             value: nomineeRelationWiseCustomerMember?.title,
  //           }
  //         : null,
  //     );
  //     setValue(
  //       "nomineeGender",
  //       nomineeRelationWiseCustomerMember?.gender
  //         ? {
  //             label: nomineeRelationWiseCustomerMember?.gender,
  //             value: nomineeRelationWiseCustomerMember?.gender,
  //           }
  //         : null,
  //     );
  //   } else if (
  //     nomineeDetailsFromApi?.nomineeRelation != nomineeRelation?.value &&
  //     nomineeRelation?.value !== "Self"
  //   ) {
  //     nomineeKeys.forEach(key => {
  //       setValue(
  //         key,
  //         ["titleNd", "nomineeGender", "nomineeDob"].includes(key) ? null : "",
  //       );
  //     });
  //   }
  // }, [
  //   nomineeRelation?.value,
  //   nomineeDetailsFromApi?.nomineeRelation,
  //   nomineeRelationWiseCustomerMember,
  // ]);

  const lifeAssuredKeys = [
    `members[${0}].lifeassuredFname`,
    `members[${0}].lifeassuredMname`,
    `members[${0}].lifeassuredLname`,
    `members[${0}].dobIMD`,
    `members[${0}].lifeAssuredage`,
    `members[${0}].lifeassuredTitle`,
    `members[${0}].genderIMD`,
    `members[${0}].educationTerm`,
    `members[${0}].occupationTerm`,
    `members[${0}].address`,
    `members[${0}].pinCode`,
    `members[${0}].state`,
    `members[${0}].city`,
    `members[${0}].annualIncome`,
    `members[${0}].heightImd`,
    `members[${0}].weightImd`,
    `members[${0}].bmiImd`,
  ];
  useEffect(() => {
    if (
      (isTermLob || isInvestmentLob) &&
      formName !== PROPOSER_DETAILS &&
      relWithprop?.value === "Self"
    ) {
      setValue(
        `lifeassuredFname`,
        watch("firstName") ?? proposerDetailsFromApi?.firstName,
      );
      setValue(
        `lifeassuredMname`,
        watch("middleName") ?? proposerDetailsFromApi?.middleName,
      );
      setValue(
        `lifeassuredLname`,
        watch("lastName") ?? proposerDetailsFromApi?.lastName,
      );
      setValue(
        `insuredContactnumber`,
        watch("mobileNo") ?? proposerDetailsFromApi?.mobileNo,
      );
      setValue(
        `assuredmobileNotwo`,
        watch("alternateMobileno") ?? proposerDetailsFromApi?.alternateMobileno,
      );
      setValue(
        `assuredEmail`,
        watch("emailId") ?? proposerDetailsFromApi?.emailId,
      );
      setValue(
        `assuredEmailtwo`,
        watch("alternateId") ?? proposerDetailsFromApi?.alternateId,
      );
      setValue(
        `assuredAnnualincome`,
        watch("annualIncome") ?? proposerDetailsFromApi?.annualIncome,
      );
      setValue(
        `assuredAddressone`,
        watch("addresspdone") ?? proposerDetailsFromApi?.addresspdone,
      );
      setValue(
        `assuredAddresstwo`,
        watch("addresspdtwo") ?? proposerDetailsFromApi?.addresspdtwo,
      );
      setValue(
        `assuredLandmark`,
        watch("landmark") ?? proposerDetailsFromApi?.landmark,
      );
      setValue(
        `assuredAnnivrsary`,
        !_.isNil(watch("anniversary")) ? new Date(watch("anniversary")) : null,
      );
      setValue(
        `dobIMD`,
        new Date(watch("dob")) || new Date(proposerDetailsFromApi?.dob) || null,
      );
      setValue(`insuredeiaNumber`, watch("eiaNumber") ?? null);
      setValue(
        `assuredeiaRepository`,
        watch("eiaRepository")
          ? {
            label: watch("eiaRepository")?.label,
            value: watch("eiaRepository")?.value,
          }
          : null,
      );
      setValue(
        `lifeassuredTitle`,
        watch("title")
          ? {
            label: watch("title")?.label,
            value: watch("title")?.value,
          }
          : proposerDetailsFromApi?.title
            ? {
              label: proposerDetailsFromApi?.title,
              value: proposerDetailsFromApi?.title,
            }
            : null,
      );
      setValue(
        `genderIMD`,
        watch("gender")
          ? {
            label: watch("gender")?.label,
            value: watch("gender")?.value,
          }
          : proposerDetailsFromApi?.gender
            ? {
              label: proposerDetailsFromApi?.gender,
              value: proposerDetailsFromApi?.gender,
            }
            : null,
      );
      setValue(
        `addresschecktwo`,
        watch("addressCheck") || proposerDetailsFromApi?.addressCheck || null,
      );
      setValue(
        `assuredaddressProof`,
        watch("addressProof")
          ? {
            label: watch("addressProof")?.label,
            value: watch("addressProof")?.value,
          }
          : proposerDetailsFromApi?.addressProof
            ? {
              label: proposerDetailsFromApi?.addressProof,
              value: proposerDetailsFromApi?.addressProof,
            }
            : null,
      );
      setValue(
        `addressvalueN`,
        watch("addressvalue") ?? proposerDetailsFromApi?.addressvalue,
      );
      dispatch(
        setUploadedFileURLAction({
          [`addressproofN`]:
            watch("uploadAddress") ?? proposerDetailsFromApi?.uploadAddress,
        }),
      );
      setValue(
        `addressproofN`,
        watch("uploadAddress") ?? proposerDetailsFromApi?.uploadAddress,
      );

      setValue(
        `assuredidProof`,
        watch("idProof")
          ? {
            label: watch("idProof")?.label,
            value: watch("idProof")?.value,
          }
          : proposerDetailsFromApi?.idProof
            ? {
              label: proposerDetailsFromApi?.idProof,
              value: proposerDetailsFromApi?.idProof,
            }
            : null,
      );
      setValue(
        watch("identityvalueN") ?? `identityvalueN`,
        proposerDetailsFromApi?.identityvalue,
      );
      dispatch(
        setUploadedFileURLAction({
          [`uploadIdentitydoc`]:
            watch("uploadIdentity") ?? proposerDetailsFromApi?.uploadIdentity,
        }),
      );
      setValue(
        `uploadIdentitydoc`,
        watch("uploadIdentity") ?? proposerDetailsFromApi?.uploadIdentity,
      );

      setValue(
        `assuredincomeProof`,
        watch("incomeProof")
          ? {
            label: watch("incomeProof")?.label,
            value: watch("incomeProof")?.value,
          }
          : proposerDetailsFromApi?.incomeProof
            ? {
              label: proposerDetailsFromApi?.incomeProof,
              value: proposerDetailsFromApi?.incomeProof,
            }
            : null,
      );
      dispatch(
        setUploadedFileURLAction({
          [`uploadIncomeN`]:
            watch("uploadIncome") ?? proposerDetailsFromApi?.uploadIncome,
        }),
      );
      setValue(
        `uploadIncomeN`,
        watch("uploadIncome") ?? proposerDetailsFromApi?.uploadIncome,
      );

      setValue(
        `assuredmaritalStatus`,
        watch("maritialStatus")
          ? {
            label: watch("maritialStatus")?.label,
            value: watch("maritialStatus")?.value,
          }
          : proposerDetailsFromApi?.maritialStatus
            ? {
              label: proposerDetailsFromApi?.maritialStatus,
              value: proposerDetailsFromApi?.maritialStatus,
            }
            : null,
      );
      setValue(
        `nationalityAssured`,
        watch("nationality")
          ? {
            label: watch("nationality")?.label,
            value: watch("nationality")?.value,
          }
          : proposerDetailsFromApi?.nationality
            ? {
              label: proposerDetailsFromApi?.nationality,
              value: proposerDetailsFromApi?.nationality,
            }
            : null,
      );
      setValue(
        `address`,

        watch("addresspdone") ??
        proposerDetailsFromApi?.addresspdone + ` ` + watch("addresspdtwo") ??
        proposerDetailsFromApi?.addresspdtwo,
      );
      setValue(
        `assuredPincode`,
        watch("pinCodetwo") ||
        watch("pincodetwo") ||
        proposerDetailsFromApi?.pinCodetwo ||
        proposerDetailsFromApi?.pincodetwo,
      );
      setValue(
        `state`,
        watch("sourcingState") || proposerDetailsFromApi?.sourcingState,
        //  ||
        // proposerDetailsFromApi?.pincodetwo,
      );
      setValue(
        `city`,
        watch("sourcingCity") || proposerDetailsFromApi?.sourcingCity,
        //  ||
        // proposerDetailsFromApi?.pincodetwo,
      );
      setValue(
        `addressAssuredone`,
        watch("addresspdone") ?? proposerDetailsFromApi?.addresspdone,
      );
      setValue(
        `addressAssuredtwo`,
        watch("addresspdtwo") ?? proposerDetailsFromApi?.addresspdtwo,
      );
      setValue(
        `landmarkAssured`,
        watch("landmarkpdtwo") ?? proposerDetailsFromApi?.landmarkpdtwo,
      );
      if (isTermLob) {
        setValue(
          `pinCode`,
          watch("sourcingPinCode") ?? proposerDetailsFromApi?.sourcingPinCode,
        );
        setValue(
          `educationTerm`,
          watch("education")
            ? {
              label: watch("education")?.label,
              value: watch("education")?.value,
            }
            : proposerDetailsFromApi?.education
              ? {
                label: proposerDetailsFromApi?.education,
                value: proposerDetailsFromApi?.education,
              }
              : null,
        );
        setValue(
          `occupationTerm`,
          watch("occupation")
            ? {
              label: watch("occupation")?.label,
              value: watch("occupation")?.value,
            }
            : proposerDetailsFromApi?.occupation
              ? {
                label: proposerDetailsFromApi?.occupation,
                value: proposerDetailsFromApi?.occupation,
              }
              : null,
        );
      }
      if (isInvestmentLob) {
        setValue(
          `pincodeInvestment`,
          watch("sourcingPinCode") ?? proposerDetailsFromApi?.sourcingPinCode,
        );
        setValue(
          `educationInvestment`,
          watch("education")
            ? {
              label: watch("education")?.label,
              value: watch("education")?.value,
            }
            : proposerDetailsFromApi?.education
              ? {
                label: proposerDetailsFromApi?.education,
                value: proposerDetailsFromApi?.education,
              }
              : null,
        );
        setValue(
          `occupationInvestment`,
          watch("occupation")
            ? {
              label: watch("occupation")?.label,
              value: watch("occupation")?.value,
            }
            : proposerDetailsFromApi?.occupation
              ? {
                label: proposerDetailsFromApi?.occupation,
                value: proposerDetailsFromApi?.occupation,
              }
              : null,
        );
      }
    }
    //  NEEDS PRODUCT TEAM APPROVAL.
    //  else if (
    //   watchMembers?.[0]?.relWithprop?.value &&
    //   lifeAssuredDetailsFromApi?.relWithprop !=
    //     watchMembers?.[0]?.relWithprop?.value &&
    //   watchMembers?.[0]?.relWithprop?.value !== "Self"
    // ) {
    //   lifeAssuredKeys.forEach(key => {
    //     setValue(key, null);
    //   });
    // }
  }, [
    // JSON.stringify(watch(PROPOSER_FIELDS)),
    relWithprop?.value,
    // lifeAssuredDetailsFromApi?.relWithprop,
    formName,
  ]);

  // Gender Set for Life Assured Details
  useEffect(() => {
    if (lifeassuredTitle) {
      if (lifeassuredTitle?.value === "Mr") {
        setValue(`genderIMD`, {
          value: "Male",
          label: "Male",
        });
      } else {
        setValue(`genderIMD`, {
          value: "Female",
          label: "Female",
        });
      }
    }
  }, [lifeassuredTitle?.value]);
  useEffect(() => {
    if (policyTerm && policystDate) {
      setValue(
        "policyExpirydate",
        new Date(
          moment(policystDate).add(policyTerm, "years").subtract(1, "day"),
        ),
      );
    }
    if (isWorkmensCompensationLob && tenure?.label && policystDate) {
      setValue(
        "policyExpirydate",
        new Date(
          moment(policystDate)
            .add(extractNumbersFromString(tenure?.label), "months")
            .subtract(1, "day"),
        ),
      );
    }
  }, [policystDate, policyTerm, tenure?.label]);

  useEffect(() => {
    const shouldSetFieldsToNull = () => {
      if (!proposerDetailsFromApi?.typeofcustomer) {
        return !!typeofcustomer?.label;
      } else {
        return (
          typeofcustomer?.label &&
          typeofcustomer?.label !== proposerDetailsFromApi?.typeofcustomer
        );
      }
    };
    if (activeFormTitle === PROPOSER_DETAILS && shouldSetFieldsToNull()) {
      matchedObject[PROPOSER_DETAILS]?.filter(
        item => item?.field_slug !== "typeofcustomer",
      )?.forEach(item => setValue(item?.field_slug, null));
    }
  }, [
    typeofcustomer?.label,
    proposerDetailsFromApi?.typeofcustomer,
    activeFormTitle,
  ]);

  // useEffect(() => {
  //   if (isPropertyInsuranceLob) {
  //     setValue("coverage", null);
  //   }
  // }, [policyDetailsPlanType, isPropertyInsuranceLob]);

  useEffect(() => {
    if (noFloaterCover) {
      floaterCoverFields?.map(item => {
        setValue(item, null);
      });
    } else {
      floaterCoverFields?.map(item => {
        setValue(item, propertyDetailsFromApi?.[item] || null);
      });
    }
  }, [noFloaterCover]);

  useEffect(() => {
    if (!floaterCover?.value) {
      setValue("floaterCover", { label: "No", value: "No" });
    }
  }, [floaterCover?.value]);

  // useEffect(() => {
  // if (policyDetailsFromApi?.businessType === businessType?.value) {
  //   setValue("businessType", policyDetailsFromApi?.businessType);
  // }
  // if (policyDetailsFromApi?.businessType !== businessType?.value) {
  //   setValue("businessType", null);
  // }
  // }, [policyDetailsFromApi?.businessType, businessType?.value]);

  useEffect(() => {
    if (
      activeFormTitle === EMPLOYEE_DETAILS &&
      !employeeDetailsFromApi?.members?.[0]?.compensationAct
    ) {
      setValue(`members[0].compensationAct`, {
        label: "No",
        value: "No",
      });
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(watchMembers) && activeFormTitle === EMPLOYEE_DETAILS) {
      watchMembers?.map((item, index) => {
        const latestValue =
          (currentField?.field?.includes("namedorUnamed") &&
            watch(`members[${currentField?.index}].namedorUnamed`)?.value) ||
          watch(`members[${currentField?.index}].namedorUnamed`)?.value;
        if (
          latestValue ||
          employeeDetailsFromApi?.members?.[0]?.namedorUnamed
        ) {
          setValue(`members[${index}].namedorUnamed`, {
            label:
              latestValue ||
              employeeDetailsFromApi?.members?.[0]?.namedorUnamed,
            value:
              latestValue ||
              employeeDetailsFromApi?.members?.[0]?.namedorUnamed,
          });
        } else {
          setValue(`members[${index}].namedorUnamed`, null);
        }
      });
    }
  }, [
    watchMembers?.[currentField?.index]?.namedorUnamed?.value,
    members.employee,
    employeeDetailsFromApi?.members?.[0]?.namedorUnamed,
  ]);

  useEffect(() => {
    if (Array.isArray(watchMembers) && activeFormTitle === EMPLOYEE_DETAILS) {
      watchMembers?.map((item, index) => {
        const annualCash = watch(`members[${index}].cashAnnual`);
        const livingAllowances = watch(
          `members[${index}].livingorOtherallowances`,
        );
        setValue(
          `members[${index}].totalWages`,
          +annualCash + +livingAllowances,
          watchMembers,
        );
      });
    }
  }, [JSON.stringify(watchMembers), members.employee]);

  useEffect(() => {
    if (Array.isArray(watchMembers) && activeFormTitle === EMPLOYEE_DETAILS) {
      watchMembers?.map((item, index) => {
        const latestValue =
          (currentField?.field?.includes("compensationAct") &&
            watch(`members[${currentField?.index}].compensationAct`)?.value) ||
          watch(`members[${currentField?.index}].compensationAct`)?.value;

        setValue(`members[${index}].compensationAct`, {
          label:
            latestValue ||
            employeeDetailsFromApi?.members?.[0]?.compensationAct ||
            "No",
          value:
            latestValue ||
            employeeDetailsFromApi?.members?.[0]?.compensationAct ||
            "No",
        });
      });
    }
  }, [
    watchMembers?.[currentField?.index]?.compensationAct?.value,
    members.employee,
    employeeDetailsFromApi?.members?.[0]?.compensationAct,
  ]);

  // useEffect(() => {
  //   if (
  //     currentField?.index != null &&
  //     currentField?.field?.includes("relWithprop")
  //   ) {
  //     if (watchMembers?.[currentField?.index]?.relWithprop?.value != "Self") {
  //       // setValue(`members[${currentField?.index}].relationId`, "");
  //       setValue(`members[${currentField?.index}].dobIMD`, null);
  //       setValue(`members[${currentField?.index}].ageIMD`, "");
  //       setValue(`members[${currentField?.index}].bmiImd`, "");
  //       setValue(`members[${currentField?.index}].heightImd`, "");
  //       setValue(`members[${currentField?.index}].weightImd`, "");
  //       setValue(`members[${currentField?.index}].remarkihd`, "");
  //       setValue(`members[${currentField?.index}].insuredmemberName`, "");
  //       setValue(`members[${currentField?.index}].insuredContactnumber`, "");
  //       setValue(`members[${currentField?.index}].genderIMD`, null);
  //       setValue(`members[${currentField?.index}].medicalHistoryim`, null);
  //     } else {
  //       // setValue(`members[${currentField?.index}].relationId`, "");
  //       setValue(`members[${currentField?.index}].bmiImd`, "");
  //       setValue(`members[${currentField?.index}].heightImd`, "");
  //       setValue(`members[${currentField?.index}].weightImd`, "");
  //     }
  //   }
  // }, [
  //   watchMembers?.[currentField?.index]?.relWithprop?.value,
  //   currentField?.index,
  //   members.members,
  // ]);

  useEffect(() => {
    //prefill gender on salutation change
    if (title?.value) {
      if (title?.value === "Mr") {
        setValue("gender", { value: "Male", label: "Male" });
      } else {
        setValue("gender", { value: "Female", label: "Female" });
      }
    }
    // if (titleNd?.value) {
    // if (titleNd?.value === "Mr") {
    // setValue("nomineeGender", { value: "Male", label: "Male" });
    // } else {
    // setValue("nomineeGender", { value: "Female", label: "Female" });
    // }
    // }
    if (watchNominee?.[currentField?.index]?.titleNd?.value) {
      if (watchNominee?.[currentField?.index]?.titleNd?.value === "Mr") {
        setValue(`nominee[${currentField?.index}].nomineeGender`, {
          value: "Male",
          label: "Male",
        });
      } else {
        setValue(`nominee[${currentField?.index}].nomineeGender`, {
          value: "Female",
          label: "Female",
        });
      }
    }
    if (titlela?.value) {
      if (titlela?.value === "Mr") {
        setValue("genderld", { value: "Male", label: "Male" });
      } else {
        setValue("genderld", { value: "Female", label: "Female" });
      }
    }
  }, [
    title,
    titleNd,
    titlela,
    watchNominee?.[currentField?.index]?.titleNd?.value,
  ]);

  const totalNomineeShare = watchNominee?.reduce(
    (acc, curr) => +curr?.nomineeShare + acc,
    0,
  );

  // const nomineeShareisZero = watchNominee?.find(
  //   curr => curr?.nomineeShare == "0",
  // );
  useEffect(() => {
    let timer;
    if (
      // !isBusinessTypeConditionsLobWise &&
      // !isInvestmentLob &&
      !isWorkmensCompensationLob
    ) {
      timer = setTimeout(() => {
        if (
          !isNaN(totalNomineeShare) &&
          totalNomineeShare !== 100 &&
          totalNomineeShare !== 0
          //  &&
          // nomineeShareisZero !== undefined
        ) {
          Swal.fire({
            html: `<p>
                     Cumulative nominee share should be 100%, Current Value is 
                     <strong>${totalNomineeShare}</strong>
                   </p>`,
            icon: "warning",
          });
        }
        // else if (nomineeShareisZero) {
        //   Swal.fire({
        //     html: `<p>
        //              Nominee Share must not be <strong>0</strong>
        //            </p>`,
        //     icon: "warning",
        //   });
        // }
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [
    totalNomineeShare,
    // nomineeShareisZero,
    // isBusinessTypeConditionsLobWise,
    isWorkmensCompensationLob,
  ]);

  useEffect(() => {
    // days auto calculate on policy start and end date

    if (policystDate && policyExpirydate) {
      const duration = parseInt(
        moment
          .duration(
            moment(policyExpirydate).add(1, "day").diff(moment(policystDate)),
          )
          .asDays(),
      );
      if (duration < 0) {
        setValue("policyPeriod", 0);
      } else {
        setValue("policyPeriod", duration.toString());
        setValue("totalDays", duration.toString());
      }
    }
    if (policyStartdate && policyExpirydatepd) {
      const duration = parseInt(
        moment
          .duration(
            moment(policyExpirydatepd)
              .add(1, "day")
              .diff(moment(policyStartdate)),
          )
          .asDays(),
      );
      setValue("totalDayspd", duration.toString());
    }
  }, [policystDate, policyExpirydate, policyStartdate, policyExpirydatepd]);

  useEffect(() => {
    watchAddons?.map((item, index) => {
      if (
        (currentField?.index == index &&
          watchAddons?.[index]?.amountType?.label !==
          summaryDataKeyValue?.addOnPremiums?.[index]?.amountType) ||
        !watchAddons?.[index]?.amountType?.label
      ) {
        setValue(`addOnPremiums[${index}].amount`, null);
      }
    });
  }, [
    watchAddons?.[currentField?.index]?.amountType?.label,
    currentField?.index,
  ]);

  useEffect(() => {
    const discountData = watchAddons?.filter(
      addon => addon?.amountType?.label === "Discount Amount",
    );
    const loadingData = watchAddons?.filter(addon =>
      ["Loading Amount", "Extra"].includes(addon?.amountType?.label),
    );
    const totalDiscountAmount = discountData?.reduce(
      (acc, curr) => +curr?.amount + acc,
      0,
    );
    const totalLoadingAmount = loadingData?.reduce(
      (acc, curr) => +curr?.amount + acc,
      0,
    );
    const calculatedAddonPremium = +totalLoadingAmount - +totalDiscountAmount;
    if (!summaryDataKeyValue?.addOnPremiums?.length && !watchAddons?.length) {
      setValue("addOnpremium", summaryDataKeyValue?.addOnpremium);
    } else {
      setValue(
        "addOnpremium",
        !Number.isNaN(totalDiscountAmount) || !Number.isNaN(totalLoadingAmount)
          ? calculatedAddonPremium
          : "",
      );
    }
  }, [
    JSON.stringify(watchAddons),
    currentField?.index,
    members.addOnPremiums,
    summaryDataKeyValue,
  ]);

  useEffect(() => {
    //redux company id dispatch on insurance company name select
    if (icName) {
      dispatch(
        setBrokerage({
          companyId: getOptionKeyByValue(icName?.value, "icName"),
        }),
      );
    }
  }, [icName]);

  const memberDOBData =
    Array.isArray(watchMembers) && watchMembers.filter(item => item?.dobIMD);

  useEffect(() => {
    //nominee age auto calcualte using nominee dob
    if (nomineeDob) {
      const today = moment();
      const birthDate = moment(nomineeDob, "YYYY-MM-DD");
      const years = today.diff(birthDate, "years");
      setValue("nomineeAge", years);

      if (lobId === "21") {
        // for some reasons  setValue("nomineeMinor", "Yes"); syntax was not working
        if (years < 18) {
          setValue("nomineeMinor", { label: "Yes", value: "Yes" });
        } else {
          setValue("nomineeMinor", { label: "No", value: "No" });
        }
      } else {
        if (years < 18) {
          setValue("nomineeMinor", "Yes");
        } else {
          setValue("nomineeMinor", "No");
          setValue("appointeeName", "");
        }
      }
    }
    if (watchMembers?.length) {
      watchMembers?.forEach((member, index) => {
        if (watch(`members[${index}].dobIMD`)) {
          const today = moment();
          const birthDate = moment(
            watch(`members[${index}].dobIMD`),
            "YYYY-MM-DD",
          );
          const years = today.diff(birthDate, "years");
          setValue(`members[${index}].ageIMD`, years);
        }
      });
    }
  }, [nomineeDob, JSON.stringify(memberDOBData)]);

  useEffect(() => {
    if (regDate) {
      const dateMoment = moment(regDate, "YYYY-MM-DD");
      const monthName = dateMoment.format("MMMM");
      const manufactureYear = moment(regDate, "YYYY-MM-DD").year();
      setValue("manufactureYear", manufactureYear);
      setValue("manufactureMonth", { label: monthName, value: monthName });
      const endDate = moment();
      const totalMonths = endDate.diff(dateMoment, "months");
      setValue("vehicleAge", totalMonths);
      // trigger(["manufactureMonth", "manufactureYear", "vehicleAge"]);
    }
  }, [regDate]);

  useEffect(() => {
    const netPremiumArr = [+odPremium, +tpPremium, +addOnpremium, +basePremium]
      .filter(value => !isNaN(value))
      .filter(item => item !== 0);

    if (!_.isEmpty(netPremiumArr)) {
      const addedNetPremium = discount
        ? netPremiumArr.reduce((acc, val) => +acc + +val, 0) - +discount
        : netPremiumArr.reduce((acc, val) => +acc + +val, 0);
      setValue("netPremium", addedNetPremium);
      // trigger(["netPremium"]);
    } else {
      setValue("netPremium", "");
    }
  }, [odPremium, tpPremium, addOnpremium, basePremium, discount]);

  // useEffect(() => {
  //   const taxes = [+sgst, +cgst, +ugst].filter(value => !isNaN(value));
  //   if (taxes.length > 0) {
  //     let totalTax;

  //     if (taxes.length === 3) {
  //       // All three are present
  //       totalTax = taxes.reduce((acc, val) => acc + val, 0);
  //     } else {
  //       // Any one or any two are present
  //       totalTax = taxes.reduce((acc, val) => acc + val, 0);
  //       totalTax = Math.min(totalTax, 100);
  //     }

  //     if (totalTax === 0) {
  //       totalTax = "";
  //     }

  //     const percentageAmount =
  //       totalTax === "" ? "" : ((totalTax / 100) * +netPremium).toFixed(2);
  //     setValue("totalTax", totalTax);
  //     setValue("taxAmount", percentageAmount);

  //     // trigger(["totaltaxAmount", "taxAmount"]);
  //   }
  // }, [sgst, cgst, ugst, netPremium]);

  useEffect(() => {
    if (taxAmount && netPremium) {
      const totalPremium = (+taxAmount + +netPremium).toFixed(2);
      setValue("totalPremium", Math.round(totalPremium));
      setValue("totalAmount", Math.round(totalPremium));

      // trigger(["totalPremium", "totalAmount"]);
    }
  }, [netPremium, taxAmount]);

  useEffect(() => {
    if (ifscData) {
      payment?.forEach((_, index) => {
        setValue(
          `payment.[${index}].banName`,
          ifscData?.data?.[index]?.data?.data?.bankName,
        );
        setValue(
          `payment.[${index}].bankBranchname`,
          ifscData?.data?.[index]?.data?.data?.bankBranch,
        );
      });
    }
  }, [JSON.stringify(ifscData)]);

  useEffect(() => {
    setValue("make", {
      label: vehicleDetailsFromApi?.make,
      value: vehicleDetailsFromApi?.make,
      id: vehicleDetailsFromApi?.makeid,
    });
    setValue("model", {
      label: vehicleDetailsFromApi?.model,
      value: vehicleDetailsFromApi?.model,
      id: vehicleDetailsFromApi?.modelid,
    });
  }, [vehicleDetailsFromApi]);
  useEffect(() => {
    if (make?.id) {
      setValue("makeid", make?.id);
      // setValue("model", null);
      // setValue("version", []);
      // setValue("fuelType", []);
      getModalData({ lobid: lobId, manufacturer_id: make.id });
    }
  }, [make]);
  useEffect(() => {
    if (model?.id) {
      setValue("modelid", model?.id);
      getVersionData({ lobid: lobId, model_id: model.id });
      getFuelData({ lobid: lobId, model_id: model.id });
    }
  }, [model]);

  useEffect(() => {
    //managed seperate to prevent infinite renders
    const destructuredValues = summaryData && Object.values(summaryData);
    let modelValue = "";
    let versionValue = "";
    let fuelValue = "";

    if (proposalData) {
      destructuredValues?.forEach(value => {
        for (let key in value) {
          if (key === "model") {
            modelValue = value[key];
          }
          if (key === "version") {
            versionValue = value[key];
          }
          if (key === "fuelType") {
            fuelValue = value[key];
          }
        }

        const modelPrefillData = modelData?.data?.filter(
          value => value.model_name === modelValue,
        );

        if (modelPrefillData?.length) {
          setValue("model", {
            label: modelPrefillData?.[0]?.model_name,
            value: modelPrefillData?.[0]?.model_name,
            id: modelPrefillData?.[0]?.model_id,
          });
        }

        if (modelPrefillData?.length) {
          setValue("version", {
            label: versionValue,
            value: versionValue,
            id: versionValue,
          });
        }

        if (modelPrefillData?.length) {
          setValue("fuelType", {
            label: fuelValue,
            value: fuelValue,
            id: fuelValue,
          });
        }
      });
    }
  }, [modelData?.data?.length]);

  const policyNoSameAsCreate =
    summaryDataKeyValue?.policyNo?.trim() === policyNo?.trim();

  const prevPolicyNoSameAsCreate =
    summaryData[PREVIOUS_POLICY_DETAILS] &&
    summaryData[PREVIOUS_POLICY_DETAILS]?.previousPolicynum?.trim() ===
    previousPolicynum?.trim();
  // useEffect(() => {
  //   if (
  //     policyNo &&
  //     isPolicyPresent &&
  //     !policyNoSameAsCreate
  //     // Object.keys(relevantFields)?.includes("policyNo") &&
  //   ) {
  //     const timer = setTimeout(() => {
  //       Swal.fire({
  //         text: `Policy number (${policyNo}) already exists`,
  //         icon: "warning",
  //         confirmButtonText: "OK",
  //         confirmButtonColor: "#DC004E",
  //         reverseButtons: true,
  //         focusConfirm: false,
  //         allowOutsideClick: false,
  //         focusCancel: false,
  //         scrollbarPadding: false,
  //       }).then(result => {
  //         if (result.isConfirmed) {
  //           setValue("policyNo", "");
  //         }
  //       });
  //     }, 1000);
  //     return () => {
  //       if (timer) clearTimeout(timer);
  //     };
  //   }
  // }, [isPolicyPresent, policyNoSameAsCreate, policyNo]);

  const paymentDetailsTotalAmount = payment?.reduce(
    (acc, curr) => +curr?.totalAmount + acc,
    0,
  );

  useEffect(() => {
    if (
      activeFormTitle === PAYMENT_DETAILS &&
      +paymentDetailsTotalAmount > +premiumDetailsFromApi?.totalPremium
    ) {
      const timer = setTimeout(() => {
        Swal.fire({
          text:
            payment?.length > 1
              ? `Cumulative amount of these payments (${paymentDetailsTotalAmount}) should not exceed the total premium (${premiumDetailsFromApi?.totalPremium})`
              : `Total amount of this payment (${paymentDetailsTotalAmount}) should not exceed the total premium (${premiumDetailsFromApi?.totalPremium})`,
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#DC004E",
          reverseButtons: true,
          focusConfirm: false,
          allowOutsideClick: false,
          focusCancel: false,
          scrollbarPadding: false,
        });
      }, 1000);
      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [paymentDetailsTotalAmount, premiumDetailsFromApi?.totalPremium]);

  // useEffect(() => {
  //   if (
  //     previousPolicynum &&
  //     isPrevPolicyPresent &&
  //     !prevPolicyNoSameAsCreate
  //     // Object.keys(relevantFields)?.includes("policyNo") &&
  //   ) {
  //     const timer = setTimeout(() => {
  //       Swal.fire({
  //         text: `Previous policy number (${previousPolicynum}) already exists`,
  //         icon: "warning",
  //         confirmButtonText: "OK",
  //         confirmButtonColor: "#DC004E",
  //         reverseButtons: true,
  //         focusConfirm: false,
  //         allowOutsideClick: false,
  //         focusCancel: false,
  //         scrollbarPadding: false,
  //       }).then(result => {
  //         if (result.isConfirmed) {
  //           setValue("previousPolicynum", "");
  //         }
  //       });
  //     }, 1000);
  //     return () => {
  //       if (timer) clearTimeout(timer);
  //     };
  //   }
  // }, [isPrevPolicyPresent, prevPolicyNoSameAsCreate, previousPolicynum]);

  useEffect(() => {
    if (endorsementDiffData?.data) {
      setEndorsementDifference(endorsementDiffData?.data);
    }
  }, [endorsementDiffData]);

  // useEffect(() => {
  //   if (endorsementStage?.value === "Approved") {
  //     Swal.fire({
  //       title: "Is there a Premium Change?",
  //       text: "",
  //       icon: "info",
  //       confirmButtonText: "Yes",
  //       allowEscapeKey: false,
  //       allowOutsideClick: false,
  //       showCloseButton: false,
  //       showDenyButton: true,
  //       showConfirmButton: true,
  //       didClose: () => {
  //         document.activeElement?.blur();
  //       },
  //     }).then(res => {
  //       if (res.isConfirmed) {
  //         setIsPremiumDetailsReadOnly(true);
  //         // setActiveApproved(1);
  //         const timeOutRef = setTimeout(() => {
  //           scrollToPremiumDetails();
  //         }, 500);
  //         return () => {
  //           clearTimeout(timeOutRef);
  //         };
  //       }
  //       //  else {
  //       //   // setActiveApproved(1);
  //       // }
  //     });
  //   }
  // }, [endorsementStage?.value]);

  if (isLoading)
    return (
      <>
        <SkeletonLoader arraySize={15} />
      </>
    );

  //Logic to insert relationship type in health case.
  //Filter fields from insured member transactional field.
  //Modify the actual array to add/remove family/member
  const multipleMembers =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(INSURED_MEMBER_DETAILS) &&
    memberFieldArray?.map(() => [
      cardFieldsPrevPolicyToggle?.[
      cardTitlesPrevPolicyToggle.indexOf(INSURED_MEMBER_DETAILS)
      ],
    ]);

  const multiplePayment =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(PAYMENT_DETAILS) &&
    paymentFieldArray?.map(() => [
      cardFieldsPrevPolicyToggle?.[
      cardTitlesPrevPolicyToggle.indexOf(PAYMENT_DETAILS)
      ],
    ]);

  const multipleEmployee =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(EMPLOYEE_DETAILS) &&
    [...Array(members?.employee)].map(() => [
      cardFieldsPrevPolicyToggle?.[
      cardTitlesPrevPolicyToggle.indexOf(EMPLOYEE_DETAILS)
      ],
    ]);

  const multipleNominee =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(NOMINEE_DETAILS) &&
    nomineeFieldArray?.map(() => [
      cardFieldsPrevPolicyToggle?.[
      cardTitlesPrevPolicyToggle.indexOf(NOMINEE_DETAILS)
      ],
    ]);
  const multipleSource =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(SOURCING_DETAILS) &&
    [...Array(members?.source)].map(() => [
      cardFieldsPrevPolicyToggle?.[
      cardTitlesPrevPolicyToggle.indexOf(SOURCING_DETAILS)
      ],
    ]);

  const multipleAddons =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(PREMIUM_DETAILS) &&
    addonFieldArray?.map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(PREMIUM_DETAILS)
      ]?.filter(item => item?.is_add_on_premium_key === "Y"),
    ]);

  const allDataObj = {};
  cardTitlesPrevPolicyToggle?.forEach((key, index) => {
    allDataObj[key] = cardFieldsPrevPolicyToggle[index];
  });

  const multipleLifeAssuredMembers =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(LIFE_ASSURED_DETAILS) &&
    watchMembers?.map(() => [
      cardFieldsPrevPolicyToggle?.[
      cardTitlesPrevPolicyToggle.indexOf(LIFE_ASSURED_DETAILS)
      ],
    ]);

  const multipleTravellingInsuredMembers =
    isTravelLob &&
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(INSURED_MEMBER_TRAVELLING_DETAILS) &&
    travelMemberFieldArray?.map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(INSURED_MEMBER_TRAVELLING_DETAILS)
      ]?.filter(item => item?.is_member_key === "Y"),
    ]);

  const multiplePetInsuredMembers =
    isPetLob &&
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(PET_DETAILS) &&
    [...Array(members?.petMember)].map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(PET_DETAILS)
      ]?.filter(item => item?.is_member_key === "Y"),
    ]);

  let summaryCardData = {};

  if (Object.keys(summaryData).length) {
    Object.keys(allDataObj).forEach(title => {
      if (allDataObj.hasOwnProperty(title)) {
        const cardData = summaryData[title];

        if (!cardData?.payments && !cardData?.members) {
          summaryCardData[title] = allDataObj?.[title]?.map(item => {
            return { ...item, value: summaryData?.[title]?.[item?.key] };
          });
        } else {
          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].arrayData) {
            summaryCardData[title].arrayData = [];
          }
          Object.keys(cardData?.members || cardData?.payments || {}).forEach(
            () => {
              const data = cardData?.members || cardData?.payments;
              summaryCardData[title].arrayData = data?.map(itemz => {
                return allDataObj?.[title]?.map(item => {
                  return { ...item, value: itemz?.[item?.key] };
                });
              });
            },
          );
        }
      }
    });
  }

  const selectedOneTimePayment = (payment || [])?.some(
    item =>
      item?.paymentFrequency?.value?.replace(/\s/g, "").toLowerCase() ===
      "onetime",
  );

  const methods = {
    register,
    setValue,
    control,
    getValues,
    watch,
    unregister,
    errors,
    trigger,
    handleSubmit,
  };

  const otherData = {
    summaryData,
    editCard,
    hideGrihaFields,
    policyDetailsBusinessType,
    isBusinessTypeConditionsLobWise,
    noFloaterCover,
    planTypeFromApi,
    policyDetailsPlanType,
    fieldsOtherThanGrihaRaksha,
    isWorkmensCompensationLob,
    isCarLob,
    isBikeLob,
    summaryDataKeyValue,
    indexOfSelfNominee,
    indexOfSelfInsured,
    isLoading,
  };
  const otherFunctions = {
    setSelectedCustomer,
    setCurrentField,
    setSelectedProduct,
    clearCustomerData,
    setFiles,
    files,
    setShowAddonBifurcation,
    showAddonBifurcation,
    setMembers,
    removeAddon,
  };
  const masterData = {
    allCustomers,
    allProducts,
    branchData,
    fuelData,
    versionData,
    modelData,
    manufacturerData,
    masterTableData,
    lobData,
    userData,
    locationData,
    icData,
    addonData,
  };

  const onSubmit = data => {
    if (activeCount === 0) {
      if (!Object.keys(errors)?.length) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      const payload = {
        transactionId: +transactionIdParams,
        lobId: +lineofBusiness,
        // stage:"Pending",
        newData: formatPayloadForEndorsement(data, multiSelectFields),
      };
      payload?.newData?.sourcing && delete payload?.newData?.sourcing;
      getEndorsementDifference(payload);
      setActiveCount(1);
      // setActiveApproved(1);
    } else {
      const payload = {
        transactionId: +transactionIdParams,
        lobId: +lineofBusiness,
        currentStage: data?.endorsementStage?.value,
        objectData: endorsementDiffData?.data?.data,
      };
      AddEndorsement(payload);
      // setActiveCount(2);
    }
  };

  const handleDisable = (
    endorsementStage,
    totalNomineeShare,
    // nomineeShareisZero,
  ) => {
    if (!endorsementStage) {
      return true;
    }

    if (
      // isCarLob ||
      // isBikeLob ||
      // isGcvLob ||
      // isPcvLob ||
      // isInvestmentLob ||
      isWorkmensCompensationLob
    ) {
      return false;
    }
    if (totalNomineeShare === 0) return false;
    if (totalNomineeShare !== undefined && totalNomineeShare !== 100) {
      return true;
    }
    // if (
    //   totalNomineeShare === 100
    //   // &&
    //   // nomineeShareisZero !== undefined &&
    //   // nomineeShareisZero
    // ) {
    //   return false;
    // }

    return false;
    // || // endorsementStage must be selected
    // (totalNomineeShare !== undefined && totalNomineeShare !== 100) || // Disable if totalNomineeShare is defined and not 100
    // (totalNomineeShare === 100 &&
    //   nomineeShareisZero !== undefined &&
    //   nomineeShareisZero) // Also disable if totalNomineeShare is 100 and nomineeShareisZero is true
  };

  // DO NOT REMOVE THIS
  // //console.warn("warn", errors);
  //console.log('form', cardTitlesPrevPolicyToggle, multipleMembers, multiplePayment, multipleNominee, errors)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {cardTitlesPrevPolicyToggle?.filter(card => card !== "Payment Details" && card !== "Premium Details")
        // ?.filter(value => value !== "Payment Details")
        ?.map((value, index) => {
          const multipleAdditionCardMapping = {
            [INSURED_MEMBER_DETAILS]: {
              fields: multipleMembers,
              label: "Member ",
              nestedForm: "nestedMembers",
              keyForArray: memberFieldArray,
            },
            [NOMINEE_DETAILS]: {
              fields: multipleNominee,
              label: "Nominee ",
              nestedForm: "nestedNominee",
              keyForArray: nomineeFieldArray,
            },
            [SOURCING_DETAILS]: {
              fields: multipleSource,
              label: "Source ",
              nestedForm: "nestedSources",
            },
            [LIFE_ASSURED_DETAILS]: {
              fields: multipleLifeAssuredMembers,
              label: "",
              nestedForm: "nestedMembers",
            },
            [INSURED_MEMBER_TRAVELLING_DETAILS]: {
              fields: multipleTravellingInsuredMembers,
              label: "Member ",
              nestedForm: "nestedTravellingMembers",
              keyForArray: travelMemberFieldArray,
            },
            [PET_DETAILS]: {
              fields: multiplePetInsuredMembers,
              label: "Pet ",
              nestedForm: "nestedPetMembers",
            },
            // [PAYMENT_DETAILS]: {
            //   fields: multiplePayment,
            //   label: "Payment ",
            //   nestedForm: "nestedPayments",
            //   keyForArray: paymentFieldArray,
            // },
            // [EMPLOYEE_DETAILS]: {
            //   fields: multipleEmployee,
            //   label: "Work Description ",
            //   nestedForm: "nestedEmployees",
            // },
            // [PREMIUM_DETAILS]: {
            //   fields: multipleAddons,
            //   label: "Addon ",
            //   nestedForm: "nestedAddons",
            //   keyForArray: addonFieldArray,
            // },
          };
          return (
            <ProposalCreateSummaryStyled
              // ref={node => {
              //   const map = getMap();
              //   if (node) {
              //     map.set(index + 1, node);
              //   } else {
              //     map.delete(index + 1);
              //   }
              // }}
              key={value}
            >
              <>
                <PolicyCreateStyled isProposal={true}>
                  <header>
                    <h3 ref={setRef(index)}>
                      {value === PI_DETAILS
                        ? `PI ${planTypeFromApi} Details`
                        : value}
                    </h3>
                  </header>
                  {[
                    INSURED_MEMBER_DETAILS,
                    PAYMENT_DETAILS,
                    // EMPLOYEE_DETAILS,
                    NOMINEE_DETAILS,
                    SOURCING_DETAILS,
                    PREMIUM_DETAILS
                    // LIFE_ASSURED_DETAILS,
                  ].includes(value) ? (
                    multipleAdditionCardMapping[value]?.fields?.map(
                      (member, memberIndex) => (
                        <div
                          onMouseEnter={() => {
                            setFormName(value);
                          }}
                          key={
                            multipleAdditionCardMapping[value]?.keyForArray?.[
                              memberIndex
                            ]?.id
                          }
                        >
                          <MemberCount index={memberIndex}>
                            {multipleAdditionCardMapping[value]?.label}
                            {multipleAdditionCardMapping[value]?.label
                              ? memberIndex + 1
                              : ""}
                          </MemberCount>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            sx={{
                              // backgroundColor:
                              //   isNewMember(
                              //     endorsementDifference?.data,
                              //     memberIndex,
                              //     value,
                              //   ) && "#C8FFC8",
                              padding: "10px",
                            }}
                          >
                            {RenderFormFieldsEndorsement({
                              fieldsArray: member[0],
                              formTitle: value,
                              formIndex: index,
                              nestedForm:
                                multipleAdditionCardMapping[value]?.nestedForm,
                              nestedIndex: memberIndex,
                              methods: methods,
                              otherData: otherData,
                              otherFunctions: otherFunctions,
                              masterData: masterData,
                              // formDifferences: formDifferences,
                              activeCount: activeCount,
                              payloadCheck: endorsementDifference,
                              isPremiumDetailsReadOnly:
                                isPremiumDetailsReadOnly,
                              theme,
                              PREMIUM_FIELDS,
                            })}
                          </Grid>
                          {[INSURED_MEMBER_DETAILS].includes(value) &&
                            planTypeFromApi?.toLowerCase() !== "individual" && (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {_.compact([
                                  multipleMembers?.length - 1 === memberIndex &&
                                  "Add Member",
                                  multipleMembers?.length > 1 &&
                                  "Remove Member",
                                ]).map(action => {
                                  return (
                                    activeCount !== 1 && (
                                      <Button
                                        type="button"
                                        themeType={"theme1"}
                                        bgColor={"#11555F"}
                                        style={{
                                          marginLeft: "10px",
                                          float: "right",
                                        }}
                                        className="multiple_action_btn"
                                        onClick={() => {
                                          if (action === "Add Member") {
                                            appendMember({
                                              ...insuredMemberDefaultValues,
                                            });
                                          } else {
                                            removeMember(memberIndex);
                                            dispatch(
                                              removeFileURLAction({
                                                parent: "members",
                                                index: memberIndex,
                                                fieldKey: "uploadDocumentim",
                                              }),
                                            );
                                            setCurrentField({
                                              field: "",
                                              index: null,
                                            });
                                          }
                                        }}
                                      >
                                        {`${action === "Add Member" ? "+ Add" : "- Remove"}`}
                                      </Button>
                                    )
                                  );
                                })}
                              </div>
                            )}
                          {[NOMINEE_DETAILS].includes(value) &&
                            watchNominee?.[0]?.relationP !== "Yes" && (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {_.compact([
                                  multipleNominee?.length - 1 === memberIndex &&
                                  "Add Nominee",
                                  multipleNominee?.length > 1 &&
                                  "Remove Nominee",
                                ]).map(action => {
                                  return (
                                    activeCount !== 1 && (
                                      <Button
                                        type="button"
                                        themeType={"theme1"}
                                        bgColor={"#11555F"}
                                        style={{
                                          marginLeft: "10px",
                                          float: "right",
                                        }}
                                        className="multiple_action_btn"
                                        onClick={() => {
                                          if (action === "Add Nominee") {
                                            appendNominee({
                                              ...nomineeDefaultValues,
                                            });
                                          } else {
                                            removeNominee(memberIndex);
                                            setCurrentField({
                                              field: "",
                                              index: null,
                                            });
                                          }
                                        }}
                                      >
                                        {`${action === "Add Nominee" ? "+ Add" : "- Remove"}`}
                                      </Button>
                                    )
                                  );
                                })}
                              </div>
                            )}
                          {/* {!selectedOneTimePayment &&
                            [PAYMENT_DETAILS].includes(value) && (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {_.compact([
                                  multiplePayment?.length - 1 === memberIndex &&
                                    "Add Payment",
                                  multiplePayment?.length > 1 &&
                                    "Remove Payment",
                                ]).map((action, index) => {
                                  return (
                                    <Button
                                      type="button"
                                      themeType={"theme1"}
                                      bgColor={"#11555F"}
                                      style={{
                                        marginLeft: "10px",
                                        float: "right",
                                      }}
                                      onClick={() => {
                                        if (action === "Add Payment") {
                                          appendPayment({
                                            ...paymentDefaultValues,
                                          });
                                        } else {
                                          removePayment(memberIndex);
                                          dispatch(
                                            removeFileURLAction({
                                              parent: "payment",
                                              index: memberIndex,
                                              fieldKey: "paymentFile",
                                            }),
                                          );
                                          if (isTermLob || isInvestmentLob) {
                                            dispatch(
                                              removeFileURLAction({
                                                parent: "payment",
                                                index: memberIndex,
                                                fieldKey: "PaymentReceipt",
                                              }),
                                            );
                                            dispatch(
                                              removeFileURLAction({
                                                parent: "payment",
                                                index: memberIndex,
                                                fieldKey: "itCertificate",
                                              }),
                                            );
                                          }
                                          setCurrentField({
                                            field: "",
                                            index: null,
                                          });
                                        }
                                      }}
                                    >
                                      {`${action === "Add Payment" ? "+ Add" : "- Remove"}`}
                                    </Button>
                                  );
                                })}
                              </div>
                            )} */}
                        </div>
                      ),
                    )
                  ) : [PREMIUM_DETAILS].includes(value) ? (
                    <>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        onMouseEnter={() => {
                          setFormName(value);
                        }}
                      >
                        {RenderFormFieldsEndorsement({
                          fieldsArray: cardFieldsPrevPolicyToggle?.[
                            index
                          ]?.filter(
                            item => item?.is_add_on_premium_key !== "Y",
                          ),
                          formTitle: value,
                          formIndex: index,
                          nestedForm: "",
                          nestedIndex: "",
                          methods: methods,
                          otherData: otherData,
                          otherFunctions: otherFunctions,
                          masterData: masterData,
                          // formDifferences: formDifferences,
                          activeCount: activeCount,
                          payloadCheck: endorsementDifference,
                          isPremiumDetailsReadOnly: isPremiumDetailsReadOnly,
                          theme,
                          PREMIUM_FIELDS,
                        })}
                      </Grid>
                      {/* <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          type="button"
                          themeType={"theme1"}
                          bgColor={"#11555F"}
                          style={{
                            marginLeft: "10px",
                            float: "right",
                          }}
                          className="multiple_action_btn"
                          onClick={() => {
                            setShowAddonBifurcation(!showAddonBifurcation);
                          }}
                        >
                          {`${showAddonBifurcation ? "Remove" : "Add"} Addon Bifurcation`}
                        </Button>
                      </div> */}
                      {showAddonBifurcation &&
                        multipleAdditionCardMapping[value]?.fields?.map(
                          (member, memberIndex) => (
                            <div
                              key={
                                multipleAdditionCardMapping[value]
                                  ?.keyForArray?.[memberIndex]?.id
                              }
                            >
                              {!memberIndex && (
                                <MemberCount>Addon Bifurcation</MemberCount>
                              )}
                              <MemberCount index={memberIndex}>
                                {multipleAdditionCardMapping[value]?.label}
                                {memberIndex + 1}
                              </MemberCount>
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              >
                                {RenderFormFieldsEndorsement({
                                  fieldsArray: member[0],
                                  formTitle: value,
                                  formIndex: index,
                                  nestedForm:
                                    multipleAdditionCardMapping[value]
                                      ?.nestedForm,
                                  nestedIndex: memberIndex,
                                  methods: methods,
                                  otherData: otherData,
                                  otherFunctions: otherFunctions,
                                  masterData: masterData,
                                  // formDifferences: formDifferences,
                                  activeCount: activeCount,
                                  payloadCheck: endorsementDifference,
                                  isPremiumDetailsReadOnly:
                                    isPremiumDetailsReadOnly,
                                  theme,
                                  PREMIUM_FIELDS,
                                })}
                              </Grid>
                              {showAddonBifurcation &&
                                [PREMIUM_DETAILS].includes(value) && (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {_.compact([
                                      multipleAddons?.length - 1 ===
                                      memberIndex && "Add Addon",
                                      multipleAddons?.length > 1 &&
                                      "Remove Addon",
                                    ]).map(action => {
                                      return (
                                        activeCount !== 1 && (
                                          <Button
                                            type="button"
                                            themeType={"theme1"}
                                            bgColor={"#11555F"}
                                            style={{
                                              marginLeft: "10px",
                                              float: "right",
                                            }}
                                            className="multiple_action_btn"
                                            onClick={() => {
                                              if (action === "Add Addon") {
                                                appendAddon({
                                                  ...addonDefaultValues,
                                                });
                                              } else {
                                                removeAddon(memberIndex);
                                                setCurrentField({
                                                  field: "",
                                                  index: null,
                                                });
                                              }
                                            }}
                                          >
                                            {`${action === "Add Addon" ? "+ Add" : "- Remove"}`}
                                          </Button>
                                        )
                                      );
                                    })}
                                  </div>
                                )}
                            </div>
                          ),
                        )}
                    </>
                  ) : (
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      onMouseEnter={() => {
                        setFormName(value);
                      }}
                    >
                      {RenderFormFieldsEndorsement({
                        fieldsArray: cardFieldsPrevPolicyToggle?.[index],
                        formTitle: value,
                        formIndex: index,
                        nestedForm: "",
                        nestedIndex: "",
                        methods: methods,
                        otherData: otherData,
                        otherFunctions: otherFunctions,
                        masterData: masterData,
                        // formName: formName,
                        // formDifferences: formDifferences,
                        activeCount: activeCount,
                        payloadCheck: endorsementDifference,
                        isPremiumDetailsReadOnly: isPremiumDetailsReadOnly,
                        theme,
                        PREMIUM_FIELDS,
                      })}
                    </Grid>
                  )}
                </PolicyCreateStyled>
                {[INSURED_MEMBER_DETAILS].includes(value) && (
                  <RenderNewChanges
                    endorsementData={endorsementDifference?.data}
                    value={value}
                    dataKey="members"
                    title="Members"
                    nameKey="insuredmemberName"
                  />
                )}
                {[NOMINEE_DETAILS].includes(value) && (
                  <RenderNewChanges
                    endorsementData={endorsementDifference?.data}
                    value={value}
                    dataKey="nominee"
                    title="Nominee"
                    nameKey="nomineeFullname"
                  />
                )}
                {[PREMIUM_DETAILS].includes(value) && (
                  <RenderNewChanges
                    endorsementData={endorsementDifference?.data}
                    value={value}
                    dataKey="addOnPremiums"
                    title="Add On"
                    nameKey="addons"
                  />
                )}

                <EndorsementFieldHeaderDifferentiator></EndorsementFieldHeaderDifferentiator>
              </>
            </ProposalCreateSummaryStyled>
          );
        })}
      <Grid
        container
        sx={{
          marginLeft: "15px",
        }}
      >
        <Tooltip
          title={
            // !isBusinessTypeConditionsLobWise &&
            // !isInvestmentLob &&
            !isWorkmensCompensationLob &&
            getTooltipTitle(
              totalNomineeShare,
              // nomineeShareisZero,
              activeCount,
              true,
            )
          }
        >
          {/* <Grid item xs={12} sm={12} md={6} lg={2.75}>
            <Dropdown
              control={control}
              // maxMenuHeight={200}
              name="endorsementStage"
              id="endorsementStage"
              placeholder="Select Endorsement Stage"
              selectObj={endorsementStageObj}
              label={"Endorsement Stage"}
              errors={errors}
              proposalRead={false}
              isDisabled={
                activeCount !== 0 ||
                // (totalNomineeShare !== undefined &&
                //   totalNomineeShare !== 100) || // Disable if totalNomineeShare is defined and not 100
                // (totalNomineeShare === 100 &&
                //   nomineeShareisZero !== undefined &&
                //   nomineeShareisZero) ||
                watch("endorsementStage")?.value
              }
              watch={watch}
              // defaultValue={"Broker"}
              isRequired={activeCount !== 1}
            />
          </Grid> */}
        </Tooltip>
      </Grid>

      <Tooltip
        title={
          // !isBusinessTypeConditionsLobWise &&
          // !isInvestmentLob &&
          !isWorkmensCompensationLob &&
          getTooltipTitle(
            totalNomineeShare,
            // nomineeShareisZero,
            activeCount,
          )
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Button
            type="submit"
            // bgColor="transparent"
            textColor="#000000 !important"
            id="add_broker"
            variant="outlined"
          // borderColor="#11555F !important"
          // disabled={handleDisable(
          //   watch("endorsementStage"),
          //   totalNomineeShare,
          //   // nomineeShareisZero,
          // )}
          >
            {`${activeCount === 0 ? "Save and Proceed" : "Submit"}`}
            {/* {`${activeCount !== 0 && 'Submit'}`} */}
          </Button>


          {/* {activeCount !== 0 && (
            <Tooltip title={"Go Back"}>
              <Button
                onClick={() => {
                  setValue("endorsementStage", null);
                  setActiveCount(prev => prev - 1);
                }}
                bgColor="transparent"
                textColor="#11555F"
                id="go_back"
                variant="outlined"
                borderColor="#11555F"
              >
                {`Go Back`}
              </Button>
            </Tooltip>
          )} */}
        </div>
      </Tooltip>
    </form>
  );
}

export default FieldListComponentForEndorsement;
