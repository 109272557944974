import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import styled from "styled-components";
import { convertCamelCaseToNormal } from "../../HelperFunctions/HelperFunctions";

const ProposalListingView = ({ viewData }) => {
  const dataArray = useMemo(
    () =>
      Object.entries(viewData).reduce(
        (acc, [key, value]) =>
          key !== "id" && {
            ...acc,
            [convertCamelCaseToNormal(key)]: value,
          },
        {},
      ),
    [viewData],
  );
  return (
    <StyledContainerGrid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{ padding: "23px 20px" }}
    >
      {Object.entries(dataArray).map(([key, value]) => {
        return (
          !!key &&
          !!value && (
            <Grid item lg={3} md={4} sm={6} mb={2}>
              <StyledLabel>
                {key === "Uservalue" ? "User Type" : key}
              </StyledLabel>
              <StyledValue>{value || "No Data Available"}</StyledValue>
            </Grid>
          )
        );
      })}
    </StyledContainerGrid>
  );
};

export default ProposalListingView;
const StyledLabel = styled("label")({
  color: "rgb(17, 85, 95)",
  paddingLeft: "1px",
  fontSize: "12px",
  fontFamily: "Montserrat-Medium",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  display: "inline-block",
});

export const StyledValue = styled("span")({
  gridArea: "1 / 1 / 2 / 3",
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "pre-line",
  color: "#000c",
  marginLeft: "3px",
  marginRight: "2px",
  boxSizing: "border-box",
  fontWeight: "600",
  fontSize: "14px",
  display: "inline-block",
  wordWrap: "break-word",
});

const StyledContainerGrid = styled(Grid)`
  ${props =>
    props.modalTitle !== undefined
      ? `
      height: 475px;
      overflow: scroll;
    `
      : ""}
`;
