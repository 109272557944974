import { isEmpty } from "lodash";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "styled-components";

function Checkbox({
  label,
  checkboxFirst,
  control,
  name,
  onClick,
  isDisabled,
  disabled,
  defaultValue,
  onChange,
  open,
  setOpen,
  selectedBrokerageType,
  isForBrokerageRule,
  keyName,
  tileVariant,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : false}
      render={({ field }) => (
        <MainContainer tileVariant={tileVariant}>
          {isForBrokerageRule && (
            <CheckboxStyled
              checkboxFirst={checkboxFirst}
              // onClick={() => {
              //   if (isForBrokerageRule) {
              //     setOpen(!open);
              //   }
              // }}
              disabled={isDisabled}
              tileVariant={tileVariant}
              // isCheckboxCheckedStyle={Boolean(selectedChecboxNames === keyName)}
              htmlFor={name}
            >
              {label}
            </CheckboxStyled>
          )}
          <input
            type="checkbox"
            {...field}
            id={name}
            checked={
              // isForBrokerageRule
              //   ? Boolean(
              //       singleSelectedData?.length > 0 &&
              //         selectedChecboxNames === keyName
              //     )
              // : field.value || false
              field.value
            }
            onClick={(e) => {
              if (onClick) {
                onClick(e);
              }
              if (isForBrokerageRule) {
                setOpen(!open);
              }
            }}
            // onChange={(e) => {
            //   onChange(e);
            //   field.onChange(e);
            // }}
            // checked={field.value}
            // onClick={onClick}
            onChange={(e) => [
              onChange && onChange(e),
              field?.onChange(e) && field.onChange(e),
            ]}
            disabled={
              isDisabled
                ? true
                : disabled
                ? field.value
                  ? false
                  : true
                : false
            }
          />
          {label && !isForBrokerageRule && (
            <label htmlFor={name}>{label}</label>
          )}
        </MainContainer>
      )}
    />
  );
}

export default Checkbox;

const MainContainer = styled.div`
  input[type="checkbox"] {
    display: ${({ tileVariant }) => (tileVariant ? "none" : "")};
  }
  input {
    cursor: pointer;
    accent-color: teal;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const CheckboxStyled = styled.label`
  border-radius: 5px;
  padding: 10px 0;
  flex-grow: 1;
  pointer-events: ${(props) => props.disabled && "none"};
  display: flex;
  gap: 10px;
  flex-direction: ${(props) => (props.checkboxFirst ? "row-reverse" : "row")};
  justify-content: ${(props) => (props.checkboxFirst ? "flex-end" : "center")};
  label {
    color: ${(props) => props.disabled && "rgba(0, 0, 0, 0.5)"};
  }
  border: ${({ tileVariant }) => (tileVariant ? "1px solid #C4C4C4" : "")};
  border-radius: 8px;
  padding: ${({ tileVariant }) => (tileVariant ? "10px" : "")};
  cursor: pointer;
  background-color: ${({ tileVariant, isCheckboxCheckedStyle }) =>
    tileVariant && isCheckboxCheckedStyle ? "lightgrey" : "#FAFAFA"};
  :hover {
    background-color: ${({ isCheckboxCheckedStyle, tileVariant }) =>
      tileVariant ? (isCheckboxCheckedStyle ? "" : "#ededed") : ""};
  }
  transition: all 0.15s linear;
`;
