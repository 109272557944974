import { useMutation, useQuery } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";

export const useGetBanner = () => {
  const { data } = useQuery(["banner"], () =>
    httpClient(`customer_banners/listing`)
  );
  return { data };
};

//Function to register user click on dashboard banner
// This reflects in Brocore: banner-config table
export const useBannerClick = () => {
  const { mutate } = useMutation((payload) =>
    httpClient(`customer_banners/update-action`, {
      method: "POST",
      data: payload,
    })
  );
  return { mutate };
};

export const useGetPolicyStatus = () => {
  const { data } = useQuery(["policyStatus"], () => {
    return httpClient(`Dash_Board/records`, {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        search: {
          lobId: 0,
          policynumber: "",
          bookDateTo: "2024-06-05",
          bookDateFrom: "2024-06-05",
          status: "",
        },
      },
    });
  });
  return { data };
};

export const useGetPolicCoverage = () => {
  const { data } = useQuery(["policyCoverage"], () =>
    httpClient(`customer_records/listPaging/coverage`, {})
  );
  return { data };
};

export const useRenewalListing = () => {
  const { data, isLoading } = useQuery(["renewalListing"], () =>
    httpClient(`/policy_master/renewal/listing`, {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        search: {
          lobId: 0,
          policynumber: "",
        },
      },
    })
  );
  return { data, isLoading };
};

export const useGetPendingTask = () => {
  const { data } = useQuery(["pendingTask"], () =>
    httpClient(`customer_activity_history/listing/transactionAndEntityId`, {
      method: "POST",
      data: {
        transactionType: "",
        activityType: "PENDING",
        entityId: 0,
      },
    })
  );
  return { data };
};

export const useGetRiskoMeter = () => {
  const { mutate, data, isLoading } = useMutation((payload) => {
    return httpClient("Dash_Board/fetch_customer_risk", {
      method: "POST",
      data: payload,
    });
  });
  return { mutate, data, isLoading };
};
