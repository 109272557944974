import { difference, isEmpty, isObject } from "lodash";

export function isMemberDeleted(difference, arrayKey = "") {
  if (isEmpty(difference)) return;
  let deletedMemberArray;
  if (difference?.[arrayKey]?.length) {
    deletedMemberArray = difference?.[arrayKey]?.filter(value => {
      return value?.type === "DELETION";
    });

    if (deletedMemberArray?.length) {
      return deletedMemberArray;
    } else {
      return [];
    }
  }
  return [];
}
