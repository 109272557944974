import { useQueries, useQuery } from "@tanstack/react-query";
import HttpClient from "../api/httpClient";
import { useDispatch } from "react-redux";
// import { setFieldDataAction } from "../modules/Brokerage/Brokerage.slice";
import { useLocation } from "react-router-dom";

const useGetMasterTableData = (tableName) => {
	const location = useLocation();
	const fetchMenuMasterData = (tableName) => {
		return (
			location?.pathname !== "/customer-vehicle-lists" &&
			location?.pathname !== "/customer-add" &&
			HttpClient(
				`segregation/get_data/${tableName?.masterSlug}/${tableName.fieldSlug}?lobId=${tableName.lobId}`
			).then((response) => response.data)
		);
	};

	const data = useQueries({
		queries: tableName.map((tableName) => {
			return {
				queryKey: [
					"master-table",
					tableName?.lobId
						? tableName?.masterSlug + tableName?.fieldSlug + tableName?.lobId
						: tableName?.masterSlug + tableName?.fieldSlug,
				],
				queryFn: () => fetchMenuMasterData(tableName),
			};
		}),
	});
	return { data };
};

export default useGetMasterTableData;

export const useGetBrokerageData = (master) => {
	const dispatch = useDispatch();
	const { data } = useQuery(
		["getBrokerageData", master],
		() => {
			return HttpClient(
				`/get_data/${master?.slug}/${master?.value}?lobId=${master?.lobId}`
			);
		}
		// {
		//   onSuccess: (data) => {
		//     dispatch(setFieldDataAction(data));
		//   },
		// }
	);
	return { data };
};

export const useGetBundleBrokerageData = (master) => {
	const { data } = useQuery(["getBundleBrokerAgeData", master], () => {
		return HttpClient(
			`get_data/${master?.slug}/${master?.value}?lobId=${master?.lobId}`
		);
	});
	// console.log('master', master.value, data?.data?.data?.[0]?.data)
	// console.log('data',data)
	return { data };
};

// separate hook for policy entry (do not touch :)
export const useGetMasterTableDataFormWise = ({
	tableName,
	currentFormMasterSlugs,
	activeFormTitle,
}) => {
	const fetchMenuMasterData = (tableName) => {
		// console.log("conditional api called");
		return HttpClient(
			`get_data/${tableName?.masterSlug}/${tableName.fieldSlug}?lobId=${tableName.lobId}`
		).then((response) => response.data);
	};

	const data = useQueries({
		queries: tableName.map((tableName) => {
			const isMasterSlugIncluded = currentFormMasterSlugs?.includes(
				tableName?.masterSlug
			);
			return {
				queryKey: [
					"master-table",
					tableName?.lobId
						? tableName?.masterSlug + tableName?.fieldSlug + tableName?.lobId
						: tableName?.masterSlug + tableName?.fieldSlug,
				],
				queryFn: () =>
					// isMasterSlugIncluded &&
					fetchMenuMasterData(tableName),
			};
		}),
	});
	return { data };
};

export const useGetCompaniesAccordingToLOB = (lob) => {
	const payload = {
		page: 0,
		size: 0,
		sortBy: "",
		sortOrder: "",
		searchValue: {
			category: "",
			companyName: "",
			lob: [lob],
		},
	};
	const { data, isFetching } = useQuery({
		queryKey: ["companies", payload],
		queryFn: () =>
			// HttpClient("/company/list/Paging_Sorting", {
			HttpClient("common_master/company/list/Paging_Sorting", {
				method: "POST",
				data: payload,
			}),
	});
	return { data, isFetching };
};
