import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsFiletypeXls, BsImages } from "react-icons/bs";
import styled from "styled-components";
import Swal from "sweetalert2";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Excel from "../../../utils/excel.png";
import {
  useDownLoadBulkUploadSampleFile,
  useUploadBulkUploadAPI,
} from "./service";
import { Button } from "../../../components";
import { isValidURL } from "../../../HelperFunctions/HelperFunctions";
import Checkbox from "../../../components/form-components/checkbox/checkbox";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "2px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  alignItems: "center",
  padding: "5px",
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  // overflow: "hidden",
};
const filename = {
  fontSize: "13px",
  whiteSpace: "nowrap",
  position: "relative",
  left: "5px",
  verticalAlign: "middle",
  fontWeight: "bold",
};
const img = {
  display: "block",
  width: "40px",
  height: "40px",
};

export const ExpenseDetailsBulkUpload = ({
  themeType,
  editDetails,
  heading,
  handleClose,
  mutate,
  isMadeChanges,
}) => {
  const { theme } = useSelector((state) => state?.theme);
  const { control, watch } = useForm();
  const [files, setFiles] = useState([]);
  // const { mutate } = useUploadBulkUploadAPI();
  const { data } = useDownLoadBulkUploadSampleFile();

  const handleDownloadSampleFile = () => {
    const urlIsValid = isValidURL(data?.data?.data);
    if (urlIsValid) {
      window.open(data?.data?.data, "_blank");

      Swal.fire({
        text: "Expense Details Bulk Sample File Successfully Downloaded.",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    } else {
      Swal.fire("", "Something went wrong !", "error");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: Excel,
          })
        )
      );
    },
  });

  const handleUploadFile = (file) => {
    if (file.length === 0) {
      Swal.fire({
        text: "Please upload your excel file",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append(
      "id",
      new URLSearchParams(window.location.search).get("mongoId")
    );
    formData.append("isupdate", watch("isSimilarRecords") ? "Y" : "N");
    mutate(formData);
    setTimeout(() => {
      mutate(formData);
    }, 500);
    handleClose();
  };

  const removeSelection = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMadeChanges) {
      Swal.fire(
        "Unsaved Changes Detected !",
        "Continuing with the bulk upload may result in the loss of unsaved data.",
        "question"
      );
    }
  }, [isMadeChanges]);

  return (
    <StyledContainer className="container">
      <PickerWrap themeType={themeType} editDetails AddDeleteFiles>
        <div {...getRootProps({ className: "dropzone" })}>
          <BsImages className="ImageIcon" />
          <input {...getInputProps()} />
          <p
            style={{
              margin: "0px",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Drag and drop an File to upload or
          </p>
          <Button themeType={themeType}>Browse Computer</Button>
          <ul>
            <li>You can upload excel file</li>
            <li>Maximum file size is 10MB</li>
            <li>Select only XLS Files</li>
          </ul>
        </div>

        {!editDetails && (
          <XlsAndButtonWrap>
            <div style={{ display: "flex" }}>
              <BsFiletypeXls
                size={30}
                // color={"#2ca92a"}
                className="ImageIcon"
              />
              <a
                style={{ cursor: "pointer", color: theme?.ascentColor }}
                onClick={handleDownloadSampleFile}
              >
                Click to Download Sample File
              </a>
            </div>
            <Checkbox
              name={`isSimilarRecords`}
              label="Please check, If similar records are there in your excel"
              control={control}
              disabled={false}
              defaultValue={false}
            />

            <Button
              themeType={themeType}
              borderRadius={"35px !important"}
              padding={"20px"}
              onClick={() => {
                handleUploadFile(files);
              }}
            >
              Click to Upload
            </Button>
          </XlsAndButtonWrap>
        )}
        <aside style={thumbsContainer}>
          {files.map((file) => (
            <div style={thumb} key={file.name}>
              <div style={thumbInner}>
                <img
                  src={file.preview}
                  alt="file"
                  style={img}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
                <p style={filename}>
                  {file.name.slice(0, file.path.indexOf("."))}
                </p>
                <CloseOutlinedIcon
                  onClick={() => removeSelection()}
                  style={{
                    position: "relative",
                    top: "10px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </aside>
      </PickerWrap>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  h1 {
    margin: 0 0 10px 0;
  }
  .CloseIcon {
    cursor: pointer;
    color: #000000;
    position: relative;
    left: 50%;
    bottom: 3px;
    font-size: 24px;
  }
`;
const PickerWrap = styled.div`
  // margin-bottom: ${(props) => (props.editDetails ? "5px" : "50px")};
  padding: 20px 20px 0;
  background: #fcfcff;
  width: ${(props) => (props.AddDeleteFiles ? "100%" : "95%")};
  box-shadow: 1px 1px 5px gray;

  /* border: 1px solid red; */
  .dropzone {
    cursor: pointer;
    background: #fcfcff;
    border: 2px dashed #a4a4cb;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
  }
  .ImageIcon {
    font-size: 60px;
    color: ${({ themeType, theme }) =>
      // themeType && theme ? theme?.[themeType]?.litePrimary : "#24aa9a"};
      theme?.ascentColor || "#24aa9a"};
  }
  ul {
    font-size: 14px;
  }
  .filename {
    position: relative;
  }
`;

const XlsAndButtonWrap = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  a {
    margin: auto;
    color: ${({ themeType, theme }) =>
      themeType && theme ? theme?.[themeType]?.litePrimary : "#24aa9a"};
    margin-left: 10px;
    font-weight: 600;
  }
`;
