import styled from "styled-components";

export const Label = styled.label`
  transition: all 0.3s;
  cursor: ${props => (props.blockDetails ? "not-allowed" : "text")};
  pointer-events: ${props => (props.blockDetails || props.readOnly) && "none"};
  top: ${props => (props.userViewInput ? "-30px" : "-22px")};
  left: ${props => (props.userViewInput ? "12px" : "3px")};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme, themeType }) => theme?.inputLabelColor};
  color: ${props => props.userViewInput && "#000"};
  line-height: 18px;
  font-size: ${({ theme: { fontSize } }) =>
        fontSize ? `calc(1rem + ${fontSize - 92}%)` : "12px"};
  font-weight: 500;
  & > span {
    font-size: ${({ theme: { fontSize } }) =>
        fontSize ? `calc(1.5rem + ${fontSize - 92}%)` : "1.5rem"};
  }
  .info {
    color: red;
    font-size: 12px;
    z-index: 1;
    position: relative;
    top: 1px;
  }
`;

export const TextInput = styled.input`
  min-height: ${props => (props.userViewInput ? "40px" : "32px")};
  margin-bottom: ${props => props.userViewInput && "20px"};
  cursor: text;
  width: 100%;
  border-radius: 4px;
  transition: all 0.15s linear;
  // min-width: 180px;
  outline: none;
  color: #000000;
  padding: ${props => (props.proposalRead ? "0" : "0 8px")};
  font-family: inherit;
  font-size: ${({ theme: { fontSize } }) =>
        fontSize ? `calc(1rem + ${fontSize - 92}%)` : "12px"};
  border: ${({ error, modern }) =>
        error ? "1px solid red" : !modern ? "1px solid #A9A7A7" : "none"};
  background-color: ${({ readOnly, proposalRead, theme, modern }) =>
        (readOnly || proposalRead) && !modern
            ? "#E2E2E2"
            : modern
                ? theme?.inputBackgroundColor
                : "#fff"};
  cursor: ${props => props.blockDetails && "not-allowed"};
  pointer-events: ${props =>
        (props.blockDetails || props.proposalRead || props.readOnly) && "none"};
  font-weight: ${props => props.proposalRead && "700"};
  font-size: ${props => props.proposalRead && "14px"};
  margin-top: ${props => props.proposalRead && "0"};
  margin-left: ${props => props.proposalRead && "3px"};
  border: ${props => props.proposalRead && "none"};
  text-align: left;
  &:focus {
    outline: none;
    border: 1px solid ${({ error, theme }) => (error ? "red" : "none")};
  }
  ::placeholder {
    font-size: ${({ theme: { fontSize } }) =>
        fontSize ? `calc(15px + ${fontSize - 92}%)` : "12px"};
    opacity: 0.5;
    letter-spacing: 0.5px;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
  }
  @media (max-width: 767px) {
    min-width: 195px;
    max-width: inherit;
  }
  @media (max-width: 450px) {
    min-width: 195px;
    max-width: inherit;
  }
`;

export const CustomLabel = styled.label`
  color: ${({ theme, modern }) => theme?.inputLabelColor};
  padding-left: 1px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
`;

export const DatePickerWrapper = styled.div`
  min-height: 75px;
  .MuiInputBase-root {
    font-size: 12px;
    height: 32px;
    background-color: ${({ readOnly, theme, modern }) =>
        readOnly ? "#E2E2E2" : modern ? theme?.inputBackgroundColor : "white"};
    pointer-events: ${({ proposalRead }) => proposalRead && "none"};
    border: ${({ proposalRead, errorMessage, modern, theme }) =>
        !proposalRead && errorMessage
            ? "1px solid red"
            : proposalRead
                ? "1px solid #b9b9b9"
                : modern
                    ? "none"
                    : "1px solid #b9b9b9"};
    fieldset {
      border: none;
    }
  }

  svg {
    display: ${({ readOnly }) => readOnly && "none"};
  }

  .MuiFormControl-root {
    width: 100%;
    & input {
      padding: 5px;
      background-color: ${props => props.readOnly && "#E2E2E2"};
      color: ${({ proposalRead }) => proposalRead && "black"};
      font-weight: ${({ proposalRead }) => proposalRead && "bold"};
      font-size: ${({ proposalRead }) => (proposalRead ? "14px" : "12px")};
      cursor: default;
    }
    button {
      display: ${({ proposalRead }) => proposalRead && "none"};
    }
  }
`;
