import { Box, Skeleton } from "@mui/material";
import styled from "styled-components";

const PaymentLogsSkeleton = ({ paymentReminder }) => {
  return (
    <LogWrapper paymentReminder={paymentReminder}>
      <PolicyDetails>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
      </PolicyDetails>
      <PolicyDetails>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
      </PolicyDetails>
      <PolicyDetails>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
      </PolicyDetails>
      <PolicyDetails style={{ marginBottom: 0 }}>
        <ContentWrapper>
          <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
          <Skeleton width={160} height={25} />
        </ContentWrapper>
      </PolicyDetails>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Skeleton width={"100%"} height={50} sx={{ borderRadius: "10px" }} />
        <Box
          sx={{
            border: "0.75px solid #e4e4e4",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Skeleton width={110} height={25} />
          <PayerDetails>
            <ContentWrapper>
              <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
              <Skeleton width={160} height={25} />
            </ContentWrapper>
            <ContentWrapper>
              <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
              <Skeleton width={160} height={25} />
            </ContentWrapper>
            <ContentWrapper>
              <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
              <Skeleton width={160} height={25} />
            </ContentWrapper>
          </PayerDetails>
          <PayerDetails>
            <ContentWrapper>
              <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
              <Skeleton width={30} height={40} sx={{ marginTop: "-10px" }} />
            </ContentWrapper>
            <ContentWrapper>
              <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
              <Skeleton width={30} height={40} sx={{ marginTop: "-10px" }} />
            </ContentWrapper>
            <ContentWrapper>
              <Skeleton width={110} height={25} sx={{ marginRight: "5px" }} />
              <Skeleton width={30} height={40} sx={{ marginTop: "-10px" }} />
            </ContentWrapper>
          </PayerDetails>
        </Box>
      </Box>
    </LogWrapper>
  );
};

export default PaymentLogsSkeleton;

const LogWrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 0.75px solid rgb(228, 228, 228);
  margin: ${({ paymentReminder }) => (paymentReminder ? "20px" : "15px 0 0 0")};
`;

const PolicyDetails = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex: 1;
`;

const PayerDetails = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
