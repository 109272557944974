import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useGetRiskoMeter } from "../customerDashboard/service";
import { useGetCityList, useGetStateList, useRiskometer } from "./service";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { Input } from "../../components";
import { saveData } from "./riskoMeterMember.slice";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
  state: Yup.object().required("State is required"),
  city: Yup.string().test("city", "City is required", function (value) {
    if (value) {
      return value;
    }
  }),
});

const RiskometerMedicalHistory = ({ handleSave, handleBack, activeTab }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const { resData } = useSelector((state) => state.riskoMeterMember);

  const [selectedCity, setSelectedCity] = useState("");
  const { data: stateList } = useGetStateList();
  const stateId = watch("state");
  const { data: cityList } = useGetCityList(stateId?.id);

  const cityLists = cityList?.data?.data;

  const ListOfState = stateList?.data?.data?.map((value) => {
    return {
      value: value.stateName,
      label: value.stateName,
      id: value.stateId,
    };
  });

  const popularCities = [
    "Delhi",
    "Bengaluru",
    "Hyderabad",
    "Pune",
    "Mumbai",
    "Thane",
    "Gurgaon",
    "Ghaziabad",
    "Lucknow",
    "Ahmedabad",
  ];
  const allCities = cityLists?.map(({ cityName, ...item }, index) => cityName);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const { mutateAsync: ageRiskometer, isLoading: customerAddLoading } =
    useRiskometer(activeTab);

  const { coverageAmountPayload, allData, range } = useSelector(
    (state) => state.riskoMeterMember
  );

  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge)?.length > 1;

  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");

  const onSubmit = async (e) => {
    // e.preventDefault();
    const validationPassed = await trigger();

    if (!validationPassed) {
      return;
    }
    const payload = {
      questionId: 5,
      lobId: +lobId,
      request_data: {
        state: watch("state")?.value,
        city: watch("city"),
        familyType: coverageAmountPayload?.request_data?.familyType,
      },
    };

    dispatch(saveData({ city: watch("city"), state: watch("state") }));
    const res = await ageRiskometer(payload);

    if (res?.success) {
      handleSave();
    }
  };

  const { theme } = useSelector(({ theme }) => theme);
  const {
    mutate: GetRiskometer,
    data: RiskometerData,
    isLoading,
  } = useGetRiskoMeter();

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    // Set color for maximum value label
    axis.renderer.labels.template.adapter.add("fill", (fill, target) => {
      if (target.dataItem && target.dataItem.value === 20) {
        return am4core.color("#FFF"); // Change color to black for max value
      }
      return fill;
    });

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  useEffect(() => {
    const timer = setTimeout(() => {
      Object.entries(errors)?.map(([key, values]) => {
        if (_.isEmpty(watch(key)) || !_.isEmpty(watch(key))) {
          trigger(key);
        }
      });
    }, 500);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [JSON.stringify(watch()), JSON.stringify(Object.keys(errors))]);

  useEffect(() => {
    setValue("city", selectedCity);
  }, [selectedCity]);

  useEffect(() => {
    if (allData) {
      setValue("city", allData?.city);
      if (allData?.state) {
        setValue("state", {
          value: allData?.state?.value,
          label: allData?.state?.label,
        });
      }
    }
  }, [allData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={handleBack}>
            <ArrowBackIcon />
          </BackButton>
        </div>
        <Title>Select your city</Title>
        <form className="space-y-4">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "20rem",
              }}
            >
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Dropdown
                  themeType={"theme1"}
                  id={`state`}
                  label="State"
                  control={control}
                  selectObj={ListOfState}
                  // isMulti={false}
                  maxMenuHeight={200}
                  placeholder={"Select"}
                  errors={errors}
                  watch={watch}
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Input
                  themeType={"theme1"}
                  label={"City"}
                  name={"city"}
                  id={"city"}
                  maxLength="100"
                  placeholder={`Enter City`}
                  autoComplete="none"
                  defaultValue={""}
                  required={false}
                  inputRef={register("city")}
                  errors={errors}
                />
              </Grid>
            </Grid>

            {allCities && <Title>Popular cities</Title>}
            {allCities && (
              <CityList>
                {allCities?.map((city) => (
                  <CityButton
                    key={city}
                    isSelected={selectedCity === city}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedCity(city);
                    }}
                    className={selectedCity === city ? "selected" : ""}
                  >
                    {city}
                  </CityButton>
                ))}
              </CityList>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <AddButton
              onClick={handleSubmit((data) => {
                onSubmit(data);
              })}
            >
              Continue
            </AddButton>
          </div>
        </form>
      </Container>
      {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />}
    </div>
  );
};

export default RiskometerMedicalHistory;

const CityButton = styled.button`
  padding: 8px 16px;
  margin: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s ease;

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    background-color: ${theme?.ascentColor};
    color: white;
  `}

  &:hover {
    border: 1px solid ${({ theme }) => theme?.ascentColor};
  }
`;

const CityList = styled.div`
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
  overflow-y: auto;
  height: 150px;
  justify-content: ${(props) =>
    props?.allCities?.length <= 2 ? "center" : "flex-start"};
  align-items: center;
  gap: 10px;

  /* ::-webkit-scrollbar {
    display: none;
  } */
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
`;

const Container = styled.div`
  padding: 24px;
  width: 125vw;
  max-width: 800px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;


const AddButton = styled.button`
  padding: 10px 20px;
  margin: 10px auto;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 256px;
`;
