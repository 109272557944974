import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import {
  setRange,
  setResAction,
  setSelfUserDataAction,
} from "./riskoMeterMember.slice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

// export const useRiskometer = () => {
//   const { data, mutate, isLoading, isError } = useMutation((payload) => {
//     return httpClient("Dash_Board/fetch_riskometer", {
//       method: "POST",
//       data: payload,
//     });
//   });

//   return { data, mutate, isLoading, isError };
// };

export const useRiskometer = (activeTab) => {
  const dispatch = useDispatch();
  const { mutateAsync, data, isLoading } = useMutation(
    (payload) => {
      return httpClient(`Dash_Board/fetch_riskometer`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data?.success) {
          Swal.fire({
            title: "Error",
            text: data?.data?.message || "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
          return;
        }
        if (data?.success) {
          if (data?.data?.data?.hasOwnProperty("range")) {
            dispatch(
              setRange({
                range: data?.data?.data?.range || 0,
                index: activeTab - 1,
              })
            );
          }
        }
        dispatch(setResAction(data));
      },
    }
  );
  return { mutateAsync, data, isLoading };
};

export const useUpdateClaimStageHistory = () => {
  const queryClient = useQueryClient();
  const { data, mutate } = useMutation((payload) => {
    return httpClient(`/claim-Stage-History/add`, {
      method: "POST",
      data: payload,
    });
  });
  return { mutate, data };
};

export const useGetCoverageAmount = (id) => {
  const { data } = useQuery(
    ["coverageAmount", id],
    () => {
      return httpClient(`/Dash_Board/customer_total_coverage/${id}`, {
        method: "GET",
      });
    },
    {
      enabled: Boolean(id),
    }
  );
  return { data };
};

export const useGetStateList = () => {
  const { data } = useQuery({
    queryKey: ["State-list"],
    queryFn: () =>
      httpClient("/state/list", {
        method: "GET",
      }),
  });
  return { data };
};

export const useGetCityList = (id) => {
  const { data } = useQuery(
    ["city", id],
    () => {
      return httpClient(`/city/list/${id}`, {
        method: "GET",
      });
    },
    {
      enabled: Boolean(id),
    }
  );

  return { data };
};

export const useGetRiskometerListing = () => {
  const { data, mutate, isLoading } = useMutation((lobId) => {
    return httpClient("/customer_lob_Wise_Risk_Mapping/listing", {
      method: "POST",
      data: lobId,
    });
  });
  return { data, mutate, isLoading };
};

export const useAddRiskometer = () => {
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      return httpClient("/customer_lob_Wise_Risk_Mapping/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        Swal.fire({
          text: `${data?.data?.message}`,
          icon: `${data?.data?.errortype}`,
          confirmButtonText: "Ok",
          confirmButtonColor: "#DC004E",
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false,
        });
      },
      onError: (error) => {
        Swal.fire({
          text: error?.message || "An unexpected error occurred.",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#DC004E",
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false,
        });
      },
    }
  );

  return { data, mutate, isLoading, isError };
};

export const useGetAnnualIncome = (id) => {
  const dispatch = useDispatch();
  const { data } = useQuery(
    ["annualIncome", id],
    () => {
      return httpClient(`/Dash_Board/fetch_customer_annual_income/${id}`, {
        method: "GET",
      });
    },
    {
      enabled: Boolean(id),
    }
  );
  dispatch(setSelfUserDataAction(data?.data?.data));

  return { data };
};
