import * as yup from "yup";
import {
  aadharRegex,
  drivingLicenseRegex,
  panRegex,
  passportRegex,
  SATP,
  TP_1_YEAR,
  TP_LONG_TERM_3_YEARS,
  TP_LONG_TERM_5_YEARS,
  voterIdRegex,
} from "../proposalCreateConstants";

export function generateDynamicYupSchema(
  dynamicArray,
  isBusinessTypeConditionsLobWise,
) {
  const yupValidate = yup.object().shape(
    dynamicArray?.reduce((acc, value) => {
      let validationRule = "";
      if (value?.key === "odexpirydate") {
        validationRule = yup
          .date()
          .nullable()
          .typeError("Date must be a valid date")
          .test(`odexpirydate`, "OD Expiry Date is required", function (value) {
            const { businessType } = this.parent;
            if (
              isBusinessTypeConditionsLobWise &&
              ![
                TP_1_YEAR,
                TP_LONG_TERM_5_YEARS,
                TP_LONG_TERM_3_YEARS,
                SATP,
              ]?.includes(businessType?.label)
            ) {
              return value;
            } else {
              return true;
            }
          });
      } else if (value?.key === "tpexpirydate") {
        validationRule = yup
          .date()
          .nullable()
          .typeError("Date must be a valid date")
          .test(`tpexpirydate`, "TP Expiry Date is required", function (value) {
            if (isBusinessTypeConditionsLobWise) {
              return value;
            } else {
              return true;
            }
          });
      } else if (value?.key === "totalPremium") {
        validationRule = yup
          .string()
          .required("Net Premium is required")
          // .typeError("Date must be a valid date")
          .test(`net-premium-is-required`, function (value, ctx) {
            const { coverage } = this.parent;
            if (!value)
              return ctx.createError({
                message: "Net Premium is required",
              });
            if (+coverage < +value) {
              return ctx.createError({
                message: `Net premium should not exceed ${isBusinessTypeConditionsLobWise ? "IDV" : "Sum Insured"}`,
              });
            }
            return true;
          });
      } else if (value.key === "identityvalue") {
        validationRule = yup
          .string()
          .required("Identity Value is required")
          .test(`identityvalue`, function (value, ctx) {
            const { idProof } = this.parent;
            const identityProof = idProof?.value?.toLowerCase();

            if (!value) {
              return true;
            }
            if (!value) {
              return ctx.createError({
                message: `${idProof?.value} is required`,
              });
            }
            if (
              identityProof?.includes("aadhaar") &&
              !aadharRegex.test(value)
            ) {
              return ctx.createError({
                message: "Aadhaar number is invalid",
              });
            }
            if (identityProof?.includes("pan card") && !panRegex.test(value)) {
              return ctx.createError({
                message: "PAN number is invalid",
              });
            }
            if (identityProof?.includes("voter") && !voterIdRegex.test(value)) {
              return ctx.createError({
                message: "Voter id is invalid",
              });
            }
            if (
              identityProof?.includes("passport") &&
              !passportRegex.test(value)
            ) {
              return ctx.createError({
                message: "Passport number is invalid",
              });
            }
            if (
              identityProof?.includes("driving") &&
              !drivingLicenseRegex.test(value)
            ) {
              return ctx.createError({
                message: "Driving license is invalid",
              });
            }
            return true;
          });
      } else if (value.field === "DROPDOWN") {
        const fieldValue =
          value.label.length > 30
            ? value.label.slice(0, 30) + "..."
            : value.label;
        validationRule = yup
          .mixed()
          .required(
            `${
              value.label === "Do You Have Any Previous Insurance Policy?"
                ? "Previous Insurance Policy"
                : fieldValue
            } is required`,
          );
      } else if (value.field === "MULTISELECT") {
        const fieldValue =
          value.label.length > 25
            ? value.label.slice(0, 30) + "..."
            : value.label;
        validationRule = yup.array().min(1, `${fieldValue} is required`);
      } else if (value.field === "DATE") {
        validationRule = yup
          .date()
          .typeError("Invalid Date!")
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .required(`${value.label} is required`)
          .test(`${value.label}-is-valid-date`, (value, ctx) => {
            if (new Date(value) < new Date("1900-01-01")) {
              return ctx.createError({
                message: "Date cannot be before 01/01/1900",
              });
            }
            return true;
          });
      } else if (value.field === "FILE") {
        validationRule = yup
          .string()
          .nullable()
          .required(`${value.label} is required`);
      } else {
        if (value?.key === "alternateId") {
          validationRule = yup.lazy(value => {
            if (value === undefined || value === null || value === "") {
              return yup.mixed().nullable();
            } else {
              return yup
                .string()
                .required(`${value.label} is required`)
                .matches(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u,
                  "Please enter valid Email",
                )
                .test(`${value?.label}-is-valid-email`, function (value, ctx) {
                  const { emailId } = this.parent;
                  if (emailId && value && emailId === value) {
                    return ctx.createError({
                      message: "Alternate Email Id cannot be same as Email Id",
                    });
                  }
                  if (
                    value &&
                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      value,
                    )
                  ) {
                    return false;
                  }
                  return true;
                });
            }
          });
        } else if (value.validation_group === "EMAIL") {
          validationRule = yup
            .string()
            .required(`${value.label} is required`)
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u,
              "Please enter valid Email",
            );
        } else if (value?.key === "alternateMobileno") {
          validationRule = yup.lazy(value => {
            if (value === undefined || value === null || value === "") {
              return yup.mixed().nullable();
            } else {
              return yup
                .string()
                .required(`${value.label} is required`)
                .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
                .min(10, "Please enter 10 digits")
                .max(10, "Please enter 10 digits")
                .test(
                  `${value?.label}-is-valid-mobile-number`,
                  function (value, ctx) {
                    const { mobileNo } = this.parent;
                    if (mobileNo && value && mobileNo === value) {
                      return ctx.createError({
                        message:
                          "Alternate Mobile No cannot be same as Mobile No",
                      });
                    }
                    if (value && !/^[6-9][0-9]{9}$/.test(value)) {
                      return false;
                    }
                    return true;
                  },
                );
            }
          });
        } else if (value.validation_group === "MOBILE") {
          validationRule = yup
            .string()
            .required(`${value.label} is required`)
            .min(10, "Please enter 10 digits")
            .max(10, "Please enter 10 digits")
            .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number");
        } else if (value.validation_group === "ADHAAR") {
          validationRule = yup
            .string()
            .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid");
        } else if (value.validation_group === "PAN") {
          validationRule = yup
            .string()
            .matches(
              /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
              "PAN number invalid",
            );
        } else if (value.validation_group === "PINCODE") {
          validationRule = yup
            .string()
            .nullable()
            .required(`${value.label} is required`)
            .min(6, "Must be 6 digits")
            .max(6, "Must be 6 digits")
            .matches(/^[0-9]+$/, "Must be only digits");
        } else if (value.field === "HIDDEN") {
          if (value.key === "customerId") {
            validationRule = yup.string().nullable().optional();
          }
        } else {
          validationRule = yup.string().required(`${value.label} is required`);
        }
      }
      acc[value.key] = validationRule;
      return acc;
    }, {}),
  );

  return yupValidate;
}

export function generateDynamicOptionalYupSchema(dynamicArray) {
  const yupValidate = yup.object().shape(
    dynamicArray?.reduce((acc, value) => {
      let validationRule = "";
      if (value.field === "DATE") {
        validationRule = yup.lazy(value => {
          if (value) {
            return yup
              .date()
              .typeError("Invalid Date!")
              .nullable()
              .transform((v, o) => (o === "" ? null : v))
              .required(`${value.label} is required`)
              .test(`${value.label}-is-valid-date`, (value, ctx) => {
                if (new Date(value) < new Date("1900-01-01")) {
                  return ctx.createError({
                    message: "Date cannot be before 01/01/1900",
                  });
                }
                return true;
              });
          }
          return yup.mixed().notRequired();
        });
      } else {
        if (value.validation_group === "EMAIL") {
          validationRule = yup.lazy(value => {
            if (value) {
              return yup
                .string()
                .required(`${value.label} is required`)
                .matches(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u,
                  "Please enter valid Email",
                );
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "MOBILE") {
          validationRule = yup.lazy(value => {
            if (value) {
              return yup
                .string()
                .required(`${value.label} is required`)
                .min(10, "Please enter 10 digits")
                .max(10, "Please enter 10 digits")
                .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number");
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "ADHAAR") {
          validationRule = yup.lazy(value => {
            if (value) {
              return yup
                .string()
                .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid");
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "PAN") {
          validationRule = yup.lazy(value => {
            if (value) {
              return yup
                .string()
                .matches(
                  /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                  "PAN number invalid",
                );
            }
            return yup.mixed().notRequired();
          });
        } else if (value.validation_group === "PINCODE") {
          validationRule = yup.lazy(val => {
            if (val) {
              if (value.key == "statetwo") {
                return yup.mixed().nullable();
              } else {
                return yup
                  .string()
                  .nullable()
                  .min(6, "Must be 6 digits")
                  .max(6, "Must be 6 digits")
                  .matches(/^[0-9]+$/, "Must be only digits");
              }
            }
            return yup.mixed().notRequired();
          });
        }
      }
      acc[value.key] = validationRule;
      return acc;
    }, {}),
  );

  return yupValidate;
}

export function generateInputFields({ labels, category }) {
  //generates dynamic proposal input fields
  const fields =
    labels?.length > 0
      ? labels?.map((item, index) => {
          const modifiedString = item.field_name
            .replace(/_/g, " ")
            .replace(/\b\w/g, char => char.toUpperCase());
          return {
            label: modifiedString,
            key: item?.field_slug,
            field: category?.[index]?.category,
            length: category?.[index]?.length,
            mandatory: category?.[index]?.mandatory,
          };
        })
      : [];

  return fields;
}
