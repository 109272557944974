import { Box, Tooltip } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import { Preview } from "@mui/icons-material";

const PaymentLogsCard = ({ theme, paymentLogData, paymentReminder }) => {
  const isValidDate = moment(
    paymentLogData?.paymentDate,
    ["DD-MM-YYYY", "D-M-YYYY"],
    true
  ).isValid();
  const formattedDate = isValidDate
    ? moment(paymentLogData?.paymentDate, ["DD-MM-YYYY", "D-M-YYYY"]).format(
        "DD-MM-YYYY"
      )
    : "- - -";

  const handleLinkToOpen = (link) => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <LogContainer paymentReminder={paymentReminder}>
      <LogWrapper paymentReminder={paymentReminder}>
        <PolicyDetails>
          <ContentWrapper>
            <Content header>Proposer Name:</Content>
            {paymentLogData?.proposerName?.length > 15 ? (
              <Tooltip title={paymentLogData?.proposerName} arrow>
                <Content>{paymentLogData?.proposerName || "- - -"}</Content>
              </Tooltip>
            ) : (
              <Content>{paymentLogData?.proposerName || "- - -"}</Content>
            )}
          </ContentWrapper>
          <ContentWrapper>
            <Content header>Mobile Number:</Content>
            <Content>{paymentLogData?.mobileNo || "- - -"}</Content>
          </ContentWrapper>
          <ContentWrapper>
            <Content header>Policy Number:</Content>
            <Content>{paymentLogData?.policyNumber || "- - -"}</Content>
          </ContentWrapper>
        </PolicyDetails>
        <PolicyDetails>
          <ContentWrapper>
            <Content header>LOB:</Content>
            <Content>{paymentLogData?.lobName || "- - -"}</Content>
          </ContentWrapper>
          <ContentWrapper>
            <Content header>Insurance Company:</Content>
            {paymentLogData?.icName?.length > 18 ? (
              <Tooltip title={paymentLogData?.icName} arrow>
                <Content>{paymentLogData?.icName || "- - -"}</Content>
              </Tooltip>
            ) : (
              <Content>{paymentLogData?.icName || "- - -"}</Content>
            )}
          </ContentWrapper>
          <ContentWrapper>
            <Content header>Payment Frequency:</Content>
            <Content>{paymentLogData?.paymentFrequency || "- - -"}</Content>
          </ContentWrapper>
        </PolicyDetails>
        <PolicyDetails>
          <ContentWrapper>
            <Content header>Payment Status:</Content>
            <Content>{paymentLogData?.paymentStatus || "- - -"}</Content>
          </ContentWrapper>
          <ContentWrapper>
            <Content header>Payment Date:</Content>
            <Content>{formattedDate}</Content>
          </ContentWrapper>
          <ContentWrapper>
            <Content header>Amount Paid:</Content>
            <Content>{paymentLogData?.amountPaid || "- - -"}</Content>
          </ContentWrapper>
        </PolicyDetails>
        <PolicyDetails>
          <ContentWrapper>
            <Content header>Remark:</Content>
            <Content>{paymentLogData?.remark || "- - -"}</Content>
          </ContentWrapper>
        </PolicyDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Header header>Payer Details</Header>
          {!!paymentLogData?.payerDetails?.length ? (
            Array.isArray(paymentLogData?.payerDetails) &&
            paymentLogData?.payerDetails?.map((item, idx) => (
              <Box
                key={idx}
                sx={{
                  border: "0.75px solid #e4e4e4",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Header>Payer {idx + 1}</Header>
                <PayerDetails>
                  <ContentWrapper>
                    <Content header>Payer Name:</Content>
                    <Content>{item?.payerName || "- - -"}</Content>
                  </ContentWrapper>
                  <ContentWrapper>
                    <Content header>Payment Mode:</Content>
                    <Content>{item?.paymentMode || "- - -"}</Content>
                  </ContentWrapper>
                  {item?.chequeNo && (
                    <ContentWrapper>
                      <Content header>Cheque No:</Content>
                      <Content>{item?.chequeNo || "- - -"}</Content>
                    </ContentWrapper>
                  )}
                  <ContentWrapper>
                    <Content header>Amount Paid:</Content>
                    <Content>{item?.amountPaid || "- - -"}</Content>
                  </ContentWrapper>
                </PayerDetails>
                <PayerDetails>
                  <ContentWrapper>
                    <Content header>Payment Receipt:</Content>
                    <Content
                      onClick={() => handleLinkToOpen(item?.paymentReceipt)}
                      link
                    >
                      <Tooltip
                        title={
                          item?.paymentReceipt
                            ? "View Payment Receipt"
                            : "Payment Receipt Not Available"
                        }
                        arrow
                        sx={{
                          marginTop: "-2px",
                          color: item?.paymentReceipt
                            ? theme?.primaryColor
                            : "grey",
                          cursor: item?.paymentReceipt ? "pointer" : "default",
                        }}
                      >
                        <Preview />
                      </Tooltip>
                    </Content>
                  </ContentWrapper>
                  <ContentWrapper>
                    <Content header>Payment Screenshot:</Content>
                    <Content
                      onClick={() => handleLinkToOpen(item?.paymentScreenshot)}
                      link
                    >
                      <Tooltip
                        title={
                          item?.paymentScreenshot
                            ? "View Payment Screenshot"
                            : "Payment Screenshot Not Available"
                        }
                        arrow
                        sx={{
                          marginTop: "-2px",
                          color: item?.paymentScreenshot
                            ? theme?.primaryColor
                            : "grey",
                          cursor: item?.paymentScreenshot
                            ? "pointer"
                            : "default",
                        }}
                      >
                        <Preview />
                      </Tooltip>
                    </Content>
                  </ContentWrapper>
                  <ContentWrapper>
                    <Content header>Payment Certificate:</Content>
                    <Content
                      onClick={() => handleLinkToOpen(item?.premiumCertificate)}
                      link
                    >
                      <Tooltip
                        title={
                          item?.premiumCertificate
                            ? "View Payment Certificate"
                            : "Payment Certificate Not Available"
                        }
                        arrow
                      >
                        <Preview
                          sx={{
                            marginTop: "-2px",
                            color: item?.premiumCertificate
                              ? theme?.primaryColor
                              : "grey",
                            cursor: item?.premiumCertificate
                              ? "pointer"
                              : "default",
                          }}
                        />
                      </Tooltip>
                    </Content>
                  </ContentWrapper>
                </PayerDetails>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                border: "0.75px solid #e4e4e4",
                borderRadius: "10px",
                padding: "10px",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              Payer Details Not Avaiable
            </Box>
          )}
        </Box>
      </LogWrapper>
    </LogContainer>
  );
};

export default PaymentLogsCard;

const LogContainer = styled.div`
  ${({ paymentReminder }) =>
    paymentReminder && `max-height: 500px; overflow-y: auto;`}
`;

const LogWrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 0.75px solid rgb(228, 228, 228);
  margin: ${({ paymentReminder }) => (paymentReminder ? "20px" : "15px 0 0 0")};
`;

const PolicyDetails = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex: 1;
`;

const Content = styled.span`
  font-size: 14px;
  ${({ header }) =>
    header
      ? `
      font-weight: 600;  
    `
      : `
      display: block; 
        max-width: 140px;
         font-weight: 500;
         margin-left: 3px;
         overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
  ${({ link }) => link && `cursor: pointer;`};
`;

const PayerDetails = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
  margin: 10px 0;
`;

const Header = styled.span`
  font-weight: 600;
  font-size: 14px;
  ${({ header }) =>
    header &&
    `background: rgb(175, 175, 175);
    padding: 5px;
    border-radius: 10px;
    text-align: center;`}
`;
