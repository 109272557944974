import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProposalCreateSummaryStyled } from "./policyEntryStyles";
import { useForm } from "react-hook-form";
import FieldListComponentForEndorsement from "./FieldListComponentForEndorsement";
import useProposalSummary from "./useProposalSummary";
import { useGetProposalValuesForEndorsement } from "./service";

const EndorsementAddWithPolicyLayout = () => {
  const location = useLocation();
  const lineofBusiness = new URLSearchParams(location.search).get("lob");
  const userIdEndorsement = new URLSearchParams(location.search).get(
    "TransactionId",
  );
  const {
    mutate,
    data: endorsementData,
    isError: endorsementError,
    isLoading: endorsementLoading,
  } = useGetProposalValuesForEndorsement();

  useEffect(() => {
    mutate({ lobId: lineofBusiness, userId: userIdEndorsement });
  }, []);
  const [members, setMembers] = useState({
    members: 1,
    payment: 1,
    employee: 1,
    nominee: 1,
    source: 1,
    addOnPremiums: 1,
    travelMember: 1,
  });

  const {
    // createFinalSubmit,
    updateFinalSubmit,
    // isCreateFinalSubmitLoading,
    isUpdateFinalSubmitLoading,
    allStepsCompleted,
    extraCardTitles,
    masterTableData,
    updateExistingData,
    generateMongoId,
    isProposalDataLoading,
    isCreateProposalDataLoading,
    isUpdateExistingProposalDataLoading,
    getModalData,
    getVersionData,
    getFuelData,
    manufacturerData,
    modelData,
    versionData,
    fuelData,
    editCard,
    setEditCard,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    current,
    setCurrent,
    isFirstView,
    setIsFirstView,
    mergedSchema,
    setMergedSchema,
    extraProposalCreate,
    mandatoryFields,
    getOptionKeyByValue,
    getPolicyKeyByValue,
    setCurrentCardIndex,
    getMap,
    buttonRef,
    checkPolicyPresent,
    isPolicyPresent,
    isPrevPolicyPresent,
    checkPrevPolicyPresent,
    fileFields,
    optionalFields,
    userId,
    setUserId,
    matchedObject,
    setSubmittedAt,
    lobId,
    dropdownFields,
    multiSelectFields,
    dateFields,
    proposalData,
    isLoading,
    isError,
  } = useProposalSummary();

  return (
    !endorsementError && (
      <ProposalCreateSummaryStyled>
        <FieldListComponentForEndorsement
          lobId={lobId}
          extraCardTitles={extraCardTitles}
          masterTableData={masterTableData}
          updateExistingData={updateExistingData}
          generateMongoId={generateMongoId}
          proposalData={proposalData}
          isProposalDataLoading={isProposalDataLoading}
          isLoading={endorsementLoading}
          isCreateProposalDataLoading={isCreateProposalDataLoading}
          isUpdateExistingProposalDataLoading={
            isUpdateExistingProposalDataLoading
          }
          getModalData={getModalData}
          getVersionData={getVersionData}
          getFuelData={getFuelData}
          manufacturerData={manufacturerData}
          modelData={modelData}
          versionData={versionData}
          fuelData={fuelData}
          editCard={editCard}
          setEditCard={setEditCard}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setCompleted={setCompleted}
          current={current}
          setCurrent={setCurrent}
          completed={completed}
          isFirstView={isFirstView}
          setIsFirstView={setIsFirstView}
          mergedSchema={mergedSchema}
          setMergedSchema={setMergedSchema}
          extraProposalCreate={extraProposalCreate}
          mandatoryFields={mandatoryFields}
          dropdownFields={dropdownFields}
          multiSelectFields={multiSelectFields}
          getOptionKeyByValue={getOptionKeyByValue}
          getPolicyKeyByValue={getPolicyKeyByValue}
          setCurrentCardIndex={setCurrentCardIndex}
          getMap={getMap}
          dateFields={dateFields}
          checkPolicyPresent={checkPolicyPresent}
          isPolicyPresent={isPolicyPresent}
          isPrevPolicyPresent={isPrevPolicyPresent}
          checkPrevPolicyPresent={checkPrevPolicyPresent}
          fileFields={fileFields}
          members={members}
          setMembers={setMembers}
          optionalFields={optionalFields}
          setUserId={setUserId}
          setSubmittedAt={setSubmittedAt}
          matchedObject={matchedObject}
          endorsementData={endorsementData?.data?.data}
          lineofBusiness={lineofBusiness}
        />
      </ProposalCreateSummaryStyled>
    )
  );
};

export default EndorsementAddWithPolicyLayout;
