import { Box, Tooltip, Typography } from "@mui/material";
import { Fragment } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RadioButton, RadioInput, RadioLabel, RadioWrapper } from "./toggleButtonStyle";
import { CustomLabel } from "./inputStyle";
import { useGetConditionalErrorMessage } from "./getConditionalErrorMessage";
import styled from "styled-components";
import { StyledValue } from "./proposalListingView";
// import { themeObj } from "../../../modules/theme/theme";
// Note : (color prop)
// By default the color is 'primary' and will default to 'primary'
// use 'opposite' where you want a mix of red and green.

const ToggleButton = ({
  isRequired,
  defaultValue,
  options = [],
  label,
  name,
  readOnly,
  color = "primary",
  disableWrap,
  register,
  errors,
  description,
  watch,
  proposalRead = false,
  modern,
  ...props
}) => {
//   const theme = themeObj;
  const { errorMessage } = useGetConditionalErrorMessage({
    errors,
    id: name,
  });

  const fieldValue = watch && watch(name);

  const summaryValue =
    proposalRead &&
    options?.filter(item => item.value === fieldValue)[0]?.label;
  return (
    <>
      <Box width="100%" id={name}>
        <CustomLabel htmlFor={name} title={label} modern={modern} theme={'theme'}>
          {label}
          {isRequired && <span className="required">*</span>}
          {description && (
            <Tooltip title={description} arrow placement="top">
              <InfoOutlinedIcon
                sx={{
                  fontSize: "12px",
                  marginLeft: "3px",
                  position: "relative",
                  top: "2px",
                }}
              />
            </Tooltip>
          )}
        </CustomLabel>
        {proposalRead ? (
          <StyledValue>{summaryValue || "- - -"}</StyledValue>
        ) : (
          <>
            <RadioWrapper color={color} disableWrap={disableWrap}>
              {options.map((option, index) => (
                <Fragment key={index}>
                  <RadioInput
                    type="radio"
                    color={color}
                    {...register(name)}
                    disabled={readOnly}
                    id={`${name}-${option.value}`}
                    value={option.value}
                    defaultChecked={defaultValue === option.value}
                    {...props}
                  />
                  <RadioLabel
                    length={options.length}
                    htmlFor={`${name}-${option.value}`}
                    sx={{
                      backgroundColor: readOnly && "#E2E2E2 !important",
                      cursor: readOnly ? "default" : "pointer",
                    }}
                  >
                    <RadioButton
                      index={index}
                      color={color}
                      className="radio-button"
                      id={`${name}_${option.code}`}
                    />
                    <Typography variant="body1" sx={{ fontSize: "12px" }}>
                      {option.label}
                    </Typography>
                  </RadioLabel>
                </Fragment>
              ))}
            </RadioWrapper>
            {name?.includes("[") && name?.includes("[")
              ? !!errorMessage && <Error>{errorMessage}</Error>
              : !!errors?.[name]?.message && (
                  <Error>{errors?.[name]?.message}</Error>
                )}
          </>
        )}
      </Box>
    </>
  );
};
export default ToggleButton;

export const Error = styled.p`
  margin-bottom: 0;
  margin-top: ${({ top }) => top || "0px"};
  margin-left: ${({ left }) => left || "0px"};
  /* font-size: ${({ theme: { fontSize } }) =>
    fontSize ? `calc(13px + ${fontSize - 92}%)` : "10px"}; */
  text-align: left;
  color: ${({ color }) => color || "red"};
  font-family: "Montserrat-Medium";
  font-weight: 500;
  bottom: ${({ bottom }) => bottom || "0px"};
  margin: 0;
`;
