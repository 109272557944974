import { Grid } from "@mui/material";
import { Button, FilePicker, Input } from "../../../components";
import {
  allowOnlyNumbers,
  preventSpecialAndNumbersInInput,
} from "../../../HelperFunctions/HelperFunctions";
import { useState } from "react";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { StyledButton } from "../styles";
import {
  useAddCustomerDocument,
  useGetDocumentDataForCustomer,
} from "../services";

const DocumentUploadForm = ({
  // onSubmit,
  handleSubmit,
  register,
  errors,
  control,
  setValue,
  watch,
  trigger,
  unregister,
  title,
  familyData,
  userFirstName,
  userLastName,
  userId,
  setOpenModal,
}) => {
  const [files, setFiles] = useState([]);
  const docTypeWatch = watch("document_type");

  const { data: docTypes } = useGetDocumentDataForCustomer();
  const { mutate: addCustomerDocument } = useAddCustomerDocument(setOpenModal);

  const documentType = docTypes?.data?.data?.map((item) => ({
    value: item?.documentName,
    label: item?.documentName,
    docId: item?.documentManagementConfigurationId,
  }));

  //appending user's name to 0th Index
  if (!familyData.some((item) => item.id === userId)) {
    familyData?.unshift({
      value: `${userFirstName} ${userLastName}`,
      label: `${userFirstName} ${userLastName}`,
      id: userId,
    });
  }

  const onSubmit = (formData) => {
    addCustomerDocument({
      documentManagementConfigId: formData?.document_type?.docId,
      documentName: formData?.document_name?.value,
      documentType:
        formData?.document_type?.label === "Others"
          ? formData?.document_description
          : formData?.document_type?.label,
      docPath: formData?.document?.[0]?.file?.path,
      data: formData?.document?.[0]?.base64String,
      customerId: formData?.document_name?.id,
      addDocumentNumber: formData?.docNumber,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <Grid container spacing={4} columns={12}> */}
      <Grid item xs={12} md={12}>
        <FilePicker
          name="document"
          label={"Document Upload Here"}
          control={control}
          files={files}
          setFiles={setFiles}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          unregister={unregister}
          isRequired={false}
          isReadOnly={title === "View"}
        />
      </Grid>
      <div style={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginBottom: "10px" }}
        >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Dropdown
              themeType="theme1"
              isRequired={true}
              id="document_name"
              name="document_name"
              label="Select Member Name"
              placeholder="Select Member Name"
              control={control}
              selectObj={familyData}
              isMulti={false}
              proposalRead={false}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Dropdown
              themeType="theme1"
              isRequired={true}
              label="Document Type"
              name="document_type"
              id="document_type"
              control={control}
              selectObj={documentType}
              placeholder="Select Document Type"
              // defaultValue={document_description}
              // onChange={(e) => preventSpecialAndNumbersInInput(e)}
              inputRef={register("document_type")}
              errors={errors}

              // disabled={title === "View"}
            />
          </Grid>
          {docTypeWatch?.docId && (
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Input
                themeType={"theme1"}
                label="Document Number"
                placeholder="Enter Document Number"
                name={`docNumber`}
                id={`docNumber`}
                onChange={(e) => allowOnlyNumbers(e)}
                autoComplete="none"
                defaultValue={""}
                inputRef={register(`docNumber`)}
                isRequired={true}
                errors={errors}
                watch={watch}
              />
            </Grid>
          )}
          {docTypeWatch?.label === "Others" && (
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Input
                themeType="theme1"
                isRequired={true}
                label="Document Description"
                name="document_description"
                id="document_description"
                maxLength="30"
                placeholder="Enter Document Description"
                autoComplete="none"
                // defaultValue={document_description}
                onChange={(e) => preventSpecialAndNumbersInInput(e)}
                required={false}
                inputRef={register("document_description")}
                errors={errors}
                disabled={title === "View"}
              />
            </Grid>
          )}
        </Grid>
      </div>

      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: title !== "View" ? "flex" : "none",
          justifyContent: "flex-end",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <StyledButton type="submit" variant="outlined">
          Submit
        </StyledButton>
      </Grid>
    </form>
  );
};

export default DocumentUploadForm;
