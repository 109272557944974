import React, { useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { saveData } from "../riskoMeterMember.slice";
import { Input } from "../../../components";
import { Grid } from "@mui/material";
import { useAddRiskometer, useRiskometer } from "../service";
import Swal from "sweetalert2";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  allowOnlyNumbers,
  preventFirstZero,
} from "../../../HelperFunctions/HelperFunctions";

const TermCurrentLiabilities = ({ handleSave, handleBack, activeTab }) => {
  const [selectedOptionLiabilities, setSelectedOptionLiabilities] = useState(
    "Short Term Liabilities"
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { coverageAmountPayload, allData, range } = useSelector(
    (state) => state.riskoMeterMember
  );

  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge)?.length > 1;

  const location = useLocation();
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to navigate to the dashboard?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DC004E",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, navigate!",
      cancelButtonText: "No, stay here",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
        window.location.reload();
      } else if (result.isDismissed) {
        handleSave();
      }
    });
  };

  const {
    mutateAsync: ageRiskometer,
    isLoading: customerAddLoading,
    data,
  } = useRiskometer(activeTab);

  const { mutate: addRisko } = useAddRiskometer();

  const onSubmit = (data) => {
    const payload = {
      questionId: 7,
      lobId: +lobId,
      request_data: {
        familyType: coverageAmountPayload?.request_data?.familyType,
        annualIncome: allData?.annualIncome,
        age: coverageAmountPayload?.request_data?.age,
        longTermLiabilities:
          selectedOptionLiabilities === "Long Term Liabilities"
            ? watch("longTermLiabilities")
            : undefined,
        shortTermLiabilities:
          selectedOptionLiabilities === "Short Term Liabilities"
            ? watch("shortTermLiabilities")
            : undefined,
      },
    };
    dispatch(
      saveData({
        Liabilities: {
          ...getValues(),
          selectedOptionLiabilities,
        },
      })
    );
    const rangeRating =
      range.reduce((acc, item) => acc + item, 0) / range.length;

    const AddRiskoPayload = {
      lobId: +lobId,
      ratings: rangeRating,
    };
    if (isSelfAgeOnly) {
      addRisko(AddRiskoPayload);
    }
    ageRiskometer(payload);
    if (isMoreThanOne) {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (allData?.Liabilities?.selectedOptionLiabilities) {
      setSelectedOptionLiabilities(
        allData?.Liabilities?.selectedOptionLiabilities
      );
    }
    if (allData?.Liabilities?.longTermLiabilities === "Long Term Liabilities") {
      setValue(
        "longTermLiabilities",
        allData?.Liabilities?.longTermLiabilities
      );
    } else {
      setValue(
        "shortTermLiabilities",
        allData?.Liabilities?.shortTermLiabilities
      );
    }
  }, [allData?.Liabilities?.selectedOptionLiabilities]);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    // Set color for maximum value label
    axis.renderer.labels.template.adapter.add("fill", (fill, target) => {
      if (target.dataItem && target.dataItem.value === 20) {
        return am4core.color("#FFF"); // Change color to black for max value
      }
      return fill;
    });

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={handleBack}>
            <ArrowBackIcon />
          </BackButton>
        </div>
        <Title>Liabilities</Title>
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "16px",
            }}
          >
            {["Long Term Liabilities", "Short Term Liabilities"].map(
              (option, index) => (
                <OptionContainer
                  key={index}
                  isSelected={selectedOptionLiabilities === option}
                  onClick={() => setSelectedOptionLiabilities(option)}
                >
                  <input
                    type="radio"
                    id={`option${index}`}
                    name="familyHistory"
                    className="mr-2"
                    style={{ display: "none" }}
                    onChange={() => setSelectedOptionLiabilities(option)}
                  />
                  <label
                    htmlFor={`option${index}`}
                    style={{ fontSize: "16px" }}
                  >
                    {option}
                  </label>
                </OptionContainer>
              )
            )}
          </div>

          {selectedOptionLiabilities && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ padding: "10px 20px" }}
              style={{
                marginTop: "20px",
                marginLeft: "-40px",
                width: "160%",
              }}
            >
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Input
                  themeType={"theme1"}
                  label={
                    selectedOptionLiabilities === "Long Term Liabilities"
                      ? "Long Term Liabilities"
                      : "Short Term Liabilities"
                  }
                  name={
                    selectedOptionLiabilities === "Long Term Liabilities"
                      ? "longTermLiabilities"
                      : "shortTermLiabilities"
                  }
                  id="annualIncome"
                  maxLength="100"
                  placeholder={`Enter Your ${selectedOptionLiabilities}`}
                  autoComplete="none"
                  onChange={(e) => [allowOnlyNumbers(e), preventFirstZero(e)]}
                  required
                  inputRef={register(
                    selectedOptionLiabilities === "Long Term Liabilities"
                      ? "longTermLiabilities"
                      : "shortTermLiabilities"
                  )}
                  errors={errors}
                />
              </Grid>
            </Grid>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "25px",
              justifyContent: "space-around",
            }}
          >
            <AddButton type="button" onClick={onSubmit}>
              Submit
            </AddButton>
            {isSelfAgeOnly && !isMoreThanOne && (
              <NavigateButton type="button" onClick={handleNavigate}>
                <ArrowBackIcon /> Navigate to Dashboard
              </NavigateButton>
            )}
          </div>
        </form>
      </Container>
      {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Riskometer Chart</DialogTitle>
        <DialogContent>
          <ChartContainer id="chartdiv" />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          <NavigateButton type="button" onClick={handleNavigate}>
            <ArrowBackIcon /> Navigate to Dashboard
          </NavigateButton>
          <AddButton onClick={() => setIsModalOpen(false)} color="primary">
            Close
          </AddButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermCurrentLiabilities;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
`;

const Container = styled.div`
  padding: 24px;
  width: 125vw;
  max-width: 800px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const OptionContainer = styled.div`
  border: ${({ isSelected, theme }) =>
    isSelected ? `1px solid ${theme?.ascentColor}` : "1px solid #e0e0e0"};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? `${theme.lightAscentColor}` : "white"};
  min-width: 240px;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  //   margin: 10px auto;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const NavigateButton = styled.button`
  gap: 5px;
  // margin: 10px auto;
  border: 1px solid ${({ theme }) => theme?.ascentColor};
  color: ${({ theme }) => theme?.ascentColor};
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  //   padding: 4px;

  &:hover {
    opacity: 0.8;
  }
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 256px;
`;
