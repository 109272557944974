import { useQueries, useQuery } from "@tanstack/react-query";
import HttpClient from "../../api/httpClient";
import { useDispatch } from "react-redux";
import { setFieldDataAction } from "./brokerage.slice";
import { useLocation } from "react-router-dom";

const useGetMasterTableData = (tableName) => {
	const location = useLocation();
	const fetchMenuMasterData = (tableName) => {
		let lobQueryParam = `${
			tableName?.lobId * 1 || tableName?.lobId * 1 === 0
				? `?lobId=${tableName?.lobId}`
				: tableName.masterSlug === "relation_master"
				? `?lobId=1`
				: ``
		}`;
		let apiPath = `${tableName?.masterSlug}/${tableName.fieldSlug}${lobQueryParam}`;
		return (
			location?.pathname !== "/customer-vehicle-lists" &&
			location?.pathname !== "/customer-add" &&
			HttpClient(`get_data/${apiPath}`).then((response) => response.data)
		);
	};

	const data = useQueries({
		queries: tableName.map((tableName) => {
			return {
				queryKey: [
					"master-table",
					tableName?.lobId
						? tableName?.masterSlug + tableName?.fieldSlug + tableName?.lobId
						: tableName?.masterSlug + tableName?.fieldSlug,
				],
				queryFn: () => fetchMenuMasterData(tableName),
			};
		}),
	});

	return { data };
};

export default useGetMasterTableData;

export const useGetBrokerageData = (master) => {
	const dispatch = useDispatch();
	const { data } = useQuery(
		["getBrokerageData", master],
		() => {
			let lobQueryParam = `${
				master?.lobId * 1 || master?.lobId * 1 === 0
					? `?lobId=${master?.lobId}`
					: ``
			}`;
			let apiPath = `${master?.slug}/${master?.value}${lobQueryParam}`;
			return HttpClient(`get_data/${apiPath}`);
		},
		{
			onSuccess: (data) => {
				dispatch(setFieldDataAction(data));
			},
		}
	);
	return { data };
};

export const useGetBundleBrokerageData = (master) => {
	const { data } = useQuery(["getBundleBrokerAgeData", master], () => {
		let lobQueryParam = `${
			master?.lobId * 1 || master?.lobId * 1 === 0
				? `?lobId=${master?.lobId}`
				: ``
		}`;
		let apiPath = `${master?.slug}/${master?.value}${lobQueryParam}`;
		return HttpClient(`get_data/${apiPath}`);
	});

	return { data };
};

// separate hook for policy entry (do not touch :)
export const useGetMasterTableDataFormWise = ({
	tableName,
	currentFormMasterSlugs,
	activeFormTitle,
}) => {
	const fetchMenuMasterData = (tableName) => {
		let lobQueryParam = `${
			tableName?.lobId * 1 || tableName?.lobId * 1 === 0
				? `?lobId=${tableName?.lobId}`
				: ``
		}`;
		// let apiPath = `${tableName?.masterSlug}/${tableName.fieldSlug}${lobQueryParam}`;
		let apiPath = `segregation/get_data/${tableName?.masterSlug}/${tableName?.fieldSlug}${lobQueryParam}`;
		return HttpClient(`${apiPath}`).then((response) => response.data);
	};

	const data = useQueries({
		queries: tableName.map((tableName) => {
			const isMasterSlugIncluded = currentFormMasterSlugs?.includes(
				tableName?.masterSlug
			);
			return {
				queryKey: [
					"master-table",
					tableName?.lobId
						? tableName?.masterSlug + tableName?.fieldSlug + tableName?.lobId
						: tableName?.masterSlug + tableName?.fieldSlug,
				],
				queryFn: () =>
					// isMasterSlugIncluded &&
					fetchMenuMasterData(tableName),
			};
		}),
	});
	return { data };
};

export const useGetCompaniesAccordingToLOB = (lob) => {
	const payload = {
		page: 0,
		size: 0,
		sortBy: "",
		sortOrder: "",
		searchValue: {
			category: "",
			companyName: "",
			lob: Array.isArray(lob) ? lob : [lob],
		},
	};
	const { data, isFetching } = useQuery({
		queryKey: ["companies", payload],
		queryFn: () =>
			HttpClient("common_master/company/list/Paging_Sorting", {
				method: "POST",
				data: payload,
			}),
		enabled: !!lob,
	});
	return { data, isFetching };
};
