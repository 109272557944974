import { createSlice } from "@reduxjs/toolkit";
// import { serializeError } from "../../utils";

const riskoMeterMember = createSlice({
  name: "riskoMeterMember",
  initialState: {
    riskoMeterMemberPayload: {},
    coverageAmountPayload: {},
    annualIncomePayload: {},
    range: [],
    allData: {},
    resData: {},
    selfUserData: [],
  },
  reducers: {
    setSelectedMembersAction: (state, { payload }) => {
      state.riskoMeterMemberPayload = payload;
    },
    resetRiskoMeterMemberPayload: (state) => {
      state.riskoMeterMemberPayload = {};
    },
    setCoverageAmountAction: (state, { payload }) => {
      state.coverageAmountPayload = payload;
    },
    setResAction: (state, { payload }) => {
      state.resData = { ...state.resData, ...payload };
    },
    saveData: (state, { payload }) => {
      state.allData = { ...state.allData, ...payload };
    },
    resetAllData: (state) => {
      state.allData = {};
    },
    setRange: (state, { payload }) => {
      const { index, range } = payload || {};
      const newRange = [...state.range];
      newRange[index] = range;
      state.range = [...newRange];
    },
    setAnnualIncomeAction: (state, { payload }) => {
      state.annualIncomePayload = payload;
    },
    removeLast: (state, { payload }) => {
      const range = [...state.range];
      range.pop();
      state.range = [...range];
    },
    setSelfUserDataAction: (state, { payload }) => {
      state.selfUserData = payload;
    },
  },
});

export const {
  setSelectedMembersAction,
  setCoverageAmountAction,
  setResAction,
  setAnnualIncomeAction,
  saveData,
  setRange,
  removeLast,
  resetAllData,
  resetRiskoMeterMemberPayload,
  setSelfUserDataAction,
} = riskoMeterMember.actions;
export default riskoMeterMember.reducer;
