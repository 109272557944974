import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../../../api/httpClient";
import Swal from "sweetalert2";

// Add Customer Document
const useAddCustomerDocument = (setOpenModal) => {
  const queryClient = useQueryClient();
  const { data, mutate, isError, isLoading, isFetching } = useMutation(
    (payload) => {
      return HttpClient("customer_document/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          Swal.fire("Added !", data?.message, "success");
          setOpenModal(false);
          queryClient.invalidateQueries(["customer_document/listing"]);
        } else {
          Swal.fire("Oops!", data?.message, "error");
        }
      },
    }
  );
  return { data, mutate, isError, isLoading, isFetching };
};

// Update Customer Document
const useUpdateCustomerDocument = () => {
  const queryClient = useQueryClient();
  const { data, mutate, isError, isLoading, isFetching } = useMutation(
    (payload) => {
      return HttpClient(`customer_document/update?id=${payload?.documentId}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          Swal.fire("Updated !!", data?.message, "success");
          queryClient.invalidateQueries(["customer_document/listing"]);
        } else {
          Swal.fire("Oops!", data?.message, "error");
        }
      },
    }
  );
  return { data, mutate, isError, isLoading, isFetching };
};

// Delete Customer Document
const useDeleteCustomerDocument = () => {
  const queryClient = useQueryClient();
  const { data, mutate, isError, isLoading, isFetching } = useMutation(
    (payload) =>
      HttpClient(`customer_document/delete?Id=${payload}`, {
        method: "DELETE",
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          Swal.fire("Deleted !!", data?.message, "success");
          queryClient.invalidateQueries(["customer_document/listing"]);
        } else {
          Swal.fire("Oops!", data?.message, "error");
        }
      },
    }
  );
  return { data, mutate, isError, isLoading, isFetching };
};

// Get Customer Document List
const useGetCustomerDocumentList = (payload) => {
  const { data, isError, isLoading, isFetching } = useQuery(
    ["customer_document/listing"],
    () => {
      return HttpClient("customer_document/listing", {
        method: "POST",
        data: payload,
      });
    }
  );
  return { data, isError, isLoading, isFetching };
};

// Get Customer Document List By Id
const useGetCustomerDocumentListById = (documentId) => {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ["customer_document/getById", documentId],
    queryFn: () =>
      HttpClient(`customer_document/getById?Id=${documentId}`, {
        method: "GET",
      }),
    enabled: !!documentId ? true : false,
  });
  return { data, isError, isLoading, isFetching };
};

const useGetDocumentDataForCustomer = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["doc_data"],
    queryFn: () =>
      HttpClient("document_management/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          documentName: "",
          documentCode: "",
          documentType: "",
        },
      }),
  });
  return { data, isFetching, refetch };
};

export {
  useAddCustomerDocument,
  useUpdateCustomerDocument,
  useDeleteCustomerDocument,
  useGetCustomerDocumentList,
  useGetCustomerDocumentListById,
  useGetDocumentDataForCustomer,
};
