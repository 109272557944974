import { useLocation } from "react-router-dom";
// import Dropdown from "../../../components/Dropdown/Dropdown";
import Dropdown from '../../components/Dropdown/Dropdown'
import {
  BIKE_LOB,
  CAR_LOB,
  COMPREHENSIVE_1_PLUS_1,
  COMPREHENSIVE_2_PLUS_2,
  COMPREHENSIVE_3_PLUS_3,
  COMPREHENSIVE_BUNDLED_1_PLUS_1,
  COMPREHENSIVE_BUNDLED_1_PLUS_3,
  COMPREHENSIVE_BUNDLED_1_PLUS_5,
  COMPREHENSIVE_BUNDLED_3_PLUS_3,
  COMPREHENSIVE_BUNDLED_5_PLUS_5,
  GCV_LOB,
  HEALTH_LOB,
  MARINE_LOB,
  NEW,
  OFFICE_PACKAGE_LOB,
  PCV_LOB,
  PROPERTY_LOB,
  PROPOSER_DETAILS,
  RENEWAL,
  RENEWAL_BREAK_IN,
  RENEWAL_ROLL_OVER,
  ROLL_OVER,
  SAOD,
  SHORT_TERM_3_MONTHS_OD_TP,
  SHORT_TERM_6_MONTHS_OD_TP,
  TERM_LOB,
  TP_1_YEAR,
  TP_LONG_TERM_3_YEARS,
  TP_LONG_TERM_5_YEARS,
} from "./proposalCreateConstants";
import { useSelector } from "react-redux";
import _ from "lodash";
import { sourcingUserTypeOptions } from "./multiLobLeadConstant";

function ProposalDropdown({
  id,
  label,
  control,
  setValue,
  masterTableData,
  isRequired,
  proposalRead,
  manufacturerData,
  modelData,
  versionData,
  branchData,
  icData,
  locationData,
  fuelData,
  userData,
  roleData,
  roleWiseUser,
  allCustomers,
  placeholder,
  type,
  filter,
  description,
  errors,
  watchMembers,
  watchNominee,
  watchSourcing,
  watchAddOnPremium,
  currentField,
  onChange,
  isClearable,
  policyType,
  typeOfCustomer,
  typeOfPropertyCustomer,
  lobSlug,
  isMulti,
  readOnly,
  addonData,
  sourceTypeData,
  userTypeDataFromSourceType,
  modeofTransportation,
  policyData,
  ClaimStatusListObj,
  ClaimSubStatusListObj,
  modeofTranspoObj,
  summaryData,
  modern,
  watch,
}) {
  // const basicDetails = useSelector(
  //   state => state.policyDetails.policyDetailsData,
  // );
  const location = useLocation();
  let masterTableField = masterTableData?.find(
    value => value?.data?.data?.[0]?.fieldSlug === filter,
  );

  let selectObj = [];

  switch (id) {
    case "fuelType":
      const fuelType_Obj = fuelData?.data?.map(value => ({
        label: value,
        value,
      }));

      selectObj = fuelType_Obj ? [...fuelType_Obj] : [];
      break;

    case "make":
      const makeType_Obj =
        Array.isArray(manufacturerData?.data) &&
        manufacturerData?.data?.map(value => ({
          label: value.manf_name,
          value: value.manf_name,
          id: value.manf_id,
        }));

      selectObj = makeType_Obj ? [...makeType_Obj] : [];
      break;

    case "model":
      const model_Obj = !_.isEmpty(watch("make"))
        ? modelData?.data?.map(value => ({
          label: value.model_name,
          value: value.model_name,
          id: value.model_id,
        }))
        : [];

      selectObj = model_Obj ? [...model_Obj] : [];
      break;

    case "version":
      const version_Obj = !_.isEmpty(watch("model"))
        ? versionData?.data?.map(value => ({
          label: value.version_name,
          value: value.version_name,
          id: value.version_id,
        }))
        : [];

      selectObj = version_Obj ? [...version_Obj] : [];
      break;

    case "branchNameic":
      const branch_Obj =
        Array.isArray(branchData?.data?.data) &&
        branchData?.data?.data?.map(value => ({
          label: value.branchName,
          value: value.branchName,
        }));

      selectObj = branch_Obj ? [...branch_Obj] : [];
      break;

    case "branchLocation":
      const location_Obj =
        Array.isArray(locationData) &&
        locationData?.map(value => ({
          label: `${value.city} - ${value.officeCode}`,
          value: value.brokerOfficeLocationRelationId,
          id: value.brokerOfficeLocationRelationId,
        }));

      selectObj = location_Obj ? [...location_Obj] : [];
      break;

    case "icName":
    case "insuranceCompanyname":
      const company_Obj =
        Array.isArray(icData?.data?.data) &&
        icData.data.data.map(value => {
          return {
            value: value.companyName,
            label: value.companyName,
            id: value.companyId,
          };
        });

      selectObj = company_Obj ? [...company_Obj] : [];
      break;

    default:
      selectObj = [];
  }

  if (
    userData?.data?.data?.length &&
    id?.includes("sourcing") &&
    !id?.includes("employeeName") &&
    !id?.includes("customerName") &&
    id?.includes("Name")
  ) {
    selectObj = userData?.data?.data?.map(value => {
      return {
        label: `${value?.first_name} ${value?.last_name}`,
        value: value?.user_id,
        id: value?.user_id,
      };
    });
  }

  if (
    roleData?.data?.data?.length &&
    id?.includes("sourcing") &&
    id?.includes("role")
  ) {
    selectObj = roleData?.data?.data?.map(value => {
      return {
        label: value?.role_name,
        value: value?.role_name,
        id: value?.roleid,
      };
    });
  }

  if (
    roleWiseUser?.data?.data?.length &&
    id?.includes("sourcing") &&
    id?.includes("employeeName")
  ) {
    selectObj = roleWiseUser?.data?.data?.map(value => {
      return {
        label: `${value?.first_name} ${value?.last_name}`,
        value: `${value?.first_name} ${value?.last_name}`,
        id: value?.user_id,
      };
    });
  }

  if (
    // userTypeDataFromSourceType?.data?.data?.userTypes?.length &&
    id?.includes("sourcing") &&
    id?.includes("userType")
  ) {
    // selectObj = userTypeDataFromSourceType?.data?.data?.userTypes?.map(
    //   value => {
    //     return {
    //       label: value?.userTypes,
    //       value: value?.userTypes,
    //       id: value?.leadSourceUserTypeId,
    //     };
    //   },
    // );

    selectObj = sourcingUserTypeOptions;
  }

  if (
    allCustomers?.length &&
    id?.includes("sourcing") &&
    id?.includes("customerName")
  ) {
    selectObj = allCustomers
      ?.filter(
        item =>
          !item?.value?.includes(summaryData?.[PROPOSER_DETAILS]?.firstName),
      )
      ?.map(value => {
        return {
          label: value?.value,
          value: value?.value,
          id: value?.id,
        };
      });
  }

  // if (id?.includes("sourcing") && id?.includes("sourceType")) {
  //   selectObj = sourceTypeData
  //     ?.filter(
  //       item =>
  //         item?.channelType ===
  //         watchSourcing?.[currentField?.index]?.channelType?.label,
  //     )
  //     ?.map(value => {
  //       return {
  //         label: value?.leadSource,
  //         value: value?.leadSource,
  //         id: value?.leadSourceMasterId,
  //       };
  //     });
  // }

  if (id === "planType") {
    if (typeOfCustomer !== "Company") {
      selectObj = masterTableField?.data?.data?.[0]?.data
        ?.filter(item => item?.optionValue !== "Annual Open ")
        ?.map(value => {
          return {
            label: value.optionValue,
            value: value.optionValue,
            id: value.optionKey,
          };
        });
    } else {
      selectObj = masterTableField?.data?.data?.[0]?.data?.map(value => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });
    }
  }

  if (id === "ownertenantGriha") {
    if (typeOfPropertyCustomer === "Business") {
      selectObj = masterTableField?.data?.data?.[0]?.data
        ?.filter(item => item?.optionValue !== "A Housing Society")
        ?.map(value => {
          return {
            label: value.optionValue,
            value: value.optionValue,
            id: value.optionKey,
          };
        });
    } else {
      selectObj = masterTableField?.data?.data?.[0]?.data?.map(value => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });
    }
  }

  if (id === "policyType") {
    const uniqueOptions = masterTableField?.data?.data?.[0]?.data?.reduce(
      (accumulator, currentValue) => {
        const existingOption = accumulator?.find(
          item => item?.optionValue === currentValue?.optionValue,
        );
        if (!existingOption) {
          accumulator?.push(currentValue);
        }
        return accumulator;
      },
      [],
    );

    selectObj = uniqueOptions?.map(value => {
      return {
        label: value?.optionValue,
        value: value?.optionValue,
        id: value?.optionKey,
      };
    });
  }

  let optionsRequired = [];
  if (lobSlug === CAR_LOB) {
    if (policyType === NEW) {
      optionsRequired = [
        TP_LONG_TERM_3_YEARS,
        COMPREHENSIVE_BUNDLED_3_PLUS_3,
        COMPREHENSIVE_BUNDLED_1_PLUS_3,
      ];
    } else if (policyType === RENEWAL) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else if (policyType === RENEWAL_ROLL_OVER) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else if (policyType === ROLL_OVER) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else if (policyType === RENEWAL_BREAK_IN) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else {
      optionsRequired = [];
    }
  }
  if (lobSlug === GCV_LOB) {
    if (policyType === NEW) {
      optionsRequired = [COMPREHENSIVE_BUNDLED_1_PLUS_3, TP_LONG_TERM_3_YEARS];
    } else if (policyType === RENEWAL) {
      optionsRequired = [COMPREHENSIVE_BUNDLED_1_PLUS_1];
    } else if (policyType === ROLL_OVER) {
      optionsRequired = [COMPREHENSIVE_BUNDLED_1_PLUS_1];
    } else {
      optionsRequired = [];
    }
  }
  if (lobSlug === PCV_LOB) {
    if (policyType === NEW) {
      optionsRequired = [COMPREHENSIVE_BUNDLED_1_PLUS_3, TP_LONG_TERM_3_YEARS];
    } else if (policyType === RENEWAL) {
      optionsRequired = [
        COMPREHENSIVE_BUNDLED_1_PLUS_1,
        SHORT_TERM_3_MONTHS_OD_TP,
        SHORT_TERM_6_MONTHS_OD_TP,
      ];
    } else if (policyType === ROLL_OVER) {
      optionsRequired = [
        COMPREHENSIVE_BUNDLED_1_PLUS_1,
        SHORT_TERM_3_MONTHS_OD_TP,
        SHORT_TERM_6_MONTHS_OD_TP,
      ];
    } else {
      optionsRequired = [];
    }
  }
  if (lobSlug === BIKE_LOB) {
    if (policyType === NEW) {
      optionsRequired = [
        TP_LONG_TERM_5_YEARS,
        COMPREHENSIVE_BUNDLED_1_PLUS_5,
        COMPREHENSIVE_BUNDLED_5_PLUS_5,
      ];
    } else if (policyType === RENEWAL) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else if (policyType === RENEWAL_ROLL_OVER) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else if (policyType === ROLL_OVER) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else if (policyType === RENEWAL_BREAK_IN) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else {
      optionsRequired = [];
    }
  }

  if (
    (id === "businessType" || id === "businessTypes") &&
    [BIKE_LOB, CAR_LOB, GCV_LOB, PCV_LOB].includes(lobSlug)
  ) {
    selectObj = masterTableField?.data?.data?.[0]?.data
      ?.reduce((accumulator, currentValue) => {
        const existingOption = accumulator?.find(
          item => item?.optionValue === currentValue?.optionValue,
        );
        if (!existingOption) {
          accumulator?.push(currentValue);
        }
        return accumulator;
      }, [])
      ?.filter(item => optionsRequired?.includes(item?.optionValue) && item)
      ?.map(value => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });
  }

  // if (id === "modeofTranspo" && lobSlug === "Marine") {
  //   selectObj =
  //     modeofTranspoObj &&
  //     Array.isArray(modeofTranspoObj) &&
  //     modeofTranspoObj?.map(item => {
  //       return {
  //         label: item.label,
  //         value: item.value,
  //       };
  //     });
  // }

  const hardCodedDropdowns = [
    "fuelType",
    "make",
    "model",
    "version",
    "branchNameic",
    "branchLocation",
    "icName",
    "insuranceCompanyname",
    "policyType",
    "planType",
    "ownertenantGriha",
    "addOnPremiums.[0].addons",
    "modeofTranspo",
  ];

  if (location.pathname === "create-claim") {
    hardCodedDropdowns.push("modeofTransportation");
  }

  if ([BIKE_LOB, CAR_LOB, GCV_LOB, PCV_LOB].includes(lobSlug)) {
    hardCodedDropdowns.push("businessType");
    hardCodedDropdowns.push("businessTypes");
  }
  if (watchSourcing?.length) {
    watchSourcing?.map((item, index) => {
      hardCodedDropdowns.push(`sourcing.[${index}].Name`);
      hardCodedDropdowns.push(`sourcing.[${index}].role`);
      hardCodedDropdowns.push(`sourcing.[${index}].employeeName`);
      hardCodedDropdowns.push(`sourcing.[${index}].customerName`);
      hardCodedDropdowns.push(`sourcing.[${index}].sourceType`);
      hardCodedDropdowns.push(`sourcing.[${index}].userType`);
    });
  }
  if (watchAddOnPremium?.length) {
    watchAddOnPremium?.map((item, index) => {
      hardCodedDropdowns.push(`addOnPremiums.[${index}].addons`);
    });
  }

  if (!hardCodedDropdowns.includes(id)) {
    selectObj = masterTableField?.data?.data?.[0]?.data?.map(value => {
      return {
        label: value.optionValue,
        value: value.optionValue,
        id: value.optionKey,
      };
    });
  }

  if (id === "manufactureMonth") {
    const monthsArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    selectObj = monthsArray.map(value => {
      return {
        label: value,
        value,
      };
    });
  }
  // if (id === "modeofTranspo" && lobSlug === MARINE_LOB) {
  //   const modes = basicDetails?.["Mode of Transportation"]?.split("|");
  //   selectObj = modes?.map(value => {
  //     return {
  //       label: value,
  //       value,
  //     };
  //   });
  //   // selectObj =
  //   //   modeofTransportation &&
  //   //   Array.isArray(modeofTransportation) &&
  //   //   modeofTransportation?.map(item => {
  //   //     return {
  //   //       label: item.label,
  //   //       value: item.value,
  //   //     };
  //   //   });
  // }
  const isSelfAlreadySelected =
    Array.isArray(watchMembers) &&
    watchMembers?.some(item => item?.relWithprop?.value === "Self");
  const selectObjWithoutSelf =
    isSelfAlreadySelected &&
    masterTableField?.data?.data?.[0]?.data
      ?.filter(item => item?.optionValue !== "Self")
      ?.map(value => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });

  const isTravelInsuredSelfAlreadySelected =
    Array.isArray(watchMembers) &&
    watchMembers?.some(
      item => item?.relationshipwithProposer?.value === "Self",
    );
  const selectObjctWithoutSelf =
    isTravelInsuredSelfAlreadySelected &&
    masterTableField?.data?.data?.[0]?.data
      ?.filter(item => item?.optionValue !== "Self")
      ?.map(value => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });

  const isSelfNomineeAlreadySelected =
    Array.isArray(watchNominee) &&
    watchNominee?.some(item => item?.nomineeRelation?.value === "Self");
  const selectObjectWithoutSelf =
    isSelfNomineeAlreadySelected &&
    masterTableField?.data?.data?.[0]?.data
      ?.filter(item => item?.optionValue !== "Self")
      ?.map(value => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });

  // const selectedEmployeeDescriptions =
  //   Array.isArray(watchEmployee) &&
  //   watchEmployee
  //     ?.map((item) => {
  //       return item?.descriptionofWorkdonebyemployee?.value;
  //     })
  //     ?.filter((item) => item);
  // const remainingEmployeeDescriptions =
  //   Array.isArray(selectObj) &&
  //   selectObj?.filter(
  //     (item) => !selectedEmployeeDescriptions?.includes(item?.value)
  //   );

  if (type === "CHAR") {
    selectObj = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ];
  }

  const members = policyData?.members;
  if (id === "relWithprop" && lobSlug === HEALTH_LOB) {
    const relWithProp = members?.map(item => {
      return {
        label: `${item?.relWithprop} - ${item?.insuredmemberName}`,
        value: `${item?.relWithprop} - ${item?.insuredmemberName}`,
      };
    });
    selectObj = relWithProp;
    // selectObj = relationWpObj
  }
  if (id === "insuredmemberName" && lobSlug === HEALTH_LOB) {
    const insuredName = members?.map(item => {
      return {
        label: item?.insuredmemberName,
        value: item?.insuredmemberName,
      };
    });
    selectObj = insuredName;
  }
  //Please check this, This is dubious and will always override API Object values even in claims.
  if (
    id === "relationWithlifeassured" &&
    lobSlug === TERM_LOB &&
    location.pathname.toLowerCase().includes("claim")
  ) {
    const relationWithlifeassured = [
      {
        label: policyData?.relationWithlifeassured,
        value: policyData?.relationWithlifeassured,
      },
    ];
    selectObj = relationWithlifeassured;
  }
  if (id === "claimStatus") {
    selectObj = ClaimStatusListObj;
  }
  if (id === "subStatus") {
    selectObj = ClaimSubStatusListObj;
  }
  if (
    (id === "modeofTranspo" || id === "modetransport") &&
    lobSlug === MARINE_LOB
  ) {
    selectObj = modeofTranspoObj?.map(item => {
      return {
        label: item?.label,
        value: item?.value,
      };
    });
  }

  if (
    // addonData?.data?.data?.length &&
    id?.includes("addOnPremiums") &&
    id?.includes("addons")
  ) {
    selectObj = addonData?.data?.data?.map(value => {
      return {
        label: value?.addonName,
        value: value?.addonName,
        id: value?.adddonid,
      };
    });
  }

  if (filter === "coverType" && lobSlug === PROPERTY_LOB) {
    selectObj = selectObj?.filter(item => item?.label !== "Stock");
  }

  if (filter === "customertype" && lobSlug === OFFICE_PACKAGE_LOB) {
    selectObj = selectObj?.filter(item => item?.label !== "A Housing Society");
  }

  return (
    <Dropdown
      id={id}
      label={label}
      control={control}
      setValue={setValue}
      selectObj={
        id.includes("relWithprop") && isSelfAlreadySelected
          ? selectObjWithoutSelf
          : id.includes("nomineeRelation") && isSelfNomineeAlreadySelected
            ? selectObjectWithoutSelf
            : id.includes("relationshipwithProposer") &&
              isTravelInsuredSelfAlreadySelected
              ? selectObjctWithoutSelf
              : // : id?.includes("descriptionofWorkdonebyemployee") &&
              //   selectedEmployeeDescriptions?.length
              // ? remainingEmployeeDescriptions
              selectObj
      }
      isRequired={isRequired}
      placeholder={placeholder}
      maxMenuHeight={200}
      proposalRead={proposalRead}
      description={description}
      errors={errors}
      onChange={onChange}
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={readOnly}
      closeMenuOnSelect={isMulti ? false : true}
      modern={modern}
    />
  );
}

export default ProposalDropdown;
