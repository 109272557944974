import { compact } from "lodash";
import moment from "moment";

export function formatPayloadForEndorsement(input, multiSelectFields = []) {
  const result = {};
  console.warn({ input });
  if (input) {
    for (const key in input) {
      // typeof null is Object and this was failing the condition.
      if (input[key] !== null && typeof input[key] === "object") {
        // To Check if the value is a Date
        if (Object.prototype.toString.call(input[key]) === "[object Date]") {
          result[key] = moment(input[key].toISOString()).format("YYYY-MM-DD");
        } else if (input[key] instanceof Array) {
          //  Multi Select Dropdowns
          if (multiSelectFields.includes(key)) {
            const multiDropDownStr = input[key]
              .map(item => item?.label || item?.value)
              .join("|");
            result[key] = multiDropDownStr;
          } else {
            result[key] = compact(input[key])?.map(value => {
              // recursion to handle nested objects, multi select array etc
              return formatPayloadForEndorsement(value, multiSelectFields);
            });
          }
        } else {
          // Normal label and value Objects are here.
          if (input[key]?.label || input[key]?.value) {
            result[key] = input[key]?.label || input[key]?.value;
          }
        }
      } else {
        result[key] = input[key] ?? "";
      }
    }
  }
  return result;
}
