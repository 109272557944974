import { isEmpty, isObject } from "lodash";
import moment from "moment";
import { isValidURL } from "../../../HelperFunctions/HelperFunctions";

export function checkDifference(difference, id, watch, PREMIUM_FIELDS = []) {
  if (isEmpty(id) || isEmpty(difference)) return;
  if (isObject(difference)) {
    if (
      id?.includes("members") ||
      id?.includes("nominee") ||
      id?.includes("addOnPremiums")
    ) {
      const splitName = id?.split("[")[0];
      if (
        difference.hasOwnProperty(splitName) &&
        difference[splitName]?.length
      ) {
        let matched = id.match(/\[(\d+)\]/);
        if (matched) {
          const memberData = difference[splitName][parseInt(matched[1])];
          if (memberData?.type === "CHANGED") {
            const memberFieldSlug = id.split(".")[1];
            // if (moment(memberData[memberFieldSlug]).isValid()) {
            //   return moment(memberData[memberFieldSlug]).format("DD-MM-YYYY");
            // }
            if (memberData.hasOwnProperty(memberFieldSlug)) {
              if (isValidDateFormat(memberData[memberFieldSlug])) {
                return moment(memberData[memberFieldSlug]).format("DD/MM/YYYY");
              }
              if (isValidURL(memberData[memberFieldSlug])) {
                return (
                  <a
                    style={{
                      color: "#ea1f44",
                      textDecoration: "none",
                    }}
                    href={memberData[memberFieldSlug]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`Old ${memberFieldSlug?.split("upload")[1]} Document`}{" "}
                  </a>
                );
              }
            }
            return memberData[memberFieldSlug];
          } else {
            return;
          }
        } else {
          return;
        }
      }
    } else if (difference.hasOwnProperty(id)) {
      const isValueSame =
        (PREMIUM_FIELDS.includes(id) && watch(id) == difference[id]) ||
        (["payFrequency"].includes(id) && watch(id)?.value == difference[id]);
      if (isValueSame) {
        return;
      } else {
        if (isValidDateFormat(difference[id])) {
          return moment(difference[id]).format("DD/MM/YYYY");
        }
        if (isValidURL(difference[id])) {
          return (
            <a
              style={{
                color: "#ea1f44",
                textDecoration: "none",
              }}
              href={difference[id]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Old ${id?.split("upload")[1]} Document`}{" "}
            </a>
          );
        }
      }

      return difference[id]?.toString();
    }
  }
}

export function isValidDateFormat(dateString) {
  const format = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
  if (dateString) {
    return new RegExp(format).test(dateString);
  }
  return false;
}
