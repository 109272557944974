import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetRiskoMeterMemberPayload,
  setSelectedMembersAction,
} from "./riskoMeterMember.slice";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import husband from "../../utils/husband.png";
import wife from "../../utils/mom.png";
import child from "../../utils/boy.png";
import daughter from "../../utils/girl.png";

import Mother from "../../utils/housewife.png";
import Father from "../../utils/old-man.png";
import Grandfather from "../../utils/grandfather.png";
import MotherInLaw from "../../utils/mother-in-law.png";
import FatherInLaw from "../../utils/father-in-law.png";
import Grandmother from "../../utils/grand-mother.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const FamilyMemberSelector = ({ handleSave }) => {
  const dispatch = useDispatch();
  const { riskoMeterMemberPayload, selfUserData } = useSelector(
    (state) => state.riskoMeterMember
  );

  const [selectedMembers, setSelectedMembers] = useState(["Self"]);
  const [sonCount, setSonCount] = useState(0);
  const [daughterCount, setDaughterCount] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [gender, setGender] = useState(selfUserData?.gender || "Female");

  const schema = Yup.object().shape({
    selectedMembers: Yup.array().min(1, "At least one member must be selected"),
  });

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (riskoMeterMemberPayload) {
      setGender(riskoMeterMemberPayload?.gender || "Male");
      setSelectedMembers(riskoMeterMemberPayload.selectedMembers || ["Self"]);
      setSonCount(riskoMeterMemberPayload.sonCount || 0);
      setDaughterCount(riskoMeterMemberPayload.daughterCount || 0);
    }
  }, [riskoMeterMemberPayload]);

  useEffect(() => {
    if (selfUserData) {
      setGender(selfUserData?.gender);
    }
  }, [selfUserData]);

  const toggleMember = (member) => {
    setSelectedMembers((prev = []) => {
      const isSelected = prev.includes(member);

      // if (member === "Self") return prev;

      if (member === "Son") {
        if (!isSelected) {
          setSonCount(1);
          return [...prev, member];
        }
      }

      if (member === "Daughter") {
        if (!isSelected) {
          setDaughterCount(1);
          return [...prev, member];
        }
      }

      return isSelected ? prev.filter((m) => m !== member) : [...prev, member];
    });
  };

  const handleGenderChange = (newGender) => {
    setGender(newGender);
    setSelectedMembers([]);
    setSonCount(0);
    setDaughterCount(0);
  };

  const onCounterChange = (member, increment) => {
    if (member === "Son") {
      setSonCount((prev) => Math.max(0, prev + increment));
      setSelectedMembers((prev) => {
        if (!prev.includes("Son")) return [...prev, "Son"];
        return prev;
      });
    }

    if (member === "Daughter") {
      setDaughterCount((prev) => Math.max(0, prev + increment));
      setSelectedMembers((prev) => {
        if (!prev.includes("Daughter")) return [...prev, "Daughter"];
        return prev;
      });
    }
  };

  const onSubmit = () => {
    if (selectedMembers.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please select at least one member!",
        confirmButtonText: "OK",
      });
      return;
    }
    const payload = {
      selectedMembers,
      sonCount,
      daughterCount,
      gender,
    };
    dispatch(setSelectedMembersAction(payload));
    handleSave();
  };

  const memberImages = {
    Self: gender === "Male" ? husband : wife,
    Husband: husband,
    Wife: wife,
    Son: child,
    Daughter: daughter,
    Father: Father,
    Mother: Mother,
    Grandfather: Grandfather,
    Grandmother: Grandmother,
    "Father-in-law": FatherInLaw,
    "Mother-in-law": MotherInLaw,
  };

  if (gender === "Male") {
    memberImages.Husband = husband;
  } else if (gender === "Female") {
    memberImages.Wife = wife;
  }

  return (
    <div
      style={{
        padding: "24px",
        position: "relative",
      }}
    >
      <BackButton
        onClick={() => {
          navigate("/");
        }}
      >
        <ArrowBackIcon />
      </BackButton>
      <Container>
        <ToggleSection>
          <ToggleButton
            selected={gender === "Male"}
            onClick={() => handleGenderChange("Male")}
          >
            Male
          </ToggleButton>
          <ToggleButton
            selected={gender === "Female"}
            onClick={() => handleGenderChange("Female")}
          >
            Female
          </ToggleButton>
        </ToggleSection>
        <Title>Select members you want to insure</Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {[
              "Self",
              gender === "Male" ? "Wife" : "Husband",
              "Son",
              "Daughter",
              "Father",
              "Mother",
            ].map((member) => (
              <MemberCard
                key={member}
                selected={selectedMembers.includes(member)}
                onClick={() => toggleMember(member)}
              >
                <Icon>
                  <img
                    src={memberImages[member]}
                    alt={member}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Icon>
                <MemberName>{member}</MemberName>
                {member === "Son" && selectedMembers.includes(member) && (
                  <CounterContainer>
                    <CounterButton
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onCounterChange("Son", -1);
                      }}
                    >
                      -
                    </CounterButton>
                    <CounterValue>{sonCount}</CounterValue>
                    <CounterButton
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onCounterChange("Son", 1);
                      }}
                    >
                      +
                    </CounterButton>
                  </CounterContainer>
                )}
                {member === "Daughter" && selectedMembers.includes(member) && (
                  <CounterContainer>
                    <CounterButton
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onCounterChange("Daughter", -1);
                      }}
                    >
                      -
                    </CounterButton>
                    <CounterValue>{daughterCount}</CounterValue>
                    <CounterButton
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onCounterChange("Daughter", 1);
                      }}
                    >
                      +
                    </CounterButton>
                  </CounterContainer>
                )}
              </MemberCard>
            ))}
            {showMore &&
              [
                "Grandfather",
                "Grandmother",
                "Father-in-law",
                "Mother-in-law",
              ].map((member) => (
                <MemberCard
                  key={member}
                  selected={selectedMembers.includes(member)}
                  onClick={() => toggleMember(member)}
                >
                  <Icon>
                    <img
                      src={memberImages[member]}
                      alt={member}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Icon>
                  <MemberName>{member}</MemberName>
                </MemberCard>
              ))}
          </Grid>
          {errors.selectedMembers && (
            <p style={{ color: "red" }}>{errors.selectedMembers.message}</p>
          )}
          <MoreMembers onClick={() => setShowMore((prev) => !prev)}>
            {showMore ? "Show less ▲" : "More members ▼"}
          </MoreMembers>
          <ContinueButton
            type="submit"
            onClick={() => {
              dispatch(resetRiskoMeterMemberPayload());
            }}
          >
            Continue <ArrowForwardIcon />
          </ContinueButton>
        </form>
      </Container>
    </div>
  );
};

export default FamilyMemberSelector;

const Container = styled.div`
  max-width: 800px;
  margin: 3rem auto;
  margin-top: -20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

const MemberCard = styled.div`
  border: ${({ selected, theme }) =>
    selected ? `1px solid ${theme?.ascentColor}` : "1px solid #e0e0e0"};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? `${theme.lightAscentColor}` : "white"};
  min-width: 240px;
`;

const Icon = styled.div`
  width: 50px;
  height: 50px;
  background-color: #f0f0f0;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 4px;
`;

const MemberName = styled.span`
  font-size: 16px;
  color: #333;
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const CounterButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background-color: #e0e0e0;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CounterValue = styled.span`
  margin: 0 8px;
  font-size: 16px;
`;

const MoreMembers = styled.div`
  text-align: center;
  margin-top: 16px;
  color: #2196f3;
  cursor: pointer;
`;

const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 24px;
  gap: 5px;
`;

const ToggleButton = styled.button`
  background-color: ${({ selected, theme }) =>
    selected ? theme?.ascentColor : "white"};
  color: ${({ selected, theme }) => (!selected ? theme?.ascentColor : "white")};
  border: 1px solid
    ${({ selected, theme }) =>
      !selected ? theme?.ascentColor : theme?.ascentColor};
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  min-width: 6rem;
`;

const ToggleSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;
