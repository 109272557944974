import React, { useEffect, useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Input } from "../../components";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import {
  allowOnlyNumbers,
  preventFirstZero,
} from "../../HelperFunctions/HelperFunctions";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import { useRiskometer } from "./service";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { saveData, setAnnualIncomeAction } from "./riskoMeterMember.slice";

const validationSchema = Yup.object().shape({
  annualIncome: Yup.number()
    .typeError("Annual Income must be a number")
    .required("Annual Income is required")
    .positive("Annual Income must be a positive number")
    .integer("Annual Income must be an integer"),
});

const RiskometerAnnualIncome = ({ handleSave, handleBack, activeTab }) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();

  const {
    coverageAmountPayload,
    annualIncomePayload,
    range,
    allData,
    selfUserData,
  } = useSelector((state) => state.riskoMeterMember);

  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge || {}).length > 1;

  const { mutateAsync: ageRiskometer, isLoading: customerAddLoading } =
    useRiskometer(activeTab);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");

  const onSubmit = (data) => {
    const payload = {
      questionId: 3,
      lobId: +lobId,
      request_data: {
        familyType: coverageAmountPayload?.request_data?.familyType,
        annualIncome: data?.annualIncome || "100000",
        age: coverageAmountPayload?.request_data?.age,
      },
    };
    dispatch(saveData({ annualIncome: watch("annualIncome") }));

    dispatch(setAnnualIncomeAction(payload));
    ageRiskometer(payload);
    handleSave();
  };

  const { theme } = useSelector(({ theme }) => theme);

  useEffect(() => {
    if (annualIncomePayload) {
      setValue("annualIncome", annualIncomePayload?.request_data?.annualIncome);
    }
  }, [annualIncomePayload]);

  useEffect(() => {
    if (selfUserData) {
      setValue("annualIncome", selfUserData?.annualInc);
    }
  }, [selfUserData]);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    // Set color for maximum value label
    axis.renderer.labels.template.adapter.add("fill", (fill, target) => {
      if (target.dataItem && target.dataItem.value === 20) {
        return am4core.color("#FFF"); // Change color to black for max value
      }
      return fill;
    });

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range?.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={handleBack}>
            <ArrowBackIcon />
          </BackButton>
        </div>
        <Title>Annual Income</Title>
        <Subtitle>What is your total household annual income?</Subtitle>
        <form className="space-y-4">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: "10px 20px" }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Input
                themeType={"theme1"}
                label={"Annual Income"}
                name={"annualIncome"}
                id={"annualIncome"}
                maxLength="100"
                placeholder={`Enter Your Household Annual Income`}
                autoComplete="none"
                defaultValue={""}
                required={true}
                onChange={(e) => [allowOnlyNumbers(e), preventFirstZero(e)]}
                inputRef={register("annualIncome")}
                watch={watch}
                numberToWord={true}
                errors={errors}
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <AddButton
              onClick={handleSubmit((data) => {
                onSubmit(data);
                handleSave();
              })}
            >
              Continue
            </AddButton>
          </div>
        </form>
      </Container>
      {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />}
    </div>
  );
};

export default RiskometerAnnualIncome;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
`;

const Container = styled.div`
  border-radius: 8px;
  padding: 24px;
  width: 125vw;
  max-width: 800px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #d0d0d0;
  }
`;

const Subtitle = styled.h3`
  text-align: center;
  font-size: 18px;
  padding-top: 30px;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin: 10px auto;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 256px;
`;
