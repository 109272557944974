import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useRiskometer } from "../service";
import { saveData } from "../riskoMeterMember.slice";
import Swal from "sweetalert2";

const TermLifestyleSurvey = ({ handleSave, handleBack, activeTab }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");

  const { coverageAmountPayload, resData, allData, range } = useSelector(
    (state) => state.riskoMeterMember
  );

  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge)?.length > 1;

  const location = useLocation();
  const navigate = useNavigate();
  const { getValues } = useForm();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");

  const values = getValues();
  const dispatch = useDispatch();

  const {
    mutateAsync: ageRiskometer,
    isLoading: customerAddLoading,
    data,
  } = useRiskometer(activeTab);

  const onSubmit = async () => {
    const payload = {
      questionId: 2,
      lobId: +lobId,
      request_data: {
        familyType: coverageAmountPayload?.request_data?.familyType,
        question: selectedOption,
        answer: selectedOption2,
      },
    };
    dispatch(
      saveData({
        lifeStyle: {
          ...values,
          selectedOption,
          selectedOption2,
        },
      })
    );
    const res = await ageRiskometer(payload);

    if (res?.success) {
      handleSave();
    }
  };

  const handleNavigate = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to navigate to the dashboard?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DC004E",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, navigate!",
      cancelButtonText: "No, stay here",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
        window.location.reload();
      } else {
        handleSave();
      }
    });
  };

  useEffect(() => {
    if (allData?.lifeStyle?.selectedOption) {
      setSelectedOption(allData?.lifeStyle?.selectedOption);
      setSelectedOption2(allData?.lifeStyle?.selectedOption2);
    }
  }, [allData?.lifeStyle?.selectedOption, allData?.lifeStyle?.selectedOption2]);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  const optionMap = {
    Diet: ["High fat", "Moderate", "Balanced"],
    AlcoholConsumption: ["Frequent", "Moderate", "Occasional"],
    Smoking: ["Regular", "Occasional"],
    TobaccoUse: ["Regular", "Occasional"],
    Exercise: ["Sedentary", "Occasional", "Regular"],
    NonSmoker: ["Non Smoker"],
    NonTobaccoUse: ["Non Tobacco User"],
  };

  const getOptionsBySelectedOption = () =>
    optionMap[selectedOption?.replace(" ", "")] || [selectedOption];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={handleBack}>
            <ArrowBackIcon />
          </BackButton>
        </div>
        <Title>Lifestyle</Title>
        <form className="space-y-4">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "16px",
            }}
          >
            {[
              "Diet",
              "Alcohol Consumption",
              "Smoking",
              "Non Smoker",
              "Tobacco Use",
              "Non Tobacco Use",
              "Exercise",
            ].map((option, index) => (
              <OptionContainer
                key={index}
                isSelected={selectedOption === option}
                onClick={() => setSelectedOption(option)}
              >
                <input
                  type="radio"
                  id={`option${index}`}
                  name="familyHistory"
                  className="mr-2"
                  style={{ display: "none" }}
                  onChange={() => setSelectedOption(option)}
                />
                <label htmlFor={`option${index}`} style={{ fontSize: "16px" }}>
                  {option}
                </label>
              </OptionContainer>
            ))}
          </div>
          {selectedOption && (
            <>
              <Subtitle>{`${selectedOption} Mode`}</Subtitle>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "16px",
                }}
              >
                {getOptionsBySelectedOption().map((option, index) => (
                  <OptionContainer
                    key={index}
                    isSelected={selectedOption2 === option}
                    onClick={() => setSelectedOption2(option)}
                  >
                    <input
                      type="radio"
                      id={`option2${index}`}
                      name="familyHistory"
                      className="mr-2"
                      style={{ display: "none" }}
                      onChange={() => setSelectedOption2(option)}
                    />
                    <label
                      htmlFor={`option2${index}`}
                      style={{ fontSize: "16px" }}
                    >
                      {option}
                    </label>
                  </OptionContainer>
                ))}
              </div>
            </>
          )}
          <div style={{ display: "flex", marginTop: "25px" }}>
            <AddButton type="button" onClick={onSubmit}>
              Continue
            </AddButton>
          </div>
        </form>
      </Container>
      {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />}
    </div>
  );
};

export default TermLifestyleSurvey;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
`;

const Container = styled.div`
  padding: 24px;
  width: 125vw;
  max-width: 800px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const Subtitle = styled.h3`
  text-align: center;
  font-size: 18px;
  padding-top: 30px;
`;

const OptionContainer = styled.div`
  border: ${({ isSelected, theme }) =>
    isSelected ? `1px solid ${theme?.ascentColor}` : "1px solid #e0e0e0"};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? `${theme.lightAscentColor}` : "white"};
  min-width: 240px;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin: 10px auto;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 256px;
`;
