import {
	Box,
	Tooltip,
	Typography,
	TextField as _TextField,
	InputLabel as _InputLabel,
	IconButton,
	alpha,
} from "@mui/material";
import { Fragment } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";
import { useGetConditionalErrorMessage } from "../../hooks/useGetConditionalErrorMessage";
// Note : (color prop)
// By default the color is 'primary' and will default to 'primary'
// use 'opposite' where you want a mix of red and green.

const ToggleButton = ({
	isRequired,
	defaultValue,
	options = [],
	label,
	name,
	readOnly,
	color = "primary",
	disableWrap,
	register,
	errors,
	description,
	labelSize,
	claim,
	setValue,
	...props
}) => {
	const { errorMessage } = useGetConditionalErrorMessage({
		errors,
		id: name,
	});

	const parentKey = name?.split(".").slice(0, -1).join(".");
	const childKey = name?.split(".").pop();

	return (
		<>
			<Box width="100%">
				<CustomLabel
					htmlFor={name}
					title={label}
					labelSize={labelSize}
					claim={claim}
				>
					{label}
					{isRequired && <span className="required">*</span>}
					{description && (
						<Tooltip
							title={description}
							arrow
							placement="top"
						>
							<InfoOutlinedIcon
								sx={{
									fontSize: "12px",
									marginLeft: "3px",
									position: "relative",
									top: "2px",
								}}
							/>
						</Tooltip>
					)}
				</CustomLabel>
				<RadioWrapper
					color={color}
					disableWrap={disableWrap}
				>
					{options.map((option, index) => (
						<Fragment key={index}>
							<RadioInput
								type="radio"
								color={color}
								{...register(name)}
								disabled={readOnly}
								id={`${name}-${option.value}`}
								value={option.value}
								defaultChecked={defaultValue === option.value}
								onChange={(e) => {
									setValue(`${name}`, e.target.value);
									if (
										childKey === "hospitalization" &&
										option.value === "Yes"
									) {
										setValue(`${parentKey}.nameofHospital`, "");
										setValue(`${parentKey}.durationinHospital`, "");
									}
								}}
								{...props}
							/>
							<RadioLabel
								length={options.length}
								htmlFor={`${name}-${option.value}`}
								style={{
									backgroundColor: readOnly && "#DBE9ED",
									cursor: "pointer",
								}}
							>
								<RadioButton
									index={index}
									color={color}
									className="radio-button"
									id={`${name}_${option.code}`}
								/>
								<Typography
									variant="body1"
									sx={{ fontSize: "12px" }}
								>
									{option.label}
								</Typography>
							</RadioLabel>
						</Fragment>
					))}
				</RadioWrapper>
				{!!errorMessage && <Error>{errorMessage}</Error>}
			</Box>
		</>
	);
};
export default ToggleButton;

export const Error = styled.p`
	margin-bottom: 0;
	margin-top: ${({ top }) => top || "0px"};
	margin-left: ${({ left }) => left || "0px"};
	font-size: ${({ theme: { fontSize } }) =>
		fontSize ? `calc(13px + ${fontSize - 92}%)` : "10px"};
	text-align: left;
	color: ${({ color }) => color || "red"};

	font-weight: 500;
	bottom: ${({ bottom }) => bottom || "0px"};
	margin: 0;
`;

export const InputLabel = styled(_InputLabel)(
	({ bordered, hasCustomLabel, removeBottomMargin }) => ({
		fontSize: "12px",
		marginBottom: bordered && !removeBottomMargin ? "5px" : 0,
		fontWeight: "500",
		position: "relative",
		display: "inline-block",
		"& .MuiInputLabel-asterisk": {
			color: "red",
			...(hasCustomLabel && {
				position: "absolute",
				top: "0",
				right: "12px",
			}),
		},
	})
);
export const CrossIconButton = styled(IconButton)({
	color: "#6E8185",
	padding: 0,
});

export const RadioLabel = styled("label")(({ length }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	minWidth: length > 3 && "100px",
	flex: length < 3 ? "1 1 0" : "unset",
	padding: "0 16px",
	//   border: "1px solid #E5E7EB",
	border: "1px solid rgb(169, 167, 167)",
	borderRadius: "5px",
	height: "33px",
	gap: "10px",
	//   transition: "all 0.3s ease",
}));
export const RadioInput = styled("input")(({ theme, color }) => ({
	cursor: "pointer",
	display: "none",
	"&:checked + label ": {
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
	},
	"&:checked + label > .radio-button": {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
	},
	...(color === "opposite" && {
		"&:checked + label:nth-of-type(1) > .radio-button ": {
			backgroundColor: "#26BFA6",
			borderColor: "#26BFA6",
		},
		"&:checked + label:nth-of-type(2) > .radio-button ": {
			backgroundColor: "#FF5744",
			borderColor: "#FF5744",
		},
		"&:checked + label:nth-of-type(1)": {
			backgroundColor: "#D9FFF2",
		},
		"&:checked + label:nth-of-type(2)": {
			backgroundColor: "#FFEEEC",
		},
	}),
}));
export const RadioWrapper = styled(Box)(({ disableWrap }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "10px",
	flexWrap: !disableWrap && "wrap",
}));
export const RadioButton = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "20px",
	height: "20px",
	borderRadius: "50%",
	//   border: `1px solid #E5E7EB`,
	border: "1px solid rgb(169, 167, 167)",
	//   transition: "all 0.3s ease",
	boxShadow: `0px 4px 4px 0px ${alpha("#E5E7EB", 0.25)}`,
	"&:after": {
		content: '""',
		width: "10px",
		height: "10px",
		borderRadius: "50%",
		backgroundColor: "white",
		display: "block",
	},
}));
export const CustomLabel = styled.label`
	color: ${({ theme, claim }) => `${theme?.primaryColor} !important`};
	padding-left: 1px;
	font-size: ${({ labelSize }) => labelSize || "18px"};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	display: inline-block;

	.required {
		color: red;
		margin-left: 3px;
	}
`;
